import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

const tailwindColors = fullConfig.theme.colors.charts;
const cloudColors = fullConfig.theme.colors.wordcloud;

const colorArray = Object.values(tailwindColors).map((values) => values);
const cloudColorArray = Object.values(cloudColors).map((values) => values);

let all_colors = [...colorArray];
let wordCloud_colors = [...cloudColorArray];

const body = {
    DEFAULT: fullConfig.theme.colors.body.DEFAULT,
    light: fullConfig.theme.colors.body.light,
    dark: fullConfig.theme.colors.body.dark,
};

const primary = {
    DEFAULT: fullConfig.theme.colors.primary.DEFAULT,
    light: fullConfig.theme.colors.primary.light,
    dark: fullConfig.theme.colors.primary.dark,
};

const colors = {
    all_colors,
    body,
    primary,
    wordCloud_colors
};

const options = {
    // deferred: {
    //     yOffset: '80%',
    //     delay: 100,
    // },
    // legend: {
    //     display: false,
    // },
    tooltip: {
        enabled: true,
        backgroundColor: body.light,
        titleAlign: 'center',
        titleColor: '#fbece9',
        bodyAlign: 'center',
        bodyColor: '#fbece9',
        borderColor: body.dark,
        borderWidth: 1,
    },
};

export default { colors, options };
