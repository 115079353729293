<template>
    <div :class="type == 'tag' ? 'flex flex-wrap' : ''">
        <div v-for="answer in answers" :key="answer.id" class="block my-3 text-sm">
            <label
                v-if="type == 'checkbox'"
                class="leading-6 cursor-pointer flex items-start mr-4 mb-2"
            >
                <input
                    class="opacity-0 absolute cursor-pointer peer"
                    type="checkbox"
                    :ref="question_id"
                    :name="answer.name"
                    :id="question_id"
                    @change="emitChange"
                    :value="answer.id"
                    :aria-label="answer.name"
                    :checked="preSelected ? preSelected.includes(answer.id.toString())  ? true : false : false "
                />
                <div
                    class="bg-white border rounded-md border-secondary flex flex-shrink-0 justify-center items-center mr-2 peer-checked:bg-primary peer-checked:border-primary"
                >
                    <svg
                        class="w-5 h-5 fill-current opacity-0 block text-white pointer-events-none"
                        version="1.1"
                        viewBox="0 0 17 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(-9 -11)" fill="#FFFFFF" fill-rule="nonzero">
                                <path
                                    d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <span class="text-base text-text-dark cursor-pointer">
                    {{ answer.name | capitalize }}
                </span>
            </label>
            <label v-if="type == 'tag'" class="leading-6">
                <input
                    class="hidden peer"
                    type="checkbox"
                    :ref="question_id"
                    :name="answer.name"
                    :value="answer.id"
                    :aria-label="answer.name"
                    @change="emitChange"
                    :checked="preSelected ? preSelected.includes(answer.id.toString())  ? true : false : false "
                />
                <span
                    class="cursor-pointer shadow-feature bg-white text-text-dark ease-in duration-100 m-1.5 px-4 py-2.5 font-semibold rounded-xl text-center hover:bg-primary hover:text-white peer-checked:bg-checked peer-checked:text-text-darker"
                >
                    {{ answer.name | capitalize }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        answers: {
            type: Array,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        question_id: {
            type: Number,
            default: null,
        },
        attribute: {
            type: Number,
            default: null,
        },
        preSelected: {
            type: Array,
            default: null
        }
    },
    mounted() {
        if (this.preSelected) {
            this.emitChange()
        }
    },
    methods: {
        emitChange() {
            let checks = this.$refs[this.question_id];
            let arr = [];

            checks.forEach((input) => {
                input.checked ? arr.push(input.name) : null;
            });

            this.$emit('change', {
                check: arr,
                id: this.question_id,
            });
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style></style>
