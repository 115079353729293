<template>
    <div v-if="display == 'accordion'" class="relative">
        <div
            class="flex justify-end items-center text-text-dark cursor-pointer absolute bottom-full right-0"
            v-if="open_all"
            @click="openAll"
        >
            {{ all_open ? 'Close All' : 'Open All' }}
            <div
                ref="open_all_arrow"
                class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mx-2"
            ></div>
        </div>
        <div :ref="attr.slug" v-for="attr in attrs" :key="attr.slug">
            <div
                class="bg-white text-text-dark border-body border rounded-md my-2 p-2 flex justify-between items-center duration-200 cursor-pointer"
                @click="toggleSection(attr.slug)"
            >
                {{ attr.name }}
                <div
                    class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2"
                ></div>
            </div>

            <div
                class="flex flex-wrap justify-center md:block overflow-hidden h-0 ml-2 border-l-2 border-secondary"
            >
                <div
                    :class="
                        child.children.length > 0
                            ? 'w-full'
                            : 'inline-flex items-center py-[2px] md:py-1'
                    "
                    v-for="child in attr.children"
                    :key="child.slug"
                >
                    <div v-if="child.children.length > 0" class="ml-2">
                        <anima-accordion
                            ref="answers"
                            :question_id="question_id"
                            :attrs="[child]"
                            :open_all="false"
                        ></anima-accordion>
                    </div>

                    <div v-else class="ml-1 my-3">
                        <label class="leading-6">
                            <input
                                class="hidden peer"
                                type="checkbox"
                                :ref="question_id"
                                :name="child.name"
                                :value="child.slug"
                                @change="emitChange(child.name)"
                                :aria-label="child.name"
                            />
                            <span
                                class="cursor-pointer shadow-feature bg-white text-text-dark ease-in duration-100 md:ml-2 px-4 py-2.5 font-bold rounded-xl text-center hover:bg-primary hover:text-white peer-checked:bg-checked peer-checked:text-text-darker"
                                >{{ child.name | capitalize }}</span
                            >
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="flex flex-wrap" :class="selected.length > 0 ? 'mb-2' : null">
            <div class="block my-3 text-sm" v-for="item in selected">
                <span
                    @click="toggleSelect(item)"
                    class="cursor-pointer shadow-feature bg-checked text-text-darker ease-in duration-100 md:ml-2 px-4 py-2.5 font-bold rounded-xl text-center hover:bg-primary hover:text-white"
                    >{{ item | capitalize }}
                    <svg-vue class="inline h-3 w-3" icon="close"></svg-vue>
                </span>
            </div>
        </div>
        <input
            class="w-full border border-secondary rounded-xl resize-none py-2 px-3 mb-5 text-text-dark text-base focus-visible:outline outline-primary-dark outline-2"
            type="text"
            v-model="search"
        />
        <div class="flex flex-wrap">
            <div
                class="block my-3 text-sm"
                v-for="item in filteredList.slice(0, 20)"
                v-show="search.length > 0"
            >
                <label class="leading-6">
                    <input
                        class="hidden"
                        type="checkbox"
                        :ref="question_id"
                        :name="item"
                        :value="item"
                        @click="toggleSelect(item)"
                        :aria-label="item"
                    />
                    <span
                        class="cursor-pointer shadow-feature bg-white text-text-dark ease-in duration-100 md:ml-2 px-4 py-2.5 font-bold rounded-xl text-center hover:bg-primary hover:text-white"
                        >{{ item | capitalize }}</span
                    >
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import animaCheckbox from './AnimaCheckbox.vue';
export default {
    name: 'animaAccordion',
    components: { animaCheckbox },
    data() {
        return {
            all_headers: [],
            all_open: false,
            search_list: [],
            search: '',
            selected: [],
        };
    },
    props: {
        attrs: {
            type: Array,
            default: null,
        },
        question_id: {
            type: Number,
            default: null,
        },
        open_all: {
            type: Boolean,
            default: true,
        },
        display: {
            type: String,
            default: 'accordion',
        },
    },
    methods: {
        openAll() {
            this.all_headers.forEach((element) => {
                let header_el = element.firstChild;
                let body_el = element.lastChild;

                if (!this.all_open) {
                    this.$refs['open_all_arrow'].classList.add('rotate-90');

                    body_el.classList.remove('h-0');
                    header_el.classList.add('bg-body');
                    header_el.lastChild.classList.add('rotate-90');
                } else {
                    this.$refs['open_all_arrow'].classList.remove('rotate-90');

                    body_el.classList.add('h-0');
                    header_el.classList.remove('bg-body');
                    header_el.lastChild.classList.remove('rotate-90');
                }
            });
            this.all_open = !this.all_open;
        },
        toggleSection(key) {
            let el = this.$refs[key][0].children[1];
            let arrow = this.$refs[key][0].firstChild.lastChild;
            if (el.classList.contains('h-0')) {
                el.classList.remove('h-0');
                this.$refs[key][0].firstChild.classList.add('bg-body');
                arrow.classList.add('rotate-90');
            } else {
                el.classList.add('h-0');
                this.$refs[key][0].firstChild.classList.remove('bg-body');
                arrow.classList.remove('rotate-90');
            }
        },
        getRefs(el, arr) {
            if (el.$refs['answers']) {
                el.$refs['answers'].forEach((element) => {
                    this.getRefs(element, arr);
                    element.$refs[this.question_id].forEach((element) => {
                        arr.push(element);
                    });
                });
            }
            return arr;
        },
        emitChange(value) {
            let checks = this.$refs[this.question_id];
            let arr = [];
            checks.forEach((input) => {
                input.checked ? arr.push(input.name) : null;
            });
            this.$emit('change', {
                check: arr,
                id: this.question_id,
            });
        },
        toggleSelect(c) {
            if (this.selected.includes(c)) {
                const index = this.selected.indexOf(c);
                this.selected.splice(index, 1);
            } else {
                this.selected.push(c);
            }
            this.$emit('change', {
                check: this.selected,
                id: this.question_id,
            });
        },
        searchLayer(element) {
            if (element.children.length > 0) {
                for (let j = 0; j < element.children.length; j++) {
                    const el = element.children[j];
                    this.searchLayer(el);
                }
            } else {
                this.search_list.push(element.name);
            }
        },
    },
    mounted() {
        if (this.display == 'search') {
            for (let i = 0; i < this.attrs.length; i++) {
                const element = this.attrs[i];
                this.searchLayer(element);
            }
            this.search_list.sort((a, b) => a.localeCompare(b));
        } else if (!this.$parent.$vnode.tag.includes('animaAccordion')) {
            const all = [];
            const all_headers = [];
            if (this.$refs['answers']) {
                this.$refs['answers'].forEach((element) => {
                    // this.getRefs(element, all);

                    for (const key in element.$refs) {
                        if (
                            key != this.question_id &&
                            key != 'answers' &&
                            key != 'open_all_arrow'
                        ) {
                            all_headers.push(element.$refs[key][0]);
                        }
                    }

                    element.$refs[this.question_id].forEach((element) => {
                        all.push(element);
                    });
                });
            }
            this.$refs[this.question_id].forEach((element) => {
                all.push(element);
            });

            for (const key in this.$refs) {
                if (key != this.question_id && key != 'answers' && key != 'open_all_arrow') {
                    all_headers.push(this.$refs[key][0]);
                }
            }

            this.$refs[this.question_id] = all;
            this.all_headers = all_headers;
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
    computed: {
        filteredList() {
            if (this.search_list.length > 0) {
                return this.search_list.filter((item) => {
                    return item.toLowerCase().match(this.search.toLowerCase());
                });
            }
        },
    },
};
</script>

<style></style>
