<template>
    <div class="z-10" :class="current_page == '/home' || current_page == '//' || current_page == '/' ? 'bg-[#55A3A5]' : ''">
        <div   
            class="md:px-14 flex items-center justify-between h-[50px] md:h-[100px] w-full max-w-[1440px] m-auto">
            <!-- logo -->
            <a href="/" aria-label="Anima Logo - Go To Homepage" class="pl-3 md:pl-0 block my-2 z-[501]">
                <svg-vue class="hidden md:inline h-14" icon="anima-logo" alt="anima logo"></svg-vue>
                <svg-vue class="inline md:hidden h-8" icon="anima-icon" alt="anima logo"></svg-vue>
            </a>

            <!-- menu items -->
            <div
                class=" rounded-bl-2xl md:rounded-none flex items-center h-full"
                :class="current_page == '/home' || current_page == '//' || current_page == '/' ? 'bg-[#55A3A5]' : 'bg-primary md:bg-body'"
            >
                <div
                    v-for="item in menu.items"
                    :key="item.id"
                    :class="current_page == item.url ? 'border-primary' : 
                            current_page == '/home' || current_page == '//' || current_page == '/' ? 'border-[#55A3A5]':'border-body'"
                    class="text-base p-2 hidden md:flex mx-[10px] lg:mx-[20px] text-text-dark border-b-4 items-center"
                >
                    <a
                        class="no-underline"
                        :class="'hover:text-text-light text-text-dark font-semibold'"
                        :href="item.url"
                        :target="item.target"
                        :aria-label="item.label"
                    >
                        {{ item.label }}
                    </a>
                </div>
            </div>

            <!-- account / register -->
            <div>
                <div class="hidden md:flex">
                    <a
                        v-if="!user"
                        href="/register"
                        aria-label="Register account"
                        class="cursor-pointer hidden md:flex h-full items-center no-underline"
                    >
                        <anima-btn long small>Register</anima-btn>
                    </a>
                    <div v-else>
                        <a
                            href="/account"
                            aria-label="Account Page"
                            class="flex justify-center items-center border-text-dark border-2 h-[64px] w-[64px] rounded-full overflow-hidden"
                            :class="user.profile_image ? '' : 'bg-text-dark'"
                        >
                            <svg-vue
                                v-if="!user.profile_image"
                                class="inline h-8"
                                icon="user"
                            ></svg-vue>
                            <img v-else :src="user.profile_image" alt="user profile picture" />
                        </a>
                    </div>
                </div>
            </div>

            <!-- mobile menu button -->
            <div class="flex md:hidden">
                <div
                    class="md:bg-body rounded-bl-2xl md:rounded-none h-[50px] md:h-[120px] flex items-center"
                >
                    <span @click="toggleMobileMenu" class="w-6 h-6 m-4">
                        <span class="h-1 mb-1 bg-text-dark rounded-full block" aria-hidden="true"></span>
                        <span class="h-1 mb-1 bg-text-dark rounded-full block" aria-hidden="true"></span>
                        <span class="h-1 bg-text-dark rounded-full block" aria-hidden="true"></span>
                        <span class="sr-only">Menu</span>
                    </span>
                </div>
            </div>
        </div>

        <!-- mobilemenu -->
        <div v-show="showMobileMenu" class="z-[500] absolute top-0 left-0 w-full h-full bg-secondary">
            <!-- close button -->
            <div @click="toggleMobileMenu">
                <svg-vue
                    :class="user ? 'top-[60px]' : null"
                    class="absolute top-0 right-0 text-text-dark hover:text-primary-800 text-lg leading-none h-6 w-6 m-4 flex justify-center items-center"
                    icon="close"
                ></svg-vue>
            </div>

            <div class="h-full flex flex-col items-center justify-center">
                <!-- menu items -->
                <div v-for="item in menu.items" :key="item.id">
                    <a
                        class="block text-lg my-6 text-text-dark font-semibold no-underline hover:text-primary-300"
                        :href="item.url"
                        :aria-label="item.label"
                        :target="item.target"
                    >
                        {{ item.label }}
                    </a>
                </div>

                <!-- account -->
                <div class="flex">
                    <a
                        v-if="!user"
                        href="/register"
                        aria-label="Register account"
                        class="cursor-pointer flex h-full items-center no-underline"
                    >
                        <anima-btn long small>Register</anima-btn>
                    </a>
                    <div v-else>
                        <a
                            href="/account"
                            aria-label="Account Page"
                            class="flex justify-center items-center border-text-dark border-2 h-[64px] w-[64px] rounded-full overflow-hidden"
                            :class="user.profile_image ? '' : 'bg-text-dark'"
                        >
                            <svg-vue
                                v-if="!user.profile_image"
                                class="inline h-8"
                                icon="user"
                            ></svg-vue>
                            <img v-else :src="user.profile_image" alt="User profile picture" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimaBtn from './AnimaBtn.vue';
export default {
    name: 'animaNav',
    components: { AnimaBtn },
    props: {
        current_page: { type: String, required: true },
        menu: { type: Object, required: true },
        user: { type: [Object, Boolean], required: true },
        token: { type: String, required: true },
    },
    data() {
        return {
            showMobileMenu: false,
        };
    },
    methods: {
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        }
    },
    watch: {
        showMobileMenu: function () {
            document.body.style.overflow = this.showMobileMenu ? 'hidden' : ''
        }
    }
};
</script>
