<template>
    <div class="h-[230px] lg:h-[320px] flex justify-center lg:m-3 lg:p-3 relative">
        <canvas :ref="id" :id="id"></canvas>
        <div 
            class="text-center absolute top-1/2 -translate-y-2/4 text-text-dark font-bold h-full -z-10"
            :class="maxHeadline != null && maxHeadline < 3 ? 'w-[35%]' : 'w-3/5'"
        >
            <div class="text-center flex h-1/2 justify-center items-end leading-none"
                :class="typeof data.average == 'string' ? data.average.length > 8 ? 'text-xl' : 'text-2xl' : 'text-3xl'"
            >
                {{ data.average }}
            </div>
            <div class="text-sm wrap-w pt-1">{{ title }}</div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js/auto';
import chartConfig from '../../chartConfig';
import ChartDeferred from 'chartjs-plugin-deferred';

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            label: 'Activities',
            chart: '',
        };
    },
    props: {
        id: { type: String, default: null },
        title: { type: String, default: null },
        data: { type: Object, default: null },
        maxHeadline: { type: Number, default: null }
    },
    mounted() {
        Chart.register(ChartDeferred);
        this.renderChart();

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        renderChart() {
            this.chart = new Chart(this.$refs[this.id], {
                type: 'doughnut',
                data: {
                    labels: this.data.labels,
                    datasets: [
                        {
                            backgroundColor: chartConfig.colors.all_colors,
                            data: this.data.data,
                            cutout: this.cutout,
                            hoverOffset: 15,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        ...chartConfig.options
                    },
                    layout: {
                        padding: 5,
                    },
                    responsive: true,
                    elements: {
                        arc: {
                            borderWidth: 0,
                        },
                    },
                },
            });
            Chart.defaults.color = '#1B3452';
        },
    },
    computed: {
        total() {
            return this.data.data.reduce((partialSum, a) => partialSum + a, 0);
        },
        cutout() {
            if (this.windowWidth < 768) {
                return '85%';
            } else {
                return '80%';
            }
        },
    },
    updated() {
        // destroy the chart
        this.chart.destroy();
        this.renderChart();
    },
    watch: {
        data: {
            handler() {
                this.$forceUpdate()
            },
            deep: true,
        },
    },
};
</script>

<style></style>
