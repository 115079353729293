<template>
    <form id="form" enctype="multipart/form-data">
        <div v-for="(question, key) in form" :key="question.id" class="my-4">
            <p class="block text-text-dark mb-1 text-base">{{ question.label | capitalize }}</p>

            <anima-input
                v-if="question.type === 'text'"
                :ref="key"
                type="text"
                :id="key"
                :name="key"
                :placeholder="question.placeholder"
                required
                :value="goal ? goal.name : null"
            >
                <template #error>
                    <div v-if="error_list && error_list[key]">
                        <span class="text-error" v-for="error in error_list[key]">{{error}}</span>
                    </div>
                </template>
            </anima-input>

            <animaOption
                v-if="question.type === 'select'"
                type="select"
                :options="question.options"
                :ref="key"
                :preSelected="goal ? goal[key] : null"
            >
                <template #error>
                    <div v-if="error_list && error_list[key]">
                        <span class="text-error" v-for="error in error_list[key]">{{error}}</span>
                    </div>
                </template>
            </animaOption>

            <AnimaMultiType 
                :ref="key" 
                :options="question.options" 
                v-if="question.type === 'multi select'"
                :preselect="goal ? goal[key] : null"
            ></AnimaMultiType>

            <AnimaTextArea :ref="key" v-if="question.type === 'textarea'" :preSelect="goal ? goal.notes : null"></AnimaTextArea>
        </div>

        <div class="mt-4 mb-11">
            <anima-button @click.prevent="sub" submit round long>
                {{goal ? 'Update' : 'Create'}}
            </anima-button>
        </div>
    </form>
</template>

<script>
    import animaInput from './../Forms/AnimaInput.vue';
    import AnimaTextArea from './../Forms/AnimaTextArea.vue';
    import animaOption from './../Forms/AnimaOption.vue';
    import animaButton from './../Elements/AnimaBtn.vue';
    import AnimaMultiType from '../Forms/AnimaMultiType.vue';

    export default {
        props: {
            url: { type: String, default: null },
            token: { type: String, default: null },
            options: { type: Object, required: true},
            goal: { type: Object, default: {}}
        },
        components: { animaInput, AnimaTextArea, animaOption, animaButton, AnimaMultiType },
        data() {
            return {
                error_list: [],
                form: {
                    name: {
                        label: 'Goal Name',
                        type: 'text',
                        placeholder: 'Goal Name'
                    },
                    // action: {
                    //     label: 'Action',
                    //     type: 'select',
                    //     options: this.options.action,
                    //     default: this.options.default_action
                    // },
                    frequency: {
                        label: 'Frequency',
                        type: 'select',
                        options: this.options.frequency,
                        default: this.options.default_frequency  
                    },
                    categories: {
                        label: 'Categories',
                        type: 'multi select',
                        options: this.options.categories
                    },
                    notes: {
                        label: 'Notes',
                        type: 'textarea'
                    },
                }
            }
        },
        methods: {
            sub() {
                let answers = {}
                for (const key in this.$refs) {
                    if (this.form[key].type == 'text') {
                        answers[key] = this.$refs[key][0].$refs['input'].value
                    } else if (this.form[key].type == 'select') {
                        answers[key] = this.$refs[key][0].selected
                    } else if (this.form[key].type == 'multi select') {
                        answers[key] = this.$refs[key][0].selected
                    } else if (this.form[key].type == 'textarea') {
                        answers[key] = this.$refs[key][0].value
                    }
                }

                fetch(this.url, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': this.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(answers),
                })
                .then(async (response) => {
                    if (response.ok) {
                        let title = 'Goal created!';
                        let message = `${answers.name} goal created successfully`;

                        if (Object.keys(this.goal).length > 0) {
                            title = 'Goal updated!';
                            message = `${answers.name} goal updated successfully`;
                        }

                        window.location.href = '/';
                        sessionStorage.setItem(
                            'notification',
                            JSON.stringify({
                                type: 'success',
                                messages: [
                                    {
                                        title,
                                        message,
                                    },
                                ],
                            })
                        );
                    }
                    let res = await response.json();

                    // @TODO: add error response
                    sessionStorage.setItem(
                        'notification',
                        JSON.stringify({
                            type: 'error',
                            messages: [
                                {
                                    title: res.error.title,
                                    message: res.error.message,
                                },
                            ],
                        })
                    );
                    res.error_list ? (this.error_list = res.error_list) : null;
                    eventHub.$emit('show');
                    this.$forceUpdate()
                })
                .catch(async (error) => {
                });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>