var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-6"},[_c('table',{staticClass:"table-fixed bg-white w-full border-collapse border border-slate-400 rounded-md overflow-hidden"},[_c('thead',{staticClass:"bg-primary text-primary-500"},[_c('tr',_vm._l((_vm.filterVisibleColumns(_vm.columns)),function(column,idx){return _c('th',_vm._g({key:idx,staticClass:"text-left p-4 font-semibold",class:{
                        'w-2/5': idx === 0,
                        'table__sortable js-table__sortable': column.sortable,
                        asc:
                            column.id === _vm.sort_state.column_id &&
                            _vm.sort_state.direction === 'asc',
                        desc:
                            column.id === _vm.sort_state.column_id &&
                            _vm.sort_state.direction === 'desc',
                    },attrs:{"data-column":column.id}},column.sortable ? { click: () => _vm.sortColumn(column.id, idx) } : {}),[_vm._v("\n                    "+_vm._s(_vm._f("capitalize")(column.title))+"\n                ")])}),0)]),_vm._v(" "),_c('tbody',{staticClass:"text-gray-500 bg-text"},_vm._l((_vm.rows),function(row,idx){return _c('tr',{key:idx},_vm._l((_vm.getRowObject(row, true)),function(value,column_id){return _c('td',{key:column_id,staticClass:"break-all text-left py-6 px-4",attrs:{"data-column":column_id}},[_vm._t(column_id,function(){return [_vm._v(_vm._s(value))]},{"row":_vm.getRowObject(row, false)})],2)}),0)}),0)]),_vm._v(" "),(_vm.pagination)?_c('anima-pagination',{attrs:{"pages":_vm.pages},model:{value:(_vm.active_page),callback:function ($$v) {_vm.active_page=$$v},expression:"active_page"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }