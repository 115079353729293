<template>
    <form id="form" enctype="multipart/form-data">
        <!-- name -->
        <div class="my-4">
            <anima-input
                ref="fname"
                type="text"
                id="First Name"
                name="fname"
                placeholder="First Name"
                required
                autofocus
                :value="details ? details.fname : ''"
            >
                <template #label>First Name</template>
                <template #error>
                    <span v-if="errors.name">
                        {{ errors.name[0] }}
                    </span>
                </template>
            </anima-input>

            <anima-input
                ref="lname"
                type="text"
                id="Last name"
                name="lname"
                placeholder="Last Name"
                required
                autofocus
                :value="details ? details.lname : ''"
            >
                <template #label>Last Name</template>
                <template #error>
                    <span v-if="errors.name">
                        {{ errors.name[0] }}
                    </span>
                </template>
            </anima-input>
        </div>

        <!-- email -->
        <div class="my-4">
            <anima-input
                ref="email"
                type="text"
                id="email"
                name="email"
                placeholder="E-mail"
                required
                autofocus
                :value="details ? details.email : ''"
            >
                <template #label>Email</template>
                <template #error>
                    <span v-if="errors.email">
                        {{ errors.email[0] }}
                    </span>
                </template>
            </anima-input>
        </div>

        <!-- country -->
        <div class="my-4">
            <animaOption
                @change="setValue($event, 'country')"
                type="select"
                :options="countries"
                ref="country"
                :pre-selected="details ? details.country : null"
            >
                <template #label>Country</template>
            </animaOption>
        </div>

        <!-- reason -->
        <div class="my-4">
            <animaOption
                @change="setValue($event, 'reason')"
                type="select"
                :options="['Question', 'Support', 'Complaint', 'Feedback', 'Sales', 'Other']"
                ref="reason"
            >
                <template #label>Reason</template>
            </animaOption>
        </div>

        <!-- message -->
        <div class="my-4">
            <p class="block text-text-dark text-base mb-1">Message</p>
            <AnimaTextArea @change="setValue($event, 'message')" ref="message"></AnimaTextArea>
        </div>

        <span class="text-error" v-if="error_list['g-recaptcha-response']">{{
            error_list['g-recaptcha-response'][0]
        }}</span>
        <slot name="google"></slot>
        <!-- buttons -->
        <div class="my-4">
            <anima-button @click.prevent="sub" submit round long>Send</anima-button>
        </div>
    </form>
</template>

<script>
import animaButton from '../Elements/AnimaBtn.vue';
import animaInput from './AnimaInput.vue';
import animaOption from './AnimaOption.vue';
import AnimaTextArea from './AnimaTextArea.vue';
import data from '../../countries.js';

export default {
    components: { animaButton, animaInput, animaOption, AnimaTextArea },
    props: {
        details: { type: [Object, String], default: null },
        url: { type: String, default: null },
        token: { type: String, default: null },
    },
    data() {
        return {
            errors: '',
            countries: [],
            error_list: [],
        };
    },
    mounted() {
        let self = this;

        // window.addEventListener('keyup', (e) => {
        //     e.key === 'Enter' ? self.submit() : null;
        // });

        window.addEventListener('beforeunload', () => {
            sessionStorage.removeItem('form_data');
        });

        this.countries = data.countries;
    },
    updated() {
        let form_data = JSON.parse(sessionStorage.getItem('form_data'));

        if (form_data) {
            this.$refs['email'].$refs['input'].value = form_data.email;
            if (form_data.name != null) {
                this.$refs['name'].$refs['input'].value = form_data.name;
            }
        }
    },
    methods: {
        setValue(e, ref) {
            ref == 'message' ? (this.$refs[ref].value = e.check) : (this.$refs[ref].value = e);
        },
        sub() {
            const form = document.getElementById('form');
            const submitter = document.querySelector('button[value=Send]');
            const formData = new FormData(form, submitter);

            formData.append('country', this.$refs['country'].value);
            formData.append('reason', this.$refs['reason'].value);
            formData.append('message', this.$refs['message'].value);
            fetch(this.url, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': this.token,
                },
                body: formData,
            })
                .then(async (response) => {
                    if (response.ok) {
                        sessionStorage.removeItem('form_data');
                        let title = 'Message Sent';
                        let message =
                            'Thank you for contacting us, we will respond as soon as possible';
                        window.location.href = '/';
                        sessionStorage.setItem(
                            'notification',
                            JSON.stringify({
                                type: 'success',
                                messages: [
                                    {
                                        title,
                                        message,
                                    },
                                ],
                            })
                        );
                    }
                    let res = await response.json();

                    sessionStorage.setItem(
                        'notification',
                        JSON.stringify({
                            type: 'error',
                            messages: [
                                {
                                    title: res.error.title,
                                    message: res.error.message,
                                },
                            ],
                        })
                    );
                    res.error_list ? (this.error_list = res.error_list) : null;
                    eventHub.$emit('show');
                    // return Promise.reject(response.json());
                })
                .catch(async (error) => {
                });
        },
    },
};
</script>
