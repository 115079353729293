<template>
    <div :class="smallView ? 'w-full md:w-3/4 lg:w-[100%]' + ' md:mx-auto mb-4 mx-auto' : 'w-full md:w-3/4 lg:w-[99.3%]' + ' md:mx-auto mb-4 mx-auto'">
        <div class="flex justify-end mr-16 mb-2 items-center">
            <p class="mr-2 mb-2">Change view</p>
            <font-awesome-icon @click="toggleSmallView" class="cursor-pointer" :icon="['fas', 'list-ul']" />
            <font-awesome-icon @click="toggleBigView" class="cursor-pointer ml-2" :icon="['fas', 'table-cells-large']" />
        </div>
        <div>
            <div v-if="paginatedExercises.length > 0" class="flex flex-wrap justify-start mx-4">
                <div v-for="(exercise, index) in paginatedExercises" :key="exercise.id" class="w-1/3">
                    <div v-if="smallView" class="p-4">
                        <anima-card :title="exercise.final_name" :img="image" type="exercise_mini" >
                            <!-- <template #text>
                            <p class="text-sm text-gray-700">{{ exercise.discipline }}</p>
                        </template>  -->
                        </anima-card>
                    </div>
                    <div v-else-if="bigView" class="p-4">
                        <anima-card :title="exercise.final_name" :img="image" type="exercise_full">
                            <template #text>
                                <p class="text-sm text-gray-700">{{ secondaryText }}</p>
                                <input type="text" class="mt-2 p-1 border border-gray-300 rounded w-full text-sm" placeholder="Campo de texto 1">
                                <input type="text" class="mt-1 p-1 border border-gray-300 rounded w-full text-sm" placeholder="Campo de texto 2">
                                <input type="text" class="mt-1 p-1 border border-gray-300 rounded w-full text-sm" placeholder="Campo de texto 3">
                                <input type="text" class="mt-1 p-1 border border-gray-300 rounded w-full text-sm" placeholder="Campo de texto 4">
                            </template>
                        </anima-card>
                    </div>
                </div>
            </div>
            <div v-else>
                No matching exercises
            </div>
        </div>
        <div v-if="totalPages > 1" class="flex justify-center mt-4">
            <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 rounded-l">Previous</button>
            <span class="px-4 py-2">{{ currentPage }} / {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 rounded-r">Next</button>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AnimaCard from '../Elements/AnimaCard.vue';

export default {
    props: {
        image: String,
        mainText: String,
        secondaryText: String,
        exercises: {
            type: [Object, Array],
            required: true
        },
        // current_filters: { type: [Object, Array], required: true }

    },
    components: {
        FontAwesomeIcon,
        AnimaCard
    },
    data() {
        return {
            smallView: true,
            bigView: false,
            currentPage: 1,
            itemsPerPageSmall: 6,
            itemsPerPageBig: 3,
            current_filters: {},
            filteredExercises: this.exercises
        };
    },
    mounted() {
        eventHub.$on('filter_update', (thing) => {
            this.current_filters = thing
            this.filter_Exercises()
            this.$forceUpdate()
        })
    },
    computed: {
        itemsPerPage() {
            return this.smallView ? this.itemsPerPageSmall : this.itemsPerPageBig;
        },
        totalPages() {
            return Math.ceil(this.exercises.length / this.itemsPerPage);
        },
        paginatedExercises() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredExercises.slice(start, end);
        }
    },
    methods: {
        filter_Exercises() {
            this.filteredExercises = this.exercises.filter((item) => {
                if (Object.values(this.current_filters).every(filter => filter === 'all')) {
                    // all filters are set to 'all', return all items
                    return true;
                } else {
                    // check all filters, and ensure every filter that is not 'all' matches the item
                    return Object.keys(this.current_filters).every(key => {
                        const filterValue = this.current_filters[key];
                        if (filterValue === 'all') {
                            return true;
                        }
                        const itemValue = item[this.slugify(key)];
                        if (itemValue != null) {
                            return itemValue.includes(filterValue);
                        }
                        return false;
                    });
                }
            });
        },
        slugify(str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
                str = str.toLowerCase(); // convert string to lowercase
                str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
                        .replace(/\s+/g, '_') // replace spaces with underscore
                        .replace(/-+/g, '_'); // remove consecutive underscore
                return str;
        },
        toggleSmallView() {
            this.smallView = true;
            this.bigView = false;
            this.currentPage = 1; 
        },
        toggleBigView() {
            this.smallView = false;
            this.bigView = true;
            this.currentPage = 1; 
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    },
};
</script>

<style>

</style>


