<template>

    <div class="flex flex-col items-center mb-5 mt-5">
        <div class="flex flex-wrap w-full mb-10 md:w-4/5 gap-1 justify-center">
            <div v-for="(info, index) in categories.featured" :key="index"
                class="flex flex-col cursor-pointer  justify-between items-center bg-card rounded-3xl shadow-feature m-2.5 md:m-5  no-underline w-2/5 md:w-auto"
                @click="selectCard(info)" 
                :class="{ 'bg-checked': selectedCard === info }" >
                <section class="shadow-checkin w-full">
                    <img 
                    :class="{ 'invert': selectedCard === info }"
                    src="https://static.thenounproject.com/png/2940539-200.png" width="250" height="90"
                        alt="About">

                </section>
                <section class="flex flex-col items-center w-full">
                    <p class="text-center text-base font-bold text-text-dark px-5 py-4"
                    :class="{ 'text-white': selectedCard === info }"
                    >
                        {{info}}
                    </p>
                </section>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: [],
                featuredlist: [],
                error: '',
                selectedCard: null
                
            }
        },
        props: {
            categories: {
                type: Object,
                default: null
            },
            
        },
        methods: {
            selectCard(info) {
                if (this.selectedCard  === info) {
                    this.selectedCard  = null
                    this.$emit('change', '');
                } else {
                    this.$emit('change', info);
                    this.selectedCard  = info;
                }
            }

        },
        

    }
</script>
