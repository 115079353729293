<template>
    <div class="mt-3 flex flex-col md:flex-row justify-between">
        <div class="w-full md:w-[48%] flex flex-wrap lg:flex-nowrap items-center justify-center py-2 rounded-xl bg-card-100 shadow-feature">
            <div class="w-1/12 lg:w-2/12 text-right">
                <button @click="changeDate('prev')">
                    <font-awesome-icon icon="angle-left" />
                </button>
            </div>
            <p class="w-9/12 md:w-7/12 lg:w-2/3 text-center px-4 text-text-dark font-bold">
                {{ displayDate }}
            </p>
            <div class="w-1/12 lg:w-2/12">
                <button v-if="!atCurrentDate" @click="changeDate('next')">
                    <font-awesome-icon icon="angle-right" />
                </button>
            </div>
        </div>

        <div class="w-full md:w-[48%] mt-3 md:mt-0 font-semibold">
            <anima-tabs @tab-change="tabChnage">
                <anima-tab name="1W" active> </anima-tab>
                <anima-tab name="4W"> </anima-tab>
                <anima-tab name="3M"> </anima-tab>
                <anima-tab name="1Y"> </anima-tab>
            </anima-tabs>
        </div>
    </div>
</template>

<script>
import AnimaTabs from './AnimaTabs.vue';
import AnimaTab from './AnimaTab.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        AnimaTabs,
        AnimaTab,
        FontAwesomeIcon,
    },
    data() {
        return {
            atCurrentDate: true,
            date_type: 'daily',
            dates: {
                start: '',
                end: '',
            },
            dateData: {
                year: new Date().getFullYear(),
                month: new Date().getMonth(),
                date: new Date().getDate(),
            },
            months: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        };
    },
    mounted() {
        this.dateInit();
    },
    methods: {
        /**
         * get the current date and sets the initial values for the date picker
         */
        dateInit() {
            let date = new Date();
            date.setDate(date.getDate() - 6);

            this.dates.start = date.toISOString().split('T')[0];
            this.dateData = {
                year: new Date().getFullYear(),
                month: new Date().getMonth(),
                date: new Date().getDate(),
            };

            this.dates.end = new Date().toISOString().split('T')[0];
        },
        /**
         * Event handler for the tab changing;
         * Gets the new date range and dates;
         * Then emits it back to the stats component.
         *
         * @param i: index of the tab
         */
        tabChnage(i) {
            i == 0 ? (this.date_type = 'daily') : 
                i == 1 ? (this.date_type = 'weekly') : 
                    i == 2 ? (this.date_type = 'quarterly') : 
                        (this.date_type = 'monthly');
            
            this.getDate();

            let enddate = new Date(this.dates.end);
            let now = new Date();

            if (new Date(now.getFullYear(), now.getMonth(), now.getDate()) <= enddate) {
                this.atCurrentDate = true;
            } else {
                this.atCurrentDate = false;
            }

            this.emitDate();
        },
        /**
         * Gets the new start and end dates for the date picker based on the date range selected.
         */
        getDate() {
            if (this.date_type == 'daily') {
                if (this.dateData.date == 1) {
                    this.dates.start = new Date(
                        this.dateData.year,
                        this.dateData.month,
                        this.dateData.date
                    )
                        .toISOString()
                        .split('T')[0];

                    let date = new Date(this.dates.start);
                    date.setDate(date.getDate() + 6);
                    this.dates.end = date.toISOString().split('T')[0];
                } else {
                    this.dates.end = new Date(
                        this.dateData.year,
                        this.dateData.month,
                        this.dateData.date
                    );

                    this.dates.end.setHours(12);

                    this.dates.end = new Date(this.dates.end).toISOString().split('T')[0];

                    let date = new Date(this.dates.end);
                    date.setDate(date.getDate() - 6);
                    this.dates.start = date.toISOString().split('T')[0];
                }
            } else if (this.date_type == 'weekly') {
                let date = new Date(this.dateData.year, this.dateData.month, this.dateData.date);
                let currentMon = this.getMonday(date);
                let d = new Date(currentMon);
                d.setDate(d.getDate() - 21);
                let weeksago = d.toISOString().split('T')[0];

                this.dates.start = weeksago;

                date.setHours(12);
                this.dates.end = date.toISOString().split('T')[0];
            } else if (this.date_type == 'quarterly') {
                let date = new Date(this.dateData.year, this.dateData.month, this.dateData.date);
                let d = new Date(this.dateData.year, this.dateData.month, this.dateData.date);
                d.setMonth(d.getMonth() - 3);
                let currentMon = this.getMonday(d);
                let weeksago = new Date(currentMon).toISOString().split('T')[0];

                this.dates.start = weeksago;

                date.setHours(12);
                this.dates.end = date.toISOString().split('T')[0];
            } else {
                let date = new Date(this.dateData.year, this.dateData.month, this.dateData.date);
                let currentMon = this.getMonday(date);
                let d = new Date(currentMon);
                d.setFullYear(d.getFullYear() - 1);
                let weeksago = d.toISOString().split('T')[0];

                this.dates.start = weeksago;

                date.setHours(12);
                this.dates.end = date.toISOString().split('T')[0];
            }
        },
        /**
         * Paginates through the dates and emits them back to the stats component.
         *
         * @param dir: the direction the date picker is going in; either 'prev' or 'next'
         */
        changeDate(dir) {
            let startdate = new Date(this.dates.start);
            let enddate = new Date(this.dates.end);

            if (dir == 'prev') {
                if (this.date_type == 'daily') {
                    startdate.setDate(startdate.getDate() - 7);
                    enddate.setDate(enddate.getDate() - 7);
                } else if (this.date_type == 'weekly') {
                    enddate.setDate(startdate.getDate() - 1);
                    enddate.setMonth(startdate.getMonth());
                    enddate.setFullYear(startdate.getFullYear());

                    let currentMon = this.getMonday(enddate);
                    let d = new Date(currentMon);
                    d.setDate(d.getDate() - 21);

                    startdate = d;
                } else if (this.date_type == 'monthly') {
                    startdate.setFullYear(startdate.getFullYear() - 1);
                    enddate.setFullYear(enddate.getFullYear() - 1);
                } else if (this.date_type == 'quarterly') {
                    if (startdate.getMonth == 0) {
                        enddate.setFullYear(startdate.getFullYear() - 1);
                    } else {
                        enddate.setFullYear(startdate.getFullYear());
                    }

                    if (startdate.getDate() == 1) {
                        enddate.setMonth(startdate.getMonth());
                        enddate.setDate(0);
                    } else {
                        enddate.setDate(startdate.getDate() - 1);
                        enddate.setMonth(startdate.getMonth());
                    }

                    let currentMon = this.getMonday(enddate);
                    let d = new Date(currentMon);
                    d.setMonth(d.getMonth() - 3);

                    startdate = d;
                    enddate.setHours(12);
                }
            } else {
                if (this.date_type == 'daily') {
                    startdate.setDate(startdate.getDate() + 7);
                    enddate.setDate(enddate.getDate() + 7);
                } else if (this.date_type == 'weekly') {
                    startdate.setDate(enddate.getDate() + 1);
                    startdate.setMonth(enddate.getMonth());
                    startdate.setFullYear(enddate.getFullYear());

                    if (new Date(enddate.setDate(startdate.getDate() + 27)) >= new Date()) {
                        enddate.setDate(new Date().getDate());
                        enddate.setMonth(new Date().getMonth());
                        enddate.setFullYear(new Date().getFullYear());
                        
                        startdate = new Date()
                        startdate.setDate(startdate.getDate() - 28);
                    }
                } else if (this.date_type == 'monthly') {
                    startdate.setFullYear(startdate.getFullYear() + 1);
                    enddate.setFullYear(enddate.getFullYear() + 1);

                    if (enddate > new Date()) {
                        enddate = new Date()
                        startdate = new Date()
                        startdate.setFullYear(startdate.getFullYear() - 1);
                    }

                } else if (this.date_type == 'quarterly') {
                    startdate.setDate(enddate.getDate() + 1);
                    startdate.setMonth(enddate.getMonth());
                    startdate.setFullYear(enddate.getFullYear());

                    if (new Date(enddate.setMonth(startdate.getMonth() + 3)) >= new Date()) {
                        enddate.setDate(new Date().getDate());
                        enddate.setMonth(new Date().getMonth());
                        enddate.setFullYear(new Date().getFullYear());

                        startdate = new Date()
                        startdate.setMonth(startdate.getMonth() - 3);
                    }
                }
            }

            this.dateData.date = enddate.getDate();
            this.dateData.month = enddate.getMonth();
            this.dateData.year = enddate.getFullYear();

            this.dates.start = startdate.toISOString().split('T')[0];
            enddate.setHours(12);
            this.dates.end = enddate.toISOString().split('T')[0];

            let now = new Date();

            if (new Date(now.getFullYear(), now.getMonth(), now.getDate()) <= enddate) {
                this.atCurrentDate = true;
            } else {
                this.atCurrentDate = false;
            }

            this.emitDate();
        },
        /**
         * Gets the previous Monday of the given date.
         *
         * @param d: A date
         * @returns The date of the previous Monday of the given date
         */
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff)).toISOString().split('T')[0];
        },
        /**
         * Emits the current date type and date ramge back to the stats component.
         */
        emitDate() {
            this.$emit('date-change', {
                dates: this.dates,
                range: this.date_type,
            });
        },
    },
    computed: {
        displayDate() {
            let start = new Date(this.dates.start);
            let end = new Date(this.dates.end);
            let startObj = {
                date: start.getDate(),
                month: start.getMonth(),
                year: start.getFullYear(),
            };
            let endObj = {
                date: end.getDate(),
                month: end.getMonth(),
                year: end.getFullYear(),
            };

            let startStr = `${startObj.date}`;
            let endStr = `${endObj.date} ${this.months[endObj.month]} ${endObj.year}`;

            if (startObj.year < endObj.year) {
                startStr = `${startObj.date} ${this.months[startObj.month]} ${startObj.year}`;
            } else if (startObj.month != endObj.month) {
                startStr = `${startObj.date} ${this.months[startObj.month]}`;
            }

            return `${startStr} - ${endStr}`;
        },
    },
};
</script>
