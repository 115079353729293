<template>
    <div class="mt-4 mb-12"> 
        <table class="w-3/5 m-auto text-center mt-3 rounded-md overflow-hidden">
            <thead class="border-2 border-primary">
                <tr class="bg-primary text-sm">
                    <th class="p-2">{{label}}</th>
                    <!-- <th class="p-2 w-1/2">Count</th> -->
                </tr>
            </thead>
            <tbody class="border-2 border-primary-200">
                <tr
                    v-for="(label, idx) in uniqueData" :key="data.label"
                    :class="idx % 2 != 0 ? 'bg-primary-200' : 'bg-primary-100'"
                >
                    <td class="text-sm p-1">{{ label }}</td>
                    <!-- <td class="text-sm p-1">{{ data.data[idx] }}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: { 
            data: { type: Object, default: null },
            label: { type: String, default: null },
        },
        computed: {
            uniqueData() {
                return this.data.labels.filter(this.onlyUnique);
            }
        },
        methods: {
            onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
        },
        mounted() {
            // this.data.labels.forEach((label, idx) => {
            //     let json_label = JSON.parse(label)
            //     console.log(json_label.join(' - '))
            //     if (typeof json_label == 'array') {
            //         this.data.labels[idx] = json_label.join(' - ')
            //     }
            // });
        }
    }
</script>