/**
 * File for any vue components that are 3rd party
 * This file is automatically required by app.js so just add any new 3rd party components here
 */
import Vue from 'vue';

import SvgVue from 'svg-vue';
import VueCookies from 'vue-cookies';
import VueTailwind from 'vue-tailwind';
import TDatepicker from 'vue-tailwind/dist/t-datepicker';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faCalendarDay,
    faCaretDown,
    faCaretUp,
    faCheck,
    faInfoCircle,
    faMessage,
    faRotate,
    faXmark,
    faCircleQuestion,
    faTableCellsLarge,
    faListUl,
    faStar,
    faFilter,
    faArrowDown,
    faCaretRight,
    faChevronRight,
    faChevronLeft,
    faLeftRight,
    faCaretLeft,
    faClockRotateLeft,
    faArrowRotateRight,
    faClock,
    faBullseye,
    faAnglesLeft,
    faAnglesRight,
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
    faSliders,
    faSearch,
    faCircle,
    faEllipsis
} from '@fortawesome/free-solid-svg-icons';

import { faCircle as faRcirle } from '@fortawesome/free-regular-svg-icons';

// import { faClock } from '@fortawesome/free-regular-svg-icons';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

/* add icons to the library */
library.add(
    faCheck,
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faCaretLeft,
    faChevronRight,
    faChevronLeft,
    faMessage,
    faRotate,
    faAngleRight,
    faAngleLeft,
    faArrowLeft,
    faArrowRight,
    faCalendarDay,
    faXmark,
    faStar,
    faInfoCircle,
    faCircleQuestion,
    faLeftRight,
    faTableCellsLarge,
    faListUl,
    faStar,
    faFilter,
    faArrowDown,
    faClockRotateLeft,
    faArrowRotateRight,
    faClock,
    faBullseye,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
    faSliders,
    faSearch,
    faCircle,
    faRcirle,
    faEllipsis
);

var VueTouch = require('vue-touch');

const settings = {
    // ...,
    't-datepicker': {
        component: TDatepicker,
        props: {
            fixedClasses: {
                navigator: 'flex',
                navigatorViewButton: 'flex items-center',
                navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
                navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
                navigatorLabel: 'flex items-center py-1',
                navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
                navigatorNextButtonIcon: 'h-6 w-6 inline-flex',

                inputWrapper: 'relative',
                viewGroup: 'inline-flex flex-wrap',
                view: 'w-64',
                calendarDaysWrapper: 'grid grid-cols-7',
                calendarHeaderWrapper: 'grid grid-cols-7',
                monthWrapper: 'grid grid-cols-4',
                yearWrapper: 'grid grid-cols-4',

                clearButton:
                    'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
                clearButtonIcon: 'fill-current h-3 w-3',
            },

            // fixedClasses: {...},
        },
    },
};

Vue.use(VueTailwind, settings);
Vue.use(VueTouch, { name: 'v-touch' });
Vue.use(SvgVue);
Vue.use(VueCookies);
Vue.use(FontAwesomeIcon);
