<template>
    <ul>
        <li v-for="(faq, idx) in faqs" :key="idx" class="bg-white text-text-dark rounded-md ">
            <p @click="toggleContent(idx)"
                :id="faq.slug"
                class="cursor-pointer bg-white text-text-dark border-body border rounded-md my-4 p-2 duration-200 ">
                <font-awesome-icon icon="circle-question" />
                {{ faq.title }}
            </p>
            <div v-show="openIndex === idx" class="pb-4">
                <div ref="content" :class="'content'">
                    <div v-for="content in faq.content" class=" px-8 ">
                        <div ref="text" class="text-justify" v-html="content.fields.content.content.html"></div>
                    </div>
                </div>
                <div v-if="showMore[idx]" @click="toggleMore(idx)" class="cursor-pointer inline-block py-2 px-8 italic underline text-blue-600">
                    {{readMore[idx] ? 'Read More' : 'Read Less'}}
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        components: { FontAwesomeIcon },
        props: {
            content: {
                type: Array,
                default: () => []
            },
            fromAnchor: { type: Boolean , default: false }
        },
        data() {
            return {
                faqs: [],
                openIndex: null,
                readMore: [],
                showMore: [false],
                firstload: true
            };
        },
        mounted() {
            this.faqs = this.content;

            this.faqs.forEach(item => {
                this.readMore.push(true)
            });

            let id = window.location.hash.split('#')[1]

            this.$nextTick(() => {
                if (this.fromAnchor && this.content.length > 0) {
                    if (id == this.content[0].slug) {
                        this.toggleContent(0)
                    }
                }
                this.firstload = false
            });
        },
        methods: {
            toggleContent(idx) {
                if (this.openIndex === idx) {
                    this.openIndex = null;
                } else {
                    this.openIndex = idx;

                    let el = this.$refs['content'][idx]
                    this.$nextTick(() => {
                        if (el.clientHeight < el.scrollHeight) {
                            this.showMore[idx] = true
                        }
                        this.$forceUpdate()
                    })
                }
            },
            toggleMore(idx) {
                if (this.$refs['content'][idx].classList.contains('content')) {
                    this.$refs['content'][idx].classList.remove('content')
                    this.readMore[idx] = false
                } else {
                    this.$refs['content'][idx].classList.add('content')
                    this.readMore[idx] = true
                }

                this.$forceUpdate()
            }
        }
    };
</script>

<style scoped>
    .content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .content p {
        display: contents;
    }

    .content p:after {
        content: "\A";
        white-space: pre;
    }
</style>