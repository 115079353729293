import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

// File for 3rd party vue components
require('./vendor');

// Enso components
import EnsoCarousel from 'enso-carousel';
import MainMenu from './enso/MainMenu.js';
import BlogIndex from './enso/blog/components/BlogIndex.vue';
import BlogPostList from './enso/blog/components/BlogPostList';

// Chart components
import barChart from './components/Charts/BarChart.vue';
import calendar from './components/Charts/Calendar.vue';
import doughnut from './components/Charts/Doughnut.vue';
import lineChart from './components/Charts/LineChart.vue';
import polarChart from './components/Charts/PolarChart.vue';
import stackedBarChart from './components/Charts/StackedBarChart.vue';
import tableChart from './components/Charts/TableChart.vue';
import chartContainer from './components/Charts/chartContainer.vue';

// Element components
import animaBody from './components/Elements/AnimaBody.vue';
import animaButton from './components/Elements/AnimaBtn.vue';
import animaCarousel from './components/Elements/AnimaCarousel.vue';
import animaConditional from './components/Elements/AnimaConditional.vue';
import animaCookie from './components/Elements/AnimaCookie.vue';
import animaDashboard from './components/Elements/AnimaDashboard.vue';
import animaExercise from './components/Elements/AnimaExercise.vue';
import animaLatest from './components/Elements/AnimaLatest.vue';
import animaList from './components/Elements/AnimaList.vue';
import AnimaModal from './components/Elements/AnimaModal.vue';
import animaNav from './components/Elements/AnimaNav.vue';
import animaNotification from './components/Elements/AnimaNotification.vue';
import animaPagination from './components/Elements/AnimaPagination.vue';
import animaResource from './components/Elements/AnimaResource.vue';
import animaSymptom from './components/Elements/AnimaSymptom.vue';
import animaTable from './components/Elements/AnimaTable.vue';
import animaToDo from './components/Elements/AnimaToDo.vue';
import animaAccount from './components/Elements/AnimaAccount.vue';
import animaCard from './components/Elements/AnimaCard.vue';
import AnimaActivityLog from './components/Elements/AnimaActivityLog.vue';
import AnimaPrivacySidebar from './components/Elements/AnimaPrivacySidebar.vue'
import AnimaTooltip from './components/Elements/AnimaTooltip.vue';

// Form components
import animaAccordion from './components/Forms/AnimaAccordion.vue';
import animaAuthForm from './components/Forms/AnimaAuthForm.vue';
import animaCheckbox from './components/Forms/AnimaCheckbox.vue';
import animaContact from './components/Forms/AnimaContact.vue';
import animaDate from './components/Forms/AnimaDate.vue';
import AnimaDateTime from './components/Forms/AnimaDateTime.vue';
import animaEmojiSlider from './components/Forms/AnimaEmojiSlider.vue';
import animaForm from './components/Forms/AnimaForm.vue';
import animaInput from './components/Forms/AnimaInput.vue';
import AnimaMapping from './components/Forms/AnimaMapping.vue';
import animaNumber from './components/Forms/AnimaNumber.vue';
import animaOption from './components/Forms/AnimaOption.vue';
import animaRadio from './components/Forms/AnimaRadio.vue';
import AnimaRelated from './components/Forms/AnimaRelated.vue';
import animaSlider from './components/Forms/AnimaSlider.vue';
import AnimaSymptom from './components/Forms/AnimaSymptom.vue';
import animaTextArea from './components/Forms/AnimaTextArea.vue';
import animaTextShort from './components/Forms/AnimaTextShort.vue';
import AnimaUpload from './components/Forms/AnimaUpload.vue';
import AnimaWysiwyg from './components/Forms/AnimaWysiwyg.vue';
import MappingSearch from './components/Forms/MappingSearch.vue';
import AnimaFeedbackForm from './components/Forms/AnimaFeedbackForm.vue';
import AnimaMultiType from './components/Forms/AnimaMultiType.vue';
import AnimaMeal from './components/Forms/AnimaMeal.vue'

// Stats components
import AnimaDateRange from './components/Stats/AnimaDateRange.vue';
import AnimaDetailsIndex from './components/Stats/AnimaDetailsIndex.vue';
import AnimaDownload from './components/Stats/AnimaDownload.vue';
import AnimaHeadline from './components/Stats/AnimaHeadline.vue';
import AnimaOther from './components/Stats/AnimaOther.vue';
import AnimaStats from './components/Stats/AnimaStats.vue';
import AnimaStatsIndex from './components/Stats/AnimaStatsIndex.vue';
import AnimaStatsList from './components/Stats/AnimaStatsList.vue';
import AnimaTab from './components/Stats/AnimaTab.vue';
import AnimaTabs from './components/Stats/AnimaTabs.vue';
import AnimaText from './components/Stats/AnimaText.vue';

// support components
import AnimaSupportIndex from './components/Support/AnimaSupportIndex.vue';
import ListQuestions from './components/Support/ListQuestions.vue'
import SearchCategory from './components/Support/SearchCategory.vue'

//exercises components
import CategoryExercise from './components/Exercise/CategoryExercise.vue'
import AnimaExerciseIndex from './components/Exercise/AnimaExerciseIndex.vue'

// goal components
import AnimaGoalForm from './components/Goals/AnimaGoalForm.vue';

window.eventHub = new Vue();

Vue.directive('click-outside', {
    bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
        if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
            // binding.value(); run the arg
        }
        };
        document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unbind: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
    }
});

const enso = {
    BlogIndex,
    BlogPostList,
    EnsoCarousel,
    MainMenu,
};

const elements = {
    animaButton,
    animaCarousel,
    animaConditional,
    animaCookie,
    animaDashboard,
    animaLatest,
    animaList,
    AnimaModal,
    animaNotification,
    animaPagination,
    animaResource,
    animaSymptom,
    animaTable,
    animaToDo,
    animaBody,
    animaExercise,
    animaNav,
    animaAccount,
    animaCard,
    AnimaActivityLog,
    AnimaPrivacySidebar,
    AnimaTooltip
};

const charts = {
    barChart,
    stackedBarChart,
    calendar,
    chartContainer,
    doughnut,
    lineChart,
    polarChart,
    tableChart
};

const forms = {
    animaAccordion,
    animaAuthForm,
    animaCheckbox,
    animaContact,
    animaDate,
    animaEmojiSlider,
    animaForm,
    animaInput,
    AnimaMapping,
    animaNumber,
    animaOption,
    animaRadio,
    animaSlider,
    AnimaSymptom,
    animaTextArea,
    animaTextShort,
    AnimaUpload,
    AnimaWysiwyg,
    AnimaDateTime,
    AnimaRelated,
    MappingSearch,
    AnimaFeedbackForm,
    AnimaMultiType,
    AnimaMeal
};

const stats = {
    AnimaDateRange,
    AnimaDetailsIndex,
    AnimaDownload,
    AnimaHeadline,
    AnimaOther,
    AnimaStats,
    AnimaStatsIndex,
    AnimaStatsList,
    AnimaTab,
    AnimaTabs,
    AnimaText,
};

const support = {
    AnimaSupportIndex,
    ListQuestions,
    SearchCategory,
};

const exercise = {
    AnimaExerciseIndex,
    CategoryExercise,
}

const goals = {
    AnimaGoalForm
}

import SiteSearch from './components/sitesearch.vue'

new Vue({
    el: '#app',
    components: {
        ...enso,
        ...elements,
        ...charts,
        ...forms,
        ...stats,
        ...support,
        ...exercise,
        ...goals,
        FontAwesomeIcon,
        SiteSearch
    },
    mounted() {
        window.addEventListener('keyup', function(ev) {
            if (ev.code == 'KeyS' && ev.ctrlKey) {
                eventHub.$emit('showSearch');
            }

            if (ev.key == 'Escape') {
                eventHub.$emit('hideSearch');
            }
        });
    }
});


window.expiredCallbackFunction = () => {
    grecaptcha.reset();
};
window.errorCallbackFunction = () => {
    grecaptcha.reset();
};

function locationHashChanged() {
    if(window.location.pathname === "/privacy-policy") {
        if (window.innerWidth < 815) {
            window.scrollTo(window.scrollX, window.scrollY - 40);
        } 
    }
}

window.onhashchange = locationHashChanged;

// (navigator.userAgent.includes('Mac'))
// (navigator.userAgent.includes('Android'))
if (navigator.userAgentData.platform.indexOf("Win32")!=-1 
    || navigator.userAgentData.platform.indexOf("Win64")!=-1){ /* IF it is windows*/
    var css = document.createElement('link');
    css.rel="stylesheet";
    css.type = 'text/css';    
    css.href = 'css/winCSS.css';
    document.getElementsByTagName("head")[0].appendChild(css);
}
