<template>
    <div>
        <div class="w-5/12">
            <anima-btn @click="generateReport">Download Report</anima-btn>
        </div>
        <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="3100"
            filename="Health Summary Report"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section
                slot="pdf-content"
                class="text-pdf_base mb-32 pt-8 px-8 w-full text-center text-text-dark flex flex-col items-center justify-center"
            >
                <h1 class="text-pdf_2xl mx-5 font-bold">Health Summary Report</h1>

                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, unde odio
                    maxime officia ut ducimus obcaecati culpa esse consectetur pariatur nemo quas?
                    Corrupti amet optio inventore! Sequi quas cumque et.
                </p>

                <section class="mt-6">
                    <h2 class="text-pdf_xl font-bold">Health Check Scores</h2>

                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, unde
                        odio maxime officia ut ducimus obcaecati culpa esse consectetur pariatur
                        nemo quas? Corrupti amet optio inventore! Sequi quas cumque et.
                    </p>

                    <div>
                        <h3 class="text-pdf_lg">Health Check</h3>
                        <!-- TODO: sort out width of chart for PDF -->
                        <chart-Container id="polar2" type="polar"></chart-Container>
                    </div>
                </section>

                <div class="html2pdf__page-break pt-20" />

                <section class="w-full mt-6">
                    <h2 class="text-pdf_xl font-bold">Health Issue Summary</h2>

                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, unde
                        odio maxime officia ut ducimus obcaecati culpa esse consectetur pariatur
                        nemo quas? Corrupti amet optio inventore! Sequi quas cumque et.
                    </p>

                    <div class="flex items-start justify-evenly">
                        <table class="w-full my-4 rounded-xl overflow-hidden">
                            <thead class="text-pdf_lg font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 pt-0 pb-3">Issue Category</td>
                                    <td class="px-2 pt-0 pb-3">Count</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Cancer & Neoplasms</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Cardiovascular</td>
                                    <td class="px-2 pt-0 pb-3">2</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Dermatological</td>
                                    <td class="px-2 pt-0 pb-3">5</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Ear, Nose, & Throat</td>
                                    <td class="px-2 pt-0 pb-3">7</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Total</td>
                                    <td class="px-2 pt-0 pb-3">15</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <!-- Symptoms Summary -->
                <section class="w-full mt-6">
                    <h2 class="text-pdf_xl font-bold">Symptoms Summary</h2>

                    <p class="">Symptoms Summary Description</p>

                    <div class="flex items-start justify-evenly">
                        <table class="w-2/3 my-4 rounded-xl overflow-hidden">
                            <thead class="text-pdf_lg font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 pt-0 pb-3">Name</td>
                                    <td class="px-2 pt-0 pb-3">Area</td>
                                    <td class="px-2 pt-0 pb-3">Area Detail</td>
                                    <td class="px-2 pt-0 pb-3">Count</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Blurred Vision</td>
                                    <td class="px-2 pt-0 pb-3">Head</td>
                                    <td class="px-2 pt-0 pb-3">Eyes</td>
                                    <td class="px-2 pt-0 pb-3">10</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Lumps</td>
                                    <td class="px-2 pt-0 pb-3">Chest</td>
                                    <td class="px-2 pt-0 pb-3">Breast</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Swelling</td>
                                    <td class="px-2 pt-0 pb-3">Head</td>
                                    <td class="px-2 pt-0 pb-3">Mouth</td>
                                    <td class="px-2 pt-0 pb-3">2</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Vomiting</td>
                                    <td class="px-2 pt-0 pb-3">Abdomen</td>
                                    <td class="px-2 pt-0 pb-3">Digestive System</td>
                                    <td class="px-2 pt-0 pb-3">3</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <div class="html2pdf__page-break pt-20" />

                <!-- Health Issue Details    -->
                <section class="w-full mt-6">
                    <h2 class="text-pdf_xl font-bold pb-4">Health Issue Details</h2>

                    <div class="w-full bg-card-200 rounded-2xl p-4 flex flex-col">
                        <div>
                            <h3 class="text-pdf_lg font-bold pt-0 pb-3">Coronavirus (COVID-19)</h3>
                            <div class="text-pdf_sm">
                                <span class="border-r-2 border-r-secondary pr-2 pt-0 pb-3"
                                    >Date Logged: 01/01/2023</span
                                >
                                <span class="pl-1 pt-0 pb-3">Issue Category: Respiratory</span>
                            </div>
                        </div>

                        <div class="flex justify-around">
                            <div class="w-[40%]">
                                <div>
                                    <div class="flex my-8">
                                        <div
                                            class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                2
                                            </div>
                                            <div class="">Energy</div>
                                        </div>
                                        <div
                                            class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                1
                                            </div>
                                            <div class="">Comfort</div>
                                        </div>
                                        <div
                                            class="w-1/3 text-center flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                3
                                            </div>
                                            <div class="">Mood</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="my-1 rounded-xl overflow-hidden bg-body p-2 text-left pt-0 pb-3"
                                >
                                    <h3 class="text-pdf_lg font-bold">Notes:</h3>
                                    <span class="pt-0 pb-3"
                                        >I feel really terrible and I can't get out of bed</span
                                    >
                                </div>
                            </div>
                            <table class="w-[58%] my-4 rounded-xl overflow-hidden">
                                <thead class="text-pdf_lg font-bold bg-secondary">
                                    <tr>
                                        <td class="px-2 pt-0 pb-3">Symptom</td>
                                        <td class="px-2 pt-0 pb-3">Category</td>
                                        <td class="px-2 pt-0 pb-3">Area</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Blocked Nose</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Head - Nose</td>
                                    </tr>
                                    <tr class="bg-card-200">
                                        <td class="px-2 pt-0 pb-3">Breathing Issues</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Lungs</td>
                                    </tr>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Breathlessness</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Chest</td>
                                    </tr>
                                    <tr class="bg-card-200">
                                        <td class="px-2 pt-0 pb-3">Chest Pain</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Chest</td>
                                    </tr>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Chills</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">General - Body</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="mt-6 w-full bg-card-200 rounded-2xl p-4 flex flex-col">
                        <div>
                            <h3 class="text-pdf_lg font-bold pt-0 pb-3">Coronavirus (COVID-19)</h3>
                            <div class="text-pdf_sm">
                                <span class="border-r-2 border-r-secondary pr-2 pt-0 pb-3"
                                    >Date Logged: 01/01/2023</span
                                >
                                <span class="pl-1 pt-0 pb-3">Issue Category: Respiratory</span>
                            </div>
                        </div>

                        <div class="flex justify-around">
                            <div class="w-[40%]">
                                <div>
                                    <div class="flex my-8">
                                        <div
                                            class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                2
                                            </div>
                                            <div class="">Energy</div>
                                        </div>
                                        <div
                                            class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                1
                                            </div>
                                            <div class="">Comfort</div>
                                        </div>
                                        <div
                                            class="w-1/3 text-center flex justify-center items-center flex-col pt-0 pb-3"
                                        >
                                            <div
                                                class="text-pdf_2xl font-bold m-auto text-center w-1/3"
                                            >
                                                3
                                            </div>
                                            <div class="">Mood</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="my-1 rounded-xl overflow-hidden bg-body p-2 text-left pt-0 pb-3"
                                >
                                    <h3 class="text-pdf_lg font-bold">Notes:</h3>
                                    <span class="pt-0 pb-3"
                                        >I feel really terrible and I can't get out of bed</span
                                    >
                                </div>
                            </div>
                            <table class="w-[58%] my-4 rounded-xl overflow-hidden">
                                <thead class="text-pdf_lg font-bold bg-secondary">
                                    <tr>
                                        <td class="px-2 pt-0 pb-3">Symptom</td>
                                        <td class="px-2 pt-0 pb-3">Category</td>
                                        <td class="px-2 pt-0 pb-3">Area</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Blocked Nose</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Head - Nose</td>
                                    </tr>
                                    <tr class="bg-card-200">
                                        <td class="px-2 pt-0 pb-3">Breathing Issues</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Lungs</td>
                                    </tr>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Breathlessness</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Chest</td>
                                    </tr>
                                    <tr class="bg-card-200">
                                        <td class="px-2 pt-0 pb-3">Chest Pain</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">Chest - Chest</td>
                                    </tr>
                                    <tr class="bg-body">
                                        <td class="px-2 pt-0 pb-3">Chills</td>
                                        <td class="px-2 pt-0 pb-3">Respiratory</td>
                                        <td class="px-2 pt-0 pb-3">General - Body</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <div class="html2pdf__page-break pt-20" />

                <!-- Health Activity Summary -->
                <section class="mt-6">
                    <h2 class="text-pdf_xl font-bold">Health Activity Summary</h2>

                    <h3 class="text-pdf_lg">
                        Health Activity Summary Table - By Practitioner Category
                    </h3>
                    <div class="flex items-start justify-evenly">
                        <table class="w-[48%] my-4 rounded-xl overflow-hidden">
                            <thead class="text-pdf_lg font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 pt-0 pb-3">Holistic Practitioner</td>
                                    <td class="px-2 pt-0 pb-3"># of Appointments</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Acupuncturist</td>
                                    <td class="px-2 pt-0 pb-3">2</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Aromatherapist</td>
                                    <td class="px-2 pt-0 pb-3">3</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Total</td>
                                    <td class="px-2 pt-0 pb-3">5</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="w-[48%] my-4 rounded-xl overflow-hidden">
                            <thead class="text-pdf_lg font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 pt-0 pb-3">Medical Practitioner</td>
                                    <td class="px-2 pt-0 pb-3"># of Appointments</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Gynaecologist</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Cardiologist</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">General Practitioner</td>
                                    <td class="px-2 pt-0 pb-3">2</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Total</td>
                                    <td class="px-2 pt-0 pb-3">4</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3 class="text-pdf_lg">Health Activity Detail Table</h3>
                    <div class="flex items-start justify-evenly">
                        <table class="w-8/12 my-4 rounded-xl overflow-hidden">
                            <thead class="text-pdf_lg font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 pt-0 pb-3">Practitioner Category</td>
                                    <td class="px-2 pt-0 pb-3">Practitioner Type</td>
                                    <td class="px-2 pt-0 pb-3">Appointment Type</td>
                                    <td class="px-2 pt-0 pb-3">Count</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Medical Practitioner</td>
                                    <td class="px-2 pt-0 pb-3">Cardiologist</td>
                                    <td class="px-2 pt-0 pb-3">Consultation</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Dental Health Practitioner</td>
                                    <td class="px-2 pt-0 pb-3">Dentist</td>
                                    <td class="px-2 pt-0 pb-3">Consultation</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3">Dental Health Practitioner</td>
                                    <td class="px-2 pt-0 pb-3">Dentist</td>
                                    <td class="px-2 pt-0 pb-3">Surgery - Treatment</td>
                                    <td class="px-2 pt-0 pb-3">2</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 pt-0 pb-3">Medical Practitioner</td>
                                    <td class="px-2 pt-0 pb-3">Gynaecologist</td>
                                    <td class="px-2 pt-0 pb-3">Diagnostic Test</td>
                                    <td class="px-2 pt-0 pb-3">1</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 pt-0 pb-3"></td>
                                    <td class="px-2 pt-0 pb-3"></td>
                                    <td class="px-2 pt-0 pb-3">Total</td>
                                    <td class="px-2 pt-0 pb-3">5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="html2pdf__page-break pt-20" />

                    <h3 class="text-pdf_lg pt-0 mt-6 pb-3">
                        Health Activity Impact Summary - By Practitioner Type
                    </h3>
                    <div class="flex flex-wrap items-start justify-between">
                        <div class="w-full my-4 bg-card-200 rounded-2xl p-4">
                            <p class="text-center font-semibold">Dentist Impact Wheel:</p>
                            <div class="flex">
                                <div class="w-6/12">
                                    <chart-Container id="polar" type="polar"></chart-Container>
                                </div>
                                <div class="w-5/12 mt-4">
                                    <div class="mb-2">
                                        <span class="font-semibold">6/3/23 - </span>
                                        <span>Spoke too much, receptionist was rude</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">28/2/23 - </span>
                                        <span>Waited for ages, waiting room really cold</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">22/2/23 - </span>
                                        <span>Really hurt my mouth, bad attitude</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full my-4 bg-card-200 rounded-2xl p-4">
                            <p class="text-center font-semibold">
                                General Practitioner Impact Wheel:
                            </p>
                            <div class="flex">
                                <div class="w-6/12">
                                    <chart-Container id="polar" type="polar"></chart-Container>
                                </div>
                                <div class="w-5/12 mt-4">
                                    <div class="mb-2">
                                        <span class="font-semibold">6/3/23 - </span>
                                        <span>Spoke too much, receptionist was rude</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">28/2/23 - </span>
                                        <span>Waited for ages, waiting room really cold</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">22/2/23 - </span>
                                        <span>Really hurt my mouth, bad attitude</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full my-4 bg-card-200 rounded-2xl p-4">
                            <p class="text-center font-semibold">Acupuncturist Impact Wheel:</p>
                            <div class="flex">
                                <div class="w-6/12">
                                    <chart-Container id="polar" type="polar"></chart-Container>
                                </div>
                                <div class="w-5/12 mt-4">
                                    <div class="mb-2">
                                        <span class="font-semibold">6/3/23 - </span>
                                        <span>Spoke too much, receptionist was rude</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">28/2/23 - </span>
                                        <span>Waited for ages, waiting room really cold</span>
                                    </div>
                                    <div class="mb-2">
                                        <span class="font-semibold">22/2/23 - </span>
                                        <span>Really hurt my mouth, bad attitude</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="w-[48%] bg-card-200 rounded-2xl p-4">
                            <p class="text-center font-semibold">
                                General Practitioner Impact Wheel:
                            </p>
                            <chart-Container id="polar" type="polar"></chart-Container>
                            <div class="mb-2">
                                <span class="font-semibold">6/3/23 - </span>
                                <span>Wouldn't listen to me, only let me talk about 2 issues</span>
                            </div>
                            <div class="mb-2">
                                <span class="font-semibold">28/2/23 - </span>
                                <span>Waited for ages, then appointment was rushed</span>
                            </div>
                            <div class="mb-2">
                                <span class="font-semibold">22/2/23 - </span>
                                <span>Accused me of being delusional and imagining symptoms</span>
                            </div>
                        </div>
                        <div class="w-[48%] bg-card-200 rounded-2xl p-4">
                            <p class="text-center font-semibold">Acupuncturist Impact Wheel:</p>
                            <chart-Container id="polar" type="polar"></chart-Container>
                            <div class="mb-2">
                                <span class="font-semibold">6/3/23 - </span>
                                <span>Treatment really hurt but feeling energised</span>
                            </div>
                            <div class="mb-2">
                                <span class="font-semibold">28/2/23 - </span>
                                <span>Practitioner was really nice and listened to me</span>
                            </div>
                            <div class="mb-2">
                                <span class="font-semibold">22/2/23 - </span>
                                <span>Feeling dizzy but headache is much better</span>
                            </div>
                        </div> -->
                    </div>
                </section>
            </section>
        </vue-html2pdf>
        <!-- <section
            slot="pdf-content"
            class="w-full text-center text-text-dark flex flex-col items-center justify-center"
        >
            <h1 class="mx-5 text-3xl italic font-bold">Health Summary Report</h1>

            <p class="text-base">Health Summary Report Description</p>

            <section class="mt-6">
                <h2 class="text-2xl font-bold">Health Check Scores</h2>

                <p class="text-base">Health Check Scores desc</p>

                <div>
                    <h3>Health Check</h3>
                    <chart-Container :loadOnView="true" id="polar2" type="polar"></chart-Container>
                </div>
            </section>

            <section class="w-full mt-6">
                <h2 class="text-2xl font-bold">Health Issue Summary</h2>

                <p class="text-base">Health Issue Summary Description</p>

                <div class="flex items-start justify-evenly">
                    <table class="w-1/3 my-4 rounded-xl overflow-hidden">
                        <thead class="font-bold bg-secondary">
                            <tr>
                                <td class="px-2 py-1">Issue Category</td>
                                <td class="px-2 py-1">Count</td>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr class="bg-body">
                                <td class="px-2 py-1">Cancer & Neoplasms</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Cardiovascular</td>
                                <td class="px-2 py-1">2</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">Dermatological</td>
                                <td class="px-2 py-1">5</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Ear, Nose, & Throat</td>
                                <td class="px-2 py-1">7</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">Total</td>
                                <td class="px-2 py-1">15</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section class="w-full mt-6">
                <h2 class="text-2xl font-bold">Symptoms Summary</h2>

                <p class="text-base">Symptoms Summary Description</p>

                <div class="flex items-start justify-evenly">
                    <table class="w-2/3 my-4 rounded-xl overflow-hidden">
                        <thead class="font-bold bg-secondary">
                            <tr>
                                <td class="px-2 py-1">Name</td>
                                <td class="px-2 py-1">Area</td>
                                <td class="px-2 py-1">Area Detail</td>
                                <td class="px-2 py-1">Count</td>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr class="bg-body">
                                <td class="px-2 py-1">Blurred Vision</td>
                                <td class="px-2 py-1">Head</td>
                                <td class="px-2 py-1">Eyes</td>
                                <td class="px-2 py-1">10</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Lumps</td>
                                <td class="px-2 py-1">Chest</td>
                                <td class="px-2 py-1">Breast</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">Swelling</td>
                                <td class="px-2 py-1">Head</td>
                                <td class="px-2 py-1">Mouth</td>
                                <td class="px-2 py-1">2</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Vomiting</td>
                                <td class="px-2 py-1">Abdomen</td>
                                <td class="px-2 py-1">Digestive System</td>
                                <td class="px-2 py-1">3</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section class="w-full mt-6">
                <h2 class="text-2xl font-bold">Health Issue Details</h2>

                <div class="flex justify-around">
                    <div class="w-2/3 lg:w-[48%] bg-card-200 rounded-2xl p-4 text-base">
                        <div>
                            <h3 class="text-xl font-bold">Coronavirus (COVID-19)</h3>
                            <div class="text-xs">
                                <span class="border-r-2 border-r-secondary pr-2"
                                    >Date Logged: 01/01/2023</span
                                >
                                <span class="pl-1">Issue Category: Respiratory</span>
                            </div>
                            <div class="flex my-8">
                                <div
                                    class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col"
                                >
                                    <div class="text-2xl font-bold m-auto text-center w-1/3">2</div>
                                    <div class="text-sm">Energy</div>
                                </div>
                                <div
                                    class="w-1/3 text-center border-r-2 border-r-secondary flex justify-center items-center flex-col"
                                >
                                    <div class="text-2xl font-bold m-auto text-center w-1/3">1</div>
                                    <div class="text-sm">Comfort</div>
                                </div>
                                <div
                                    class="w-1/3 text-center flex justify-center items-center flex-col"
                                >
                                    <div class="text-2xl font-bold m-auto text-center w-1/3">3</div>
                                    <div class="text-sm">Mood</div>
                                </div>
                            </div>
                        </div>
                        <table class="w-full my-4 rounded-xl overflow-hidden">
                            <thead class="font-bold bg-secondary">
                                <tr>
                                    <td class="px-2 py-1">Symptom</td>
                                    <td class="px-2 py-1">Category</td>
                                    <td class="px-2 py-1">Area</td>
                                </tr>
                            </thead>
                            <tbody class="text-sm">
                                <tr class="bg-body">
                                    <td class="px-2 py-1">Blocked Nose</td>
                                    <td class="px-2 py-1">Respiratory</td>
                                    <td class="px-2 py-1">Head - Nose</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 py-1">Breathing Issues</td>
                                    <td class="px-2 py-1">Respiratory</td>
                                    <td class="px-2 py-1">Chest - Lungs</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 py-1">Breathlessness</td>
                                    <td class="px-2 py-1">Respiratory</td>
                                    <td class="px-2 py-1">Chest - Chest</td>
                                </tr>
                                <tr class="bg-card-200">
                                    <td class="px-2 py-1">Chest Pain</td>
                                    <td class="px-2 py-1">Respiratory</td>
                                    <td class="px-2 py-1">Chest - Chest</td>
                                </tr>
                                <tr class="bg-body">
                                    <td class="px-2 py-1">Chills</td>
                                    <td class="px-2 py-1">Respiratory</td>
                                    <td class="px-2 py-1">General - Body</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-full my-4 rounded-xl overflow-hidden bg-body p-2">
                            <h3 class="text-lg font-bold">Notes:</h3>
                            <span class="text-sm"
                                >I feel really terrible and I can't get out of bed</span
                            >
                        </div>
                    </div>
                </div>
            </section>

            <section class="mt-6">
                <h2 class="text-2xl font-bold">Health Activity Summary</h2>

                <h3 class="text-xl font-bold">
                    Health Activity Summary Table - By Practitioner Category
                </h3>
                <div class="flex items-start justify-evenly">
                    <table class="w-4/12 my-4 rounded-xl overflow-hidden">
                        <thead class="font-bold bg-secondary">
                            <tr>
                                <td class="px-2 py-1">Practitioner Type</td>
                                <td class="px-2 py-1">Count</td>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr class="bg-body">
                                <td class="px-2 py-1">Acupuncturist</td>
                                <td class="px-2 py-1">2</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Aromatherapist</td>
                                <td class="px-2 py-1">3</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">Holistic Practitioner Total</td>
                                <td class="px-2 py-1">5</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="w-4/12 my-4 rounded-xl overflow-hidden">
                        <thead class="font-bold bg-secondary">
                            <tr>
                                <td class="px-2 py-1">Practitioner Type</td>
                                <td class="px-2 py-1">Count</td>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr class="bg-body">
                                <td class="px-2 py-1">Gynaecologist</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Cardiologist</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">General Practitioner</td>
                                <td class="px-2 py-1">2</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Total Medical Practitioner</td>
                                <td class="px-2 py-1">4</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3 class="text-xl font-bold">Health Activity Detail Table</h3>
                <div class="flex items-start justify-evenly">
                    <table class="w-8/12 my-4 rounded-xl overflow-hidden">
                        <thead class="font-bold bg-secondary">
                            <tr>
                                <td class="px-2 py-1">Practitioner Category</td>
                                <td class="px-2 py-1">Practitioner Type</td>
                                <td class="px-2 py-1">Appointment Type</td>
                                <td class="px-2 py-1">Count</td>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr class="bg-body">
                                <td class="px-2 py-1">Medical Practitioner</td>
                                <td class="px-2 py-1">Cardiologist</td>
                                <td class="px-2 py-1">Consultation</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Dental Health Practitioner</td>
                                <td class="px-2 py-1">Dentist</td>
                                <td class="px-2 py-1">Consultation</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1">Dental Health Practitioner</td>
                                <td class="px-2 py-1">Dentist</td>
                                <td class="px-2 py-1">Surgery - Treatment</td>
                                <td class="px-2 py-1">2</td>
                            </tr>
                            <tr class="bg-card-200">
                                <td class="px-2 py-1">Medical Practitioner</td>
                                <td class="px-2 py-1">Gynaecologist</td>
                                <td class="px-2 py-1">Diagnostic Test</td>
                                <td class="px-2 py-1">1</td>
                            </tr>
                            <tr class="bg-body">
                                <td class="px-2 py-1"></td>
                                <td class="px-2 py-1"></td>
                                <td class="px-2 py-1">Total</td>
                                <td class="px-2 py-1">5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3 class="text-xl font-bold">
                    Health Activity Impact Summary - By Practitioner Type
                </h3>
                <div class="flex flex-wrap items-start justify-between">
                    <div class="w-1/2 mx-[25%] lg:m-0 lg:w-[32%] bg-card-200 rounded-2xl p-4">
                        <p class="text-base text-center font-semibold">Dentist Impact Wheel:</p>
                        <chart-Container id="polar" type="polar"></chart-Container>
                        <div class="mb-2">
                            <span class="font-semibold">6/3/23 - </span>
                            <span>Spoke too much, receptionist was rude</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">28/2/23 - </span>
                            <span>Waited for ages, waiting room really cold</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">22/2/23 - </span>
                            <span>Really hurt my mouth, bad attitude</span>
                        </div>
                    </div>
                    <div class="w-1/2 mx-[25%] lg:m-0 lg:w-[32%] bg-card-200 rounded-2xl p-4">
                        <p class="text-base text-center font-semibold">
                            General Practitioner Impact Wheel:
                        </p>
                        <chart-Container id="polar" type="polar"></chart-Container>
                        <div class="mb-2">
                            <span class="font-semibold">6/3/23 - </span>
                            <span>Wouldn't listen to me, only let me talk about 2 issues</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">28/2/23 - </span>
                            <span>Waited for ages, then appointment was rushed</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">22/2/23 - </span>
                            <span>Accused me of being delusional and imagining symptoms</span>
                        </div>
                    </div>
                    <div class="w-1/2 mx-[25%] lg:m-0 lg:w-[32%] bg-card-200 rounded-2xl p-4">
                        <p class="text-base text-center font-semibold">
                            Acupuncturist Impact Wheel:
                        </p>
                        <chart-Container id="polar" type="polar"></chart-Container>
                        <div class="mb-2">
                            <span class="font-semibold">6/3/23 - </span>
                            <span>Treatment really hurt but feeling energised</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">28/2/23 - </span>
                            <span>Practitioner was really nice and listened to me</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-semibold">22/2/23 - </span>
                            <span>Feeling dizzy but headache is much better</span>
                        </div>
                    </div>
                </div>
            </section>
        </section> -->
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import chartContainer from '../Charts/chartContainer.vue';
import AnimaBtn from '../Elements/AnimaBtn.vue';

export default {
    components: { chartContainer, VueHtml2pdf, AnimaBtn },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
    },
};
</script>

<style>
.content-wrapper {
    margin: auto;
}
</style>
