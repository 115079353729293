<template>
    <div>
        <label class="block text-xl md:text-2xl">
            <slot name="label"></slot>
        </label>

        <p>
            <slot name="description"></slot>
            <span v-if="$slots.tip" class="block my-2 text-sm">
                <slot name="tip"></slot>
            </span>
        </p>

        <anima-option v-model="render" :type="type" :id="id" :options="options"></anima-option>
        <div
            class="rounded-md border-2 border-primary bg-text py-6 px-4 mb-4"
            v-if="render == 'yes'"
        >
            <slot name="render"></slot>
        </div>
    </div>
</template>

<script>
import animaOption from '../Forms/AnimaOption.vue';
export default {
    data() {
        return {
            render: '',
        };
    },
    components: { animaOption },
    props: {
        id: { type: String, default: null },
        type: { type: String, default: null },
        options: { type: Array, default: null },
    },
};
</script>
