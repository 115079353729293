<template>
    <div :class="wrapperClasses" :style="style">
        <div v-if="coming && coming.yes" class="bg-primary shadow-feature absolute top-0 w-full px-6 py-2 text-center font-bold">Coming Soon</div>
        <a :href="link" :class="cardClasess" >
            <img v-if="img" :class="imgClasses" :src="img" alt="">
            <p :class="titleClasses">{{title}}</p>
            <p v-if="text" :class="textClasses">{{ text }}</p>
            <div v-else-if="$slots.text" :class="textClasses">
                <slot name="text" :class="textClasses" />
            </div>
        </a>
    </div>
</template>
<script>
    export default {
        name: 'animaCard',
        props: {
            title: { type: String, required: true },
            text: { type: String, default: null },
            img: { type: String, default: null },
            link: { type: String, default: null },
            mini: { type: Boolean, default: false },
            exercise_mini: { type: Boolean, default: false },
            exercise_full: { type: Boolean, default: false },
            feature: { type: Boolean, default: false },
            coming: { type: Object, default: null },
            max_width: { type: Number, default: null },
            per_page: { type: Number , default: null },
            type: { type: String, default: 'default' }
        },
        computed: {
            style () {
                if (this.max_width) {
                    let margin = 'margin-left: auto; margin-right: auto; margin-top:0;'
                    if (this.per_page != 1) {
                        margin = 'margin-left: 25px; margin-right: 25px; margin-top:0;'
                    }
                    return 'max-width: ' + this.max_width + 'px;' + margin
                }
                return null
            },
            cardClasess(){
                const base = 'no-underline w-full items-center'
                const variants = {
                    exercise_mini: 'flex'
                }

                return [
                    base,
                    variants[this.type] ?? variants['default']
                ]
            },
            wrapperClasses() {
                const base = 'bg-card-100 transition-shadow shadow-feature relative overflow-hidden'
                const variants = {
                    default: 'flex flex-col justify-between items-center m-2.5 md:my-5 md:mx-10 w-full no-underline rounded-3xl',
                    mini: 'w-full max-w-[200px] min-w-36 mx-[20px] inline-block my-4 py-2 rounded-md',
                    exercise_mini:'rounded-md h-[83px] my-0 ',
                    exercise_full:'rounded-md md:ml-0 h-[385px]',
                    link: 'cursor-pointer hover:shadow-featureHover',
                    feature: 'flex flex-col justify-between items-center mt-4 mb-6 mx-6 w-full no-underline rounded-3xl'
                }

                return [
                    base,
                    variants[this.type] ?? variants['default'],
                    this.link ? variants['link'] : null
                ]
            
            },
            imgClasses() {
                const base = 'h-14 w-16 md:h-20 md:w-24 m-auto my-4'
                const variants = {
                    default: 'my-8 lg:h-28 lg:w-32',
                    mini: 'lg:w-20',
                    exercise_mini: 'm-0 my-0',
                    exercise_full: '',
                    feature: 'lg:h-28 lg:w-32'
                }

                return [
                    base,
                    variants[this.type] ?? variants['default']
                ]
            },
            titleClasses() {
                const base = 'text-center font-semibold text-text-dark'
                const variants = {
                    default: 'text-base px-5 py-6',
                    mini: 'text-sm px-3 py-2',
                    exercise_mini: 'py-0 pl-2  text-xs text-start border-t-0',
                    exercise_full: 'text-sm',
                    feature: 'text-base px-5 py-4'
                }

                return [
                    base,
                    this.img ? 'border-t-[#F7E6DA] border-t' : null,
                    variants[this.type] ?? variants['default']
                ]
            },
            textClasses() {
                const base = 'text-base md:text-xl md:text-base px-5 pb-5 text-text-dark text-center'
                
                return [
                    base
                ]
            }
        }
    }
</script>