<template>
    <div class="flex justify-center relative">
        <a
            href="/stats"
            class="absolute top-0 right-full md:right-auto left-0 pl-2 no-underline text-text-dark text-lg"
        >
            <font-awesome-icon icon="arrow-left" />
        </a>

        <div class="flex flex-col md:flex-row items-center md:h-12 w-8/12 md:w-1/2 overflow-hidden rounded-xl font-bold bg-white shadow-feature"
            :class="minisise ? 'h-[44px]' : null">
            <select
                class="h-11 w-[calc(100%-4px)] md:w-1/2 sm:text-sm py-2 px-3 m-[2px] md:mx-[2px] rounded-t-[0.65rem] md:rounded-tr-none md:rounded-l-[0.65rem] outline-primary"
                name="current_type"
                id="current_type"
                v-model="current_type"
            >
                <option v-for="category in types" :key="category.slug" :value="category.slug">
                    {{ category.name | capitalize }}
                </option>
            </select>
            <div class="flex justify-center items-center w-full md:w-12 h-8 md:h-12 bg-card-100">
                <font-awesome-icon class="block md:hidden" :icon="['fas', 'arrow-down']" />
                <font-awesome-icon class="hidden md:block" :icon="['fas', 'arrow-right']" />
            </div>
            <select
                class="h-11 w-[calc(100%-4px)] md:w-1/2 sm:text-sm py-2 px-3 m-[2px] md:mx-[2px] rounded-b-[0.65rem] md:rounded-bl-none md:rounded-r-[0.65rem] outline-primary"
                name="current_name"
                id="current_name"
                @change="emit"
            >
                <option disabled :selected="current_name === 'Select Category'">Select Category...</option>
                <option :selected="current_name === category.slug" v-for="category in current_list" :key="category.id" :value="category.slug">
                    {{ category.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: { FontAwesomeIcon },
    props: {
        name: { type: String, default: null },
        type: { type: String, default: null },
        url: { type: String, default: null },
    },
    data() {
        return {
            list: '',
            assessments: [],
            journals: [],
            checkins: [],
            current_name: 'Select Category',
            current_type: this.type,
            types: [
                {name:'assessment', slug:'assessment'},
                {name:'journal', slug:'journal'},
                {name:'health check', slug:'checkin'}
            ],
            minisise: false
        };
    },
    mounted() {
        this.fetchStats();
    },
    methods: {
        async fetchStats() {
            try {
                let response = await fetch(this.url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                this.list = data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].type == 'assessment') {
                        this.assessments.push(data[i]);
                    }
                    if (data[i].type == 'journal') {
                        this.journals.push(data[i]);
                    }
                    if (data[i].type == 'checkin') {
                        this.checkins.push(data[i]);
                    }
                }
            } catch (error) {
                this.error = 'Error getting stats.';
            } finally {
                //this.loading_categories = false;
                const vue = this;
                if (this.current_type === 'assessment') {
                    this.assessments.forEach((category) => {
                        if (category.slug == vue.name) {
                            vue.current_name = category.slug;
                        }
                    });
                } else if (this.current_type === 'journal') {
                    this.journals.forEach((category) => {
                        if (category.slug == vue.name) {
                            vue.current_name = category.slug;
                        }
                    });
                } else {
                    this.checkins.forEach((category) => {
                        if (category.slug == vue.name) {
                            vue.current_name = category.slug;
                        }
                    });
                }
                
                //this.$emit('select', this.current_name);
            }
        },
        emit(e) {
            this.current_name = e.target.value
            this.$emit('select', {name: e.target.value, type: this.current_type});
        },
    },
    computed: {
        current_list() {
            if (this.current_type === 'assessment') {
                return this.assessments
            } else if (this.current_type === 'journal') {
                return this.journals
            } else {
                return this.checkins
            }
        }
    },
    watch: {
        current_type(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.current_name = 'Select Category'
            }
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            value = value.split(' ');
            value.forEach((word, idx) => {
                value[idx] = word.charAt(0).toUpperCase() + word.slice(1)
            });
            value = value.join(' ')
            return value
        },
    },
};
</script>
