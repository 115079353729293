<template>
    <div
        class="w-full bg-card-200 p-4 rounded-3xl flex flex-col lg:flex-row justify-center text-text-dark"
    >
        <div
            ref="single"
            v-for="(stat, key, idx) in show"
            :key="stat.id"
            class="w-full lg:w-1/3 border-secondary flex justify-start text-center items-center flex-col relative"
            :class="idx == 0 ? 'border-t-0 border-l-0' : 'border-t-2 lg:border-t-0 lg:border-l-2'"
        >
            <div class="flex flex-col">
                <div class="text-sm font-bold">{{ stat.chart_label }}</div>
            </div>
            <div class="font-bold m-0 min-h-[70px] text-center w-full"
                :class="stat.text_stat_display != 'average' && stat.chart_data.labels && stat.chart_data.labels.length > 0 && stat.chart_data.labels[0].length >= 8 ? 'text-2xl' : 'text-3xl'"
            >
                {{ stat.question_type == 'emoji_slider' ? likert[stat.question_display][Math.round(stat.chart_data.average)] :
                        stat.text_stat_display == 'average' ? 
                            stat.chart_data.average : 
                            stat.chart_data.labels && stat.chart_data.labels.length > 0 ?
                                stat.chart_data.labels[0] :
                                stat.chart_data.average  
                }}
            </div>
            <div class="absolute right-3 top-[55%]" v-if="stat.chart_data.average_label && stat.chart_data.average_label.length > max_tooltip">
                <anima-tooltip position="bottom">
                    <template #tip>
                        <p v-for="(item, idx) in stat.chart_data.average_label" 
                            :key="item.id" 
                            :class="idx != 0 ? 'ml-2' : ''">
                            {{ idx == stat.chart_data.average_label.length - 1 ? `${item} ` : `${item};` }}
                        </p>
                    </template>
                </anima-tooltip>
            </div>
            <div class="flex flex-col">
                <div v-if="stat.chart_data.average_label">
                    <div v-if="stat.chart_data.average_label.length > max_tooltip" class="text-sm flex flex-wrap justify-center">
                        <p v-if="idx < max_tooltip" v-for="(item, idx) in stat.chart_data.average_label" 
                            :key="item.id"
                            :class="idx != 0 ? 'ml-2' : ''">
                            {{ idx == max_tooltip - 1 || idx == stat.chart_data.average_label.length - 1 ? `${item} ` : `${item};` }}
                        </p>
                    </div>
                    <div v-else class="text-sm flex flex-wrap justify-center">
                        <p v-for="(item, idx) in stat.chart_data.average_label" 
                            :key="item.id" 
                            :class="idx != 0 ? 'ml-2' : ''">
                            {{ idx == stat.chart_data.average_label.length - 1 ? `${item}` : `${item};` }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chartContainer from '../Charts/chartContainer.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AnimaTooltip from '../Elements/AnimaTooltip.vue';


export default {
    components: { chartContainer, FontAwesomeIcon, AnimaTooltip },
    data() {
        return {
            show: [],
            likert: {
                frequency: {
                    0: 'Never', 
                    1: 'Rarely', 
                    2: 'Sometimes', 
                    3: 'Regularly', 
                    4: 'Daily'
                },
                risk: {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Medium', 
                    3: 'High', 
                    4: 'Very High'
                },
                accuracy: {
                    0: 'Not At All',
                    1: 'Slightly', 
                    2: 'Moderately', 
                    3: 'Very', 
                    4: 'Extremely'
                },
                intensity: {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Moderate', 
                    3: 'High', 
                    4: 'Very High',
                }
            },
            likertValue: {
                frequency: {
                    'Never': 0, 
                    'Rarely': 1, 
                    'Sometimes': 2, 
                    'Regularly': 3, 
                    'Daily': 4
                },
                risk: {
                    'Very Low': 0,
                    'Low': 1,
                    'Medium': 2,
                    'High': 3,
                    'Very High': 4
                },
                accuracy: {
                    'Not At All': 0,
                    'Slightly': 1,
                    'Moderately': 2,
                    'Very': 3,
                    'Extremely': 4
                },
                intensity: {
                    'Very Low': 0, 
                    'Low': 1, 
                    'Moderate': 2, 
                    'High': 3, 
                    'Very High': 4,
                }
            },
            likertTotalValue: {
                frequency: {
                    'Never': 0, 
                    'Rarely': 25, 
                    'Sometimes': 50, 
                    'Regularly': 75, 
                    'Daily': 100
                },
                risk: {
                    'Very Low': 0,
                    'Low': 25,
                    'Medium': 50,
                    'High': 75,
                    'Very High': 100
                },
                accuracy: {
                    'Not At All': 0,
                    'Slightly': 25,
                    'Moderately': 50,
                    'Very': 75,
                    'Extremely': 100
                },
                intensity: {
                    'Very Low': 0, 
                    'Low': 25, 
                    'Moderate': 50, 
                    'High': 75, 
                    'Very High': 100,
                }
            },
            tooltipwidth: 0,
            max_tooltip: 3
        };
    },
    props: {
        stats: { type: Object, required: true },
    },
    watch: {
        stats: function (newVal, oldVal) {
            // watch it
            this.sortStats();
        },
    },
    mounted() {
        this.sortStats();
        window.addEventListener('resize', this.onWidthChange)
        this.$nextTick(() => {
            this.tooltipwidth = this.$refs['single'][0].getBoundingClientRect().width.toFixed(2)
        });
    },
    methods: {
        onWidthChange() {
            this.tooltipwidth = this.$refs['single'][0].getBoundingClientRect().width.toFixed(2)
        },
        sortStats() {
            this.show = {};
            for (const question in this.stats) {
                if (Object.hasOwnProperty.call(this.stats, question)) {
                    const element = structuredClone(this.stats[question]);
                    for (const key in element) {
                        if (Object.hasOwnProperty.call(element, key)) {
                            const el = structuredClone(element[key]);

                            if (el.question_type === 'mapping' && el['mapping level'].length > 0) {
                                let answers = {}
                                el.chart_data['labels'].forEach((label, idx) => {
                                    let pieces = label.split("; ");
                                    let str = ''
                                    for (let i = 0; i < el['mapping level'].length; i++) {
                                        const element = el['mapping level'][i];
                                        if (i == el['mapping level'].length - 1) {
                                            str = str.concat(pieces[element - 1]);
                                        } else {
                                            str = str.concat(pieces[element - 1], " - ");
                                        }
                                    }
                                    if (!answers.hasOwnProperty(str)) {
                                        answers[str] = 1
                                    } else {
                                        answers[str] += 1
                                    }
                                });
                                el.chart_data['labels'] = Object.getOwnPropertyNames(answers)
                                el.chart_data['data'] = Object.values(answers)
                            }
                            
                            if (!this.show[el.chart_label]) {
                                this.show[el.chart_label] = {
                                    chart_data: {},
                                    chart_type: el.chart_type,
                                    chart_label: el.chart_label,
                                };
                                
                                if (el.chart_label.toLowerCase().includes('top') || el.chart_label.toLowerCase().includes('bottom')) {
                                    if (el.question_type == 'date_time') {
                                        this.show[el.chart_label]['chart_data'][el.data_label ] = el.chart_data.labels[0];
                                    // @FIXME: removed to test top & bottom scores, dont know if will cause error somewhere else
                                    // 11/7/24 - added back in as was making work activity top / bottom score weird
                                    // 24/7/24 - changed what is being looped over as work activity / health activity were work, now correct
                                    } else if (!/^\d+$/.test(el.chart_data.labels[0])) {
                                        this.stats[question][key].chart_data.data.forEach((val, idx) => {
                                            this.show[el.chart_label]['chart_data'][el.chart_data.labels[idx]] = val
                                        });
                                    } else {
                                        this.show[el.chart_label]['chart_data'][el.data_label] = el.chart_data.average;
                                    }
                                } else {
                                    this.show[el.chart_label]['chart_data'][el.data_label] = el.chart_data.average;
                                }
                            } else if (!this.show[el.chart_label][el.data_label]) {
                                if (el.chart_label.toLowerCase().includes('top') || el.chart_label.toLowerCase().includes('bottom')) {       
                                    if (el.question_type == 'date_time') {
                                        this.show[el.chart_label]['chart_data'][el.data_label] =
                                            el.chart_data.labels[0];
                                    // @FIXME: removed to test top & bottom scores, dont know if will cause error somewhere else
                                    // 11/7/24 - added back in as was making work activity top / bottom score weird
                                    // 24/7/24 - changed what is being looped over as work activity / health activity were work, now correct
                                    } else if (!/^\d+$/.test(el.chart_data.labels[0])) {
                                        el.chart_data.data.forEach((val, idx) => {
                                            this.show[el.chart_label]['chart_data'][el.chart_data.labels[idx]] = val
                                        });
                                        // this.show[el.chart_label]['chart_data'][el.chart_data.labels[0]] = el.chart_data.average;
                                    } else {
                                        this.show[el.chart_label]['chart_data'][el.data_label] = el.chart_data.average;
                                    }
                                } else {
                                    this.show[el.chart_label]['chart_data'][el.data_label] = el.chart_data.average;
                                }
                            }

                            if (
                                el.chart_label.toLowerCase().includes('top') ||
                                el.chart_label.toLowerCase().includes('bottom')
                            ) {
                                this.show[el.chart_label].position = el.position;
                                let chart_data = structuredClone(this.show[el.chart_label]['chart_data']);

                                delete chart_data.average;
                                delete chart_data.average_label;
                                let arr
                                let min
                                let max

                                if (el.question_type == 'date_time') {
                                    arr = Object.values(chart_data);
                                    max = arr.reduce((max, duration) => {
                                        const minutes = this.getMinutesFromDuration(duration);
                                        return minutes > max ? minutes : max;
                                    }, 0);
                                    min = arr.reduce((min, duration) => {
                                        const minutes = this.getMinutesFromDuration(duration);
                                        return minutes < min ? minutes : min;
                                    }, 0);
                                    
                                    max = this.getDurationFromMinutes(max)
                                    min = this.getDurationFromMinutes(min)
                                } else {
                                    arr = Object.values(chart_data);
                                    min = Math.min(...arr);
                                    max = Math.max(...arr);
                                }

                                let max_label = [];
                                let min_label = [];

                                for (const key in chart_data) {
                                    if (Object.hasOwnProperty.call(chart_data, key)) {
                                        if (key != 'average' && key != 'average_label') {
                                            const element = chart_data[key];
                                            if (element == max) {
                                                max_label.push(key);
                                            }
                                            if (element == min) {
                                                min_label.push(key);
                                            }
                                        }
                                    }
                                }

                                if (el.chart_label.toLowerCase().includes('top')) {
                                    this.show[el.chart_label]['chart_data'].average = max;
                                    this.show[el.chart_label]['chart_data'].average_label =
                                        max_label;
                                }
                                if (el.chart_label.toLowerCase().includes('bottom')) {
                                    this.show[el.chart_label]['chart_data'].average = min;
                                    this.show[el.chart_label]['chart_data'].average_label =
                                        min_label;
                                }
                            } else {
                                if (this.show[el.chart_label].answers) {
                                    for (const ans in el.answers) {
                                        const val = el.answers[ans];
                                        if (ans in this.show[el.chart_label].answers) {
                                            this.show[el.chart_label].answers[ans] += val
                                        } else {
                                            this.show[el.chart_label].answers[ans] = val
                                        }
                                        this.show[el.chart_label].chart_data.average++
                                    }

                                    if (this.show[el.chart_label].data_aggregation == 'average' && /[0-9]+/i.test(Object.keys(this.show[el.chart_label].answers)[0])) {
                                        let total = 0
                                        let count = 0
    
                                        for (const key in this.show[el.chart_label].answers) {
                                            const val = this.show[el.chart_label].answers[key];
                                            total += (val*key)
                                            count += val
                                        }
                                        this.show[el.chart_label].chart_data.average = (total / count).toFixed(1)
                                    }

                                } else {
                                    this.show[el.chart_label] = el;

                                    if (el.question_type == 'emoji_slider') {
                                        for (const key in this.show[el.chart_label].answers) {
                                            const val = this.show[el.chart_label].answers[Math.round(key)];
                                            let newKey = this.likert[el.question_display][Math.round(key)]

                                            this.show[el.chart_label].answers[newKey] = val
                                            delete this.show[el.chart_label].answers[Math.round(key)]
                                        }
                                        this.show[el.chart_label]['chart_data']['labels'].forEach((element, idx) => {
                                            this.show[el.chart_label]['chart_data']['labels'][idx]
                                                = this.likert[el.question_display][Math.round(element)]
                                        });
                                    }
                                }
                            }

                            if (this.show[el.chart_label]['chart_data'].average % 1 == 0) {
                                this.show[el.chart_label]['chart_data'].average = parseInt(this.show[el.chart_label]['chart_data'].average).toFixed(0)
                            }
                        }
                    }
                }
            }

            let sortable = [];

            for (const stat in this.show) {
                sortable.push(this.show[stat]);
            }

            sortable.sort(function (a, b) {
                return a.position - b.position;
            });

            this.show = sortable.reduce((a, v) => ({ ...a, [v.chart_label]: v }), {});
        },
        getMinutesFromDuration(duration) {
            const [hours, minutes] = duration.split(":").map(Number);
            return hours * 60 + minutes;
        },
        getDurationFromMinutes(minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(remainingMinutes).padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        },
        setWidth() {
            let el = this.$refs['tooltip_content'][0]
            const viewportWidth = window.innerWidth;
            const elementRect = el.getBoundingClientRect();
            if (elementRect.right > viewportWidth) {
                el.style.left = -(elementRect.width / 2) + 30 + 'px';
            }
        }
    },
    watch: {
        tooltipwidth(newVal, oldVal) {
            if (this.$refs['tooltip_content']) {
                if (window.innerWidth < 1024) {
                    this.$refs['tooltip_content'].forEach(el => {
                        el.style.width = parseInt(newVal / 2) + 'px'
                    });
                } else {
                    this.$refs['tooltip_content'].forEach(el => {
                        el.style.width = newVal + 'px'
                    });
                }
            }
        }
    }
};
</script>
