export default {
  data() {
    return {
      menuVisible: false,
      subVisible: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    toggleSub() {
      this.subVisible = !this.subVisible;
    },
    closeMenu() {
      this.menuVisible = false
      this.subVisible = false
    },
    signOutNotification() {
        sessionStorage.setItem('notification', JSON.stringify({
            type: 'info',
            messages: [{
                title: 'Signed out'
            }]
        }))
    }
  },
  render() {
    return this.$scopedSlots.default({
      menuVisible: this.menuVisible,
      toggleMenu: this.toggleMenu,
      toggleSub: this.toggleSub,
      subVisible: this.subVisible,
      closeMenu: this.closeMenu,
      signOutNotification: this.signOutNotification
    });
  },
};
