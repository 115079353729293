<template>
    <div>
        <div v-show="show" class="flex justify-center lg:p-5 lg:pt-2 min-h-[225px] h-[290px] md:h-[500px] lg:m-2 lg:mt-0">
            <canvas :ref="id" :id="id"></canvas>
        </div>
        <div v-show="!show">
            <p class=" p-5 mx-[20%] my-5 rounded-md bg-card-300 text-base text-center">No stats available</p>
        </div>
    </div>
</template>
<script>
import { Chart } from 'chart.js/auto';
import chartConfig from '../../chartConfig';
import ChartDeferred from 'chartjs-plugin-deferred';
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            chart: '',
            dataset: [],
            chartData: this.data,
            months: [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ],
        };
    },
    props: {
        id: { type: String, default: null },
        data: { type: Object, default: null },
        labels: { type: Array, default: null },
        lineSteps: { type: Object, default: null },
        start_date: { type: String, default: null },
        question_type: { type: String, default: null },
        stacked: { type: Boolean, default: false },
    },
    mounted() {
        setTimeout(() => {
            this.$refs[this.id].style.width = `${this.$el.offsetWidth}px`;
        }, 100);

        this.setDatasets()

        Chart.register(ChartDeferred);
        this.renderChart();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        setDatasets() {
            this.dataset = []
            this.fillInDates();

            const resultArray = this.labels.map(property => Object.values(this.chartData).map(date => date[property] ?? 0));
            
            resultArray.forEach((element, idx) => {
                this.dataset.push({
                    label: this.labels[idx],
                    backgroundColor: chartConfig.colors.all_colors[idx],
                    data: element,
                })
            });
        },
        fillInDates() {
            let start = new Date(this.start_date);
            const startDatesArray = [];
            if (this.lineSteps.unit == 'month') {
                
                let today = new Date()
                let monthsAgo = new Date(today.getFullYear() - 1, today.getMonth() + 1, 1)

                let totalMonths
                if (start > monthsAgo) {
                    totalMonths = 3
                } else {
                    totalMonths = 12
                }

                for (let i = 0; i < totalMonths; i++) {
                    const nextMonthStartDate = new Date(start.getFullYear(), start.getMonth() + i + 1, 1);

                    const offset = nextMonthStartDate.getTimezoneOffset(); 
                    nextMonthStartDate = new Date(nextMonthStartDate.getTime() + (-offset*60*1000)); 
                    startDatesArray.push(nextMonthStartDate.toISOString().split('T')[0]); //toISOString().split('T')[0]
                }
            } else if (this.lineSteps.unit == 'day') {
                if (this.lineSteps.step == 7) {
                    const today = new Date();

                    let currentMonday = this.getNextMonday(this.start_date)
                    currentMonday.setHours(12)

                    while (currentMonday <= today) {
                        startDatesArray.push(new Date(currentMonday).toISOString().split('T')[0]);
                        currentMonday.setDate(currentMonday.getDate() + 7);
                    }
                } else {
                    const today = new Date();
                    let currentDate = new Date(this.start_date);

                    while (currentDate <= today) {
                        startDatesArray.push(new Date(currentDate).toISOString().split('T')[0]);
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                }
            }

            startDatesArray.forEach(date => {
                if (!this.chartData.hasOwnProperty(date)) {
                    this.chartData[date] = {}
                    this.labels.forEach(label => {
                        this.chartData[date][label] = 0
                    });
                }
            });
        },
        renderChart() {
            this.chart = new Chart(this.$refs[this.id], {
                type: 'bar',
                data: {
                    labels: Object.keys(this.data),
                    datasets: this.dataset,
                },
                options: {
                    // maintainAspectRatio: this.aspectRatio,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        ...chartConfig.options,
                        tooltip: {
                            callbacks: {
                                label: (ctx) => {
                                    let label = ctx.dataset.label
                                    if (this.question_type != 'date_time') {
                                        return `${label}: ${ctx.dataset.data[ctx.dataIndex]}`
                                    }
                                    return `${label}: ${this.getDurationFromMinutes(ctx.dataset.data[ctx.dataIndex])}`;
                                }
                            }
                        }
                    },
                    layout: {
                        padding: 20,
                    },
                    elements: {
                        arc: {
                            borderWidth: 0,
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            stacked: this.stacked,
                            time: {
                                tooltipFormat: this.tooltipFormat,
                                unit: this.lineSteps.unit,
                                displayFormats: {
                                    day: 'MMM DD',
                                    week: 'MMM DD',
                                    month: "MMM 'YY",
                                },
                            },
                            ticks: {
                                stepSize: this.lineSteps.step,
                            },
                        },
                        y: {
                            max: this.dataset.length > 0 ? null : 10,
                            stacked: this.stacked,
                            ticks: {
                                callback: this.question_type == 'date_time' ? function (value, index, values) {
                                    const hours = Math.floor(value / 60);
                                    return `${hours}h`
                                } : function (value, index, values) {
                                    return value
                                },
                                stepSize: this.question_type == 'date_time' ? 60 : 1
                            },
                        },
                    },

                    
                },
            });
            Chart.defaults.color = '#1B3452';
        },
        getNextMonday(givenDate) {
            const date = new Date(givenDate);
            const dayOfWeek = date.getDay();
            const daysUntilNextMonday = (7 - dayOfWeek + 1) % 7; // 1 represents Monday

            date.setDate(date.getDate() + daysUntilNextMonday);

            return date;
        },
        getDurationFromMinutes(minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(remainingMinutes).padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
    },
    computed: {
        aspectRatio() {
            return true;
            if (this.windowWidth < 768) {
                return false;
            } else {
                return true;
            }
        },
        tooltipFormat() {
            if (this.lineSteps.unit === 'day' || this.lineSteps.unit === 'week') {
                return 'MMM DD';
            } else if (this.lineSteps.unit === 'month') {
                return "MMM 'YY";
            }
        },
        getDates() {
            const today = new Date();
            const dates = [];

            for (let i = 6; i >= 0; i--) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                dates.push(date.toISOString().split('T')[0]);
            }

            if (this.date_type)

            return dates;
        },
        show() {
            if (this.dataset.length > 0) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        data: {
            handler() {
                // destroy the chart
                this.chartData = this.data
                this.chart.destroy();
                this.setDatasets();
                this.renderChart();
                this.$forceUpdate()
            },
            deep: true,
        },
    },
};
</script>
<style></style>
