<template>
    <div
        ref="container"
        class="w-full lg:w-1/2 animate-slide-up transition-all duration-300 fixed bottom-0 lg:bottom-3 lg:left-1/4 z-[11]"
        v-if="messages && show"
    >
        <div
            v-for="(message, idx) in messages"
            :key="idx"
            :class="[classes, { 'rounded-tr-3xl rounded-tl-3xl': idx === 0 }]"
            class="w-full lg:rounded-3xl inline-block lg:mb-2"
        >
            <svg
                class="absolute h-5 w-5 my-5 mx-4"
                v-if="types == 'error' || types == 'warning'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 17.985c-4.403 0-7.985-3.582-7.985-7.984 0-4.403 3.582-7.986 7.985-7.986 4.403 0 7.985 3.582 7.985 7.985 0 4.403-3.582 7.985-7.985 7.985M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm0-4.71a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-1-3.277h2V4.65H9v7.362z"
                    :fill="types == 'error' ? '#fff' : '#2E4B44'"
                    fill-rule="nonzero"
                />
            </svg>
            <svg
                class="absolute h-5 w-5 my-5 mx-4"
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 2.015c4.403 0 7.985 3.582 7.985 7.984 0 4.403-3.582 7.986-7.985 7.986-4.403 0-7.985-3.582-7.985-7.985 0-4.403 3.582-7.985 7.985-7.985M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 4.71a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm1 3.277H9v7.362h2V7.987z"
                    fill="#2E4B44"
                    fill-rule="nonzero"
                />
            </svg>

            <div class="my-4 mr-9 ml-12 m-auto">
                <p class="font-body mb-4">
                    <strong>{{ message.title }}</strong>
                    <span v-if="message.message"> - {{ message.message }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            types: '',
            messages: '',
            show: false,
        };
    },
    props: {
        type: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
    },
    beforeMount() {
        let notification = sessionStorage.getItem('notification');
        if (notification) {
            notification = JSON.parse(notification);

            if (notification.state) {
                this.show = false;
            } else {
                notification.state = 1;
                this.show = true;
                sessionStorage.setItem('notification', JSON.stringify(notification));
            }
        }
    },
    mounted() {
        let notification = JSON.parse(sessionStorage.getItem('notification'));
        if (notification && this.show == true) {
            this.types = notification.type;
            this.messages = notification.messages;

            if (this.types == 'info' || this.types == 'success') {
                setTimeout(() => {
                    this.$refs.container.classList.add('translate-y-[120%]');
                }, 8000);
            }
        }

        eventHub.$on('show', () => {
            let notification = sessionStorage.getItem('notification');
            if (notification) {
                notification = JSON.parse(notification);

                this.types = notification.type;
                this.messages = notification.messages;
                this.show = true;
                notification.state = 1;
                sessionStorage.setItem('notification', JSON.stringify(notification));

                if (this.types == ('info' || 'success')) {
                    setTimeout(() => {
                        this.$refs.container.classList.add('translate-y-[120%]');
                    }, 3000);
                }
            }
        });
    },
    computed: {
        classes() {
            const { types } = this;

            return [
                types == 'error' ? 'bg-error text-white' : null,
                types == 'warning' ? 'bg-warning text-[#2E4B44]' : null,
                types == 'info' ? 'bg-info text-[#2E4B44]' : null,
                types == 'success' ? 'bg-success text-[#2E4B44]' : null,
            ];
        },
    },
};
</script>

<style></style>
