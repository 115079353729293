<template>
    <div class="relative">
        <input
            class="w-full border border-secondary rounded-xl text-text-dark text-base focus-visible:outline outline-primary-dark outline-2 p-2"
            min="0"
            type="number"
            :ref="question_id"
            :name="question_id"
            :step="step"
            @change="emitChange"
            v-model="value"
        />
        <div class="absolute inset-y-0 right-8 flex items-center">
            <span class="text-text-dark">{{ symbol }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            symbol: '',
            step: 1,
            value: this.preSelected ? this.preSelected : ''
        };
    },
    props: {
        display: {
            type: String,
            default: null,
        },
        question_id: {
            type: Number,
            default: null,
        },
        preSelected: {
            type: String,
            default: null
        }
    },
    methods: {
        emitChange(e) {
            this.$emit('change', {
                check: this.value,
                id: this.question_id,
                type: 'Number',
            });
        },
    },
    mounted() {
        this.symbol =
            this.display == 'weight'
                ? 'Kgs'
                : this.display == 'distance'
                ? 'cms'
                : this.display == 'height'
                ? 'Inches'
                : null;

        this.step = this.display == 'whole' ? 1 : this.display == 'float' ? 0.01 : 1;
    },
};
</script>

<style></style>
