<template>
    <div class="lg:m-5 lg:p-5">
        <div class="flex justify-center min-h-[225px]">
            <canvas :ref="id" :id="id"></canvas>
        </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js/auto';
import chartConfig from '../../chartConfig';
import ChartDeferred from 'chartjs-plugin-deferred';

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            chart: '',
        };
    },
    props: {
        id: { type: String, default: null },
        data: { type: Object, default: null },
    },
    mounted() {
        setTimeout(() => {
            this.$refs[this.id].style.width = `${this.$el.offsetWidth}px`;
        }, 100);

        Chart.register(ChartDeferred);
        this.renderChart();

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        renderChart() {
            this.chart = new Chart(this.$refs[this.id], {
                type: 'bar',
                data: {
                    labels: this.data.labels,
                    datasets: [
                        {
                            label: this.id,
                            backgroundColor: chartConfig.colors.all_colors,
                            data: this.data.data,
                        },
                    ],
                },
                options: {
                    // maintainAspectRatio: this.aspectRatio,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: chartConfig.options,
                    layout: {
                        padding: 20,
                    },
                    cutoutPercentage: 90,
                    elements: {
                        arc: {
                            borderWidth: 0,
                        },
                    },
                },
            });
            Chart.defaults.color = '#1B3452';
        },
    },
    computed: {
        aspectRatio() {
            return true;
            // if (this.windowWidth < 768) {
            //     return false;
            // } else {
            //     return true;
            // }
        },
    },
    watch: {
        data: {
            handler() {
                // destroy the chart
                this.chart.destroy();
                this.renderChart();
            },
            deep: true,
        },
    },
};
</script>

<style></style>
