<template>
    <div class="mt-4 mb-6 w-full">
        <label v-if="$slots.label" :for="id" class="block text-text-dark text-base">
            <slot name="label"></slot>
            <!-- <span v-if="required == true" style="color: red;">*</span> -->
        </label>

        <span v-if="$slots.hint" class="block text-text-dark text-sm">
            <slot name="hint"></slot>
        </span>

        <span v-if="type == 'number'">
            <div class="mt-1">
                <input
                    @keyup="keyup"
                    v-model="inputValue"
                    :id="id"
                    :name="name"
                    :type="type"
                    step="1"
                    :placeholder="placeholder"
                    :aria-placeholder="placeholder"
                    aria-label="number"
                    :disabled="disabled"
                    :required="required"
                    ref="input"
                    :class="classes"
                />
            </div>
        </span>

        <span v-else-if="type == 'currency'">
            <div class="mt-1 relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm"> {{ currency }} </span>
                </div>
                <input
                    type="text"
                    :id="id"
                    :name="name"
                    class="pl-7 pr-12"
                    :class="classes"
                    placeholder="0.00"
                />
                <div class="absolute inset-y-0 right-0 flex items-center">
                    <label for="currency" class="sr-only">Currency</label>
                    <select
                        v-model="currentCY"
                        id="currency"
                        name="currency"
                        class="h-full py-0 pl-2 pr-7"
                        :class="classes"
                    >
                        <option>GBR</option>
                        <option>USD</option>
                        <option>EUR</option>
                    </select>
                </div>
            </div>
        </span>

        <span v-else-if="type == 'textarea'">
            <div class="mt-1">
                <textarea
                    @keyup="keyup"
                    v-model="inputValue"
                    :name="id"
                    :id="id"
                    :placeholder="placeholder"
                    :aria-placeholder="placeholder"
                    aria-label="text"
                    :disabled="disabled"
                    :required="required"
                    ref="input"
                    :class="classes"
                ></textarea>
            </div>
        </span>

        <span v-else-if="type == 'password'">
            <div class="mt-1 relative">
                <font-awesome-icon
                    :icon="eyeIcon"
                    @click="toggleEye"
                    class="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer text-primary-dark"
                />
                <input
                    @keyup="keyup"
                    v-model="currentValue"
                    :id="id"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :aria-placeholder="placeholder"
                    aria-label="password"
                    :disabled="disabled"
                    :required="required"
                    :ref="`input_${id}`"
                    :class="classes"
                />
            </div>
            <slot name="forgot"></slot>
        </span>

        <span v-else>
            <div class="mt-1">
                <input
                    @keyup="keyup"
                    v-model="inputValue"
                    :id="id"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :aria-placeholder="placeholder"
                    aria-label="text"
                    :disabled="disabled"
                    :required="required"
                    ref="input"
                    :class="classes"
                />
            </div>
        </span>

        <span v-if="$slots.error" class="input__error text-error">
            <i class="fas fa-exclamation-triangle"></i>
            <slot name="error"></slot>
        </span>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
library.add(faEye, faEyeSlash);

export default {
    components: { FontAwesomeIcon },
    data() {
        return {
            currentCY: 'GBR',
            eyeIcon: 'eye-slash',
            currentValue: '',
        };
    },
    props: {
        id: { type: String, default: null },
        name: { type: String, default: null },
        type: { type: String, default: null },
        required: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        autofocus: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        value: {
            validator: (prop) => ['string', 'number'].includes(typeof prop) || prop === null,
            default: null,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.emitValue(value);
            },
        },
        classes() {
            const { type, $slots } = this;

            return [
                `focus-visible:outline outline-2 placeholder-[#96BCB6] rounded-full block w-full sm:text-sm rounded-md py-2 px-3 outline-primary`,
                type == 'textarea' ? 'h-28 resize-none' : null,
                $slots.error ? 'border-error outline-error bg-error-light' : null,
            ];
        },
        currency() {
            const { currentCY } = this;

            if (currentCY == 'GBR') {
                return '£';
            } else if (currentCY == 'USD') {
                return '$';
            } else if (currentCY == 'EUR') {
                return '€';
            }
        },
    },
    methods: {
        toggleEye() {
            if (this.eyeIcon == 'eye-slash') {
                this.eyeIcon = 'eye';
                this.$refs[`input_${this.id}`].type = 'text';
            } else {
                this.eyeIcon = 'eye-slash';
                this.$refs[`input_${this.id}`].type = 'password';
            }
        },
        emitValue(value) {
            if (this.number == true || this.currency == true) {
                if (value == '') {
                    value = 0;
                }
                value = parseFloat(value);
            }
            this.$emit('input', value);
        },
        keyup(e) {
            this.$emit('keyup', e);
        },
    },
    mounted() {
        this.autofocus ? this.$refs[`input_${this.id}`].focus() : '';
        this.currentValue = this.value;
    },
};
</script>
