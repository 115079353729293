<template>
    <div>
        <label v-if="$slots.label" :for="id" class="block text-text-dark text-sm">
            <slot name="label"></slot>
            <!-- <span v-if="required == true" style="color: red;">*</span> -->
        </label>
        <span v-if="range">

        </span>
        <div v-if="range">
            <select class="block w-full px-3 py-2 mb-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white rounded-3xl shadow-sm focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed h-10" @change="setDeafult" v-model="defaultRange" name="preset" id="preset">
                <option selected disabled value="">All</option>
                <option value="custom">Custom</option>
                <option value="week">This Week</option>
                <option value="lweek">Last Week</option>
                <option value="month">This Month</option>
                <option value="lmonth">Last Month</option>
                <option value="year">This Year</option>
                <option value="lyear">Last Year</option>
            </select>
            <t-datepicker
                v-if="defaultRange != ''"
                id="dob"
                name="dob"
                v-model="date"
                :placeholder="placeholder"
                :classes="classes"
                :range="range"
                userFormat="d-m-y"
            >
            </t-datepicker>
        </div>
        <div v-else>
            <t-datepicker
                id="dob"
                name="dob"
                v-model="date"
                :placeholder="placeholder"
                :classes="classes"
                :range="range"
            >
            </t-datepicker>
        </div>
        <span v-if="$slots.error" class="input__error text-error">
            <i class="fas fa-exclamation-triangle"></i>
            <slot name="error"></slot>
        </span>
    </div>
</template>

<script>
import { TDatepicker } from 'vue-tailwind/dist/components';

export default {
    components: { TDatepicker },
    props: {
        value: {
            validator: (prop) => ['string', 'number'].includes(typeof prop) || prop === null,
            default: null,
        },
        id: { type: [String, Number], default: 'dob' },
        placeholder: { type: String, default: 'Date of Birth' },
        range: { type: Boolean, default: false },
    },
    data() {
        return {
            defaultRange: '',
            classes: {
                wrapper: 'flex flex-col',
                dropdownWrapper: 'relative z-10',

                // Dropdown related classes
                dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
                enterClass: 'opacity-0 scale-95',
                enterActiveClass: 'transition transform ease-out duration-100',
                enterToClass: 'opacity-100 scale-100',
                leaveClass: 'opacity-100 scale-100',
                leaveActiveClass: 'transition transform ease-in duration-75',
                leaveToClass: 'opacity-0 scale-95',

                // Wrapper for inline calendar
                inlineWrapper: '',
                inlineViews: 'rounded bg-white border mt-1 inline-flex flex-col',

                // Text input related classes
                inputWrapper: '',
                input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white rounded-3xl shadow-sm focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
                clearButton:
                    'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
                clearButtonIcon: '',

                // Picker views
                viewGroup: '',
                view: '',

                // Navigator
                navigator: 'pt-2 px-3',
                navigatorViewButton:
                    'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
                navigatorViewButtonIcon: 'fill-current text-gray-400',
                navigatorViewButtonBackIcon: 'fill-current text-gray-400',
                navigatorViewButtonMonth: 'text-gray-700 font-semibold',
                navigatorViewButtonYear: 'text-gray-500 ml-1',
                navigatorViewButtonYearRange: 'text-gray-500 ml-1',
                navigatorLabel: 'py-1',
                navigatorLabelMonth: 'text-gray-700 font-semibold',
                navigatorLabelYear: 'text-gray-500 ml-1',
                navigatorPrevButton:
                    'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorNextButton:
                    'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorPrevButtonIcon: 'text-gray-400',
                navigatorNextButtonIcon: 'text-gray-400',

                // Calendar View
                calendarWrapper: 'px-3 py-2',
                calendarHeaderWrapper: '',
                calendarHeaderWeekDay:
                    'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
                calendarDaysWrapper: '',
                calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',

                // Day item
                otherMonthDay:
                    'text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
                emptyDay: '',
                inRangeFirstDay: 'text-sm bg-primary-dark text-white w-full h-8 rounded-l-full',
                inRangeLastDay: 'text-sm bg-primary-dark text-white w-full h-8 rounded-r-full',
                inRangeDay:
                    'text-sm bg-primary w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
                selectedDay:
                    'text-sm rounded-full w-8 h-8 mx-auto bg-primary-dark text-white disabled:opacity-50 disabled:cursor-not-allowed',
                activeDay:
                    'text-sm rounded-full bg-primary w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                highlightedDay:
                    'text-sm rounded-full bg-primary w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary disabled:opacity-50 disabled:cursor-not-allowed',
                today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary disabled:opacity-50 disabled:cursor-not-allowed border border-primary-dark',

                // Months View
                monthWrapper: 'px-3 py-2',
                selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-primary-dark text-white',
                activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-primary',
                month: 'text-sm rounded w-full h-12 mx-auto hover:bg-primary',

                // Years View
                yearWrapper: 'px-3 py-2',
                year: 'text-sm rounded w-full h-12 mx-auto hover:bg-primary',
                selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-primary-dark text-white',
                activeYear: 'text-sm rounded w-full h-12 mx-auto bg-primary',

                // Time selector *Since 2.2*
                timepickerWrapper: 'flex items-center px-4 py-2 space-x-2',
                timepickerTimeWrapper: 'flex items-center space-x-2',
                timepickerTimeFieldsWrapper:
                    'bg-gray-100 rounded-md w-full text-right flex items-center border border-gray-100 focus:border-primary-dark focus:ring-2 focus:ring-primary-dark focus:outline-none focus:ring-opacity-50',
                timepickerOkButton:
                    'text-text-dark text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-primary-dark focus:ring-2 focus:ring-primary-dark focus:outline-none focus:ring-opacity-50 rounded cursor-pointer',
                timepickerInput:
                    'text-center w-8 border-transparent bg-transparent p-0 h-6 text-sm transition duration-100 ease-in-out border border-transparent focus:border-primary-dark focus:ring-2 focus:ring-primary-dark focus:outline-none focus:ring-opacity-50 rounded',
                timepickerTimeLabel: 'flex-grow text-sm text-gray-500',
                timepickerAmPmWrapper:
                    'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-primary-dark focus:ring-2 focus:ring-primary-dark focus:outline-none focus:ring-opacity-50',
                timepickerAmPmWrapperChecked:
                    'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-primary-dark focus:ring-2 focus:ring-primary-dark focus:outline-none focus:ring-opacity-50',
                timepickerAmPmWrapperDisabled:
                    'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
                timepickerAmPmWrapperCheckedDisabled:
                    'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
                timepickerAmPmButton:
                    'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded shadow',
                timepickerAmPmButtonChecked:
                    'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-full bg-white rounded shadow',
                timepickerAmPmCheckedPlaceholder:
                    'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
                timepickerAmPmUncheckedPlaceholder:
                    'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
            },
            date: this.value ? this.value : this.defaultRange
        };
    },
    methods: {
        setDeafult(e) {
            if (e.target.value != 'custom') {
                let today = new Date()
                let prev
                if (e.target.value == 'week') {
                    prev = this.getMonday(new Date())
                } else if (e.target.value == 'lweek') {
                    prev = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)  
                } else if (e.target.value == 'month') {
                    prev = new Date(today.getFullYear(), today.getMonth(), 1);
                } else if (e.target.value == 'lmonth') {
                    prev = new Date(new Date().setMonth(new Date().getMonth() - 1))
                } else if (e.target.value == 'year') {
                    prev = new Date(today.getFullYear(), 0, 1);
                } else if (e.target.value == 'lyear') {
                    prev = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                }
                today = today.toISOString().split('T')[0]
    
                this.date = [prev, today]
            }
        },
        getMonday(d) {
            d = new Date(d);
            var day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        }
    },
    watch: {
        date(newVal, oldVal) {
            this.$emit('input', this.date);
            if (newVal === null || newVal === undefined || newVal.length === 0) {
                this.defaultRange = ''
                this.$emit('input', 'all');

            }
        },
    },
};
</script>

<style></style>
