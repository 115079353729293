<template>
    <div class="flex relative flex-col md:flex-row">
        <aside ref="aside" @click="toggleMenu" class="bg-card-100 shadow-feature flex flex-col md:block w-full md:w-1/4 lg:w-1/6 z-[1] sticky top-0 h-[67px] overflow-hidden md:h-screen md:rounded-tr-lg">

            <div 
                @click="active = 'creds'" 
                class="md:w-auto text-base py-3 px-4 m-2 rounded-md md:cursor-pointer hover:bg-secondary-300" 
                :class="active == 'creds' ? 'hover:bg-card-100 md:hover:bg-secondary order-1 font-bold md:bg-secondary' : 'cursor-pointer w-auto order-2'">
                    Account
            </div>
            <div 
                @click="active = 'info'" 
                class="md:w-auto text-base py-3 px-4 m-2 rounded-md md:cursor-pointer hover:bg-secondary-300" 
                :class="active == 'info' ? 'hover:bg-card-100 md:hover:bg-secondary order-1 font-bold md:bg-secondary' : 'cursor-pointer w-auto order-2'">
                    Profile
            </div>
            <div 
                @click="active = 'preferences'" 
                class="md:w-auto text-base py-3 px-4 m-2 rounded-md md:cursor-pointer hover:bg-secondary-300" 
                :class="active == 'preferences' ? 'hover:bg-card-100 md:hover:bg-secondary order-1 font-bold md:bg-secondary' : 'cursor-pointer w-auto order-2'">
                    Preferences
            </div>
            
            <div class="border-t border-secondary m-2 order-last ">
                <a href="history" class="no-underline text-text-dark">
                    <div class="text-base py-3 px-4 my-2 rounded-md cursor-pointer no-underline hover:bg-secondary-300">
                        History
                    </div>
                </a>
            </div>

            <div class="border-t border-secondary m-2 order-last">
                <div
                    @click="signOut"
                    class=" text-base py-3 px-4 my-2 rounded-md cursor-pointer hover:bg-secondary-300"
                >
                    Sign Out
                </div>
            </div>

            <div ref="arrow" class="md:hidden absolute top-[23px] right-4">
                <font-awesome-icon icon="caret-right" />
            </div>
        </aside>

        <main class="w-full md:w-3/4 lg:w-5/6 px-4 md:px-14 mb-4">
            <div ref="creds" v-show="active == 'creds'">
                <h2 class="text-2xl font-bold leading-none mb-4 mt-4 md:mt-0">Account</h2>
                <form id="creds" class="bg-card-100 px-6 py-1 w-full lg:w-3/4 rounded-lg shadow-feature" method="POST" action="account/credentials-info">
                    <slot name="csrf"></slot>
                    <input type="hidden" name="_method" value="PUT">
                    <input type="hidden" name="_page" value="cred">

                    <anima-input required type='email' id="creds_email" name='email' :value='forms.account.email'>
                        <template #label>Email</template>
                    </anima-input>

                    <anima-input required type='password' id="creds_existing_password" name='existing_password' :value='null'>
                        <template #label>Existing Password</template>
                        <template #error>
                            <div v-if="errors && errors.existing_password">
                                <span class="text-error" v-for="error in errors.existing_password">{{error}}</span>
                            </div>
                        </template>
                    </anima-input>

                    <anima-input type='password' id="creds_password" name='password' :value='forms.account.new_pass'
                        >
                            <template #label>New Password</template>
                            <template #hint>
                                <ul class="mt-2">
                                    <li>- Contains a minimum of 8 characters</li>
                                    <li>- Contains a combination of uppercase and lowercase letters</li>
                                    <li>- Contains at least one number</li>
                                    <li>- Contains at least one special character (!@#$%^&*)</li>
                                </ul>
                            </template>
                            <template #error>
                                <div v-if="errors && errors.password">
                                    <p class="text-error" v-for="error in errors.password">{{error}}</p>
                                </div>
                            </template>
                        </anima-input>

                    <anima-input type='password' id="creds_password_confirmation" name='password_confirmation' :value='forms.account.confirm_new_pass'>
                        <template #label>Confirm Password</template>
                    </anima-input>

                    <div class="w-full my-5">
                        <anima-button long submit>Update</anima-button>
                    </div>
                </form>
            </div>
            <div ref="info" v-show="active == 'info'">
                <h2 class="text-2xl font-bold leading-none mb-4 mt-4 md:mt-0">Profile</h2>
                <form id="profile" class="bg-card-100 px-6 py-1 w-full lg:w-3/4 rounded-lg shadow-feature" method="POST" enctype="multipart/form-data" action="account/personal-info">
                    <slot name="csrf"></slot>
                    <input type="hidden" name="_method" value="PUT">
                    <input type="hidden" name="_page" value="info">
                    <input type="hidden" name="_file" :value="current">

                    <anima-input required type='text' id="profile_fname" name='fname' :value='forms.profile.fname'>
                        <template #label>First Name</template>
                    </anima-input>

                    <anima-input required type='text' id="profile_lname" name='lname' :value='forms.profile.lname'>
                        <template #label>Last Name</template>
                    </anima-input>

                    <div class="mt-4 mb-6">
                        <anima-date name='dob' id='profile_dob' :value='forms.profile.dob'>
                            <template #label>Date of Birth</template>
                            <template #error>
                            <div v-if="errors && errors.dob">
                                <span class="text-error" v-for="error in errors.dob">{{error}}</span>
                            </div>
                        </template>
                        </anima-date>
                    </div>

                    <div class="mt-4 mb-6">
                        <label class="block text-text-dark text-sm ">Gender</label>
                        <select id="profile_gender" name="gender" class="h-12 block w-full sm:text-sm rounded-3xl py-2 px-3 outline-2 focus-within:outline outline-primary" >
                            <option disabled :selected="user.gender == null">Please Select One...</option>
                            <option v-for="(g, idx) in gender" :value="idx" :selected="user.gender == idx">{{ g }}</option>
                        </select>
                        <div v-if="errors && errors.gender">
                            <span class="text-error" v-for="error in errors.gender">{{error}}</span>
                        </div>
                    </div>

                    <div class="mt-4 mb-6">
                        <label for="logo" class="col-md-4 col-form-label">Profile Picture</label>

                        <AnimaUpload
                            ref="profile_image"
                            id="profile_image"
                            name="profile_image"
                            :current="user.profile_image"
                            @input="setImg($event, 'profile_image')"
                        >
                            <template #error>
                                <span v-if="errors && errors.profile_pic">
                                    <span class="text-error" v-for="error in errors.profile_pic">{{error}}</span>
                                </span>
                            </template>
                        </AnimaUpload>
                    </div>

                    <div class="w-full my-5">
                        <anima-button :notification="{ type: 'success', message: 'Personal Info Updated!'}" long submit>Update</anima-button>
                    </div>
                </form>
            </div>
            <div ref="preferences" v-show="active == 'preferences'">
                <h2 class="text-2xl font-bold leading-none mb-4 mt-4 md:mt-0">Preferences</h2>
                <h2 class="text-lg md:text-xl text-text-dark font-bold">Marketing</h2>
                <form id="preferences" class="bg-card-100 px-6 py-1 w-full lg:w-3/4 rounded-lg shadow-feature" method="POST" action="account/credentials-info/marketing">

                    <slot name="csrf"></slot>
                    <slot name="honey"></slot>
                    <input type="hidden" name="_method" value="PUT">
                    <input type="hidden" name="_page" value="preferences">

                    <!-- <anima-option type='checkbox' 
                        :options='["all", "newsletter", "none"]' 
                        :options_text='[
                            "all - Tick to receive newsletters, exclusive offers, product updates, and exciting news from Anima Hub. You can optout at any time by unticking this box.", 
                            "Newsletter Only - Tick to receive newsletters from Anima Hub. You will not receive any other forms of marketing communication. You can optout at any time by unticking this box.",
                            "Opt-Out"
                        ]' 
                        :pre-selected='user.marketing'
                    >
                    </anima-option> -->

                    <anima-option type='radio' name='marketing' id='marketing'
                        :options='["all", "newsletter", "none"]' 
                        :options_text='[
                            "all - Select this option to receive newsletters, exclusive offers, product updates, and exciting news from Anima Hub.", 
                            "Newsletter Only - Select this option to receive newsletters from Anima Hub. You will not receive any other forms of marketing communication.",
                            "Opt-Out - Select this option if you do not wish to receive any marketing communications or newsletters. You will still receive essential information regarding your account."
                        ]' 
                        :pre-selected='user.marketing'
                    >
                    </anima-option>

                    <div class="w-full my-5">
                        <anima-button :notification="{ type: 'success', message: 'Account Info Updated!'}" long submit>Update</anima-button>
                    </div>
                </form>
            </div>
        </main>

        <form ref="logout" action="/logout" method="post" class="hidden">
            <slot name="csrf"></slot>
        </form>
    </div>
</template>

<script>
    import AnimaUpload from '../Forms/AnimaUpload.vue'
    import animaInput from '../Forms/AnimaInput.vue'
    import animaDate from '../Forms/AnimaDate.vue'
    import animaOption from '../Forms/AnimaOption.vue'
    import animaButton from './AnimaBtn.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    
    export default {
        components: {
            animaInput,
            animaDate,
            animaButton,
            animaOption,
            AnimaUpload,
            FontAwesomeIcon 
        },
        props: {
            user: { type: Object, required: true },
            errors: { type: [Object, Array], required: true },
            gender: { type: [Object, Array], required: true },
        },
        data() {
            return {
                active: 'creds',
                forms: {
                    account: {
                        email: this.user.email,
                        pass: '',
                        new_pass: '',
                        confirm_new_pass: ''
                    },
                    profile: {
                        fname: this.user.fname,
                        lname: this.user.lname,
                        dob: this.user.dob,
                        gender: this.gender[this.user.gender],
                        profile_image: this.user.profile_image
                    },
                    preferences: {
                        marketing: this.user.marketing
                    }
                },
                current: this.user.profile_image
            }
        },
        mounted() {
            if (window.location.hash == '#info') {
                this.active = 'info'
            } else if (window.location.hash == '#preferences') {
                this.active = 'preferences'
            } else {
                this.active = 'creds'
            }
        },
        methods: {
            setImg(e) {
                if (e !== null) {
                    this.forms.profile.profile_image = e[0]
                    this.current = e[0]
                } else {
                    this.forms.profile.profile_image = null
                    this.current = null
                }
            },
            signOut() {
                sessionStorage.setItem(
                    'notification',
                    JSON.stringify({
                        type: 'info',
                        messages: [
                            {
                                title: 'Signed out',
                            },
                        ],
                    })
                );
                this.$refs['logout'].submit();
            },
            toggleMenu() {
                if(this.$refs['aside'].classList.contains('h-[67px]')) {
                    this.$refs['aside'].classList.remove('h-[67px]')
                    this.$refs['arrow'].classList.add('rotate-90')
                } else {
                    this.$refs['aside'].classList.add('h-[67px]')
                    this.$refs['arrow'].classList.remove('rotate-90')
                }
            }
        }
    }
</script>