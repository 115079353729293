<template>
    <div>
        <div class="bg-card rounded-t-3xl md:rounded-3xl pt-px md:mb-8 shadow-feature" 
            :class="disabled ? 'blur-[2px] saturate-0 relative' : ''">

            <div class="max-w-[850px] m-auto">

                <div v-if="type != 'assessment'">
                    <p class="text-text-dark font-title font-bold text-xl mt-5">
                        Date
                    </p>
                    <div v-for="question in dateForForm" :key="question.id" class="shadow-checkin pb-5">
                        <div class="text-base text-text-dark pt-2 pb-1">
                            <p v-html="question.label"></p>
                        </div>
                        <div v-if="question.type == 'date_time'">
                            <anima-date-time
                                :question_id="parseInt(0)"
                                display="date"
                                :default="setDateFormat()"
                                @change="setFormDate"
                            ></anima-date-time>
                        </div>
                    </div>
                </div>

                <input name="form_uuid" type="hidden" :value="form.uuid" />
        
                <div v-if="disabled" class=" absolute top-0 w-full h-full z-10"></div>
                <div v-show="pagination[section.id]" v-for="(section, indx) in form.sections" :key="section.id" :class="indx !== form.sections.length - 1 ? 'shadow-checkin' : ''">
                    <div v-show="showSections[section.id]"> 
                        <p class="text-text-dark font-title font-bold text-xl mt-5">
                            {{ section.name }}
                        </p>
                        <div class="text-text-dark text-base">
                            <p v-html="section.description"></p>
                        </div>
                        <div
                            v-show="showQuestions[question.id]"
                            :class="['bg-body-' + ((idx % 2) + 1), idx !== section.questions.length - 1 ? 'shadow-checkin' : '']"
                            class="pb-5"
                            v-for="(question, idx) in section.questions"
                            :key="question.id"
                        >
                            <div class="text-lg font-semibold text-text-dark pt-6 pb-1">
                                <p v-html="question.content"></p>
                            </div>
                            <div class="text-sm text-text-dark pb-2">
                                <p v-html="question.description"></p>
                            </div>

                            <div v-if="question.type == 'radio'">
                                <anima-radio
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :answers="question.answers"
                                ></anima-radio>
                            </div>
                            <div v-if="question.type == 'checkbox'">
                                <anima-checkbox
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :type="question.display"
                                    :answers="question.answers"
                                    :preSelected="edit_answers ? edit_answers.answers[question.id] : null"
                                ></anima-checkbox>
                            </div>
                            <div v-if="question.type == 'emoji_slider'">
                                <anima-emoji-slider
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :display="question.display"
                                    :preSelected="edit_answers ? {[question.id]: edit_answers.answers[question.id]} : null"
                                ></anima-emoji-slider>
                            </div>
                            <div v-if="question.type == 'slider'">
                                <anima-slider
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :preSelected="edit_answers ? {[question.id]: edit_answers.answers[question.id]} : null"
                                ></anima-slider>
                            </div>
                            <div v-if="question.type == 'select'">
                                <anima-select
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :question="question"
                                    :attribute="question.attribute"
                                    :preSelect="edit_answers ? edit_answers.answers[question.id] : null"
                                ></anima-select>
                            </div>
                            <div v-if="question.type == 'text'">
                                <anima-text-area
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :preSelect="edit_answers ? !question.content.toLowerCase().includes('notes') ? edit_answers.answers[question.id] : null : null"
                                ></anima-text-area>
                            </div>
                            <div v-if="question.type == 'accordion'">
                                <anima-accordion
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :attrs="question.answers"
                                    :display="question.display"
                                ></anima-accordion>
                            </div>
                            <div v-if="question.type == 'number'">
                                <anima-Number
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :display="question.display"
                                    :preSelected="edit_answers ? edit_answers.answers[question.id] : null"
                                ></anima-Number>
                            </div>
                            <div v-if="question.type == 'text_short'">
                                <anima-text-short
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :preSelect="edit_answers ? edit_answers.answers[question.id] : null"
                                ></anima-text-short>
                            </div>
                            <div v-if="question.type == 'mapping'">
                                <anima-mapping
                                    @change="toggleHidden"
                                    ref="answers"
                                    :question_id="question.id"
                                    :route="question.api_route ? question.api_route[0] : null"
                                    :display="question.display"
                                    :preSelected="edit_answers ? flattenJson(edit_answers.answers[question.id]) : null"
                                ></anima-mapping>
                            </div>
                            <div v-if="question.type == 'related'">
                                <anima-related
                                    ref="answers"
                                    :question_id="question.id"
                                    :options="question.related"
                                ></anima-related>
                            </div>
                            <div v-if="question.type == 'date_time'">
                                <anima-date-time
                                    ref="answers"
                                    :question_id="question.id"
                                    :display="question.display"
                                    @change="setDateTime"
                                    :default="edit_answers ? edit_answers.answers[question.id] : null"
                                ></anima-date-time>
                            </div>
                            <div v-if="question.type == 'symptom'">
                                <anima-symptom
                                    ref="answers"
                                    :question_id="question.id"
                                    :url="baseurl"
                                    :token="token"
                                ></anima-symptom>
                            </div>
                            <div v-if="question.type == 'meal'">
                                <anima-meal
                                    ref="answers"
                                    :question_id="question.id"
                                    :url="baseurl"
                                    :token="token"
                                    :api_route="question.api_route[0]"
                                    :display="question.display"
                                ></anima-meal>
                            </div>
                        </div>
                    </div>
                </div>
            
                    <div
                        v-show="totalPages > 1"
                        class="flex px-12 py-4"
                        :class="activePagination == 0 ? 'justify-end' : 'justify-between'"
                    >
                        <div class="w-1/4 flex justify-center">
                            <anima-button long v-show="activePagination != 0" @click="movePage('prev')">
                                Previous
                            </anima-button>
                        </div>
                        <div class="w-full flex justify-evenly">
                            <button
                                v-for="i in totalPages"
                                :key="i"
                                class="border-2 rounded-full group my-1 cursor-pointer ease-in duration-100 text-center text-base text-text-dark border-primary-dark px-6 hover:bg-primary hover:border-primary hover:text-white inline-block"
                                :class="
                                    i == activePagination + 1
                                        ? 'bg-checked text-text-darker border-checked'
                                        : ''
                                "
                                @click="setPage(i)"
                            >
                                {{ i }}
                            </button>
                        </div>
                        <div class="w-1/4 flex justify-center">
                            <anima-button
                                long
                                v-show="activePagination + 1 != totalPages"
                                @click="movePage('next')"
                            >
                                Next
                            </anima-button>
                        </div>
                    </div>
            
                <div class="block pb-12" v-show="activePagination + 1 == totalPages">
                    <anima-button long @click="submit">Submit</anima-button>
                </div>
            </div>

    
    
        </div>
    </div>
</template>

<script>
import animaRadio from './AnimaRadio.vue';
import animaCheckbox from './AnimaCheckbox.vue';
import animaEmojiSlider from './AnimaEmojiSlider.vue';
import animaSlider from './AnimaSlider.vue';
import animaTextArea from './AnimaTextArea.vue';
import animaSelect from './AnimaSelect.vue';
import animaAccordion from './AnimaAccordion.vue';
import animaButton from './../Elements/AnimaBtn.vue';
import animaNumber from './AnimaNumber.vue';
import animaTextShort from './AnimaTextShort.vue';
import animaMapping from './AnimaMapping.vue';
import AnimaSymptom from './AnimaSymptom.vue';
import AnimaDateTime from './AnimaDateTime.vue';
import AnimaRelated from './AnimaRelated.vue';
import AnimaMeal from './AnimaMeal.vue';

export default {
    components: {
        animaButton,
        animaRadio,
        animaCheckbox,
        animaEmojiSlider,
        animaSlider,
        animaTextArea,
        animaSelect,
        animaAccordion,
        animaNumber,
        animaTextShort,
        animaMapping,
        AnimaSymptom,
        AnimaDateTime,
        AnimaRelated,
        AnimaMeal
    },
    data() {
        return {
            errors: '',
            questions: [],
            showQuestions: {},
            sections: [],
            showSections: {},
            activePagination: 0,
            pagination: {},
            totalPages: 0,
            sectionsPerPage: 3,
            paginationFormList: ['Weightlifting Assessment'],
            dateForForm: {
                date: {
                    id: 'dateForForm.date',
                    label: 'Please select a date for this entry:',
                    type: 'date_time',
                    value: '',
                }
            }
        };
    },
    props: {
        form: { type: Object, default: null },
        url: { type: String, default: null },
        baseurl: { type: String, default: null },
        token: { type: String, default: null },
        name: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        type: { type: String, required: true },
        edit_answers: { type: Object, default: null },
    },
    methods: {
        setFormDate(e) {
            this.dateForForm.date.value = e.value 
        },
        setDateFormat() {
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const formattedToday = yyyy + '-' + mm + '-' + dd;
            return formattedToday
        },  
        switchCheck(comparitor, check, value, id, type, question_type) {
            if (question_type == 'Number') {
                check = parseInt(check);
                value = parseInt(value);
            }
            let match
            switch (comparitor) {
                case 'eq':
                    if (check == value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'neq':
                    if (check != value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'gt':
                    if (check > value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'gte':
                    if (check >= value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'lt':
                    if (check < value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'lte':
                    if (check <= value) {
                        type[id] = true;
                    } else {
                        type[id] = false;
                    }
                    break;
                case 'cta':
                    match = false
                    if (question_type === 'String') {
                        if (check === value) {
                            type[id] = true;
                        } else {
                            type[id] = false;
                        }
                    } else {
                        check.preview.forEach((item) => {
                            if (item.toLowerCase().includes(value.toLowerCase())) {
                                match = true
                            }
                        })
                        match ? type[id] = true : type[id] = false
                    }
                    break;
                case 'cto':
                    match = false
                    if (question_type === 'String') {
                        if (check <= value) {
                            type[id] = true;
                        } else {
                            type[id] = false;
                        }
                    } else {
                        if (check.preview.length === 1) {
                            for (let i = 0; i < check.preview.length; i++) {
                                const item = check.preview[i];
                                if (item.toLowerCase().includes(value.toLowerCase())) {
                                    match = true
                                    break;
                                }
                                match = false
                            }
                        }
                        match ? type[id] = true : type[id] = false
                    }
                    break;
                case 'ctn':
                    match = true
                    if (question_type === 'String') {
                        if (check != value) {
                            type[id] = true;
                        } else {
                            type[id] = false;
                        }
                    } else {
                        for (let i = 0; i < check.preview.length; i++) {
                            const item = check.preview[i];
                            if (item.toLowerCase().includes(value.toLowerCase())) {
                                match = false
                                break;
                            }
                            match = true
                        }
                        match ? type[id] = true : type[id] = false
                    }
                    break;
                default:
                    type[id] = false;
            }
        },
        searchConditions(search_arr, check, id, condition_arr, question_type) {
            search_arr.forEach((item) => {
                item.conditions.forEach((condition) => {
                    const { question_id, comparitor, value } = condition;
                    if (question_id == id) {
                        //if answer to question can be an array e.g. checkbox loop through answers checked
                        if (typeof check == 'object' && check.length > 0) {
                            check.forEach((checkValue) => {
                                this.switchCheck(
                                    comparitor,
                                    checkValue,
                                    value,
                                    item.id,
                                    condition_arr,
                                    question_type
                                );
                            });
                        } else {
                            this.switchCheck(
                                comparitor,
                                check,
                                value,
                                item.id,
                                condition_arr,
                                question_type
                            );
                        }
                    }
                });
            });
        },
        toggleHidden({ check, id, type = 'String' }) {
            //check all questions for conditions related to changed question
            this.searchConditions(this.questions, check, id, this.showQuestions, type);

            //check all sections for conditions related to changed question
            this.searchConditions(this.sections, check, id, this.showSections, type);
        },
        submit() {
            let form_data = {
                form_uuid: this.form.uuid,
                answers: {},
                date: this.setDateFormat(),
                sections: this.showSections,
                questions: this.showQuestions,
            };
            form_data.answers = this.getAnswers();

            if (this.dateForForm.date.value != '') {
                form_data.date = this.dateForForm.date.value
            }

            for (let id in form_data.answers) {
                let question
                let section
                for (let key in this.questions) {
                    question = this.questions[key]
                    if (question.id == id) {
                        section = question.section_id
                        break;
                    }
                }
                if (section) {
                    if (!this.showSections[section]) {
                        form_data.answers[id] = null;
                    } else if (!this.showQuestions[id]) {
                        form_data.answers[id] = null;
                    } else if (question.type == 'slider' && form_data.answers[id] == 0) {
                        form_data.answers[id] = null
                    }
                    
                }

            }

            fetch(this.url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'X-CSRF-TOKEN': this.token,
                },
                body: JSON.stringify(form_data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then((response) => {
                    sessionStorage.setItem(
                        'notification',
                        JSON.stringify({
                            type: 'success',
                            messages: [
                                {
                                    title: `${this.name} complete`,
                                },
                            ],
                        })
                    );

                    // this.$router.push('/')
                    window.location.href = '/';
                })
                .catch((error) => {
                    this.errors = error;
                    error.json().then((json) => {
                        this.errors = json;
                        let arr = [];
                        for (let i = 0; i < json.errors.answers.length; i++) {
                            const error = json.errors.answers[i];
                            arr.push({
                                title: json.message,
                                message: error,
                            });
                        }
                        let notification = {
                            type: 'error',
                            messages: arr,
                        };
                        sessionStorage.setItem('notification', JSON.stringify(notification));
                        // used as if error occurs page doesnt change so need to manualy trigger event
                        eventHub.$emit('show');
                    });
                    window.scrollTo(0, 0);
                });
        },
        getAnswers() {
            //get answers based on $refs from each input child componenet
            const answers = this.$refs['answers'];
            let ans = {};

            for (let i = 0; i < answers.length; i++) {
                const refs = answers[i].$refs;
                for (const property in refs) {
                    if (refs[property].data && typeof refs[property].data == 'object') {
                        ans[property] = refs[property].value;
                    } else if (refs[property].id == 'symptom_selector_data') {
                        ans[property] = JSON.parse(refs[property].textContent);
                    } else if (refs[property].id == 'meal_selector_data') {
                        ans[property] = JSON.parse(refs[property].textContent);
                    } else if (refs[property][0]) {
                        if (refs[property][0].type == 'checkbox') {
                            let arr = [];
                            for (let i = 0; i < refs[property].length; i++) {
                                const element = refs[property][i];
                                if (element.checked) {
                                    let question = this.questions.filter((obj) => {
                                        return obj.id == property;
                                    });

                                    if (question[0].attribute == null) {
                                        arr.push(element.value);
                                    } else {
                                        arr.push(element.name);
                                    }
                                }
                            }
                            ans[property] = arr;
                        } else if (refs[property][0].type == 'radio') {
                            for (let i = 0; i < refs[property].length; i++) {
                                const element = refs[property][i];
                                if (element.checked) {
                                    let question = this.questions.filter((obj) => {
                                        return obj.id == property;
                                    });
                                    if (question[0].attribute == null) {
                                        ans[property] = element.value;
                                    } else {
                                        ans[property] = element.name;
                                    }
                                }
                            }
                        } else if (refs[property].tagName == 'SELECT') {
                            ans[property] = refs[property].value;
                        } else if (refs[property][0].tagName == 'SELECT') {
                            let arr = [];
                            for (let i = 0; i < refs[property].length; i++) {
                                const element = refs[property][i];
                                arr.push(element.value);
                            }
                            ans[property] = arr;
                        } else {
                            if (refs[property][0].tagName != 'OPTION') {
                                ans[property] = refs[property][0].value;
                            }
                        }
                    } else {
                        if (refs[property].getAttribute('data-type') == 'datetime') {
                            if (refs[property].value == 'null:null') {
                                ans[refs[property].name] = '00:00'
                            } else {
                                ans[refs[property].name] = refs[property].value;
                            }
                        } else if (property != 'slider__container') {
                            ans[refs[property].name] = refs[property].value;
                        }
                    }
                }
            }

            return ans;
        },
        setDateTime($e) {
            // this.$refs['answers'][$e.id] = $e.value;
        },
        setPage(i) {
            this.activePagination = i - 1;
            let idx = 0;
            for (const key in this.pagination) {
                if (this.pagination[key]) {
                    this.pagination[key] = false;
                }
                if (Math.floor(idx / 2) == this.activePagination) {
                    this.pagination[key] = true;
                }
                idx++;
            }
            window.scrollTo(0, 0);
        },
        movePage(dir) {
            if (dir == 'next' && this.activePagination + 1 < this.totalPages) {
                this.activePagination++;
            }

            if (dir == 'prev' && this.activePagination > 0) {
                this.activePagination--;
            }

            let idx = 0;
            for (const key in this.pagination) {
                if (this.pagination[key]) {
                    this.pagination[key] = false;
                }
                if (Math.floor(idx / 2) == this.activePagination) {
                    this.pagination[key] = true;
                }
                idx++;
            }
            window.scrollTo(0, 0);
        },
        recursiveFlatten(obj, flatList, path = "") {
            if (Array.isArray(obj)) {
                if (Array.isArray(obj[0])) {
                    obj.forEach(item => this.recursiveFlatten(item, flatList, path));
                } else {
                    if(obj.length > 0) {
                        this.recursiveFlatten(obj[obj.length - 1], flatList, path)
                    }
                }
            } else if (typeof obj === "object" && obj !== null) {
                for (const key in obj) {
                    const newPath = path ? `${path} - ${key}` : key;
                    this.recursiveFlatten(obj[key], flatList, newPath);
                }
            } else {
                flatList.push(`${path} - ${obj}`);
            }

            return flatList
        },
        flattenJson(jsonObj) {
            let flatList = [];

            flatList = this.recursiveFlatten(jsonObj, flatList);
            return flatList;
        },
    },
    created() {
        if (this.paginationFormList.indexOf(this.form.name) > -1) {
            this.totalPages = Math.ceil(this.form.sections.length / this.sectionsPerPage);
        } else {
            this.totalPages = 1;
        }

        this.form.sections.forEach((section, idx) => {
            if (this.totalPages != 1) {
                if (Math.floor(idx / this.sectionsPerPage) == 0) {
                    this.$set(this.pagination, section.id, true);
                } else {
                    this.$set(this.pagination, section.id, false);
                }
            } else {
                this.$set(this.pagination, section.id, true);
            }

            this.sections.push(section);
            if (section.conditions.length > 0) {
                this.$set(this.showSections, section.id, false);
            } else {
                this.$set(this.showSections, section.id, true);
            }
            section.questions.forEach((question) => {
                question.section_id = section.id
                if (question.type == 'mapping') {
                    if (question.api_route != null) {
                        this.questions.push(question);

                        if (question.conditions.length > 0) {
                            this.$set(this.showQuestions, question.id, false);
                        } else {
                            this.$set(this.showQuestions, question.id, true);
                        }
                    } else {
                        this.$set(this.showQuestions, question.id, false);
                    }
                } else {
                    this.questions.push(question);

                    if (question.conditions.length > 0) {
                        this.$set(this.showQuestions, question.id, false);
                    } else {
                        this.$set(this.showQuestions, question.id, true);
                    }
                }
            });
        });
    },
};
</script>

<style>
.bg-body-1 {
    @apply bg-card;
}
.bg-body-2 {
    @apply bg-card-200;
}
</style>
