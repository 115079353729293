<template>
    <div :id="id" class="w-full">
        <div class="flex items-center justify-around py-2 rounded-xl bg-card-100 shadow-feature">
            <div v-for="(tab, i) in tabs" :key="i" @click="tabChange(i)">
                <p
                    :class="[
                        activeTab == i ? 'bg-secondary text-text-dark' : ' text-text-darker',
                        tab.$props.disabled ? 'cursor-not-allowed text-gray-500' : null
                    ]"
                    class="cursor-pointer text-base px-4 rounded-xl"
                >
                    {{ tab.$props.name }}
                </p>
            </div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabs: [],
            activeTab: null,
        };
    },
    props: {
        id: { type: String, default: null },
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        for (let i = 0; i < this.$children.length; i++) {
            if (this.tabs[i]._data.isActive == true) {
                this.activeTab = i;
            }
        }
    },
    methods: {
        tabChange(i) {
            if (!this.tabs[i].$props.disabled) {
                this.activeTab = i;

                for (let j = 0; j < this.tabs.length; j++) {
                    this.tabs[j]._data.isActive = false;
                }

                this.tabs[i]._data.isActive = true;
                this.$emit('tab-change', i);
            }
        },
    },
};
</script>
