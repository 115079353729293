<template>
    <div class="flex flex-wrap">
        <div v-for="r in this.resources.slice(0, limit)" :key="r.id" class="w-full md:w-1/3">
            <div class="bg-card text-text-dark rounded-3xl m-5 shadow-feature">
                <img
                    v-if="r.thumbnail"
                    class="w-full rounded-t-3xl"
                    :src="
                        r.thumbnail.url
                            ? r.thumbnail.url
                            : r.thumbnail.urls.default
                            ? r.thumbnail.urls.default
                            : null
                    "
                    alt="hola"
                />

                <div class="text-base p-4">
                    <a :href="'resources/'+r.slug" class="no-underline text-text-dark hover:underline">{{ r.title }}</a>
                    <a :href="'resources/'+r.slug" class="no-underline text-text-dark hover:underline">{{ r.title }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resources: { type: Array, default: null },
        limit: { type: Number, default: 15 },
    },
};
</script>
