<template>
    <div>
        <div class="flex justify-center lg:p-5 lg:pt-2 min-h-[225px] h-[290px] md:h-[500px]" 
            :class="currentFilter ? 'lg:m-2 lg:mt-0' : 'lg:m-2' ">
            <canvas :ref="id" :id="id"></canvas>
        </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js/auto';
import chartConfig from '../../chartConfig';
import ChartDeferred from 'chartjs-plugin-deferred';
import 'chartjs-adapter-moment';

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            chart: '',
            datasets: [],
            datasets_combined: {},
            maxY: 0,
            stepY: 0,
            yLabels: {},
        };
    },
    props: {
        id: { type: String, default: null },
        linedata: { type: Object, default: null },
        dates: { type: Object, default: null },
        lineSteps: { type: Object, default: null },
        currentFilter: { type: String, default: null },
        filter_type: { type: String, default: null },
        question_type: { type: String, default: null },
        question_display: { type: String, default: null },
        data_aggregation: { type: String, default: null },
        persist: { type: Boolean, default: false },
    },
    async mounted() {
        setTimeout(() => {
            this.$refs[this.id].style.width = `${this.$el.offsetWidth}px`;
        }, 100);

        if (this.question_type == 'emoji_slider') {
            if (this.question_display == 'frequency') {
                this.yLabels = {
                    0: 'Never', 
                    1: 'Rarely', 
                    2: 'Sometimes', 
                    3: 'Regularly', 
                    4: 'Daily',
                }
            } else if (this.question_display == 'intensity') {
                this.yLabels = {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Moderate', 
                    3: 'High', 
                    4: 'Very High',
                }
            } else {
                this.yLabels = {
                    0: 'Not At All',
                    1: 'Slightly', 
                    2: 'Moderately', 
                    3: 'Very', 
                    4: 'Extremely'
                }
            }
        }

        this.setDataset();
        this.orderDatasets()
        this.combineDatasets();
        this.fillInDates();
        Chart.register(ChartDeferred);
        this.renderChart();

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        setDataset() {
            let idx = 0;
            this.datasets = [];
            this.maxY = 0;
            for (const key in this.linedata) {
                if (Object.hasOwnProperty.call(this.linedata, key)) {
                    const element = this.linedata[key];
                    element.data.forEach(point => {
                        if (point.y != undefined) {
                            if (point.y > this.maxY) {
                                this.maxY = Math.ceil(point.y)
                            }
                        }
                    });

                    const color = idx > 15 ? chartConfig.colors.all_colors[Math.ceil(idx % 15)] : chartConfig.colors.all_colors[idx]

                    this.datasets.push({
                        data: element.data,
                        label: element.label,
                        backgroundColor: color,
                        borderColor: color,
                        fill: false,
                        categoies: element.categories
                    });
                    idx++;
                }
            }

            this.maxY = Math.ceil(this.maxY * 1.1)

            if (this.question_type == 'emoji_slider') {
                this.maxY = 4
            }

            this.stepY = (this.maxY <= 10) ? 1 :
                (this.maxY <= 20) ? 2 :
                (this.maxY <= 50) ? 5 :
                (this.maxY <= 200) ? 10 :
                (this.maxY <= 500) ? 50 :
                (this.maxY <= 1000) ? 100 : 250;


            this.maxY = Math.round(this.maxY/this.stepY) * this.stepY;

            if (this.maxY == 0 || this.question_type == 'slider') {
                this.maxY = 10
            }
        },
        renderChart() {
            const chartAreaBorder = {
                id: 'chartAreaBorder',
                beforeDraw(chart, args, options) {
                    const {ctx, chartArea: {left, top, width, height}} = chart;
                    ctx.save();
                    ctx.strokeStyle = options.borderColor;
                    ctx.lineWidth = options.borderWidth;
                    ctx.setLineDash(options.borderDash || []);
                    ctx.lineDashOffset = options.borderDashOffset;
                    ctx.strokeRect(left, top, width, height);
                    ctx.restore();
                }
            };

            this.chart = new Chart(this.$refs[this.id], {
                type: 'line',
                data: {
                    datasets: this.datasets_combined,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        ...chartConfig.options,
                        tooltip: {
                            callbacks: {
                                label: (ctx) => {
                                    let label = ctx.dataset.label

                                    if (this.question_type == 'emoji_slider') {
                                        return `${label}: ${this.yLabels[parseInt(ctx.dataset.data[ctx.dataIndex].y)]}`
                                    }
                                    return `${label}: ${parseFloat(ctx.dataset.data[ctx.dataIndex].y)}`;
                                }
                            }
                        },
                        chartAreaBorder: {
                            borderWidth: 1,
                            borderColor: '#DFD8D1'
                        }
                    },
                    layout: {
                        padding: 20,
                    },
                    elements: {
                        arc: {
                            borderWidth: 0,
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            min: this.dates.start,
                            max: this.dates.end,
                            time: {
                                tooltipFormat: this.tooltipFormat,
                                unit: this.lineSteps.unit,
                                displayFormats: {
                                    day: 'MMM DD',
                                    week: 'MMM DD',
                                    month: "MMM 'YY",
                                },
                            },
                            ticks: {
                                stepSize: this.lineSteps.step,
                            },
                        },
                        y: {
                            min: 0,
                            max: this.maxY,
                            ticks: {
                                callback: (value, index, values) => {
                                    if (this.question_type == 'emoji_slider') {
                                        return this.yLabels[value]
                                    }
                                    return value
                                },
                                stepSize: this.stepY
                            },
                        },
                    },
                },
                plugins: [chartAreaBorder]
            });
            Chart.defaults.color = '#1B3452';
        },
        orderDatasets() {
            this.datasets.forEach(dataset => {
                dataset.data.sort((a,b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))
            });
        },
        combineDatasets() {
            this.datasets_combined = {}
            this.datasets.forEach(dataset => {
                
                if (dataset.label in this.datasets_combined) {
                    dataset.data.forEach(newData => {
                        if (newData.x in this.datasets_combined[dataset.label].data) {
                            if (newData.y != 0) {
                                this.datasets_combined[dataset.label].data[newData.x].count += 1
                                this.datasets_combined[dataset.label].data[newData.x].y = 
                                    parseFloat(this.datasets_combined[dataset.label].data[newData.x].y) 
                                    + parseInt(newData.y)
                            }
                        } else {
                            this.datasets_combined[dataset.label].data[newData.x] = {
                                x: newData.x,
                                y: newData.y,
                                count: 1
                            }
                        }
                    });
                } else {
                    this.datasets_combined[dataset.label] = structuredClone(dataset)

                    this.datasets_combined[dataset.label].data = {}
                    dataset.data.forEach(item => {
                        this.datasets_combined[dataset.label].data[item.x] = {
                            x: item.x,
                            y: item.y,
                            count: 1
                        };
                    });
                }
            });

            this.datasets_combined = Object.values(this.datasets_combined)
            for (const key in this.datasets_combined) {
                const dataset = this.datasets_combined[key];
                dataset.data = Object.values(dataset.data)
                if (this.data_aggregation == 'average') {
                    dataset.data.forEach(data => {
                        data.y = parseFloat(data.y / data.count).toFixed(1)
                    });
                }
            }

            this.datasets_combined.forEach(dataset => {
                dataset.data.sort((a,b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))
            });
        },
        fillInDates() {
            this.datasets_combined.forEach((set) => {
                let firstData = set.data[0].x
                let lastData = set.data[set.data.length - 1].x
                let currentDtae = new Date(firstData)
                let lastDtae = new Date(lastData)
                let prevDate
                let nextDate

                if (this.lineSteps.unit == 'day') {
                    if (this.lineSteps.step == 1) {
                        prevDate = currentDtae.setDate(currentDtae.getDate() - 1)
                        prevDate = new Date(prevDate).toISOString().split('T')[0]

                        nextDate = lastDtae.setDate(lastDtae.getDate() + 1)
                        nextDate = new Date(nextDate).toISOString().split('T')[0]
                    } else if (this.lineSteps.step == 7) {
                        prevDate = currentDtae.setDate(currentDtae.getDate() - 7)
                        prevDate = new Date(prevDate).toISOString().split('T')[0]

                        nextDate = lastDtae.setDate(lastDtae.getDate() + 7)
                        nextDate = new Date(nextDate).toISOString().split('T')[0]
                    }
                } else if (this.lineSteps.unit == 'month') {
                    prevDate = new Date(currentDtae.getFullYear(), currentDtae.getMonth() - 1, 1);
                    prevDate = new Date(prevDate).toISOString().split('T')[0]

                    nextDate = new Date(lastDtae.getFullYear(), lastDtae.getMonth() + 1, 1);
                    nextDate = new Date(nextDate).toISOString().split('T')[0]
                }

                if (set.data[0].x > this.dates.start) {
                    let obj = {
                        x: prevDate,
                        y: 0
                    }
                    set.data.unshift(obj)
                
                    if (prevDate !== this.dates.start) {
                        let obj2 = {
                            x: this.dates.start,
                            y: 0
                        }
                        set.data.unshift(obj2)
                    }
                }

                if (this.persist) {
                    let obj3 = {
                        x: this.dates.end,
                        y: set.data[set.data.length - 1].y
                    }
                    set.data.push(obj3)
                } else {
                    if (this.lineSteps.step != 1 && this.lineSteps.unit != 'day') {
                        let obj3 = {
                            x: nextDate,
                            y: 0
                        }
                        set.data.push(obj3)
        
                        if (set.data[set.data.length - 1].x < this.dates.end) {
                            let obj4 = {
                                x: this.dates.end,
                                y: 0
                            }
                            set.data.push(obj4)
                        }
                    } else {
                        let d1 = new Date(set.data[set.data.length - 1].x)
                        let d2 = new Date(this.dates.end)
                        if (this.lineSteps.step == 1 && this.lineSteps.unit == 'month') {
                            if (d1.getMonth() !== d2.getMonth()) {
                                let obj3 = {
                                    x: nextDate,
                                    y: 0
                                }
                                set.data.push(obj3)
                
                                if (d2.getMonth() - d1.getMonth() != 1) {
                                    let obj4 = {
                                        x: this.dates.end,
                                        y: 0
                                    }
                                    set.data.push(obj4)
                                }
                            }
                        } else if (this.lineSteps.step == 7 && this.lineSteps.unit == 'day') {
                            let currentMonday = this.getPrevMonday(this.dates.end)
                            currentMonday = currentMonday.toISOString().split('T')[0]

                            const diffDays = this.dateDiff(set.data[set.data.length - 1].x, currentMonday)

                            if (diffDays > 7) {
                                let obj3 = {
                                    x: nextDate,
                                    y: 0
                                }
                                set.data.push(obj3)
                            }

                            if (set.data[set.data.length - 1].x < currentMonday) {
                                let obj4 = {
                                    x: currentMonday,
                                    y: 0
                                }
                                set.data.push(obj4)
                            }
                        } else {
                            if (nextDate < this.dates.end) {
                                let obj3 = {
                                    x: nextDate,
                                    y: 0
                                }
                                set.data.push(obj3)
    
                                let obj4 = {
                                    x: this.dates.end,
                                    y: 0
                                }
                                set.data.push(obj4)
                            } else if (nextDate == this.dates.end) {
                                let obj4 = {
                                    x: this.dates.end,
                                    y: 0
                                }
                                set.data.push(obj4)
                            }
                        }
                    }
                }
            });
        },
        getPrevMonday(givenDate) {
            const date = new Date(givenDate);
            const dayOfWeek = date.getDay();
            const daysUntilNextMonday = (7 - dayOfWeek + 1) % 7; // 1 represents Monday

            date.setDate(date.getDate() - 7 + daysUntilNextMonday);

            return date;
        },
        dateDiff(d1, d2) {
            const diffTime = Math.abs(new Date(d2) - new Date(d1));
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        }
    },
    computed: {
        aspectRatio() {
            return true;
            // if (this.windowWidth < 768) {
            //     return false;
            // } else {
            //     return true;
            // }
        },
        tooltipFormat() {
            if (this.lineSteps.unit === 'day' || this.lineSteps.unit === 'week') {
                return 'MMM DD';
            } else if (this.lineSteps.unit === 'month') {
                return "MMM 'YY";
            }
        },
    },
    updated() {
        this.chart.destroy();
        this.setDataset();
        this.orderDatasets();
        this.combineDatasets();
        this.fillInDates();
        this.renderChart();
    },
    watch: {
        linedata: {
            handler() {
                this.$forceUpdate()
            },
        },
        currentFilter: {
            handler() {
                this.chart.destroy();
                this.setDataset();
                let results
                if (this.currentFilter != 'All') {
                    if (this.filter_type == 'label') {
                        results = this.datasets.filter((set) => {
                            return set.label.includes(this.currentFilter) ? set : null
                        })
                    } else {
                        results = this.datasets.filter((set) => {
                            return set.categoies.includes(this.currentFilter) ? set : null
                        })
                    }
                    this.datasets = results;

                }
                this.orderDatasets()
                this.combineDatasets();

                this.renderChart();
            },
        }
    },
};
</script>