<template>
    <div class="mt-1 mb-4 relative">
        <h3 class="text-lg md:text-xl">{{ option | capitalize }}</h3>
        <slot name="tip"></slot>
        <!-- <div class="relative input__slider--score mt-4">
            <div
                ref="score"
                :style="'left:' + computed_left"
                class="bg-checked absolute inline-block p-1 px-2 rounded-lg font-bold w-8 text-center"
            >
                {{ slider_value }}
                <div
                    class="w-0 h-0 border-t-[6px] border-r-[6px] border-l-[6px] border-transparent border-t-checked absolute top-[100%] left-[10px]"
                ></div>
            </div>
        </div> -->
        <label ref="slider__container" class="input__slider--container" data-min="0" data-max="10">
            <div class="length__title field-title" data-length="0"></div>
            <input
                v-model="slider_value"
                :name="question_id"
                :ref="question_id"
                @change="update(option)"
                :id="option"
                class="input__slider"
                type="range"
                step="1"
                min="0"
                max="10"
                value="0"
            />
            <span class="absolute bottom-0 w-[calc(100%-22px)] flex justify-between">
                <span class="w-[2px] h-2 opacity-25 text-center bg-[#205685]"></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span
                    class="w-[2px] h-2 opacity-25 text-center bg-[#205685] flex justify-center"
                ></span>
                <span class="w-[2px] h-2 opacity-25 text-center bg-[#205685]"></span>
            </span>
        </label>
        <div class="flex justify-between text-[#205685] font-semibold">
            <span class="w-6 text-center">0</span>
            <span class="w-6 text-center flex justify-center">1</span>
            <span class="w-6 text-center flex justify-center">2</span>
            <span class="w-6 text-center flex justify-center">3</span>
            <span class="w-6 text-center flex justify-center">4</span>
            <span class="w-6 text-center flex justify-center">5</span>
            <span class="w-6 text-center flex justify-center">6</span>
            <span class="w-6 text-center flex justify-center">7</span>
            <span class="w-6 text-center flex justify-center">8</span>
            <span class="w-6 text-center flex justify-center">9</span>
            <span class="w-6 text-center">10</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            slider_value: '0',
            sliderProps: {
                fill: '#86c4b9',
                background: '#edd3c2',
            },
        };
    },
    props: {
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        options: { type: Array, default: null },
        option: { type: String, default: null },
        question_id: { type: [Number, String], default: null },
        preSelected: { type: Object, default: null },
    },
    methods: {
        applyFill(slider, sliderValue) {
            const percentage = (100 * (slider.value - slider.min)) / (slider.max - slider.min);
            const bg = `linear-gradient(90deg, ${this.sliderProps.fill} ${percentage}%, ${
                this.sliderProps.background
            } ${percentage + 0.1}%)`;
            slider.style.background = bg;
            sliderValue.setAttribute('data-length', slider.value);
        },
        update(option) {
            if (this.options) {
                this.results[option] = this.$refs[option][0].value;
                this.$emit('change', {
                    check: this.$refs[option][0].value,
                    id: this.question_id,
                    type: 'Numnber',
                });
            }
        },
    },
    created() {
        if (this.preSelected) {
            for (const key in this.preSelected) {
                if (Object.hasOwnProperty.call(this.preSelected, key)) {
                    const val = this.preSelected[key];
                    if (key == this.question_id) {
                        this.slider_value = val;
                    }
                }
            }
        }
    },
    mounted() {
        if (this.$refs.slider__container) {
            if (this.options) {
                const all = this.$refs.slider__container;
                for (let i = 0; i < all.length; i++) {
                    const slider = all[i];
                    const sliderValue = slider.querySelector('.length__title');

                    slider.querySelector('input').addEventListener('input', (event) => {
                        sliderValue.setAttribute('data-length', event.target.value);
                        this.applyFill(event.target, sliderValue);
                    });

                    this.applyFill(slider.querySelector('input'), sliderValue);
                }
            } else {
                const slider = this.$refs.slider__container;
                const sliderValue = slider.querySelector('.length__title');

                slider.querySelector('input').addEventListener('input', (event) => {
                    sliderValue.setAttribute('data-length', event.target.value);
                    this.applyFill(event.target, sliderValue);
                });

                this.applyFill(slider.querySelector('input'), sliderValue);
            }
        }
    },
    computed: {
        results() {
            if (this.options) {
                let arr = {};
                this.options.forEach((o) => {
                    arr[o] = this.$refs[o][0].value;
                });
                this.$emit('input', arr);
                return arr;
            } else {
                this.$emit('input', this.$refs[this.option].value);
                return this.$refs[this.option].value;
            }
        },
        computed_left() {
            let max = 10;
            let per = (this.slider_value / max) * 100;
            return 'calc(' + per + '% - ' + '6px)';
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
    watch: {
        slider_value(slider_value) {
            this.$emit('input', slider_value);
        },
    },
};
</script>

<style>
.input__slider {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;
    border: none;
    background: #86c4b9;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.input__slider--container {
    position: relative;
    width: 100%;
    height: calc(65px - 10px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
}

.input__slider--notch,
.input__slider--score {
    width: calc(100% - (18px));
    height: 10px;
    position: absolute;
    bottom: 0;
}

.input__slider--score {
    bottom: 125%;
}

.input__slider--notch span {
    display: inline-block;
    width: 2px;
    height: 10px;
    position: absolute;
    background: #205685;
    top: 0;
}

.input__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #1d7078;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.input__slider::-webkit-slider-thumb:hover {
    background: #86c4b9;
    transform: scale(1.2);
}

.input__slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #1d7078;
    cursor: pointer;
    transition: background 0.15s ease-in-out;
}

.input__slider::-moz-range-thumb:hover {
    background: #86c4b9;
}
</style>
