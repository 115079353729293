<template>
    <div class="w-full h-full flex flex-col justify-between">
        <form id="form" enctype="multipart/form-data" class="w-full h-full relative">
            <div v-if="error_list.invite">
                <label class="block text-text-dark text-sm">
                    <slot name="label">Invite Code</slot>
                    <!-- <span v-if="required == true" style="color: red;">*</span> -->
                </label>
                <span class="input__error text-error">
                    {{ error_list.invite[0] }}
                </span>
            </div>

            <slot name="honey"></slot>

            <!-- name -->
            <div v-if="type == 'register' || type == 'interest'" class="my-4">
                <anima-input
                    ref="fname"
                    type="text"
                    id="First Name"
                    name="fname"
                    placeholder="First Name"
                    required
                    :value="details ? details.fname : ''"
                >
                    <template #label>First Name</template>
                    <template #error>
                        <span v-if="error_list.name">
                            {{ error_list.name[0] }}
                        </span>
                    </template>
                </anima-input>

                <anima-input
                    ref="lname"
                    type="text"
                    id="Last name"
                    name="lname"
                    placeholder="Last Name"
                    required
                    :value="details ? details.lname : ''"
                >
                    <template #label>Last Name</template>
                    <template #error>
                        <span v-if="error_list.name">
                            {{ error_list.name[0] }}
                        </span>
                    </template>
                </anima-input>
            </div>

            <!-- profile pic -->
            <div v-if="type == 'register'" class="my-4">
                <label for="logo" class="col-md-4 col-form-label">Profile Picture</label>

                <AnimaUpload
                    ref="profile_pic"
                    id="profile_pic"
                    name="profile_pic"
                    @input="setValue($event, 'profile_pic')"
                >
                    <template #error>
                        <span v-if="error_list.file">
                            {{ error_list.file[0] }}
                        </span>
                    </template>
                </AnimaUpload>
            </div>

            <!-- gender, dob -->
            <div v-if="type == 'register'" class="my-4">
                <anima-date @input="setValue($event, 'dob')" name="dob" id="dob" ref="dob">
                    <template #label>Date of Birth</template>
                    <template #error>
                        <span v-if="error_list.dob">
                            {{ error_list.dob[0] }}
                        </span>
                    </template>
                </anima-date>

                <animaOption
                    @change="setValue($event, 'gender')"
                    type="select"
                    :options="['Male', 'Female', 'Other']"
                    ref="gender"
                >
                    <template #label>Gender</template>
                    <template #error>
                        <span v-if="error_list.gender">
                            {{ error_list.gender[0] }}
                        </span>
                    </template>
                </animaOption>
            </div>

            <!-- country, lang -->
            <div v-if="type == 'register' || type == 'interest'" class="my-4">
                <animaOption
                    @change="setValue($event, 'country')"
                    type="select"
                    :options="countries"
                    ref="country"
                    :pre-selected="details ? details.country : null"
                >
                    <template #label>Country</template>
                    <template #error>
                        <span v-if="error_list.country">
                            {{ error_list.country[0] }}
                        </span>
                    </template>
                </animaOption>

                <animaOption
                    @change="setValue($event, 'lang')"
                    type="select"
                    :options="language"
                    ref="lang"
                    :pre-selected="details ? details.language : null"
                >
                    <template #label>Preferred Language</template>
                    <template #error>
                        <span v-if="error_list.lang">
                            {{ error_list.lang[0] }}
                        </span>
                    </template>
                </animaOption>
            </div>

            <!-- account type -->
            <div v-if="type == 'register' || type == 'interest'" class="my-4">
                <animaOption
                    @change="setValue($event, 'account_type')"
                    type="select"
                    :options="['Business', 'Personal']"
                    ref="account_type"
                    :pre-selected="details ? details.account_type : null"
                >
                    <template #label>User Type</template>
                    <template #error>
                        <span v-if="error_list.account_type">
                            {{ error_list.account_type[0] }}
                        </span>
                    </template>
                </animaOption>

                <anima-input
                    v-show="isBusiness"
                    ref="business_name"
                    type="text"
                    id="business name"
                    name="business name"
                    placeholder="Business Name"
                    required
                    :value="details ? details.business_name : ''"
                >
                    <template #label>Business Name</template>
                    <template #error>
                        <span v-if="error_list.business">
                            {{ error_list.business[0] }}
                        </span>
                    </template>
                </anima-input>
            </div>

            <!-- business role -->
            <div v-if="type == 'register'" class="my-4">
                <anima-input
                    v-show="isBusiness"
                    ref="business_role"
                    type="text"
                    id="business role"
                    name="business role"
                    placeholder="Business Role"
                    required
                    :value="details ? details.business_role : ''"
                >
                    <template #label>Business Role</template>
                    <template #error>
                        <span v-if="error_list.business">
                            {{ error_list.business[0] }}
                        </span>
                    </template>
                </anima-input>
            </div>

            <!-- email -->
            <div class="my-4">
                <anima-input
                    ref="email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    required
                    :value="details ? details.email : ''"
                >
                    <template #label>Email</template>
                    <template #error>
                        <span v-if="error_list.email">
                            {{ error_list.email[0] }}
                        </span>
                    </template>
                </anima-input>
            </div>

            <!-- password -->
            <div v-if="type != 'interest'" class="my-4 mb-12 md:mb-4 flex w-full">
                <anima-input
                    ref="password"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required
                >
                    <template #label>Password</template>
                    <template #hint v-if="type != 'login'">
                        <ul>
                            <li>- Contains a minimum of 8 characters</li>
                            <li>- Contains a combination of uppercase and lowercase letters</li>
                            <li>- Contains at least one number</li>
                            <li>- Contains at least one special character (!@#$%^&*)</li>
                        </ul>
                    </template>
                    <template #error>
                        <span class="text-error" v-if="error_list.password">
                            {{ error_list.password[0] }}
                        </span>
                    </template>
                    <template #forgot>
                        <a v-if="type == 'login'" class="block no-underline w-fit text-xs pt-2" :href="forgot_password_url">
                            <anima-button long text xsmall>Forgot Password?</anima-button>
                        </a>
                    </template>
                </anima-input>
            </div>

            <!-- password confirmation -->
            <div v-if="type == 'register'" class="my-4">
                <anima-input
                    ref="password_confirmation"
                    type="password"
                    id="password-confirm"
                    name="password_confirmation"
                    placeholder="Password..."
                    required
                >
                    <template #label>Re-Type Password</template>
                </anima-input>
            </div>

            <!-- marketing -->
            <anima-option
                v-if="type == 'register'"
                ref="marketing"
                type="checkbox"
                :preSelected="'Tick this box to receive exclusive offers, product updates, and exciting news from Anima Hub. You can opt-out at any time by updating your account settings in “My Preferences”.'"
                :options="['Tick this box to receive exclusive offers, product updates, and exciting news from Anima Hub. You can opt-out at any time by updating your account settings in “My Preferences”.']"
                small
            ></anima-option>

            <!-- recaptcha -->
            <div v-if="type == 'interest'" class="my-4">
                <span class="text-error" v-if="error_list['g-recaptcha-response']">{{
                    error_list['g-recaptcha-response'][0]
                }}</span>
                <slot name="google"></slot>
            </div>

            <p v-if="type == 'interest'">
                By registering your interest you are opting-in to marketing messaging so we can keep you up-to-date with exclusive offers, product updates, and exciting news.
            </p>
            <p v-if="type == 'register'">
                By signing up you agree to the terms of our
                <a class="underline cursor-pointer" href="/privacy-policy">Privacy Policy</a>.
            </p>

            <!-- buttons -->
            <div v-if="type == 'register'" class="my-4">
                <anima-button @click.prevent="sub" submit round long>Register</anima-button>
            </div>
            <div v-else-if="type == 'interest'" class="my-4">
                <anima-button @click.prevent="sub" submit round long>Register Interest</anima-button>
            </div>
            <div v-else class="mt-4 flex flex-col items-center absolute bottom-0 w-full">
                <anima-button @click.prevent="sub" submit round long>Log In</anima-button>
            </div>
        </form>
</div>
</template>

<script>
import animaButton from '../Elements/AnimaBtn.vue';
import animaInput from './AnimaInput.vue';
import animaDate from './AnimaDate.vue';
import animaOption from './AnimaOption.vue';
import AnimaUpload from './AnimaUpload.vue';
import data from '../../countries.js';
export default {
    components: { animaButton, animaInput, animaDate, animaOption, AnimaUpload },
    props: {
        forgot_password_url: {
            type: String,
            default: null,
        },
        invite: { type: String, default: null },
        inviteRequired: { type: Boolean, default: false },
        redirect_success: { type: String, default: '/' },
        token: { type: String, default: null },
        type: { type: String, default: null },
        url: {
            type: String,
            default: null,
        },
        details: { type: Object, default: null },
    },
    data() {
        return {
            error_list: '',
            countries: data.countries,
            language: data.languages,
            isBusiness: false,
            error_list: '',
        };
    },
    mounted() {
        let self = this;

        window.addEventListener('keyup', (e) => {
            e.key === 'Enter' ? self.submit() : null;
        });

        window.addEventListener('beforeunload', () => {
            sessionStorage.removeItem('form_data');
        });
    },
    updated() {
        let form_data = JSON.parse(sessionStorage.getItem('form_data'));

        if (form_data) {
            this.$refs['email'].$refs['input'].value = form_data.email;
            if (form_data.fname != null) {
                this.$refs['fname'].$refs['input'].value = form_data.fname;
            }
            if (form_data.lname != null) {
                this.$refs['lname'].$refs['input'].value = form_data.lname;
            }
            if (form_data.business_name != null) {
                this.$refs['business_name'].$refs['input'].value = form_data.business_name;
            }
        }
    },
    methods: {
        setValue(e, ref) {
            ref == 'dob' ? (this.$refs[ref].selected = e) : (this.$refs[ref].value = e);

            if (ref == 'account_type') {
                this.isBusiness = e === 'Business';
            }
        },
        sub() {
            this.error_list = []
            const form = document.getElementById('form');
            const submitter = document.querySelector('button[value=Register]');
            const formData = new FormData(form, submitter);

            if (this.type == 'interest') {
                formData.append('account_type', this.$refs['account_type'].value);
                formData.append('lang', this.$refs['lang'].value);
                formData.append('country', this.$refs['country'].value);
            } else if (this.type == 'register') {
                formData.append('account_type', this.$refs['account_type'].value);
                formData.append('lang', this.$refs['lang'].value);
                formData.append('country', this.$refs['country'].value);
                formData.append('gender', this.$refs['gender'].value);
                formData.append('invite', this.invite);
                if (this.$refs['marketing'].optionSelected.length > 0) {
                    formData.append('marketing', true);
                } else {
                    formData.append('marketing', false);
                }
            }

            if (Object.keys(this.validate(formData)).length > 0) {
                sessionStorage.setItem(
                    'notification',
                    JSON.stringify({
                        type: 'error',
                        messages: [
                            {
                                title: 'Error',
                                message: 'Something went wrong! Please try again',
                            },
                        ],
                    })
                );
                this.error_list = this.validate(formData)
                eventHub.$emit('show');
            } else {
                this.setSessionData();

                fetch(this.url, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': this.token,
                    },
                    body: formData,
                })
                .then(async (response) => {
                    if (response.ok) {
                        sessionStorage.removeItem('form_data');
    
                        let title;
                        let message;
                        switch (this.type) {
                            case 'login':
                                title = 'Signed In';
                                message = '';
                                break;
                            case 'register':
                                title = 'Registered';
                                message = '';
                                break;
                            case 'interest':
                                title = 'Interest Registered';
                                message =
                                    'Thank you for registering your interest, you will receive a comfirmation email shortly';
                                break;
                        }
                        window.location.href = this.redirect_success;

                        sessionStorage.setItem(
                            'notification',
                            JSON.stringify({
                                type: 'info',
                                messages: [
                                    {
                                        title,
                                        message,
                                    },
                                ],
                            })
                        );
                    }
                    let res = await response.json();
                    sessionStorage.setItem(
                        'notification',
                        JSON.stringify({
                            type: 'error',
                            messages: [
                                {
                                    title: res.error.title,
                                    message: res.error.message,
                                },
                            ],
                        })
                    );
                    res.error_list ? (this.error_list = res.error_list) : null;
                    eventHub.$emit('show');
                    grecaptcha.reset();
                    // return Promise.reject(response);
                })
                .catch(async (error) => {});
            }

        },
        setSessionData() {
            let obj = {
                email: this.$refs['email'].$refs['input'].value,
            };

            if (this.type != 'login') {
                obj.fname = this.$refs['fname'].$refs['input'].value;
                obj.lname = this.$refs['lname'].$refs['input'].value;
                if (this.type === 'interest') {
                    obj.business_name = this.$refs['business_name'].$refs['input'].value;
                }
            }

            sessionStorage.setItem('form_data', JSON.stringify(obj));
        },
        validate(formData) {
            const errors = {}
            for (var pair of formData.entries()) {
                if (pair[0] === 'email') {
                    if (!this.validateEmail(pair[1])) {
                        errors.email = ['Enter a valid email']
                    }
                } else if (pair[0] === 'password') {
                    if (pair[1] === null || pair[1] === '') {
                        errors.password = ['Enter a valid password']
                    }
                }
            }
            return errors
        },
        validateEmail(email) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
    },
};
</script>
