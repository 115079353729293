<template>
    <div>
        <anima-button long @click="openModal">Choose Symptoms</anima-button>
        
        <!-- modal container -->
        <div v-show="showModal" class="fixed top-0 left-0 z-[99] overflow-hidden">
            <!-- overlay -->
            <div
                id="overlay"
                @click="showModal = false"
                class="z-20 h-screen w-screen bg-slate-600 opacity-50"
            ></div>
            <!-- modal -->
            <div
                id="modal"
                class="fixed z-30 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col bg-card-100 min-h-[95%] min-w-[95%] h-[95vh] w-full lg:w-[95%] rounded-lg shadow-feature text-text-dark"
            >
                <!-- close button -->
                <div class="cursor-pointer" @click="showModal = false">
                    <svg-vue class="h-5 w-5 m-5 absolute right-0" icon="close"></svg-vue>
                </div>

                <!-- step count -->
                <div class="flex justify-center h-[15%]">
                    <div v-for="(step, idx) in steps" class="flex items-center">
                        <div class="flex flex-col items-center relative">
                            <span
                                :class="
                                    idx <= currentStep
                                        ? 'border-green-300 bg-green-300'
                                        : 'border-slate-300'
                                "
                                class="h-5 w-5 rounded-full border-2 flex items-center justify-center text-white text-xs"
                            >
                                <font-awesome-icon
                                    v-if="idx < currentStep"
                                    icon="fa-solid fa-check"
                                />
                            </span>
                            <p
                                :class="idx == currentStep ? 'font-bold' : null"
                                class="absolute top-6"
                            >
                                {{ step.name }}
                            </p>
                        </div>
                        <div
                            v-if="idx != steps.length - 1"
                            :class="idx < currentStep ? 'bg-green-300' : 'bg-slate-300'"
                            class="h-2 w-[9vw] lg:w-[5vw] rounded-md mx-3"
                        ></div>
                    </div>
                </div>

                <!-- content -->
                <!-- w-full my-5 px-8 lg:px-10 absolute h-[65%] top-[20%] lg:relative lg:top-0 lg:h-[70vh] bg-body-200 -->
                <div class="w-full px-8 lg:px-10 h-3/4 bg-body-200"
                    :class="currentStep == 1 ? 'overflow-hidden' : 'overflow-scroll'"
                    ref="main_content"
                >
                    <!-- main content -->
                    <div v-if="currentStep == steps.length - 1" class="flex flex-col w-3/5 m-auto">
                        <div class="flex justify-evenly my-6 flex-wrap">
                            <div class="text-left w-full lg:w-1/2">
                                <div class="text-xl font-bold m-auto text-left">Condition Category</div>
                                <div class="text-base">
                                    {{ preview.category != '' && preview.category != "I Don't Know" ? preview.category : 'Unknown' }}
                                </div>
                            </div>
                            <div class="text-right w-full lg:w-1/2">
                                <div class="text-xl font-bold m-auto text-right">Condition Name</div>
                                <div class="text-base">
                                    {{ preview.condition != '' && preview.condition != "I Don't Know" ? preview.condition : 'Unknown' }}
                                </div>
                            </div>
                        </div>
                        <div class="text-xl font-bold m-auto text-center">Symptoms</div>
                        <table class="w-full text-center mx-auto mt-5 mb-7 rounded-md overflow-hidden">
                            <thead>
                                <tr class="bg-secondary text-base">
                                    <th class="p-2">Symptom</th>
                                    <th class="p-2">Severity</th>
                                </tr>
                            </thead>
                            <tbody class="border-2 border-secondary-200">
                                <tr
                                    v-for="(key, item, idx) in preview.severity"
                                    :class="idx % 2 != 0 ? 'bg-secondary-200' : 'bg-body-100'"
                                >
                                    <td class="text-sm p-1">{{ item }}</td>
                                    <td class="text-sm p-1">{{ key }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else-if="currentStep == 1" class="flex flex-col lg:flex-row items-start relative overflow-hidden h-full">
                        <div
                            class="block lg:h-full w-full lg:w-3/5  my-6 lg:my-auto" 
                            v-for="question in steps[currentStep].questions"
                        >
                            <div v-show="show[question.id]">
                                <div v-if="errors[question.id]" class="text-red-600">
                                    {{ errors[question.id] }}
                                </div>
                                <p class="text-base text-text-dark pb-6">
                                    {{ question.question }}
                                </p>
                                <div v-if="question.type == 'mapping'">
                                    <anima-mapping
                                        @change="selectConditions"
                                        :question_id="question.id"
                                        :display="question.display"
                                        :route="question.route"
                                        :preSelected="question.preSelected"
                                        :filter="question.filter"
                                        isModal
                                    ></anima-mapping>
                                </div>
                            </div>
                        </div>
                        <div class="w-2/5">
                            <anima-body @change="filterByArea"></anima-body>
                        </div>
                    </div>
                    <div v-else class="my-6">
                        <div v-for="question in steps[currentStep].questions">
                            <div v-show="show[question.id]">
                                <div v-if="errors[question.id]" class="text-red-600">
                                    {{ errors[question.id] }}
                                </div>
                                <p class="text-base text-text-dark py-6">
                                    {{ question.question }}
                                </p>
                                <div v-if="question.type == 'select'">
                                    <anima-option
                                        :ref="question.id"
                                        @input="checkInput($event, question.id)"
                                        type="select"
                                        :options="question.answers"
                                        :pre-selected="
                                            preview[question.id] ? preview[question.id] : null
                                        "
                                    ></anima-option>
                                </div>
                                <div v-if="question.type == 'radio'">
                                    <anima-option
                                        :id="question.id"
                                        :ref="question.id"
                                        @change="checkInput($event, question.id)"
                                        type="radio"
                                        :options="question.answers"
                                    ></anima-option>
                                </div>
                                <div v-if="question.type == 'mapping'">
                                    <anima-mapping
                                        @change="selectConditions"
                                        :question_id="question.id"
                                        :display="question.display"
                                        :route="question.route"
                                        :request_data="question.request_data"
                                        :preSelected="question.preSelected"
                                    ></anima-mapping>
                                </div>
                                <div v-if="question.type == 'slider'">
                                    <div v-if="question.for">
                                        <div v-for="(item, idx) in preview[question.for]">
                                            <p class="text-base font-semibold">{{ item }}</p>
                                            <anima-slider
                                                @input="checkSlide($event, item)"
                                                :question_id="item"
                                                :preSelected="question.preSelected"
                                            ></anima-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- buttons -->
                <div class="flex justify-between h-[10%] w-full bottom-0 py-4 lg:pb-4 lg:pt-2 px-8 lg:px-10 bg-card-100">
                    <div class="w-4/12 lg:w-2/12">
                        <anima-button v-if="currentStep >= 1" long @click="goBack"
                            >Back</anima-button
                        >
                    </div>
                    <div class="w-4/12 lg:w-2/12">
                        <anima-button v-if="currentStep < steps.length - 1" long @click="validate"
                            >Next</anima-button
                        >
                        <anima-button
                            v-else
                            long
                            @click="
                                showModal = false;
                                currentStep = 0;
                            "
                            >Finish</anima-button
                        >
                    </div>
                </div>
            </div>
        </div>

        <!-- preview once closed -->
        <div v-if="!showModal && started">
            <div class="flex flex-col text-text-dark">
                <div class="my-3 flex justify-center">
                    <div class="w-1/2">
                        <div class="text-base font-bold m-auto">Condition Category</div>
                        <div class="text-sm">
                            {{ preview.category != '' && preview.category != "I Don't Know" ? preview.category : 'Unknown' }}
                        </div>
                    </div>
                    <div class="w-1/2">
                        <div class="text-base font-bold m-auto">Condition Name</div>
                        <div class="text-sm">
                            {{ preview.condition != '' && preview.condition != "I Don't Know" ? preview.condition : 'Unknown' }}
                        </div>
                    </div>
                </div>
                <div class="text-base font-bold">Symptoms</div>
                <table class="w-full text-center mt-3 rounded-md overflow-hidden">
                    <thead>
                        <tr class="bg-secondary text-sm">
                            <th class="p-2">Symptom</th>
                            <th class="p-2">Severity</th>
                        </tr>
                    </thead>
                    <tbody class="border-2 border-secondary-200">
                        <tr
                            v-for="(key, item, idx) in preview.severity"
                            :class="idx % 2 != 0 ? 'bg-secondary-200' : 'bg-body-100'"
                        >
                            <td class="text-sm p-1">{{ item }}</td>
                            <td class="text-sm p-1">{{ key }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="hidden" id="symptom_selector_data" :ref="question_id">{{ preview }}</div>
    </div>
</template>

<script>
import animaButton from './../Elements/AnimaBtn.vue';
import animaRadio from './AnimaRadio.vue';
import animaCheckbox from './AnimaCheckbox.vue';
import animaEmojiSlider from './AnimaEmojiSlider.vue';
import animaSlider from './AnimaSlider.vue';
import animaTextArea from './AnimaTextArea.vue';
import animaSelect from './AnimaSelect.vue';
import animaAccordion from './AnimaAccordion.vue';
import animaNumber from './AnimaNumber.vue';
import animaTextShort from './AnimaTextShort.vue';
import animaMapping from './AnimaMapping.vue';
import AnimaOption from './AnimaOption.vue';
import animaBody from '../Elements/AnimaBody.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        FontAwesomeIcon,
        AnimaOption,
        animaButton,
        animaRadio,
        animaCheckbox,
        animaEmojiSlider,
        animaSlider,
        animaTextArea,
        animaSelect,
        animaAccordion,
        animaNumber,
        animaTextShort,
        animaMapping,
        animaBody,
    },
    data() {
        return {
            started: false,
            show: {},
            showModal: false,
            currentStep: 0,
            steps: [
                {
                    name: 'Condition',
                    questions: [
                        {
                            id: 'category',
                            question: 'Select a category:',
                            type: 'mapping',
                            display: 'dropdown',
                            route: `${this.url}/api/symptom/all_categories`,
                        },
                        {
                            id: 'condition',
                            question: 'Select a condition:',
                            type: 'mapping',
                            display: 'dropdown',
                            route: `${this.url}/api/symptom/all_conditions`,
                        },
                    ],
                },
                {
                    name: 'Symptoms',
                    questions: [
                        {
                            id: 'symptoms',
                            type: 'mapping',
                            question: 'Please select your symptoms:',
                            route: `${this.url}/api/symptom/area`,
                            display: 'search',
                            filter: '',
                        },
                    ],
                },
                {
                    name: 'Details',
                    questions: [
                        {
                            id: 'details',
                            type: 'mapping',
                            display: 'details',
                            question: 'Please select the details for each symptom:',
                            route: null,
                            request_data: null,
                        },
                    ],
                },
                {
                    name: 'Severity',
                    questions: [
                        {
                            id: 'severity',
                            type: 'slider',
                            question: 'Please select the severity of each symptom:',
                            for: 'sliderList',
                        },
                    ],
                },
                {
                    name: 'Review',
                },
            ],
            errors: {},
            preview: {
                condition: '',
                category: '',
                symptoms: [],
                severity: {},
                details: [],
                sliderList: [],
            },
            symptom_area: '',
            symptom_filter: '',
            skip: false,
            details_data: null,
        };
    },
    props: {
        token: { type: String, default: null },
        url: { type: String, default: null },
        question_id: { type: Number, default: null },
    },
    methods: {
        filterByArea(e) {
            this.symptom_filter = e;
            this.symptom_area = e;
        },
        openModal() {
            this.showModal = true;
            this.started = true;
            this.getVisible();
        },
        checkSlide($event, symptom) {
            this.preview.severity[symptom] = $event;
        },
        selectConditions(e) {
            if (this.currentStep == 0) {
                if (e.id == 'condition') {
                    this.preview['condition'] = e.check[0];
                } else {
                    this.preview['category'] = e.check[0];
                }
            } else if (this.currentStep == 2) {
                this.preview['details'] = e.check.preview;
            } else {
                this.preview['symptoms'] = e.check.preview;
            }
        },
        checkInput($event, id) {
            this.preview[id] = $event;
            this.getVisible(id);
        },
        getVisible(id) {
            for (let i = 0; i < this.steps[this.currentStep].questions.length; i++) {
                const question = this.steps[this.currentStep].questions[i];
                if (question.condition) {
                    let all_meet = [];
                    for (let i = 0; i < question.condition.length; i++) {
                        const element = question.condition[i];
                        if (element.length == 1) {
                            if (this.preview[element[0]] != '') {
                                all_meet[i] = true;
                            } else {
                                all_meet[i] = false;
                            }
                        } else {
                            if (this.preview[element[0]] == element[1]) {
                                all_meet[i] = true;
                            } else {
                                all_meet[i] = false;
                            }
                        }
                    }
                    if (all_meet.includes(true)) {
                        this.show[question.id] = true;
                    } else {
                        this.show[question.id] = false;
                    }
                } else {
                    this.show[question.id] = true;
                }
            }
        },
        setVisible() {
            let obj = {};
            for (let i = 0; i < this.steps[this.currentStep].questions.length; i++) {
                const question = this.steps[this.currentStep].questions[i];
                if (question.condition) {
                    obj[question.id] = false;
                } else {
                    obj[question.id] = true;
                }
            }
            this.show = obj;
        },
        goBack() {
            if (this.currentStep == 3 && this.skip) {
                this.currentStep = 1;
            } else {
                this.currentStep--;
            }
            this.$refs.main_content.scrollTop = 0
            this.setVisible();
        },
        async validate() {
            let errors = {};
            let data;

            switch (this.currentStep) {
                case 0:
                    if (!this.preview.condition) {
                        errors['condition'] = 'Condition must be selected';
                    }
                    this.steps[0].questions[0].preSelected = this.preview.condition;
                    break;
                case 1:
                    if (this.preview.symptoms.length == 0) {
                        errors['symptoms'] = 'At least one Symptoms must be selected';
                    }
                    this.steps[1].questions[0].preSelected = this.preview.symptoms;
                    for (const key in this.preview.severity) {
                        if (Object.hasOwnProperty.call(this.preview.severity, key)) {
                            if (this.preview.symptoms.indexOf(key) === -1) {
                                delete this.preview.severity[key];
                            }
                        }
                        this.steps[3].questions[0].preSelected = this.preview.severity;
                    }

                    this.steps[2].questions[0].request_data = this.details_data;
                    try {
                        this.symptom_area = '';
                        let response = await fetch(
                            `${this.url}/api/symptom/details?name=${this.preview.condition}`,
                            {
                                method: 'POST',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json;charset=utf-8',
                                    'X-CSRF-TOKEN': this.token,
                                },
                                body: JSON.stringify({
                                    syms: this.preview.symptoms,
                                }),
                            }
                        );
                        data = await response.json();
                        if (data.message == 'skip') {
                            this.skip = true;
                        } else {
                            this.skip = false;
                        }
                    } catch (error) {
                        this.error = error;
                    }
                    break;
                case 2:
                    if (this.preview.details.length == 0) {
                        errors['details'] = 'At least one detail must be selected for each symptom';
                    }

                    for (const key in this.details_data) {
                        let detailExists = false;
                        this.preview.details.some((str) => {
                            if (str.includes(key)) {
                                detailExists = true;
                            }
                        });
                        if (!detailExists) {
                            errors['details'] =
                                'At least one detail must be selected for each symptom';
                        }
                    }

                    for (const key in this.preview.severity) {
                        let noDetail = false;
                        let noSym = false;
                        if (Object.hasOwnProperty.call(this.preview.severity, key)) {
                            if (this.preview.details.indexOf(key) === -1) {
                                noDetail = true;
                            }
                            if (this.preview.symptoms.indexOf(key) === -1) {
                                noSym = true;
                            }

                            if (noDetail && noSym) {
                                delete this.preview.severity[key];
                            }
                        }
                        this.steps[3].questions[0].preSelected = this.preview.severity;
                    }

                    let list = [];
                    for (let i = 0; i < this.preview.symptoms.length; i++) {
                        let detailExists = false;
                        let detail = '';
                        const symptom = this.preview.symptoms[i];
                        this.preview.details.some((str) => {
                            if (str.includes(symptom)) {
                                detailExists = true;
                                detail = str;
                            }
                            if (detailExists) {
                                list.push(detail);
                            }
                        });
                        if (!detailExists) {
                            list.push(symptom);
                        }
                    }
                    this.preview.sliderList = [...new Set(list)];
                    this.steps[2].questions[0].preSelected = this.preview.details;
                    this.$refs.main_content.scrollTop = 0
                    break;
                case 3:
                    this.steps[3].questions[0].preSelected = this.preview.severity;
                    this.$refs.main_content.scrollTop = 0
                    break;
                default:
                    break;
            }

            if (Object.keys(errors).length == 0) {

                if (this.currentStep == 1) {
                    if (this.skip) {
                        this.currentStep = 2;
                        this.preview.detail = [];

                        let list = [];
                        for (let i = 0; i < this.preview.symptoms.length; i++) {
                            const symptom = this.preview.symptoms[i];
                            list.push(symptom);
                        }
                        this.preview.sliderList = list;
                    } else {
                        this.details_data = data.message;
                        this.steps[2].questions[0].request_data = data.message;
                    }
                }
                this.errors = {};
                this.currentStep++;
                this.setVisible();
                
            } else {
                this.errors = errors;
            }
        },
        async fetchData(url, abortSignal = false) {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                if (abortSignal) {
                    // await controller.abort();
                }

                let response = await fetch(url, {
                    signal,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                return data;
            } catch (error) {
                this.error = error;
            } finally {
                if (this.display == 'dropdown') {
                    this.setSelected();
                }
                this.loading = false;
            }
        },
    },
    async created() {
        this.setVisible();

        this.steps[1].questions[0].route = this.symptomsRoute;
    },
    computed: {
        symptomsRoute() {
            if (this.preview['condition'] && this.preview['condition'] != "I Don't Know") {
                if (this.symptom_area != '') {
                    return `${this.url}/api/symptom/conditions?name=${encodeURIComponent(this.preview['condition'])}&area=${encodeURIComponent(this.symptom_area)}`;
                } else {
                    return `${this.url}/api/symptom/conditions?name=${encodeURIComponent(this.preview['condition'])}`;
                }
            } else if (this.symptom_area != '') {
                return `${this.url}/api/symptom/area?name=${this.symptom_area}`;
            } else {
                return `${this.url}/api/symptom/area`;
            }
        },
        conditionRoute() {
            if (this.preview['category'] && this.preview['category'] != "I Don't Know") {
                return `${this.url}/api/symptom/all_conditions?category=${encodeURIComponent(this.preview['category'])}`;
            } else {
                return `${this.url}/api/symptom/all_conditions`;
            }
        },
    },
    watch: {
        async symptomsRoute(symptomsRoute) {
            this.steps[1].questions[0].route = symptomsRoute;
            this.steps[1].questions[0].filter = this.symptom_filter;
        },
        async conditionRoute(conditionRoute) {
            this.steps[0].questions[1].route = conditionRoute;
        },
        showModal: function () {
            document.body.style.overflow = this.showModal ? 'hidden' : ''
        }
    },
};
</script>

<style scoped>
div::-webkit-scrollbar{
    display: none;
}

div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.body_part {
    fill: #fff;
    stroke: #fff;
    stroke-width: 4.17px;
    cursor: pointer;
}

.body_part:hover {
    fill: #5c9e95;
    stroke: #5c9e95;
}

.active {
    fill: #73c6ba;
    stroke: #73c6ba;
}
</style>
