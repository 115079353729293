<template>
    <div class="w-full flex flex-wrap lg:flex-nowrap justify-center my-5 lg:m-auto">
        <div
            :class="[
                Object.keys(show).length > 1 ? 'w-1/2' : 'w-full',
                `lg:w-1/${Object.keys(show).length}`,
                idx == 0 ? 'mx-9 lg:m-auto' : null,
            ]"
            v-for="(stat, key, idx) in show"
            :key="stat.id"
        >
            <div v-if="stat.filter != null">
                <select v-if="stat.filter.length > 0" v-model="stat.currentFilter" @change="update(key)" name="filter" id="filter" class="h-12 block w-4/5 md:w-2/5 sm:text-sm border border-gray-300 rounded-md py-2 px-3 outline-primary m-auto mt-4">
                    <option selected>All</option>
                    <option v-for="filt in stat.filter">{{ filt }}</option>
                </select>
            </div>
            <chart-Container
                :id="stat.chart_label"
                :title="stat.chart_label"
                :type="stat.chart_type"
                :data="stat.chart_data"
                :maxHeadline="Object.keys(show).length"
                :question_type="stat.question_type"
            ></chart-Container>
        </div>
    </div>
</template>

<script>
import { max } from 'moment';
import chartContainer from '../Charts/chartContainer.vue';

export default {
    components: { chartContainer },
    data() {
        return {
            show: [],
            polar: {},
            likert: {
                frequency: {
                    0: 'Never', 
                    1: 'Rarely', 
                    2: 'Sometimes', 
                    3: 'Regularly', 
                    4: 'Daily'
                },
                risk: {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Medium', 
                    3: 'High', 
                    4: 'Very High'
                },
                accuracy: {
                    0: 'Not At All',
                    1: 'Slightly', 
                    2: 'Moderately', 
                    3: 'Very', 
                    4: 'Extremely'
                },
                intensity: {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Moderate', 
                    3: 'High', 
                    4: 'Very High',
                }
            },
            likertValue: {
                frequency: {
                    'Never': 0, 
                    'Rarely': 1, 
                    'Sometimes': 2, 
                    'Regularly': 3, 
                    'Daily': 4
                },
                risk: {
                    'Very Low': 0,
                    'Low': 1,
                    'Medium': 2,
                    'High': 3,
                    'Very High': 4
                },
                accuracy: {
                    'Not At All': 0,
                    'Slightly': 1,
                    'Moderately': 2,
                    'Very': 3,
                    'Extremely': 4
                },
                intensity: {
                    'Very Low': 0, 
                    'Low': 1, 
                    'Moderate': 2, 
                    'High': 3, 
                    'Very High': 4,
                }
            },
            likertTotalValue: {
                frequency: {
                    'Never': 0, 
                    'Rarely': 25, 
                    'Sometimes': 50, 
                    'Regularly': 75, 
                    'Daily': 100
                },
                risk: {
                    'Very Low': 0,
                    'Low': 25,
                    'Medium': 50,
                    'High': 75,
                    'Very High': 100
                },
                accuracy: {
                    'Not At All': 0,
                    'Slightly': 25,
                    'Moderately': 50,
                    'Very': 75,
                    'Extremely': 100
                },
                intensity: {
                    'Very Low': 0, 
                    'Low': 25, 
                    'Moderate': 50, 
                    'High': 75, 
                    'Very High': 100,
                }
            },
        };
    },
    props: {
        stats: { type: Object, required: true },
        days: { type: Number, required: true },
    },
    watch: {
        stats: function (newVal, oldVal) {
            // watch it
            this.sortStats();
        },
    },
    mounted() {
        this.sortStats();
    },
    methods: {
        update(idx) {
            const currentFilter = this.show[idx].currentFilter;
            if (this.show[idx].chart_type == 'polar') {
                if (this.show[idx].currentFilter != 'All') {
                    let results = {}
                    results = Object.fromEntries(Object.entries(this.polar[idx]).filter((item) => {
                        
                        let match = false;

                        item[1].categories.forEach(category => {
                            category == this.show[idx].currentFilter ? match = true : null
                        });

                        return match ? item[1] : null
                    }));

                    this.show[idx] = {}
                    for (const id in results) {
                        const el = results[id];

                        if (Object.keys(this.show[idx]).length === 0) {

                            this.show[el.chart_label] = {
                                chart_data: {},
                                chart_type: 'polar',
                                chart_label: el.chart_label,
                                filter: el.filter,
                                filter_type: el.filter_type,
                                currentFilter: currentFilter
                            };
    
                            this.show[el.chart_label]['chart_data'][el.data_label] =
                                el.chart_data.average;
                        } else if (!this.show[el.chart_label][el.data_label]) {
                            this.show[el.chart_label]['chart_data'][el.data_label] =
                                el.chart_data.average;
                        }
                    }

                } else {
                    this.sortStats()
                }
            }
            this.$forceUpdate();
        },
        getlikertTotalValue(data, display) {
            let sum = data.reduce((partialSum, a) => partialSum + a, 0);
            let maxTotal = (sum / this.days) * 4;
            const likertTotalValue = {};
            let currentPercentage = 0; 

            likertTotalValue[display] = {}
            while (currentPercentage <= 100) {
                const key = `${currentPercentage}%`;
                likertTotalValue[display][key] = ((currentPercentage / 100) * maxTotal).toFixed(1);
                currentPercentage += 25;
            }

            let idx = 0
            for (const key in likertTotalValue[display]) {
                this.likertTotalValue[display][this.likert[display][idx]] = parseFloat(likertTotalValue[display][key])
                this.likertTotalValue['risk'][this.likert['risk'][idx]] = parseFloat(likertTotalValue[display][key])
                idx++
            }
        },
        sortStats() {
            this.show = {};
            for (const question in this.stats) {
                if (Object.hasOwnProperty.call(this.stats, question)) {
                    const element = this.stats[question];
                    for (const key in element) {
                        if (Object.hasOwnProperty.call(element, key)) {
                            const el = element[key];
                            if (el.chart_type == 'polar') {
                                !this.polar[el.chart_label] ? this.polar[el.chart_label] = {} : null;
                                
                                this.polar[el.chart_label][el.question_id] = {
                                    chart_data: el.chart_data,
                                    chart_label: el.chart_label,
                                    filter: el.filter,
                                    filter_type: el.filter_type,
                                    currentFilter: 'All',
                                    categories: el.categories,
                                    data_label: el.data_label,
                                    question_type: el.question_type,    
                                }

                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = {
                                        chart_data: {},
                                        chart_type: el.chart_type,
                                        chart_label: el.chart_label,
                                        filter: el.filter,
                                        filter_type: el.filter_type,
                                        currentFilter: 'All',
                                        question_type: el.question_type,
                                    };

                                    this.show[el.chart_label]['chart_data'][el.data_label] =
                                        el.chart_data.average;
                                } else if (!this.show[el.chart_label][el.data_label]) {
                                    this.show[el.chart_label]['chart_data'][el.data_label] =
                                        el.chart_data.average;
                                }
                            } else {
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = structuredClone(el);
                                } else {
                                    let chart_data = this.show[el.chart_label].chart_data
                                    let new_data = el.chart_data

                                    new_data.labels.forEach((label, idx) => {
                                        if (chart_data.labels.includes(label)) {
                                            let current_idx = chart_data.labels.indexOf(label)
                                            chart_data.data[current_idx] += new_data.data[idx]
                                        } else {
                                            chart_data.data.push(new_data.data[idx])
                                            chart_data.labels.push(label)
                                            if (el.data_aggregation == 'unique') {
                                                chart_data.average++
                                            } else if (el.data_aggregation == 'total') {
                                                chart_data.average = chart_data.data.reduce((partialSum, a) => partialSum + a, 0);
                                            } else {
                                                if (/[0-9]+/i.test(Object.keys(this.show[el.chart_label].chart_data.labels)[0])) {
                                                    let total = 0
                                                    let count = 0
                    
                                                    for (const key in this.show[el.chart_label].chart_data.data) {
                                                        const val = this.show[el.chart_label].chart_data.labels[key];
                                                        const key_val = this.show[el.chart_label].chart_data.data[key];
                                                        total += (parseFloat(val)*key_val)
                                                        count += parseFloat(key_val)
                                                    }
                                                    this.show[el.chart_label].chart_data.average = (total / count).toFixed(1)
                                                } else {
                                                    chart_data.average = parseInt(chart_data.average) + parseInt(new_data.average)
                                                }
                                            }
                                        }
                                    });

                                    let pairedList = this.show[el.chart_label].chart_data.data.map((data, index) => {
                                        return { data: data, label: this.show[el.chart_label].chart_data.labels[index] };
                                    });

                                    pairedList.sort((a, b) => parseFloat(a.label) - parseFloat(b.label));

                                    this.show[el.chart_label].chart_data.data = pairedList.map(pair => pair.data);
                                    this.show[el.chart_label].chart_data.labels = pairedList.map(pair => pair.label);
                                }
                            }
                        }
                    }
                }
            }

            for (const stat in this.show) {
                let el = this.show[stat]
                if (el.question_type == 'emoji_slider') {
                    let data = el.chart_data.data
                    let labels = el.chart_data.labels
                    let value = 0
                    data.forEach((item, idx) => {
                        value += this.likertValue[el.display][labels[idx]] * item
                    });

                    value = value / this.days;

                    this.getlikertTotalValue(data, el.display)
                    
                    let likertValue
                    if (el.chart_label.toLowerCase().includes('risk level')) {
                        for (const key in this.likertTotalValue['risk']) {
                            if (value <= this.likertTotalValue['risk'][key]) {
                                likertValue = key;
                                break;
                            }
                        }
                    } else {
                        for (const key in this.likertTotalValue[el.display]) {
                            if (value <= this.likertTotalValue[el.display][key]) {
                                likertValue = key;
                                break;
                            }
                        }
                    }
                    
                    this.show[stat].chart_data.average = likertValue
                } else if (el.question_type == 'date_time') {

                    /**
                     * Had this running on time stats but returns total minutes
                     * without it, it stays in format HH:MM which is correct
                     * have to wait and see of something comes up
                     */
                    // for (const key in this.show[el.chart_label]['chart_data']) {
                    //     this.show[el.chart_label]['chart_data'][key] 
                    //         = this.getMinutesFromDuration(this.show[el.chart_label]['chart_data'][key])
                    // }
                }
            }

            let sortable = [];

            for (const stat in this.show) {
                sortable.push([stat, this.show[stat]]);
            }

            sortable.sort(function (a, b) {
                return a[1].position - b[1].position;
            });

            this.show = sortable.reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        },
        getMinutesFromDuration(duration) {
            if (/[0-9]+:[0-9]+/i.test(duration)) {
                if (typeof duration == 'string') {
                    const [hours, minutes] = duration.split(":").map(Number);
                    return hours * 60 + minutes;
                } else {
                    let total = 0
                    duration.forEach(time => {
                        const [hours, minutes] = time.split(":").map(Number);
                        total += hours * 60 + minutes
                    });
                    return total
                }
            } else {
                return duration
            }           
        }
    },
};
</script>
