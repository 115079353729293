<template>
    <div>
        <select
            class="h-12 block w-4/5 md:w-2/5 sm:text-sm my-3 mx-auto border border-gray-300 rounded-md py-2 px-3 outline-primary"
            name="current_category"
            id="current_category"
            v-if="Object.keys(show).length > 1"
            v-model="current_stat"
            @change="setCurrentStat"
        >
            <option selected>All Charts</option>
            <option v-for="(item, key) in show" :key="key" :value="key">
                {{ key }}
            </option>
        </select>
        <div v-else class=" my-6"></div>

        <div class="w-full mb-4 bg-card-200 p-4 rounded-3xl text-text-dark">        
            <div v-for="(stat, idx) in filteredShow" :key="stat.id" >
                <div>
                    <p class="text-center text-lg font-semibold">{{ stat.chart_label }}</p>
                    <div v-if="stat.filter != null">
                        <div v-if="stat.filter_type == 'category' && stat.active_filters && stat.active_filters.length > 0" class="flex items-center justify-center mt-4 mb-8">
                            <font-awesome-icon :icon="['fas', 'filter']" />
                            <select v-model="stat.currentFilter" @change="update(idx)" 
                                :name="stat.chart_label" :id="stat.chart_label" class="h-12 block w-4/5 md:w-2/5 sm:text-sm border border-gray-300 rounded-md py-2 px-3 outline-primary ml-2">
                                <option selected>All Data</option>   
                                <option v-for="filt in stat.active_filters">{{ filt }}</option>
                            </select>
                        </div>
                        <div v-else-if="stat.active_filters && stat.active_filters.length > 0" class="flex items-center justify-center">
                            <font-awesome-icon :icon="['fas', 'filter']" />
                            <select v-model="stat.currentFilter" @change="update(idx)" 
                                :name="stat.chart_label" :id="stat.chart_label" class="h-12 block w-4/5 md:w-2/5 sm:text-sm border border-gray-300 rounded-md py-2 px-3 outline-primary ml-2">
                                <option selected>All Data</option>
                                <option v-for="filt in stat.active_filters">{{ filt }}</option>
                            </select>
                        </div>
                    </div>
                    <chart-container
                        :id="`${stat.chart_label}_${stat.question_id}`"
                        :data="stat.chart_data"
                        :question_id="stat.question_id"
                        :type="stat.chart_type"
                        :title="stat.chart_label"
                        :dates="dates"
                        :lineSteps="stat.lineSteps"
                        :url="url"
                        :labels="stat.labels"
                        :start_date="start_date"
                        :data_label="stat.data_label"
                        :currentFilter="stat.currentFilter"
                        :filter_type="stat.filter_type"
                        :question_type="stat.question_type ? stat.question_type : null"
                        :question_display="stat.question_display ? stat.question_display : null"
                        :data_aggregation="stat.data_aggregation ? stat.data_aggregation : null"
                        :persist="stat.persist ? stat.persist : false"
                        :hasTitle="false"
                    ></chart-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chartContainer from '../Charts/chartContainer.vue';
import chartConfig from '../../chartConfig';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: { chartContainer, FontAwesomeIcon },
    data() {
        return {
            show: [],
            showFilter: [],
            polar: {},
            wordCloud: {},
            current_stat: 'All Charts',
            word_Color: {}
        };
    },
    props: {
        stats: { type: Object, required: true },
        dates: { type: Object, required: true },
        url: { type: String, default: null },
        start_date: { type: String, default: null },
        type: { type: String, default: null },
    },
    watch: {
        stats: function (newVal, oldVal) {
            this.sortStats();
        },
    },
    mounted() {
        this.sortStats();
        
        const session_stat = sessionStorage.getItem('anima_current_stat')
        if (session_stat != '' && session_stat != null && session_stat != undefined) {
            this.current_stat = session_stat
        } else {
            this.current_stat = 'All Charts'
        }
    },
    methods: {
        setCurrentStat() {
            sessionStorage.setItem('anima_current_stat', this.current_stat)
        },
        update(idx) {
            if (this.showFilter[idx].chart_type == 'word cloud') {

                if (this.show[idx].currentFilter != 'All Data') {
                    let results = {}
                    results = Object.fromEntries(Object.entries(this.wordCloud[idx]).filter((item) => {
                        let match = false;

                        if (this.show[idx].filter_type == 'category') {
                            item[1].categories.forEach(category => {
                                category == this.show[idx].currentFilter ? match = true : null
                            });
                        } else {
                            item[1].data_label == this.show[idx].currentFilter ? match = true : null
                            // match = true
                        }

                        return match ? item[1] : null
                        // return item[1].data_label.includes(this.show[idx].currentFilter) ? item[1] : null
                    }));

                    let answers = {};
                    for (const key in results) {
                        for (const ans in results[key].answers) {
                            if (ans in answers) {
                                answers[ans] += results[key].answers[ans]
                            } else {
                                answers[ans] = results[key].answers[ans]
                            }
                        }
                    }
                    let words = [];

                    for (const answer in answers) {
                        const degs = [0, 0];

                        words.push({
                            rotation: degs[Math.floor(Math.random() * degs.length)],
                            rotationUnit: 'deg',
                            text: answer,
                            weight: answers[answer],
                            color: this.word_Color[idx][answer],
                        });
                    }
    
                    this.show[idx].chart_data = {
                        ...this.show[idx].chart_data,
                        data: {
                            ...this.show[idx].chart_data.data,
                            words
                        }
                    }
                } else {
                    this.sortStats()
                }
            } else if (this.showFilter[idx].chart_type == 'polar') {
                if (this.showFilter[idx].currentFilter != 'All Data') {
                    let currentFilter = this.showFilter[idx].currentFilter
                    let results = {}
                    results = Object.fromEntries(Object.entries(this.polar[idx]).filter((item) => {
                        let match = false;

                        if (item[1].filter_type == 'category') {
                            item[1].categories.forEach(category => {
                                category == this.showFilter[idx].currentFilter ? match = true : null
                            });
                        } else {
                            item[1].data_label == this.showFilter[idx].currentFilter ? match = true : null
                        }

                        return match ? item[1] : null
                    }));

                    this.showFilter[idx] = {}
                    for (const id in results) {
                        const el = results[id];

                        if (Object.keys(this.showFilter[idx]).length === 0) {
                            this.showFilter[el.chart_label] = {
                                chart_data: {},
                                chart_type: 'polar',
                                chart_label: el.chart_label,
                                filter: el.filter,
                                filter_type: el.filter_type,
                                currentFilter: currentFilter,
                                active_filters: this.show[idx].active_filters,
                                categories: this.show[idx].categories
                            };
    
                            this.showFilter[el.chart_label]['chart_data'][el.data_label] =
                                el.chart_data.average;
                        } else if (!this.show[el.chart_label][el.data_label]) {
                            this.showFilter[el.chart_label]['chart_data'][el.data_label] =
                                el.chart_data.average;
                        }
                    }
                } else {
                    this.sortStats()
                }
            } else {
                let currentFilter = this.show[idx].currentFilter
                this.sortStats()
                if (currentFilter != 'All Data') {
                    let obj = {}
                    for (const key in this.show[idx].chart_data) {
                        if (this.show[idx].chart_data[key].categories.includes(currentFilter)) {
                            obj[key] = this.show[idx].chart_data[key]
                        }
                    }
                    this.show[idx].currentFilter = currentFilter
                    this.show[idx].chart_data = obj
                }
            }
            this.$forceUpdate()
        },
        sortStats() {
            this.show = {};
            for (const question in this.stats) {
                if (Object.hasOwnProperty.call(this.stats, question)) {
                    const element = structuredClone(this.stats[question]);
                    for (const key in element) {
                        if (Object.hasOwnProperty.call(element, key)) {
                            const el = element[key];
                            if (el.question_type === 'mapping' && el['mapping level'].length > 0 && el.chart_type !== 'line') {
                                el.chart_data['data'].forEach((dataObj, idx) => {
                                    let answers = {}
                                    for (const key in dataObj) {
                                        let pieces = key.split("; ");   
                                        let str = ''
                                        for (let i = 0; i < el['mapping level'].length; i++) {
                                            const element = el['mapping level'][i];
                                            if (i == el['mapping level'].length - 1) {
                                                str = str.concat(pieces[element - 1]);
                                            } else {
                                                str = str.concat(pieces[element - 1], "; ");
                                            }
                                        }    
                                        if (!answers.hasOwnProperty(str)) {
                                            answers[str] = 1
                                        } else {
                                            answers[str] += 1
                                        } 
                                    }
                                    el.chart_data['data'][idx] = answers
                                })
                            }

                            if (el.chart_type == 'line') {
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = {
                                        chart_data: {},
                                        chart_type: el.chart_type,
                                        chart_label: el.chart_label,
                                        month_aggregation: el.month_aggregation,
                                        week_aggregation: el.week_aggregation,
                                        lineSteps: el.lineSteps,
                                        position: el.position,
                                        filter: el.filter,
                                        filter_type: el.filter_type,
                                        question_type: el.question_type,
                                        question_id: el.question_id,
                                        question_display: el.question_display,
                                        categories: el.categories,
                                        currentFilter: 'All Data',
                                        data_aggregation: el.data_aggregation,
                                        persist: el.persist,
                                    };
                                    this.show[el.chart_label]['chart_data'][el.question_id] = {
                                        label: el.data_label,
                                        data: el.chart_data.data,
                                        categories: el.categories,
                                        filter: el.filter,
                                        filter_type: el.filter_type,
                                        currentFilter: 'All Data',
                                        position: el.position,
                                    };
                                    this.show[el.chart_label]['active_filters'] = []
                                    if (Object.keys(el.answers).length !== 0) {
                                        this.show[el.chart_label]['label_filters'] = [el.data_label];
                                    } else {
                                        this.show[el.chart_label]['label_filters'] = [];
                                    }
                                } else if (!this.show[el.chart_label]['chart_data'][el.question_id]) {
                                    this.show[el.chart_label]['chart_data'][el.question_id] = {
                                        label: el.data_label,
                                        data: el.chart_data.data,
                                        position: el.position,
                                        filter: el.filter,
                                        currentFilter: 'All Data',
                                        categories: el.categories,
                                    };
                                    if (Object.keys(el.answers).length !== 0) {
                                        this.show[el.chart_label]['label_filters'].push(el.data_label)
                                        this.show[el.chart_label]['label_filters'] = 
                                            this.show[el.chart_label]['label_filters'].filter((value, index, array) => array.indexOf(value) === index);
                                    }
                                }

                                if (el.filter) {
                                    el.filter.forEach(filter => {
                                        let current_filters
                                        if (el.filter_type == 'label') {
                                            current_filters = el['label_filters']
                                        } else {
                                            current_filters = el['categories']
                                        }
                                        if (current_filters) {
                                            current_filters.forEach(label => {
                                                if (Object.keys(el.chart_data.data).length != 0) {
                                                    if (label.includes(filter)) {
                                                        this.show[el.chart_label]['active_filters'].push(filter)
                                                    }
                                                }
                                            });
                                        }
                                    });
                                    this.show[el.chart_label]['active_filters'] = 
                                        this.show[el.chart_label]['active_filters'].filter((value, index, array) => array.indexOf(value) === index);
                                }
                            } else if (el.chart_type == 'word cloud') {

                                const degs = [0, 0];
                                const colors = ['#73C6BA','#97D86C', '#FFCF09', '#EC9E90', '#FF6060','#9473E2', '#4A4AE6', '#88B4C3'];
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = el;
                                    this.show[el.chart_label]['currentFilter'] = 'All Data'
                                    this.show[el.chart_label]['active_filters'] = []
                                    if (Object.keys(el.answers).length !== 0) {
                                        this.show[el.chart_label]['label_filters'] = [el.data_label];
                                    } else {
                                        this.show[el.chart_label]['label_filters'] = [];
                                    }
                                } else {
                                    if (Object.keys(el.answers).length !== 0) {
                                        this.show[el.chart_label]['label_filters'].push(el.data_label)
                                    }
                                    this.show[el.chart_label]['categories'] = [...el.categories, ...this.show[el.chart_label]['categories']]
                                    this.show[el.chart_label]['categories'] = 
                                        this.show[el.chart_label]['categories'].filter((value, index, array) => array.indexOf(value) === index);
                                }

                                if (el.filter) {
                                    el.filter.forEach(filter => {
                                        let current_filters
                                        if (el.filter_type == 'label') {
                                            current_filters = el['label_filters']
                                        } else {
                                            current_filters = el['categories']
                                        }
                                        if (current_filters) {
                                            current_filters.forEach(label => {
                                                if (Object.keys(el.chart_data.data).length != 0) {
                                                    if (label.includes(filter)) {
                                                        this.show[el.chart_label]['active_filters'].push(filter)
                                                    }
                                                }
                                            });
                                        }
                                    });

                                    // get unique
                                    this.show[el.chart_label]['active_filters'] = 
                                        this.show[el.chart_label]['active_filters'].filter((value, index, array) => array.indexOf(value) === index);
                                }

                                let words = [];
                                let color_idx = 0
                                if (!this.word_Color[el.chart_label]) {
                                    this.word_Color[el.chart_label] = {}
                                }
                                for (const word in el.answers) {
                                    const weight = el.answers[word];
                                    const color = color_idx > 8 ? chartConfig.colors.wordCloud_colors[Math.ceil(color_idx % 8)] :
                                        chartConfig.colors.wordCloud_colors[color_idx]

                                    words.push({
                                        rotation: degs[Math.floor(Math.random() * degs.length)],
                                        rotationUnit: 'deg',
                                        text: word,
                                        weight: weight,
                                        color: color,
                                    });
                                    this.word_Color[el.chart_label][word] = color
                                    color_idx++
                                }
                                let current = this.show[el.chart_label]['chart_data'].data.words;

                                if (current != undefined) {
                                    this.show[el.chart_label]['chart_data'].data = {
                                        words: words.concat(current),
                                    };
                                    this.combinedSameWord(this.show[el.chart_label]['chart_data'].data.words);
                                } else {
                                    this.show[el.chart_label]['chart_data'].data = {
                                        words,
                                    };
                                }

                                !this.wordCloud[el.chart_label] ? this.wordCloud[el.chart_label] = {} : null;
                                
                                this.wordCloud[el.chart_label][el.question_id] = {
                                    chart_data: el.chart_data,
                                    data_label: el.data_label,
                                    answers: el.answers,
                                    categories: el.categories,
                                }
                            } else if (el.chart_type == 'stacked bar' || el.chart_type == 'bar') {
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = {
                                        chart_data: {},
                                        chart_type: el.chart_type,
                                        chart_label: el.chart_label,
                                        month_aggregation: el.month_aggregation,
                                        week_aggregation: el.week_aggregation,
                                        position: el.position,
                                        lineSteps: el.lineSteps,
                                        labels: [],
                                        question_id: el.question_id,
                                        question_type: el.question_type,
                                    };

                                    el.chart_data.labels.forEach((element, idx) => {
                                        if (el.chart_data.data[idx]) {
                                            this.show[el.chart_label]['chart_data'][element] = el.chart_data.data[idx]
                                        }
                                    });
                                    el.chart_data.data.forEach((ele) => {
                                        for (const k in ele) {
                                            if (!this.show[el.chart_label]['labels'].includes(k)) {
                                                this.show[el.chart_label]['labels'].push(k)
                                            }
                                        }
                                    })
                                    
                                } else {
                                    el.chart_data = this.stats[question][key].chart_data
                                    el.chart_data.labels.forEach((element, idx) => {
                                        if (!this.show[el.chart_label]['chart_data'][element]) {
                                            this.show[el.chart_label]['chart_data'][element] = el.chart_data.data[idx]
                                        } else {
                                            if (!this.isEmpty(el.chart_data.data[idx])) {
                                                for (const key in el.chart_data.data[idx]) {
                                                    this.show[el.chart_label]['chart_data'][element][key] = el.chart_data.data[idx][key]
                                                    if (!this.show[el.chart_label]['labels'].includes(key)) {
                                                        this.show[el.chart_label]['labels'].push(key)
                                                    }
                                                }
                                            }
                                        }
                                    });

                                    this.show[el.chart_label]['labels'].forEach((lab, idx) => {
                                        if (lab == undefined) {
                                            this.show[el.chart_label]['labels'].splice(idx, 1)
                                        }
                                    })
                                }
                            } else if (el.chart_type == 'calendar') {
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = el
                                } else {
                                    for (const key in el.chart_data.data) {
                                        const data = el.chart_data.data[key];
                                        if (this.show[el.chart_label].chart_data.data.hasOwnProperty(key)) {
                                            let chart_data = this.show[el.chart_label].chart_data.data[key]
                                            
                                            if (typeof chart_data == 'object') {   
                                                chart_data = chart_data
                                            } else {
                                                chart_data = [chart_data]
                                            }
                                            if (chart_data) {
                                                chart_data.push(data)
                                            }
                                            this.show[el.chart_label].chart_data.data[key] = chart_data

                                        } else {
                                            this.show[el.chart_label].chart_data.data[key] = data
                                        }
                                    }
                                }
                            } else if (el.chart_type == 'table') {
                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = el;
                                } else {
                                    let chart_data = this.show[el.chart_label].chart_data
                                    let new_data = el.chart_data
                                    new_data.labels.forEach((label, idx) => {
                                        if (!chart_data.labels.includes(label)) {
                                            chart_data.data.push(new_data.data[idx])
                                            chart_data.labels.push(label)
                                        }
                                    });
                                }
                            } else if (el.chart_type == 'polar') {
                                !this.polar[el.chart_label] ? this.polar[el.chart_label] = {} : null;
                                this.polar[el.chart_label][el.question_id] = {
                                    chart_data: el.chart_data,
                                    chart_label: el.chart_label,
                                    filter: el.filter,
                                    filter_type: el.filter_type,
                                    currentFilter: 'All Data',
                                    categories: el.categories,
                                    data_label: el.data_label,
                                    question_id: el.question_id,
                                }

                                if (!this.show[el.chart_label]) {
                                    this.show[el.chart_label] = {
                                        chart_data: {},
                                        chart_type: el.chart_type,
                                        chart_label: el.chart_label,
                                        filter: el.filter,
                                        filter_type: el.filter_type,
                                        currentFilter: 'All Data',
                                        categories: el.categories,
                                        active_filters: []
                                    };

                                    this.show[el.chart_label]['chart_data'][el.data_label] =
                                        el.chart_data.average;
                                } else if (!this.show[el.chart_label][el.data_label]) {
                                    this.show[el.chart_label].categories.push(...el.categories)
                                    this.show[el.chart_label].categories = [...new Set(this.show[el.chart_label].categories)]


                                    if (el.filter) {
                                        el.filter.forEach(filter => {
                                            let current_filters
                                            if (el.filter_type == 'label') {
                                                current_filters = el['label_filters']
                                            } else {
                                                current_filters = el['categories']
                                            }
                                            if (current_filters) {
                                                current_filters.forEach(label => {
                                                    if (Object.keys(el.chart_data.data).length != 0) {
                                                        if (label.includes(filter)) {
                                                            this.show[el.chart_label]['active_filters'].push(filter)
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                        this.show[el.chart_label]['active_filters'] = 
                                            this.show[el.chart_label]['active_filters'].filter((value, index, array) => array.indexOf(value) === index);
                                    }

                                    this.show[el.chart_label]['chart_data'][el.data_label] =
                                        el.chart_data.average;
                                }
                            } else {
                                this.show[el.chart_label] = el;
                            }
                        } else {
                            this.show[el.chart_label] = el;
                        }
                    }
                }
            }

            this.showFilter = this.sortObject(this.show)

            this.show = this.sortObject(this.show)
        },
        combinedSameWord(data) {
            let temp = [];
            let combine = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element != undefined) {
                    if (temp.includes(element.text)) {
                        combine.push([temp.indexOf(element.text), i])
                    } else {
                        temp.push(element.text);
                    }
                }
            }
            this.combineValues(combine, data)
        },
        combineValues(arr, data) {
            let tmp = []
            arr.forEach(item => {
                let item1 = data[item[0]];
                let item2 = data[item[1]];
                item1.weight += item2.weight
                data[item[0]] = item1
                tmp.push(item[1])
            })
            tmp.sort((a, b) => b - a);
            tmp.forEach(index => {
                data.splice(index, 1);
            });
        },
        sortObject(o) {
            let sorted = {},
            key, a = [];

            for (key in o) {
                if (o.hasOwnProperty(key)) {
                    a.push(key);
                }
            }

            a.sort();

            for (key = 0; key < a.length; key++) {
                sorted[a[key]] = o[a[key]];
            }
            return sorted;
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
    },
    computed: {
        filteredShow() {
            if (this.current_stat == 'All Charts') {
                return this.showFilter
            } else {
                return Object.keys(this.showFilter)
                    .filter(key => this.current_stat == key)
                    .reduce((obj, key) => {
                        obj[key] = this.showFilter[key];
                        return obj;
                    }, {});
            }
        }
    }
};
</script>
