<template>
    <div v-show="show" class="w-2/5 fixed top-1/4 left-1/2 -translate-x-1/2 overflow-hidden flex flex-col items-center shadow-featureHover rounded-2xl bg-white opacity-90 text-lg z-[999]">
        <div class="w-full h-12 relative">
            <font-awesome-icon :icon="['fas', 'magnifying-glass']" class=" absolute top-[54%] -translate-y-1/2 left-3 z-10 opacity-70" />
            <input v-click-outside="hide" v-model="search" ref="focusMe" @input="onEnter" placeholder="search animahub..." class="w-full h-full px-3 pl-12 opacity-90 rounded-2xl outline-none" type="text">
        </div>

        <div v-if="results.length > 0" class="w-full bg-white opacity-90 rounded-2xl py-2 px-4 max-h-[50vh] overflow-scroll">
            <div v-for="result in results">
                <a :href="result.url ? result.url : null" class="p-2 rounded-lg hover:bg-primary cursor-pointer no-underline text-text-dark w-full block">
                    {{ result.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    export default {
        data() {
            return {
                show: false,
                search: '',
                results: []
            }
        },
        components: { FontAwesomeIcon },
        mounted() {
            eventHub.$on('showSearch', () => {
                this.show = !this.show
                if (this.show === false) {
                    this.search = ''
                    this.results = []
                } else {
                    this.results = []
                    this.$nextTick(() => this.$refs.focusMe.focus())
                }
            })

            eventHub.$on('hideSearch', () => {
                this.show = false
                this.search = ''
                this.results = []
            })
        },
        methods: {
            hide() {
                this.show = false
                this.search = ''
                this.results = []
            },
            async onEnter() {
                let response = await fetch(`${window.location.origin}/api/search?key=${this.search}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                this.results = data.results
                /**
                 * - send request to server with search string
                 * - return search page with all thing related to search string
                 *      - pages
                 *      - forms
                 *      - resources
                 *      - faq's
                 *      - exercises
                 *      - routines
                 *      - stats?
                 */
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>