<template>
    <div>
        <div v-show="!loading">
            <div ref="mainGrid" class="grid grid-cols-2 md:grid-cols-6 grid-rows-[repeat(10,_24px)] gap-5 relative px-6 lg:px-14 py-4 w-full max-w-[1440px] m-auto" >

                <div class="bg-white shadow-feature overflow-hidden rounded-3xl   row-span-2 col-span-2 md:col-span-3 lg:col-span-2 flex justify-center items-center">
                    <h2 class="text-xl text-center p-3  ">
                        Hi, <strong>{{ user.fname ?? null }} {{ user.lname ?? null }}</strong>
                    </h2>
                </div>
                
                <anima-to-do v-if="!loading" :recommend="recommend" :url="url" :grid="reco_grid"></anima-to-do>
                
                <a href="stats" class="bg-white text-secondary no-underline flex flex-col shadow-feature overflow-hidden rounded-3xl col-span-2 md:col-span-3 lg:col-span-2 row-span-5 md:row-span-5 order-2 md:order-2">
                    <div class="  flex justify-center mx-8 items-center">
                        <h2 class="font-bold text-xl text-text-dark text-center pt-3">
                            Wellness Score
                        </h2>
                    </div>
                    <div class="flex justify-between mx-auto mt-3 mb-5 h-full">
                        <div class="flex justify-center items-end py-4 pr-7 lg:pr-3 xl:pr-7 border-r border-r-secondary" ref="score">
                            <p class="font-bold text-4xl leading-[5rem]">
                                {{ score.toFixed(1) }}
                            </p>
                            <p class="text-lg leading-loose">/10</p>
                        </div>
                        <div class="flex font-bold justify-center items-end py-4 pl-7 lg:pl-3 xl:pl-7 text-4xl leading-[5rem]" 
                            :class="wellness_score.difference < 0 ? 'text-error' : wellness_score.difference > 0 ? 'text-charts-900' : 'text-secondary'">
                            <div class="text-3xl leading-normal" v-if="wellness_score.difference > 0">
                                <font-awesome-icon icon="caret-up" />
                            </div>
                            <div class="text-3xl leading-normal" v-else-if="wellness_score.difference < 0">
                                <font-awesome-icon icon="caret-down" />
                            </div>
                            <div class="text-3xl leading-normal" v-else>
                                <!-- <font-awesome-icon icon="caret-left" /> -->
                                <font-awesome-icon icon="caret-right" />
                            </div>
                            <div class="pl-1" v-if="wellness_score.difference < 0">{{-wellness_score.difference.toFixed(1)}}</div>
                            <div class="pl-1" v-else>{{wellness_score.difference.toFixed(1)}}</div>
                        </div>
                    </div>
                </a>

                <a href="history" class="col-span-1 row-span-4 shadow-feature order-6 bg-white rounded-3xl flex flex-col group no-underline text-text-dark cursor-pointer">
                    <h2 class="text-xl text-center p-3 pt-[13px] font-bold">
                        History
                    </h2>
                    <div class="flex justify-center items-center text-3xl h-full relative pb-4">
                        <img src="svg/history.svg" alt="history" class="h-16">
                    </div>
                    <!-- <div class="flex justify-center items-center text-3xl h-full relative pb-3">
                        <div class="absolute">
                            <font-awesome-icon class="bg-text-dark text-white rounded-full" icon="clock" />
                        </div>
                        <div class="group-hover:animate-spin-once">
                            <font-awesome-icon class="scale-x-[-1.6] scale-y-[1.6] font-thin" icon="arrow-rotate-right" />
                        </div>
                    </div> -->
                </a>

                <a href="goals" class="col-span-1 row-span-4 shadow-feature order-6 bg-white rounded-3xl flex flex-col group no-underline text-text-dark cursor-pointer">
                    <h2 class="text-xl text-center p-3 pt-[13px] font-bold">
                        Goals
                    </h2>
                    <div class="flex justify-center items-center text-3xl h-full relative pb-4">
                        <img src="svg/goal.svg" alt="goals" class="h-16">
                    </div>
                </a>
                
                <!-- <div class=" col-span-1 row-[span_8_/_span_8] shadow-feature order-6 bg-white rounded-3xl"></div> -->
            </div>
        </div>
        <div v-show="loading">
            <div class="text-xl text-center flex items-center justify-center">
                <font-awesome-icon :icon="['fas', 'rotate']" class="animate-spin" />
                <p class="pl-2">Loading Dashboard...</p>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimaToDo from './AnimaToDo.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import resolveConfig from 'tailwindcss/resolveConfig'
    import tailwindConfig from '../../../../tailwind.config.js'

    export default {
        name: 'dash',
        components: {
            AnimaToDo,
            FontAwesomeIcon
        },
        props: {
            wellness_score: {
                type: Object,
                default: null
            },
            url: {
                type: String,
                default: ''
            },
            recommend: {
                type: String,
                default: ''
            },
            user: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                grid_rows: 1,
                score: 0,
                recommendation: {
                    Assessments: [],
                    Journals: [],
                    'Health Checks': [],
                },
                numOfRecom: 0,
                reco_grid: 0,
                loading: true,
                windowWidth: 0,
                chroma: [
                    '#BB5B44',
                    '#D19181',
                    '#E99B44',
                    '#FFBC74',
                    '#FFCF09',
                    '#97D86C',
                    '#73C6BA',
                    '#0189A2',
                    '#01C2E4',
                    '#4A4AE6',
                    '#79A8FF'
                ]
            };
        },
        async created() {
            this.loading = true

            const recommend = JSON.parse(this.recommend)
            this.numOfRecom = recommend.length
            recommend.forEach((item) => {
                if (item.type == 'assessment') {
                    this.recommendation.Assessments.push(item);
                } else if (item.type == 'journal') {
                    this.recommendation.Journals.push(item);
                } else {
                    this.recommendation['Health Checks'].push(item);
                }
            });

            this.recommendation.Assessments.length > 0 ? this.grid_rows += 7 : null
            this.recommendation.Journals.length > 0 ? this.grid_rows += 7 : null
            this.recommendation['Health Checks'].length > 0 ? this.grid_rows += 7 : null
            this.grid_rows < 14 ? this.grid_rows-- : null
            this.reco_grid = `grid-row: span ${this.grid_rows};`
            
            window.addEventListener('resize', this.onScreenChange)
        },
        async mounted() {
            this.loading = true
            if (this.wellness_score.current_score == '0.0') {
                this.score = 0
            } else {
                const total = parseFloat(this.wellness_score.current_score).toFixed(1)
                for (let i = 0; i <= total; i += 0.1) {
                    await this.intervalScore();
                }
            }

            this.$refs.mainGrid.style.gridTemplateRows = `repeat(${this.grid_rows}, 24px)`
            this.$refs.score.style.color = this.chroma[Math.floor(this.score.toFixed(1))]
            
            const fullConfig = resolveConfig(tailwindConfig)
            const md = parseInt(fullConfig.theme.screens.md.split('px')[0])
            this.windowWidth = window.innerWidth

            if (this.windowWidth < md) {
                this.$refs.mainGrid.style.gridTemplateRows = `repeat(10, 24px)`
            }
            this.loading = false
        },
        methods: {
            sleep(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
            },
            intervalScore() {
                return this.sleep(30 / parseFloat(this.wellness_score.current_score)).then((this.score += 0.1));
            },
            onScreenChange() {
                const fullConfig = resolveConfig(tailwindConfig)
                const md = parseInt(fullConfig.theme.screens.md.split('px')[0])
                this.windowWidth = window.innerWidth

                if (this.windowWidth < md) {
                    this.$refs.mainGrid.style.gridTemplateRows = `repeat(10, 24px)`
                }
            }
        }
    };
</script>