<template>
    <div class="w-full lg:w-9/12 max-w-[100vw]"> 
        <div class="mx-auto my-4 p-4 rounded-3xl flex flex-col lg:flex-row justify-around text-text-dark h-[50vh]"
            v-if="computedLoad">
            <div class="text-xl text-center flex items-center justify-center">
                <font-awesome-icon :icon="['fas', 'rotate']" class="animate-spin" />
                <p class="pl-2">Loading stats...</p>
            </div>
        </div>

        <div v-else>
            <div
                class="mx-auto my-4 p-4 rounded-3xl flex flex-col lg:flex-row justify-around text-text-dark h-[50vh]"
                v-if="Object.keys(showStats).length === 0"
            >
                <p class="text-xl text-center flex items-center justify-center">No stats available for this date range</p>
            </div>
            <div v-else>
                <anima-headline v-if="showStats.headline" :stats="showStats.headline" :days="averageDivider"></anima-headline>
                <anima-text v-if="showStats.text" :stats="showStats.text"></anima-text>
                <anima-other
                    v-if="showStats.other"
                    :dates="dates"
                    :stats="showStats.other"
                    :url="fetchURL"
                    :start_date="dates.start"
                    :type="type"
                ></anima-other>
            </div>
        </div>
    </div>
</template>

<script>
import chartContainer from '../Charts/chartContainer.vue';
import AnimaHeadline from './AnimaHeadline.vue';
import AnimaText from './AnimaText.vue';
import AnimaOther from './AnimaOther.vue';
import AnimaDateRange from './AnimaDateRange.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        chartContainer,
        AnimaHeadline,
        AnimaText,
        AnimaOther,
        AnimaDateRange,
        FontAwesomeIcon
    },
    props: {
        name: { type: String, default: null },
        url: { type: String, default: null },
        type: { type: String, default: null },
        date_type: { type: String, default: 'daily' },
        dates: { type: Object, default() {
            return {start: '', end: ''}
        }}
    },
    data() {
        return {
            firstLoad: true,
            fetchURL: '',
            loading_stats: true,
            loading: true,
            error: '',
            form: [],
            stats: [],
            showStats: {
                headline: {},
                text: {},
                other: {},
            },
            questions: {},
            includes_calendar: false,
            likert: {
                frequency: {
                    0: 'Never', 
                    1: 'Rarely', 
                    2: 'Sometimes', 
                    3: 'Regularly', 
                    4: 'Daily'
                },
                accuracy: {
                    0: 'Not At All',
                    1: 'Slightly', 
                    2: 'Moderately', 
                    3: 'Very', 
                    4: 'Extremely'
                },
                intensity: {
                    0: 'Very Low', 
                    1: 'Low', 
                    2: 'Moderate', 
                    3: 'High', 
                    4: 'Very High',
                }
            }
        };
    },
    async mounted() {
        await this.fetchStats();

        if (this.type != 'wellness') {
            await this.getQuestions();
        }

        await this.dateInit();

        if (!this.loading_stats) {
            this.getStats();
        }
        this.loading = false;
        this.firstLoad = false;
    },
    computed: {
        computedLoad() {
            return this.loading
        },
        filteredStats() {
            let obj = {};

            let data = {};
            if (this.stats != null) {
                
                if (this.date_type == 'daily' || this.date_type == 'weekly') {
                    data = this.stats.daily;
                } else if (this.date_type == 'quarterly') {
                    data = this.stats.weekly;
                } else {
                    data = this.stats.monthly;
                }
            }

            for (const date in data) {
                if (
                    new Date(date) >= new Date(this.dates.start) &&
                    new Date(date) <= new Date(this.dates.end)
                ) {
                    obj[date] = data[date];
                }
            }
            return obj;
        },
        averageDivider() {
            if (this.date_type == 'daily') {
                return Object.keys(this.filteredStats).length == 0
                    ? 1
                    : Object.keys(this.filteredStats).length;
            } else {
                let total = 0;
                for (const date in this.stats.daily) {
                    if (
                        new Date(date) >= new Date(this.dates.start) &&
                        new Date(date) <= new Date(this.dates.end)
                    ) {
                        total++;
                    }
                }
                return total == 0 ? total + 1 : total;
            }
        },
        aggregatedWeeks() {
            let dates = [];
            if (this.date_type == 'weekly' || this.date_type == 'quarterly') {
                for (const date in this.stats.weekly) {
                    if (
                        new Date(date) >= new Date(this.dates.start) &&
                        new Date(date) <= new Date(this.dates.end)
                    ) {
                        dates.push(date);
                    }
                }
            }
            return dates;
        },
        aggregatedMonths() {
            let dates = [];
            if (this.date_type == 'quarterly' || this.date_type == 'monthly') {
                for (const date in this.stats.monthly) {
                    if (
                        new Date(date) >= new Date(this.dates.start) &&
                        new Date(date) <= new Date(this.dates.end)
                    ) {
                        dates.push(date);
                    }
                }
            }
            return dates;
        },
    },
    methods: {
        async dateInit() {
            let date = new Date();
            date.setDate(date.getDate() - 6);
            this.dates.start = date.toISOString().split('T')[0];
            this.dates.end = new Date().toISOString().split('T')[0];
        },
        async setDates(dateObj) {
            this.dates = dateObj.dates; 
            this.date_type = dateObj.range;

            let current_year = new Date(this.dates.end).getFullYear();

            if (
                new Date(this.dates.start).getFullYear() < current_year ||
                new Date(this.dates.end) > new Date(Object.keys(this.stats.daily)[0])
            ) {
                await this.fetchStats(this.dates.end);
            }

            this.getStats();
        },
        async fetchStats(date = null) {
            try {
                let param = '';
                if (date != null) {
                    param = `?date=${date}`;
                }

                this.fetchURL = `${this.url}/${this.name}`;
                let response = await fetch(`${this.url}/${this.name}${param}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();

                if (this.type == 'wellness') {
                    this.questions = data.questions
                } else {
                    this.form = data.form;
                }
                this.stats = data.form_data;

                this.loading_stats = false;
            } catch (error) {
                this.error = 'Error getting stats.';
            }
        },
        /**
         * gets a list of all questions on the form regardless of sections
         */
        getQuestions() {
            this.form.form.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    this.questions[question.id] = question;
                    if (this.type != 'wellness') {
                        this.questions[question.id].wellness_category = [...this.questions[question.id].wellness_category, ...section.categories]
                        this.questions[question.id].wellness_category = [...new Set(this.questions[question.id].wellness_category)]
                    }
                });
            });
        },
        /**
         * get the number of days from start of current range to end of current range
         * @param range: 'daily' or 'weekly'
         */
        getNumberOfDays() {
            let now = new Date();
            let sdate = new Date(this.dates.start);
            let edate = new Date(this.dates.end);
            let start;
            let end;

            if (this.date_type == 'monthly') {
                if (sdate.getFullYear() == now.getFullYear()) {
                    start = new Date(now.getFullYear(), 0, 0);
                    end = now;
                } else {
                    start = new Date(sdate.getFullYear(), 0, 0);
                    end = edate;
                }
            } else {
                if (
                    sdate.getFullYear() == now.getFullYear() &&
                    sdate.getMonth() == now.getMonth()
                ) {
                    start = new Date(now.getFullYear(), now.getMonth(), 0);
                    end = now;
                } else {
                    start = new Date(sdate.getFullYear(), sdate.getMonth(), 0);
                    end = edate;
                }
            }

            let diff =
                end - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
            let oneDay = 1000 * 60 * 60 * 24;
            let day = Math.floor(diff / oneDay);
            return day;
        },
        /**
         * get the daily stats for the daily date range
         *
         * @returns dailyStats: the stats for the daily date range
         */
        getStats() {
            let finalStats = {};

            //Gets the data for each type of chart from the stats
            for (const date in this.filteredStats) {
                for (const question_id in this.filteredStats[date]) {
                    const question = this.questions[question_id];

                    question.stats.forEach((chart, idx) => {
                        let tempAnswers = this.filteredStats[date][question_id];
                        let answer_id;
                        let key;

                        if (tempAnswers) {
                            if (typeof tempAnswers === 'object' && tempAnswers.length > 0) {
                                this.filteredStats[date][question_id].forEach((stat, i) => {
                                    answer_id = stat;
                                    key = this.getKey(date, question, answer_id);
                                    finalStats = this.createStatsObject(
                                        finalStats,
                                        question,
                                        key,
                                        chart,
                                        idx,
                                        date,
                                        i
                                    );
                                });
                            } else {
                                answer_id = this.filteredStats[date][question_id];
                                finalStats = this.createStatsObject(
                                    finalStats,
                                    question,
                                    tempAnswers,
                                    chart,
                                    idx,
                                    date
                                );
                            }
                        } else {
                            answer_id = this.filteredStats[date][question_id];
                            key = this.getKey(date, question, answer_id);
                            finalStats = this.createStatsObject(
                                finalStats,
                                question,
                                key,
                                chart,
                                idx,
                                date
                            );
                        }

                        finalStats[chart.location][question_id][idx]['answers'] = this.orderAnswers(
                            finalStats[chart.location][question_id][idx]['answers']
                        );

                        finalStats[chart.location][question_id][idx] = this.setAverage(
                            finalStats[chart.location][question_id][idx]
                        );
                    });
                }
            }

            if (Object.keys(finalStats).length !== 0) {
                //Aggregate the data for each line chart
                for (const question_id in finalStats.other) {
                    const question = finalStats.other[question_id];
    
                    for (const chart_idx in question) {
                        let chart = question[chart_idx];
    
                        if (chart.chart_type == 'line' || chart.chart_type == 'stacked bar' || chart.chart_type == 'bar') {
                            switch (this.date_type) {
                                case 'weekly':
                                    if (chart.week_aggregation == 'weeks') {
                                        chart = this.chartAgg(chart, 'week');
                                    } else {
                                        chart.lineSteps.unit = 'day';
                                        chart.lineSteps.step = 1;
                                    }
                                    break;
                                case 'quarterly':
                                    if (chart.month_aggregation == 'weeks') {
                                        chart = this.chartAgg(chart, 'week');
                                    } else if (chart.month_aggregation == 'months') {
                                        chart = this.chartAgg(chart, 'month');
                                    }
                                    break;
                                case 'monthly':
                                    chart = this.chartAgg(chart, 'month');
                                    break;
                                default:
                                    chart.lineSteps.unit = 'day';
                                    chart.lineSteps.step = 1;
                            }
                        }
                    }
                }
    
                this.showStats = finalStats;
    
                // get calendar stat data
                if (this.includes_calendar) {
                    let ids = this.getCalendarQuestionIDs()
                    let data = this.getCalendarData(ids)
                    this.adjustCalendarChartData(ids, data)
                }
            } else {
                this.showStats = finalStats;
            }
        },
        /**
         * get the lables of the answers for the charts
         *
         * @returns key: the label of the answer
         */
        getKey(date, question, answer_id) {
            let key = answer_id;
            if (question.answers) {
                question.answers.forEach((answer) => {
                    if (answer.id == answer_id) {
                        key = answer.name;
                    }
                });
            }

            if (question.type == 'number') {
                if (this.filteredStats[date][question.id]) {
                    key = this.filteredStats[date][question.id][0];
                } else {
                    key = 0;
                }
            } else if (question.type == 'slider') {
                key = answer_id
            }

            return key;
        },
        /**
         * creates an object that holds the chart info for each question
         *
         * @returns finalStats: the updated object that holds the chart info for each question
         */
        createStatsObject(obj, question, key, chart, idx, date = null, i = null) {
            if (!obj[chart.location]) {
                obj[chart.location] = {};
                obj[chart.location][question.id] = {};
            } else if (!obj[chart.location][question.id]) {
                obj[chart.location][question.id] = {};
            }

            if (!obj[chart.location][question.id][idx]) {
                obj = this.initStatObj(obj, chart, question, idx, key);
            }

            if (chart.type == 'line') {
                let data_y
                if (question.answers){
                    let new_key = this.getKey(date, question, key)

                    if (new_key == 'No') {
                        data_y = 1
                    } else if(new_key == 'Yes' || /Yes - [A-Za-z0-9]+/i.test(new_key)) {
                        data_y = 0
                    } else {
                        data_y = key ?? 0;
                    }
                } else {
                    data_y = key ?? 0;
                }

                if (/[0-9]+-[0-9]+/i.test(key)) {
                    data_y = parseInt(key.split('-')[1]);
                } else if (/([0-9]*[.])?[0-9]+ - ([0-9]*[.])?[0-9]+ [A-Za-z]+/i.test(key)) {
                    data_y = parseInt(key.split(' ')[2]);
                }

                if (question.type == 'mapping') {
                    data_y = 0
                    if (this.filteredStats[date][question.id] != '') {
                        if (typeof this.filteredStats[date][question.id] == 'string') {
                            data_y += 1
                        } else {
                            data_y += this.filteredStats[date][question.id].length
                        }
                    }
                }
                if (question.type == 'text_short') {
                    data_y = 0
                    if (this.filteredStats[date][question.id] != '') {
                        data_y = 1
                    }
                }
                if (question.type == 'date_time') {
                    data_y = this.convertToDecimal(data_y)
                }
                if (question.type == 'symptom') {
                    const data = JSON.parse(key)
                    const issue_ctx = chart.issue_context.toLowerCase()
                    data.forEach(item => {
                        let ans = item[0][issue_ctx]

                        if (issue_ctx === 'symptoms') {
                            const symptom_ctx = chart.symptom_context.toLowerCase()
                            const flattened = {};
                            this.flatten(flattened, ans);

                            for (const key in flattened) {
                                const item = flattened[key];
                                let pieces = key.split(' - ')
                                
                                if (symptom_ctx === 'area') {
                                    if (data_y > 0) {
                                        data_y += 1;
                                    } else {
                                        data_y = 1;
                                    }
                                } else {
                                    if (data_y > 0) {
                                        data_y += 1;
                                    } else {
                                        data_y = 1;
                                    }
                                }
                            }
                        } else {
                            if (data_y > 0) {
                                data_y += 1;
                            } else {
                                data_y = 1;
                            }
                        }
                    });
                }

                obj[chart.location][question.id][idx]['answers'][date] = {
                    x: date,
                    y: parseInt(data_y),
                };
            } else if (chart.type == 'calendar') {
                this.includes_calendar = true
                obj[chart.location][question.id][idx]['answers'][date] = key;
            } else if (chart.type == 'word cloud') {
                if (key && key.length > 0) {
                    let answers = []
                    if (question.type == 'mapping') {
                        let arr = JSON.parse(key)
                        arr.forEach((item) => {
                            let pieces = item.split('; ')
                            
                            if (chart.is_mapping) {
                                let str = ''
                                for (let i = 0; i < chart.mapping_level.length; i++) {
                                    const element = chart.mapping_level[i];
                                    if (i == chart.mapping_level.length - 1) {
                                        str = str.concat(pieces[element - 1]);
                                    } else {
                                        str = str.concat(pieces[element - 1], " - ");
                                    }
                                }
                                if (!answers.includes(str)) {
                                    if (str.toLowerCase() !== 'other') {
                                        answers.push(str)
                                    }
                                }
                            }
                        })
                    } else {
                        if (typeof key === 'object') {
                            key.forEach((item) => {
                                answers.push(item)
                            })
                        } else {
                            if (key.toLowerCase() !== 'other') {
                                answers = key.split(', ');
                            }
                        }
                    }
                    answers.forEach((ans) => {
                        if (/[\n]/i.test(ans) == false) {
                            if (obj[chart.location][question.id][idx]['answers'][ans] != undefined) {
                                obj[chart.location][question.id][idx]['answers'][ans] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][ans] = 1;
                            }
                        }
                    });
                }
            } else if (chart.type == 'stacked bar' || chart.type == 'bar') {
                if (question.answers != null && question.answers.length > 0) {
                    question.answers.forEach((ans) => {
                        const content = question.content.replace(/(<([^>]+)>)/gi, '');

                        if (ans.id == key) {
                            if (!obj[chart.location][question.id][idx]['answers'][date]) {
                                obj[chart.location][question.id][idx]['answers'][date] = {}
                            }

                            if (chart.is_radio && chart.radio_yesNo == true) {
                                if (ans.name == 'No') {
                                    if (obj[chart.location][question.id][idx]['answers'][date]['No']) {
                                        obj[chart.location][question.id][idx]['answers'][date]['No'] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date]['No'] = 1;
                                    }
                                } else {
                                    if (obj[chart.location][question.id][idx]['answers'][date][ans.name]) {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] = 1;
                                    }
                                }
                            } else {
                                if (ans.name == 'Yes') {
                                    if (obj[chart.location][question.id][idx]['answers'][date][content]) {
                                        obj[chart.location][question.id][idx]['answers'][date][content] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][content] = 1;
                                    }
                                } else if (ans.name != 'No') {
                                    if (obj[chart.location][question.id][idx]['answers'][date][ans.name]) {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] = 1;
                                    }
                                    obj[chart.location][question.id][idx].data_label = ans.name
                                } else {
                                    obj[chart.location][question.id][idx]['answers'][date][content] = 0;
                                }
                            }
                        } else if (ans.name == key) {
                            if (!obj[chart.location][question.id][idx]['answers'][date]) {
                                obj[chart.location][question.id][idx]['answers'][date] = {}
                            }
                            if (chart.is_radio && chart.radio_yesNo == true) {
                                if (ans.name == 'No') {
                                    if (obj[chart.location][question.id][idx]['answers'][date]['No']) {
                                        obj[chart.location][question.id][idx]['answers'][date]['No'] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date]['No'] = 1;
                                    }
                                } else {
                                    if (obj[chart.location][question.id][idx]['answers'][date][ans.name]) {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] = 1;
                                    }
                                }
                            } else {
                                if (ans.name == 'Yes') {
                                    if (obj[chart.location][question.id][idx]['answers'][date][content]) {
                                        obj[chart.location][question.id][idx]['answers'][date][content] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][content] = 1;
                                    }
                                } else if (ans.name != 'No') {
                                    if (obj[chart.location][question.id][idx]['answers'][date][ans.name]) {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][ans.name] = 1;
                                    }
                                    obj[chart.location][question.id][idx].data_label = ans.name
                                } else {
                                    obj[chart.location][question.id][idx]['answers'][date][content] = 0;
                                }
                            }
                        }
                    })
                } else if (question.type == 'mapping') {
                    if (!obj[chart.location][question.id][idx]['answers'][date]) {
                        obj[chart.location][question.id][idx]['answers'][date] = {}
                    } 

                    if (this.filteredStats[date][question.id] != '') {  
                        if (typeof this.filteredStats[date][question.id] == 'object') {

                            this.filteredStats[date][question.id].forEach((element, ii) => {
                                if (element === null) {
                                    this.filteredStats[date][question.id][ii] = "[]"
                                }
                            });

                            if (this.isValidJSON(this.filteredStats[date][question.id])) {
                                if (this.filteredStats[date][question.id] !== '[]') {
                                    let parsed = JSON.parse(this.filteredStats[date][question.id])  
                                    parsed.forEach(item => { 
                                        if (!item.toLowerCase().includes('other')) {
                                            if (!obj[chart.location][question.id][idx]['answers'][date][item]) {
                                                obj[chart.location][question.id][idx]['answers'][date][item] = 0
                                            }
                                            obj[chart.location][question.id][idx]['answers'][date][item] += 1
                                        }
                                    });
                                }
                            } else {
                                if (this.filteredStats[date][question.id][i] !== '[]') {
                                    if (this.isValidJSON(this.filteredStats[date][question.id][i])) {
                                        let parsed = JSON.parse(this.filteredStats[date][question.id][i])
                                        parsed.forEach(item => { 
                                            let pieces = item.split('; ')
                                            let str = ''
                                            for (let i = 0; i < chart.mapping_level.length; i++) {
                                                const element = chart.mapping_level[i];
                                                if (i == chart.mapping_level.length - 1) {
                                                    str = str.concat(pieces[element - 1]);
                                                } else {
                                                    str = str.concat(pieces[element - 1], " - ");
                                                }
                                            }
                                            if (obj[chart.location][question.id][idx]['answers'][date][str] >= 0) {
                                                obj[chart.location][question.id][idx]['answers'][date][str] += 1
                                            } else {
                                                obj[chart.location][question.id][idx]['answers'][date][str] = 1
                                            }
                                        });
                                    } else {
                                        if (obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] >= 0) {
                                            obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] += 1
                                        } else {
                                            obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] = 1
                                        }
                                    }
                                }
                            }
                        } else {
                            try {
                                let parsed = JSON.parse(this.filteredStats[date][question.id])
                                obj[chart.location][question.id][idx]['answers'][date][question.content.replace(/(<([^>]+)>)/gi, '')] = 0
                                parsed.forEach(item => { 
                                    obj[chart.location][question.id][idx]['answers'][date][question.content.replace(/(<([^>]+)>)/gi, '')] += 1
                                });
                            } catch (error) {
                                if (obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] >= 0) {
                                    obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] += 1
                                } else {
                                    obj[chart.location][question.id][idx]['answers'][date][this.filteredStats[date][question.id][i]] = 1
                                }
                            }
                        }
                    }
                } else if (question.type == 'text_short') {
                    if (this.filteredStats[date][question.id] != '') {
                        if (!obj[chart.location][question.id][idx]['answers'][date]) {
                            obj[chart.location][question.id][idx]['answers'][date] = {}
                        } 
                        if (key) {
                            if (typeof key === 'object') {
                                key.forEach((item) => {
                                    // answers.push(item)
                                    obj[chart.location][question.id][idx]['answers'][date][item] = 1
                                })
                            } else {
                                if (key.toLowerCase() !== 'other') {
                                    obj[chart.location][question.id][idx]['answers'][date][question.content.replace(/(<([^>]+)>)/gi, '')] = 1
                                }
                            }
                        }
                    } else {
                        obj[chart.location][question.id][idx]['answers'][date] = {}
                    }
                } else if (question.content.toLowerCase().includes('other')) {
                    if (this.filteredStats[date][question.id] != '') {
                        if (!obj[chart.location][question.id][idx]['answers'][date]) {
                            obj[chart.location][question.id][idx]['answers'][date] = {}
                        } 
                        if (typeof this.filteredStats[date][question.id] === 'object') {
                            this.filteredStats[date][question.id].forEach((item) => {
                                // answers.push(item)
                                obj[chart.location][question.id][idx]['answers'][date][item] = 1
                            })
                        } else {
                            if (key.toLowerCase() !== 'other') {
                                obj[chart.location][question.id][idx]['answers'][date][question.content.replace(/(<([^>]+)>)/gi, '')] = 1
                            }
                        }
                    } else {
                        obj[chart.location][question.id][idx]['answers'][date] = {}
                    }

                } else if (question.type == 'date_time') {
                    if (!obj[chart.location][question.id][idx]['answers'][date]) {
                        obj[chart.location][question.id][idx]['answers'][date] = {}
                    } 
                    obj[chart.location][question.id][idx]['answers'][date][question.content.replace(/(<([^>]+)>)/gi, '')]
                        = this.getMinutesFromDuration(this.filteredStats[date][question.id])
                } else if (question.type == 'emoji_slider') {
                    if (key != undefined && key != '') {
                        let value = this.likert[question.display][key]
                        if (!obj[chart.location][question.id][idx]['answers'][date]) {
                            obj[chart.location][question.id][idx]['answers'][date] = {}
                        }
                        if (obj[chart.location][question.id][idx]['answers'][date][value]) {
                            obj[chart.location][question.id][idx]['answers'][date][value] += 1;
                        } else {
                            obj[chart.location][question.id][idx]['answers'][date][value] = 1;
                        }
                    }
                } else if (question.type == 'symptom') {
                    const data = JSON.parse(key)
                    const issue_ctx = chart.issue_context.toLowerCase()
                    data.forEach(item => {
                        let ans = item[0][issue_ctx]

                        if (issue_ctx === 'symptoms') {
                            const symptom_ctx = chart.symptom_context.toLowerCase()
                            const flattened = {};
                            this.flatten(flattened, ans);

                            for (const key in flattened) {
                                const item = flattened[key];
                                let pieces = key.split(' - ')
                                
                                if (symptom_ctx === 'area') {
                                    let area = pieces.shift()
                                    if (!obj[chart.location][question.id][idx]['answers'][date]) {
                                        obj[chart.location][question.id][idx]['answers'][date] = {}
                                    }
                                    if (obj[chart.location][question.id][idx]['answers'][date][area]) {
                                        obj[chart.location][question.id][idx]['answers'][date][area] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][area] = 1;
                                    }
                                } else {
                                    let sym = pieces.join(' - ')
                                    if (!obj[chart.location][question.id][idx]['answers'][date]) {
                                        obj[chart.location][question.id][idx]['answers'][date] = {}
                                    }
                                    if (obj[chart.location][question.id][idx]['answers'][date][sym]) {
                                        obj[chart.location][question.id][idx]['answers'][date][sym] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][date][sym] = 1;
                                    }
                                }
                            }
                        } else {
                            if (!obj[chart.location][question.id][idx]['answers'][date]) {
                                obj[chart.location][question.id][idx]['answers'][date] = {}
                            }
                            if (obj[chart.location][question.id][idx]['answers'][date][ans]) {
                                obj[chart.location][question.id][idx]['answers'][date][ans] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][date][ans] = 1;
                            }
                        }
                    });
                } 
            } else if (chart.type == 'doughnut') {
                if (question.type == 'symptom') {
                    const data = JSON.parse(key)
                    const issue_ctx = chart.issue_context.toLowerCase()
                    data.forEach(item => {
                        let ans = item[0][issue_ctx]

                        if (issue_ctx === 'symptoms') {
                            const symptom_ctx = chart.symptom_context.toLowerCase()
                            const flattened = {};
                            this.flatten(flattened, ans);

                            for (const key in flattened) {
                                const item = flattened[key];
                                let pieces = key.split(' - ')
                                
                                if (symptom_ctx === 'area') {
                                    let area = pieces.shift()
                                    if (obj[chart.location][question.id][idx]['answers'][area]) {
                                        obj[chart.location][question.id][idx]['answers'][area] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][area] = 1;
                                    }
                                } else {
                                    let sym = pieces.join(' - ')
                                    if (obj[chart.location][question.id][idx]['answers'][sym]) {
                                        obj[chart.location][question.id][idx]['answers'][sym] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][sym] = 1;
                                    }
                                }
                            }
                        } else {
                            if (obj[chart.location][question.id][idx]['answers'][ans]) {
                                obj[chart.location][question.id][idx]['answers'][ans] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][ans] = 1;
                            }
                        }
                    });

                } else if (question.type == 'emoji_slider') {
                    obj[chart.location][question.id][idx]['display'] = question.display
                    if (key != undefined) {
                        key = Math.round(key)
                        if (obj[chart.location][question.id][idx]['answers'][this.likert[question.display][key]]) {
                            obj[chart.location][question.id][idx]['answers'][this.likert[question.display][key]] += 1;
                        } else {
                            obj[chart.location][question.id][idx]['answers'][this.likert[question.display][key]] = 1;
                        }
                    }
                } else {
                    if (question.type == 'mapping') {
                        let answers = []
                        if (key && key.length > 0) {
                            if (this.isValidJSON(key)) {
                                let arr = JSON.parse(key)
    
                                if (arr) {
                                    arr.forEach((item) => {
                                        let pieces = item.split('; ')
                                        
                                        if (chart.is_mapping) {
                                            let str = ''
                                            for (let i = 0; i < chart.mapping_level.length; i++) {
                                                const element = chart.mapping_level[i];
                                                if (i == chart.mapping_level.length - 1) {
                                                    str = str.concat(pieces[element - 1]);
                                                } else {
                                                    str = str.concat(pieces[element - 1], " - ");
                                                }
                                            }
                                            if (!answers.includes(str)) {
                                                answers.push(str)
                                            }
                                        }
                                    })
                                }
                                
                            } else {
                                let pieces = key.split('; ')
                                
                                if (pieces.length > 1) {
                                    if (chart.is_mapping) {
                                        let str = ''
                                        for (let i = 0; i < chart.mapping_level.length; i++) {
                                            const element = chart.mapping_level[i];
                                            if (i == chart.mapping_level.length - 1) {
                                                str = str.concat(pieces[element - 1]);
                                            } else {
                                                str = str.concat(pieces[element - 1], " - ");
                                            }
                                        }
                                        if (!answers.includes(str)) {
                                            answers.push(str)
                                        }
                                    }
                                } else {
                                    answers.push(key)
                                }
                            }
                            answers.forEach((ans) => {
                                if (/[\n]/i.test(ans) == false) {
                                    if (obj[chart.location][question.id][idx]['answers'][ans] != undefined) {
                                        obj[chart.location][question.id][idx]['answers'][ans] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][ans] = 1;
                                    }
                                }
                            });
                        }
                    } else {
                        if (key != undefined) {
                            if (obj[chart.location][question.id][idx]['answers'][key]) {
                                obj[chart.location][question.id][idx]['answers'][key] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][key] = 1;
                            }
                        }
                    }
                }
            } else {
                if (question.type == 'mapping') {
                    let answers = []
                    if (this.isValidJSON(key)) {
                        let arr = JSON.parse(key)
                        if (arr) {
                            arr.forEach((item) => {
                                let pieces = item.split('; ')
                                
                                if (chart.is_mapping) {
                                    let str = ''
                                    for (let i = 0; i < chart.mapping_level.length; i++) {
                                        const element = chart.mapping_level[i];
                                        if (i == chart.mapping_level.length - 1) {
                                            str = str.concat(pieces[element - 1]);
                                        } else {
                                            str = str.concat(pieces[element - 1], " - ");
                                        }
                                    }
                                    if (!answers.includes(str)) {
                                        answers.push(str)
                                    }
                                }
                            })
                        }
                    } else {
                        answers.push(key)
                    }
                    answers.forEach((ans) => {
                        if (/[\n]/i.test(ans) == false) {
                            if (obj[chart.location][question.id][idx]['answers'][ans] != undefined) {
                                obj[chart.location][question.id][idx]['answers'][ans] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][ans] = 1;
                            }
                        }
                    });
                } else if (question.type == 'date_time' && chart.type == 'polar') {
                    if (!obj[chart.location][question.id][idx]['answers']) {
                        obj[chart.location][question.id][idx]['answers'] = {}
                    } 
                    obj[chart.location][question.id][idx]['answers'][question.content.replace(/(<([^>]+)>)/gi, '')]
                        = this.filteredStats[date][question.id]

                } else if (question.type == 'symptom') {
                    const data = JSON.parse(key)
                    const issue_ctx = chart.issue_context.toLowerCase()
                    data.forEach(item => {
                        let ans = item[0][issue_ctx]

                        if (issue_ctx === 'symptoms') {
                            const symptom_ctx = chart.symptom_context.toLowerCase()
                            const flattened = {};
                            this.flatten(flattened, ans);

                            for (const key in flattened) {
                                const item = flattened[key];
                                let pieces = key.split(' - ')
                                
                                if (symptom_ctx === 'area') {
                                    let area = pieces.shift()
                                    if (obj[chart.location][question.id][idx]['answers'][area]) {
                                        obj[chart.location][question.id][idx]['answers'][area] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][area] = 1;
                                    }
                                } else {
                                    let sym = pieces.join(' - ')
                                    if (obj[chart.location][question.id][idx]['answers'][sym]) {
                                        obj[chart.location][question.id][idx]['answers'][sym] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][sym] = 1;
                                    }
                                }
                            }
                        } else {
                            if (obj[chart.location][question.id][idx]['answers'][ans]) {
                                obj[chart.location][question.id][idx]['answers'][ans] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][ans] = 1;
                            }
                        }
                    });

                } else {
                    if (key != undefined && key != '') {
                        if (typeof key === 'string' && key.toLowerCase() === 'other') {
                        } else if (chart.type == 'table') {
                            if (typeof key == 'object') {
                                key.forEach((item) => {
                                    if (obj[chart.location][question.id][idx]['answers'][item]) {
                                        obj[chart.location][question.id][idx]['answers'][item] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][item] = 1;
                                    }
                                })
                            } else {
                                let pieces = key.split(';; ')
                                pieces.forEach((item) => {
                                    if (obj[chart.location][question.id][idx]['answers'][item]) {
                                        obj[chart.location][question.id][idx]['answers'][item] += 1;
                                    } else {
                                        obj[chart.location][question.id][idx]['answers'][item] = 1;
                                    }
                                })
                            }
                        } else {
                            if (obj[chart.location][question.id][idx]['answers'][key]) {
                                obj[chart.location][question.id][idx]['answers'][key] += 1;
                            } else {
                                obj[chart.location][question.id][idx]['answers'][key] = 1;
                            }
                        }
                    }
                }
            }

            obj[chart.location][question.id][idx].chart_data = {
                labels: [],
                data: [],
                average: 0,
            };

            return obj;
        },
        initStatObj(obj, chart, question, idx, key) {
            if (chart.type == 'line') {
                obj[chart.location][question.id][idx] = {
                    answers: [],
                    chart_label: chart.display_name ? chart.display_name : chart.label,
                    chart_type: chart.type,
                    position: parseInt(chart.position),
                    data_aggregation: chart.data_aggregation,
                    data_label: question.content.replace(/(<([^>]+)>)/gi, ''),
                    month_aggregation: chart.month_aggregation,
                    week_aggregation: chart.week_aggregation,
                    question_id: question.id,
                    question_type: question.type,
                    question_display: question.display,
                    lineSteps: { unit: 'day', step: 1 },
                    persist: chart.persist
                };
            } else if (chart.type == 'calendar' || chart.type == 'word cloud') {
                obj[chart.location][question.id][idx] = {
                    answers: {},
                    chart_label: chart.display_name ? chart.display_name : chart.label,
                    chart_type: chart.type,
                    position: parseInt(chart.position),
                    data_aggregation: chart.data_aggregation,
                    data_label: question.content.replace(/(<([^>]+)>)/gi, ''),
                    month_aggregation: chart.month_aggregation,
                    week_aggregation: chart.week_aggregation,
                    question_id: question.id,
                };
            } else {
                obj[chart.location][question.id][idx] = {
                    answers: {},
                    chart_label: chart.display_name ? chart.display_name : chart.label,
                    chart_type: chart.type,
                    position: parseInt(chart.position),
                    data_aggregation: chart.data_aggregation,
                    data_label: question.content.replace(/(<([^>]+)>)/gi, ''),
                    month_aggregation: chart.month_aggregation,
                    week_aggregation: chart.week_aggregation,
                    question_id: question.id,
                    question_type: question.type,
                    question_display: question.display ? question.display : null,
                    lineSteps: { unit: 'day', step: 1 },
                };
                if (chart.type != 'bar' 
                    && chart.type != 'stacked bar' 
                    && (chart.type != 'doughnut' && question.type != 'emoji_slider')
                    && (chart.location != 'text' && question.type != 'mapping')
                    && (question.type != 'date_time' && chart.type != 'polar')
                    && (chart.type != 'table' && question.type != 'text')) 
                {
                    if (key != undefined) {
                        obj[chart.location][question.id][idx]['answers'][key] = 0;
                    }
                }
            }

            if (question.type == 'mapping') {
                obj[chart.location][question.id][idx]['mapping level'] = chart.mapping_level
            }

            if (question.type == 'radio') {
                obj[chart.location][question.id][idx]['is_radio'] = chart.is_radio
                obj[chart.location][question.id][idx]['radio_yesNo'] = chart.radio_yesNo
            }

            if (question.type == 'symptom') {
                obj[chart.location][question.id][idx]['is_symptom'] = chart.is_symptom
                obj[chart.location][question.id][idx]['issue_context'] = chart.issue_context
                obj[chart.location][question.id][idx]['symptom_context'] = chart.symptom_context
                obj[chart.location][question.id][idx]['data_label'] = chart.label.split(' by ')[0] ?? chart.label
            }

            if (chart.type == 'polar') {
                obj[chart.location][question.id][idx]['categories'] = question.wellness_category
            }

            let categories = []
            if (this.type != 'wellness') {
                // question.id
                categories = question.categories ? JSON.parse(question.categories) : question.wellness_category;
            } else {
                categories = question.categories ? JSON.parse(question.categories) : question.wellness_category.map(a => a.name);
            }

            obj[chart.location][question.id][idx]['filter'] = chart.filter
            obj[chart.location][question.id][idx]['filter_type'] = chart.filter_type
            obj[chart.location][question.id][idx]['categories'] = categories
            obj[chart.location][question.id][idx]['display_name'] = chart.display_name

            if (this.type == 'wellness') {
                obj[chart.location][question.id][idx]['form'] = question.form.name
            }

            if (chart.location == 'text') {
                obj[chart.location][question.id][idx]['text_stat_display'] = chart.text_stat_display
            }

            return obj;
        },
        /**
         * orders the answers in numerical order
         *
         * @returns Object of answers
         */
        orderAnswers(answers) {
            return Object.keys(answers)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = answers[key];
                    return obj;
                }, {});
        },
        getAverageDivider(question_id) {
            let total = 0;
            const { daily } = this.stats;
            const startDate = new Date(this.dates.start);
            const endDate = new Date(this.dates.end);

            for (const date in daily) {
                const currentDate = new Date(date);
                if (currentDate >= startDate && currentDate <= endDate && question_id in daily[date]) {
                    if (Array.isArray(daily[date][question_id])) {
                        total += daily[date][question_id].length
                    } else {
                        total++;
                    }
                }
            }

            

            return total === 0 ? total + 1 : total;
        },
        /**
         * get the average for the question depending on the type of stat (average / unique)
         * @param question: the question object for the chart
         *
         * @returns question: the updated question object with the average
         */
        setAverage(question) {
            let average = 0;
            for (const key in question['answers']) {
                let value = question['answers'][key];

                if (question.chart_type == 'calendar' || question.chart_type == 'word cloud') {
                    question.chart_data.data = question['answers'];
                } else if (!question.chart_data.labels.includes(key) && key != '') {
                    question.chart_data.labels.push(key);
                    question.chart_data.data.push(value);
                }
                average = this.checkRegex(key, value, average, question.question_type);
            }
            question.chart_data.average = average;

            if (question.data_aggregation == 'average') {
                if (this.type != 'wellness') {
                    if (question.question_type == 'date_time') {
                        if (question.chart_type != 'line') {
                            question.chart_data.average = this.divideDuration(average, this.getAverageDivider(question.question_id))
                        }
                    } else {
                        question.chart_data.average = (
                            question.chart_data.average / this.getAverageDivider(question.question_id)
                        ).toFixed(1);
                    }
                } else {
                    const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);
                    let wellnessAverageDivider = (sumValues(question.answers))

                    if (question.question_type == 'date_time') {
                        question.chart_data.average = this.divideDuration(average, wellnessAverageDivider)
                    } else {
                        question.chart_data.average = (
                            question.chart_data.average / wellnessAverageDivider
                        ).toFixed(1);
                    }
                }
            }
            if (question.data_aggregation == 'unique') {
                question.chart_data.average = question.chart_data.data.length;
            }

            return question;
        },
        /**
         * updated the average of the question based on the answer type using regex
         *
         * @param key: Answer label
         * @param value: Answer value
         * @param average: total value of current answer
         *
         * @returns average: the updated average value
         */
        checkRegex(key, value, average, question_type) {
            // duration
            if (/(0[0-9]|1[0-9]|2[0-3]):+([0-5][0-9])/i.test(key)) {
                if (average == 0) {
                    average = '00:00'
                }
                let keys = []
                for (let i = 0; i < value; i++) {
                    keys.push(key)
                }
                average = this.addDurations(average, ...keys);
            }
            //range e.g 6-7
            else if (/[0-9]+-[0-9]+/i.test(key)) {
                let num = parseInt(key.split('-')[1]);
                average += num * value;
            }
            //range e.g 12+
            else if (/[0-9]+\+/i.test(key)) {
                let num = parseInt(key.split('+')[0]);
                average += num * value;
            }
            //string
            else if (/[A-Za-z]+/i.test(key)) {
                average += value;
            }
            //number
            else if (/[0-9]+/i.test(key)) {
                let num = parseFloat(key);
                average += num * value;
            } 

            return average;
        },
        chartAgg(chart, type) {
            let dates;
            if (type == 'week') {
                chart.lineSteps.unit = 'day';
                chart.lineSteps.step = 7;
                dates = this.aggregatedWeeks;
            } else {
                chart.lineSteps.unit = 'month';
                chart.lineSteps.step = 1;
                dates = this.aggregatedMonths;
            }

            if (chart.chart_type == 'line') {
                chart = this.lineChartAgg(chart, dates)
            } else {
                chart = this.stackedChartAgg(chart, dates)
            }

            return chart
        },
        /**
         *  Aggregates data for line charts to weeks or months instead of days
         * @param chart: chart object
         * @param type: type of aggregation for the chart; week or month
         */
        lineChartAgg(chart, dates) {
            let agg = {};
            for (const key of dates) {
                agg[key] = {
                    y: parseInt(0),
                    x: key,
                    count: 0,
                    total: parseInt(0),
                };
            }

            for (const date_key in chart.answers) {
                for (const key in agg) {
                    if (new Date(date_key) >= new Date(key)) {
                        if (Number.isInteger(chart.answers[date_key].y)) {
                            agg[key]['total'] += parseInt(chart.answers[date_key].y);
                        } else {
                            agg[key]['total'] += parseFloat(chart.answers[date_key].y);
                        }
                        agg[key]['count']++;
                        agg[key]['y'] = (agg[key]['total'] / agg[key]['count']).toFixed(1);
                        break;
                    }
                }
            }

            for (const key in agg) {
                const obj = agg[key];
                delete obj.count;
                delete obj.total;
            }

            chart.answers = agg;
            chart.chart_data.data = [];
            for (const key in chart.answers) {
                let value = chart.answers[key];
                chart.chart_data.data.push(value);
            }
            return chart;
        },
        stackedChartAgg(chart, dates) {
            let agg = {}
            for (const key of dates) {
                agg[key] = {};
            }

            chart.chart_data.data.forEach((el, idx) => {
                const aggDates = Object.keys(agg);
                for (let i = 0; i < aggDates.length; i++) {
                    let date = aggDates[i]
                    let nextDate;
                    if (i == 0) {
                        nextDate = null
                        let currentDate = new Date(aggDates[i])
                        nextDate = currentDate.setDate(currentDate.getDate() + 366);
                    } else {
                        nextDate = aggDates[i-1]
                    }

                    if (new Date(chart.chart_data.labels[idx]) >= new Date(date) 
                        && new Date(chart.chart_data.labels[idx]) < new Date(nextDate)) {
                        for (const key in el) {
                            const element = el[key];
                            if(!agg[date][key]) {
                                agg[date][key] = element
                            } else {
                                agg[date][key] += element
                            }
                        }
                    }
                }
            });

            chart.answers = agg
            chart.chart_data.data = [];
            for (const key in chart.answers) {
                let value = chart.answers[key];
                chart.chart_data.data.push(value);
            }
            chart.chart_data.labels = dates

            return chart;
        },
        convertToDecimal(duration) {
            // Split the duration into hours and minutes
            let [hours, minutes] = duration.split(':').map(Number);
            
            // Convert minutes to decimal (divide by 60)
            let decimalMinutes = minutes / 60;
            
            // Add hours and decimal minutes together
            let decimalDuration = hours + decimalMinutes;
            
            return decimalDuration.toFixed(2);
        },
        addDurations(...durations) {
            let totalHours = 0;
            let totalMinutes = 0;

            // Iterate through each duration
            durations.forEach(duration => {
                let [hours, minutes] = duration.split(':').map(Number);
                totalHours += hours;
                totalMinutes += minutes;
            });

            // Convert excess minutes to hours if any
            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes = totalMinutes % 60;

            // Format the total duration
            let formattedDuration = `${totalHours}:${totalMinutes.toString().padStart(2, '0')}`;
            return formattedDuration;
        },
        divideDuration(duration, divisor) {
            // Parse the duration into hours and minutes
            let [hours, minutes] = duration.split(':').map(Number);
            
            // Divide hours and minutes by the divisor
            let dividedHours = Math.floor(hours / divisor);
            let dividedMinutes = Math.floor((hours % divisor * 60 + minutes) / divisor);
            
            // Format the result
            let result = `${dividedHours}:${dividedMinutes.toString().padStart(2, '0')}`;
            return result;
        },
        getMinutesFromDuration(duration) {
            const [hours, minutes] = duration.split(":").map(Number);
            return hours * 60 + minutes;
        },
        isValidJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },
        getCalendarQuestionIDs() {
            let ids = []
            for (const date in this.stats.daily) {
                const day_stats = this.stats.daily[date]
                for (const question_id in day_stats) {
                    const question = this.questions[question_id]
                    for (const stat_idx in question.stats) {
                        const stat = question.stats[stat_idx]
                        if (stat.type === 'calendar') {
                            if (!ids.includes(question_id)) {
                                ids.push(question_id)
                            }
                        }
                    }
                }
            }
            return ids.sort(function(a, b){return a-b})
        },
        getCalendarData(ids) {
            let data = {}
            for (const date in this.stats.daily) {
                const day_stats = this.stats.daily[date]
                ids.forEach(id => {
                    if (day_stats.hasOwnProperty(id)) {
                        if (day_stats[id] != null) {
                            if (data.hasOwnProperty(date)) {
                                data[date].push(parseInt(day_stats[id]))
                            } else {
                                data[date] = [parseInt(day_stats[id])]
                            }
                        }
                    }
                });
            }
            return data
        },
        adjustCalendarChartData(ids, data) {
            let stat
            for (const question_id in this.showStats.other) {
                if (ids.includes(question_id)) {
                    let stats = structuredClone(this.showStats.other[question_id])
                    for (const idx in stats) {
                        if (stats[idx].chart_type === 'calendar') {
                            stat = structuredClone(this.showStats.other[question_id][idx])
                            delete this.showStats.other[question_id][idx]
                            if (Object.keys(this.showStats.other[question_id]).length === 0) {
                                delete this.showStats.other[question_id]
                            }
                        }
                    }
                }
            }

            if (stat) {
                stat.chart_data.data = data
                stat.question_id = parseInt(ids[0])
                if (this.showStats.other[ids[0]]) {
                    let length = Object.keys(this.showStats.other[ids[0]]).length
                    this.showStats.other[ids[0]][length] = stat
                } else {
                    this.showStats.other[ids[0]] = {0: stat}
                }
            }
        },
        flatten(flattened, obj, parentKey = '') {
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = parentKey ? `${parentKey} - ${key}` : key;

                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        this.flatten(flattened, obj[key], newKey);
                    } else {
                        flattened[newKey] = obj[key];
                    }
                }
            }
            return flattened
        },
    },
    watch: {
        name: async function (newVal, oldVal) {
            if (!this.firstLoad) {
                this.loading = true;
                await this.fetchStats();
    
                if (this.type != 'wellness') {
                    this.questions = {};
                    await this.getQuestions();
                }
    
                await this.getStats();
                this.loading = false;
            }
        },
        dates: {
            async handler() {
                if (!this.firstLoad) {
                    this.loading = true;
                    let current_year = new Date(this.dates.end).getFullYear();
    
                    if (
                        new Date(this.dates.start).getFullYear() < current_year ||
                        new Date(this.dates.end) > new Date(Object.keys(this.stats.daily)[0])
                    ) {
                        await this.fetchStats(this.dates.end);
                    }
                    await this.getStats();
                    this.loading = false;
                }
            },
            deep: true,
        },
    },
};
</script>
