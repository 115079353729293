import { render, staticRenderFns } from "./FaqContent.vue?vue&type=template&id=233878fc&scoped=true&"
import script from "./FaqContent.vue?vue&type=script&lang=js&"
export * from "./FaqContent.vue?vue&type=script&lang=js&"
import style0 from "./FaqContent.vue?vue&type=style&index=0&id=233878fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233878fc",
  null
  
)

export default component.exports