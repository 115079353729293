<template>
   <div v-if="options">
      <slot name="check-body"></slot>
      <anima-option v-model="results" type="checkbox" id="TESTfruitsT" :options='options'></anima-option>
      
      <slot v-if="results.length != 0" name="slider-body"></slot>
      <div v-for="r in results" :key="r.id">
         <div v-if="r != `I Don't Know` && r !=  `Other`">
            <anima-slider :option='r'></anima-slider>
         </div>
      </div>
   </div>
</template>

<script>
   import animaOption from '../Forms/AnimaOption.vue'
   import animaSlider from '../Forms/AnimaSlider.vue'

export default {
   components: {animaSlider, animaOption},
   data() {
      return {
         results: []
      }
   },
   props: {
      options: { type: Array, default: null },
   }
}
</script>

<style>

</style>