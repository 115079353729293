<template>
    <div class="tab__content font-semibold" :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: this.active,
        };
    },
    props: {
        name: { type: String, default: null },
        active: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        classes() {
            const { isActive } = this;
            return [isActive ? 'block' : 'hidden'];
        },
    },
};
</script>
