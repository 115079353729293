<template>
    <button ref="button" @click="clicked" :type="type" :class="classes">
        <i v-if="hasIcon" :class="icon"></i>
        <slot></slot>
    </button>
</template>

<script>
export default {
    data() {
        return {
            default: false,
            hasIcon: false,
            type: 'button',
        };
    },
    props: {
        primary: { type: Boolean, default: true },
        secondary: { type: Boolean, default: false },
        hero: { type: Boolean, default: false },
        danger: { type: Boolean, default: false },
        text: { type: Boolean, default: false },
        long: { type: Boolean, default: false },
        md: { type: Boolean, default: false },
        shadow: { type: Boolean, default: false },
        icon_edit: { type: Boolean, default: false },
        icon_add: { type: Boolean, default: false },
        icon_remove: { type: Boolean, default: false },
        submit: { type: Boolean, default: false },
        round: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        xsmall: { type: Boolean, default: false },
        arrow: { type: Boolean, default: false },
        notification: { type: Object, default: null },
    },
    computed: {
        icon() {
            const { icon_edit, icon_add, icon_remove, round } = this;

            return [
                !round ? 'mr-2' : null,
                icon_edit ? ['fas fa-pen'] : null,
                icon_add ? ['fas fa-plus'] : null,
                icon_remove ? ['fas fa-trash'] : null,
            ];
        },
        classes() {
            const { primary, secondary, hero, danger, text, long, md, shadow, round, small, xsmall } = this;

            this.default = secondary || text ? false : true;

            return [
                'inline-flex items-center justify-center whitespace-nowrap rounded-full border-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-5 no-underline',

                primary && this.default
                    ? [
                        'text-text-dark border-text-dark px-8 py-2 hover:bg-primary hover:border-primary hover:text-white',
                    ]
                    : null,

                secondary
                    ? [
                        'border-text-dark text-text-dark px-8 py-2 hover:bg-text-dark hover:border-text-dark hover:text-white',
                    ]
                    : null,
                
                hero ? ['border-body text-body px-8 py-2 hover:bg-primary hover:border-primary hover:text-white'] : null,

                danger ? ['bg-danger text-white hover:bg-danger-dark px-4 py-2'] : null,
                text
                    ? [
                        'border-none rounded-none text-text-dark m-0 p-0 hover:text-primary-300 ease-in hover:underline underline-offset-2',
                    ]
                    : null,
                long ? ['block w-full'] : ['inline-block w-1/2'],
                small ? ['text-sm'] : null,
                xsmall ? ['text-xs'] : null,
                md ? ['min-w-button'] : null,
                shadow ? ['hover:shadow-lg'] : null,
                round ? ['rounded-full'] : null,
            ];
        },
    },
    mounted() {
        const { primary, secondary, danger, text, icon_edit, icon_add, icon_remove, submit } = this;

        !primary && !secondary && !danger && !text ? (this.default = true) : null;

        this.hasIcon = icon_edit || icon_add || icon_remove ? true : false;

        this.type = submit ? 'submit' : 'button';

        if (this.notification) {
            this.$refs['button'].addEventListener('click', this.onClick);
        }
    },
    methods: {
        onClick() {
            sessionStorage.setItem(
                'notification',
                JSON.stringify({
                    type: this.notification.type,
                    messages: [
                        {
                            title: this.notification.message,
                        },
                    ],
                })
            );
        },
        clicked: function (e) {
            this.$emit('click', e);
            this.type === 'loading' ? (this.isHidden = true) : null;
        },
    },
};
</script>

<style scoped>
button + button {
    /* @apply ml-1; */
}
</style>
