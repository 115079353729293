<template>
    <div>
        <div class="tooltip group" @mouseenter='checkPos'>
            <div>
                <font-awesome-icon class="group-hover:flex hidden relative text-[20px] " :icon="['fas', 'circle']" />
                <font-awesome-icon class="group-hover:hidden flex relative text-[20px] " :icon="['far', 'circle']" />
                <font-awesome-icon class=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-text-dark group-hover:text-card-100" :icon="['fas', 'ellipsis']" />
            </div>
            <span ref="tip" :class="classes" class="rounded-xl shadow-feature">
                <slot name="tip"></slot>
            </span>
        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        data() {
            return {
                newPos: null
            }
        },
        components: { FontAwesomeIcon },
        props: {
            position: { type: String, default: 'bottom' }
        },
        methods: {
            checkPos() {
                let x = this.$refs.tip.getBoundingClientRect().x
                let y = this.$refs.tip.getBoundingClientRect().y
                let height = window.innerHeight
                let width = window.innerWidth

                let elHeight = this.$refs.tip.offsetHeight
                let elWidth = this.$refs.tip.offsetWidth

                //if its below the window
                y + elHeight > height ? this.newPos = 'top' : null

                //if above window
                y < 0 ? this.newPos = 'bottom' : null

                //if right of window
                x + elWidth > width ? this.newPos = 'left' : null

                //if left of window
                x < 0 ? this.newPos = 'right' : null

                if (y - elHeight < 0 && y + elHeight > height && y < 0) {
                    this.newPos = 'right'
                }
            }
        },
        computed: {
            classes() {
                const { position, newPos } = this

                let tooltipDir = newPos != null ? newPos : position

                return [
                    'tooltip__text',
                    `tooltip__text--${tooltipDir}`
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
/* CSS file for component */
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip:hover .tooltip__text {
  visibility: visible;
}
.tooltip__text {
  width: max-content;
  position: absolute;
  transform: translateX(-50%);
  background: #1B3452;
  color: white;
  padding: 5px 10px;
  visibility: hidden;
  z-index: 99;
}
.tooltip__text--top {
  bottom: 140%;
  left: 50%;
}
.tooltip__text--bottom {
  top: 140%;
  left: 50%;
}
.tooltip__text--left {
  right: 150%;
  transform: translateY(calc(-50% - 10px));
}
.tooltip__text--right {
  left: 150%;
  transform: translateY(calc(-50% - 10px));
}

/* Additional CSS to replace SCSS @include triangle mixins */
.tooltip__text--top::after,
.tooltip__text--bottom::after,
.tooltip__text--left::after,
.tooltip__text--right::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip__text--top::after {
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px 7px 0 7px;
  border-color: #1B3452 transparent transparent transparent;
}

.tooltip__text--bottom::after {
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #1B3452 transparent;
}

.tooltip__text--left::after {
  top: 50%;
  right: -7px;
  transform: translateY(-50%);
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #1B3452;
}

.tooltip__text--right::after {
  top: 50%;
  left: -7px;
  transform: translateY(-50%);
  border-width: 7px 7px 7px 0;
  border-color: transparent #1B3452 transparent transparent;
}

</style>