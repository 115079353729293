module.exports = {
    darkMode: 'selector',
    theme: {
        extend: {
            keyframes: {
                slide: {
                    '0%': {
                        transform: 'translateY(110%)',
                    },
                    '100%': {
                        transform: 'translateY(0%)',
                    },
                },
                center_left: {
                    '0%': {
                        transform: 'translateX(0%)',
                    },
                    '100%': {
                        transform: 'translateX(-100%)',
                    },
                },
                left_center: {
                    '0%': {
                        transform: 'translateX(-100%)',
                    },
                    '100%': {
                        transform: 'translateX(0%)',
                    },
                },
                right_center: {
                    '0%': {
                        transform: 'translateX(100%)',
                    },
                    '100%': {
                        transform: 'translateX(0%)',
                    },
                },
                center_right: {
                    '0%': {
                        transform: 'translateX(0%)',
                    },
                    '100%': {
                        transform: 'translateX(100%)',
                    },
                },
                open_panel: {
                    '0%': {
                        height: '0',
                    },
                    '100%': {
                        height: '500px',
                    },
                },
                grow_margin: {
                    '0%': {
                        'margin-bottom': '0',
                    },
                    '100%': {
                        'margin-bottom': '14rem',
                    },
                },
            },

            animation: {
                'slide-up': 'slide .3s linear',
                'slide-down': 'slide .3s reverse forwards',
                'slide-next-in': 'right_center .2s linear forwards',
                'slide-next-out': 'center_left .2s linear forwards',
                'slide-prev-in': 'left_center .2s linear forwards',
                'slide-prev-out': 'center_right .2s linear forwards',
                'open-dash': '.3s open_panel .3s linear forwards',
                'grow-dash': '.2s grow_margin .3s linear forwards',
            },
            screens: {
                'md': '815px'
            },
            colors: {
                body: '#EAF6F6',

                card: {
                    DEFAULT: '#FCF7F3',
                    100: '#FCF7F3',
                    200: '#F9F0E9',
                    300: '#F7E7D9'
                },

                primary: {
                    DEFAULT: '#73C6BA',
                    dark: '#457770',
                    100: '#e3f4f1',
                    200: '#c7e8e3',
                    300: '#abddd6',
                    400: '#8fd1c8',
                    500: '#73c6ba',
                    600: '#5c9e95',
                    700: '#457770',
                    800: '#2e4f4a',
                    900: '#172825',
                },

                secondary: {
                    DEFAULT: '#EC9E90',
                    100: '#fbece9',
                    200: '#f7d8d3',
                    300: '#f4c5bc',
                    400: '#f0b1a6',
                    500: '#ec9e90',
                    600: '#bd7e73',
                    700: '#8e5f56',
                    800: '#5e3f3a',
                    900: '#2f201d',
                },

                text: {
                    light: '#265792',
                    dark: '#1B3452',
                },

                chroma: {
                    0: '#BB5B44', //dark stone
                    1: '#D19181', //light stone
                    2: '#E99B44', //dark orange
                    3: '#FFBC74', //light orange
                    4: '#FFCF09', //dark yellow
                    5: '#4A4AE6', //dark sky blue
                    6: '#79A8FF', //light sky blue
                    7: '#0189A2', //dark turquoise
                    8: '#01C2E4', //light turquoise
                    9: '#97D86C', //dark green
                    10: '#BAF593'
                },

                // coral: '#FBF5EB',
                // body: {
                //     lighter: '#FCF7F3',
                //     light: '#F9F0E9',
                //     DEFAULT: '#EAF6F6',
                //     dark: '#EC9E90',
                //     darker: '#DFC1AF',
                //     100: '#FCF7F3',
                //     200: '#F9F0E9',
                // },
                // primary: {
                //     100: '#2E4B44',
                //     200: '#1B3452',
                //     300: '#64B2A7',
                //     400: '#73C6BA',
                //     500: '#BCF8F3',
                //     light: '#BCF8F3',
                //     DEFAULT: '#73C6BA',
                //     dark: '#265792',
                //     darker: '#1B3452',
                // },
                checked: '#EC9E90',
                error: '#FF6060',
                warning: '#E99841',
                info: '#B6E0E0',
                success: '#69BEC7',
                'black-rgba': 'rgba(0, 0, 0, 0.1)',
                charts: {
                    100: '#4A4AE6', //dark sky blue
                    200: '#79A8FF', //light sky blue
                    300: '#0159A0', //dark blue
                    400: '#009DFE', //light blue
                    500: '#0189A2', //dark turquoise
                    600: '#01C2E4', //light turquoise
                    700: '#73C6BA', //dark teal
                    800: '#9BE8DD', //light teal
                    900: '#97D86C', //ark green
                    1000: '#BAF593', //light green
                    1100: '#FFCF09', //dark yellow
                    1200: '#FFE882', //light yellow
                    1300: '#EC9E90', //dark skin
                    1400: '#F3C5BC', //light skin
                    1500: '#BB5B44', //dark stone
                    1600: '#D19181',// light stone
                    1700: '#E99B44', //dark orange
                    1800: '#FFBC74', //light orange
                },
                wordcloud: {
                    100: '#4A4AE6', //dark sky blue
                    200: '#0159A0', //dark blue
                    300: '#0189A2', //dark turquoise
                    400: '#73C6BA', //dark teal
                    500: '#97D86C', //dark green
                    600: '#FFCF09', //dark yellow
                    700: '#EC9E90', //dark skin
                    800: '#BB5B44', //dark stone
                    900: '#E99B44', //dark orange
                }
            },
            width: {
                button: '230px',
            },
            spacing: {
                'social-btn': '30px',
            },
            minWidth: {
                button: '230px',
            },
            fontSize: {
                xs: '0.875rem;', //14px
                sm: '1rem;', //16px
                base: '1.125rem', //18px
                md: '1.38rem', //22px
                lg: '1.5rem', //24px
                xl: '1.75rem', //28px
                '2xl': '2.25rem', //36px
                'tagline': '2.75rem', //44px
                '3xl': '3rem', //48px
                '4xl': '4rem', //64px
                '5xl': '6rem', //96px
            },
        },
        fontFamily: {
            // quick: ['Quicksand'],
            sans: ['sans-serif'],
            body: ['Quicksand', 'sans-serif'],
            title: ['nueva-std', 'serif'],
            title_condensed: ['nueva-std-condensed', 'serif'],
            title_extended: ['nueva-std-extended', 'serif'],
        },
        flex: {
            half: '1 1 50%',
        },
        boxShadow: {
            feature: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
            featureHover: '0 6px 15px 0 rgba(0, 0, 0, 0.2)',
            resource: 'inset 0 1px 0 0 #edd3c2;',
            checkin: 'inset 0 -1px 0 0 #f7e6da',
            decrease: '0 4px 10px 0 #FF6060',
            increase: '0 4px 10px 0 #97D86C',
        },
    },
    variants: {},
    plugins: [],
    content: [
        './resources/**/*.blade.php',
        './resources/**/*.js',
        './resources/**/*.vue',
        './vendor/yadda/enso-core/resources/views/dev/responsive-helper.blade.php',
    ],
};
