<template>
    <div>
        <input :ref="question_id" data-type="datetime" type="hidden" :name="question_id" v-model="value" />
        <div v-if="display == 'date'">
            <div class="inline-block">
                <anima-date
                    @input="setDate"
                    :id="question_id"
                    placeholder="Select Date..."
                    :value="date"
                ></anima-date>
            </div>
        </div>
        <div v-else-if="display == 'time'">
            <div class="bg-white p-2 rounded-full inline-block">
                <input
                    @change="setTime"
                    @focusout="setBaseValue('hour')"
                    @focusin="clear('hour')"
                    v-model="time.hour"
                    type="number"
                    min="1"
                    max="12"
                    placeholder="HH"
                    step="1"
                    class=" max-w-[25px]"
                />:
                <input
                    @change="setTime"
                    @focusout="setBaseValue('min')"
                    @focusin="clear('min')"
                    v-model="time.minute"
                    type="number"
                    min="0"
                    max="59"
                    placeholder="MM"
                    step="1"
                    class=" max-w-[25px]"
                />
                <select v-model="time.ampm" name="ampm" id="ampm">
                    <option selected>am</option>
                    <option>pm</option>
                </select>
            </div>
        </div>
        <div v-else-if="display == 'duration'">
            <div class="bg-white p-2 rounded-full inline-block">
                <input
                    @change="setDuration"
                    @focusout="setBaseValue('hour')"
                    @focusin="clear('hour')"
                    v-model="duration.hour"
                    type="number"
                    min="0"
                    max="24"
                    placeholder="HH"
                    step="1"
                    class=" max-w-[25px]"
                />:
                <input
                    @change="setDuration"
                    @focusout="setBaseValue('min')"
                    @focusin="clear('min')"
                    v-model="duration.minute"
                    type="number"
                    min="0"
                    max="59"
                    placeholder="MM"
                    step="1"
                    class=" max-w-[25px]"
                />
            </div>
        </div>
        <div v-else>
            <div class="inline-block bg-white rounded-full">
                <div class="inline-block">
                    <anima-date
                        @input="setDate"
                        :id="question_id"
                        placeholder="Select Date..."
                    ></anima-date>
                </div>
                <div class="bg-white p-2 rounded-full inline-block">
                    <input
                        @change="setTime"
                        @focusout="setBaseValue('hour')"
                        @focusin="clear('hour')"
                        v-model="time.hour"
                        type="number"
                        min="1"
                        max="12"
                        placeholder="HH"
                        step="1"
                        class=" max-w-[25px]"
                    />:
                    <input
                        @change="setTime"
                        @focusout="setBaseValue('min')"
                        @focusin="clear('min')"
                        v-model="time.minute"
                        type="number"
                        min="0"
                        max="59"
                        placeholder="MM"
                        step="1"
                        class=" max-w-[25px]"
                    />
                    <select v-model="time.ampm" name="ampm" id="ampm">
                        <option selected>am</option>
                        <option>pm</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimaDate from './AnimaDate.vue';

export default {
    components: {
        AnimaDate,
    },
    props: {
        question_id: { type: Number, required: true },
        display: { type: String, required: true },
        default: { type: String, default: null },
    },
    data() {
        return {
            date: '',
            time: {
                hour: null,
                minute: null,
                ampm: 'am',
            },
            duration: {
                hour: null,
                minute: null,
            },
        };
    },
    mounted() {
        if (this.default) {
            this.date = this.default
            if (this.display == 'duration') {
                this.duration = {
                    hour: this.default.split(':')[0],
                    minute: this.default.split(':')[1],
                }
            } else if (this.display == 'time') {
                this.time = {
                    hour: this.default.split(':')[0],
                    minute: this.default.split(':')[1],
                    ampm: 'am',
                }
            }
        }
    },
    methods: {
        clear(time) {
            if (time == 'min') {
                this.duration.minute = ''
                this.time.minute = ''
            } else {
                this.duration.hour = ''
                this.time.hour = ''
            }
        },
        setBaseValue(time) {
            if (time == 'min') {
                if (this.duration.minute == null && this.time.minute == null 
                    || isNaN(this.duration.minute) && isNaN(this.time.minute)
                    || this.duration.minute == '' && this.time.minute == '') {
                    this.duration.minute = 0
                    this.time.minute = 0
                }

                this.time.minute = parseInt(this.time.minute).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
                this.duration.minute = parseInt(this.duration.minute).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
            } else {
                if (this.duration.hour == null && this.time.hour == null 
                    || isNaN(this.duration.hour) && isNaN(this.time.hour)
                    || this.duration.hour == '' && this.time.hour == '') {
                    this.duration.hour = 0
                    this.time.hour = 0
                }

                this.time.hour = parseInt(this.time.hour).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
                this.duration.hour = parseInt(this.duration.hour).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
            }

        },
        setDuration() {

            if (this.duration.hour === null) {
                this.duration.hour = 0
            }
            if (this.duration.minute === null) {
                this.duration.minute = 0
            }

            this.duration.hour > 24 ? (this.duration.hour = 24) : null;

            if (this.duration.hour == 24) {
                this.duration.minute = 0
            } else {
                this.duration.minute > 59 ? (this.duration.minute = 59) : null;
            }

            this.duration.hour = parseInt(this.duration.hour).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });

            this.duration.minute = parseInt(this.duration.minute).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });
        },
        setTime() {
            if (this.time.hour === null) {
                this.time.hour = 0
            }
            if (this.time.minute === null) {
                this.time.minute = 0
            }

            this.time.hour > 12 ? (this.time.hour = 12) : null;
            this.time.minute > 59 ? (this.time.minute = 59) : null;

            this.time.hour = parseInt(this.time.hour).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });

            this.time.minute = parseInt(this.time.minute).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });
        },
        setDate($e) {
            this.date = $e;
        },
    },
    computed: {
        value() {
            if (this.display == 'date') {
                return this.date;
            } else if (this.display == 'time') {
                return `${this.time.hour}:${this.time.minute} ${this.time.ampm}`;
            } else if (this.display == 'duration') {
                return `${this.duration.hour}:${this.duration.minute}`;
            } else {
                return `${this.date} - ${this.time.hour}:${this.time.minute} ${this.time.ampm}`;
            }
        },
    },
    watch: {
        value(newVal, oldVal) {
            this.$emit('change', {
                value: newVal,
                id: this.question_id,
            });
        },
    },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

textarea:focus,
input:focus {
    outline: none;
}

*:focus {
    outline: none;
}
</style>
