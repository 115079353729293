<template>
    <div>
        <select
            class="h-12 block w-full sm:text-sm border border-secondary text-text-dark rounded-md py-2 px-3 outline-primary"
            :name="question_id"
            :id="question_id"
            :ref="question_id"
        >
            <option selected disabled>Please Select One..</option>
            <option v-for="option in options">
                {{ option.journal_id ? option.journal_id : option.date + '_' + option.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        question_id: { type: Number, required: true },
        options: { type: [Array, Object], required: true },
    },
    filters: {
        date: function (value) {
            if (!value) return '';
            let date = new Date(value);
            return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
        },
    },
};
</script>

<style lang="scss" scoped></style>
