<template>
    <div>
        <select
            class="h-12 block w-full sm:text-sm border border-secondary text-text-dark rounded-md py-2 px-3 outline-primary"
            :name="question.id"
            :id="question.id"
            :ref="question.id"
            v-model="selectedOption"
            @change="onChange"
        >
            <option selected value>Please Select One</option>
            <option
                v-for="answer in question.answers"
                :key="answer.id"
                :name="answer.name"
                :value="attribute ? answer.name : answer.id"
                :ref="attribute ? answer.name : answer.id"
            >
                {{ answer.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        question: {
            type: Object,
            default: null,
        },
        question_id: {
            type: Number,
            default: null,
        },
        attribute: {
            type: Number,
            default: null,
        },
        preSelect: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            selectedOption: '',
            selected: ''
        }
    },
    mounted() {
        this.question.answers.sort(function(a, b) {
            if (/^\d+$/.test(a.name)) {
                return a.name - b.name;
            }
        });

        if (this.preSelect) {
            this.selectedOption = this.preSelect
            this.selected = this.$refs[this.preSelect][0]
            this.$emit('change', {
                check: this.selected.label,
                id: this.question_id,
            });
        }
    },
    methods: {
        onChange(e) {
            this.selected = e.target.selectedOptions[0];
            this.selectedOption = this.selected.value
            if (this.selectedOption) {
                this.$emit('change', {
                    check: this.selected.label,
                    id: this.question_id,
                });
            }
        },
    },
};
</script>

<style></style>
