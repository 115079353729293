<template>
    <div class="w-full mb-4 bg-card-200 p-4 rounded-3xl text-text-dark text-center">
        <div ref="calendar">
            <div ref="month" class="relative flex justify-center">
                <button @click="prevMonth">
                    <font-awesome-icon icon="arrow-left" />
                </button>
                <div class="text-lg font-bold md:px-8 w-1/2 md:w-1/3" ref="date">
                    {{
                        windowWidth <= 640
                            ? this.months[this.month].substring(0, 3)
                            : this.months[this.month]
                    }}
                    {{ ' ' + this.year }}
                </div>
                <button v-if="this.year < today.getFullYear() || (this.year == today.getFullYear() && this.month <= today.getMonth() - 1)" @click="nextMonth">
                    <font-awesome-icon icon="arrow-right" />
                </button>
                <button
                    title="Today"
                    aria-label="Today"
                    class="absolute top-1/2 right-0 -translate-y-1/2 w-[14.28%]"
                    @click="toToday"
                >
                    <font-awesome-icon icon="calendar-day" />
                </button>
            </div>

            <div id="weekdays" class="flex items-center justify-between">
                <div v-for="day in days" :key="day.id" class="w-[14.28%] font-bold p-2">
                    {{ windowWidth <= 640 ? day.substring(0, 1) : day | capitalize }}
                </div>
            </div>

            <div ref="days" class="flex items-center justify-between flex-wrap">
                <div
                    v-for="days in prevDaysToShow"
                    :key="days.id"
                    class="w-[14.28%] text-center p-2 flex flex-col items-center"
                >
                    {{ days }}
                    <div
                        v-if="data[new Date(year, month - 1, days).toISOString().split('T')[0]]"
                        :style="`background-color: ${
                            colors[
                                data[new Date(year, month - 1, days).toISOString().split('T')[0]]
                            ]
                        }`"
                        class="my-2 h-8 w-8 text-white rounded-lg flex justify-center items-center"
                    >
                        {{ data[new Date(year, month - 1, days).toISOString().split('T')[0]] }}
                    </div>
                    <div
                        v-else
                        class="my-2 h-8 w-8 text-text-dark rounded-lg flex justify-center items-center"
                    >
                        -
                    </div>
                </div>

                <div
                    v-for="days in daysOfMonth"
                    :key="days.id"
                    class="w-[14.28%] text-center p-2 flex flex-col items-center"
                >
                    {{ days }}
                    <div
                        v-if="data[`${year}-${month+1 < 10 ? '0'+parseInt(month+1) : month+1}-${days < 10 ? '0'+parseInt(days) : days}`]"
                        :style="`background-color: ${
                            colors[data[`${year}-${month+1 < 10 ? '0'+parseInt(month+1) : month+1}-${days < 10 ? '0'+parseInt(days) : days}`]]
                        }`"
                        class="my-2 h-8 w-8 text-white rounded-lg flex justify-center items-center"
                    >
                        {{ data[`${year}-${month+1 < 10 ? '0'+parseInt(month+1) : month+1}-${days < 10 ? '0'+parseInt(days) : days}`] }}
                    </div>
                    <div
                        v-else
                        class="my-2 h-8 w-8 text-text-dark rounded-lg flex justify-center items-center"
                    >
                        -
                    </div>
                </div>

                <div
                    v-for="days in daysOfNextMonth"
                    :key="days.id"
                    class="w-[14.28%] text-center p-2 flex flex-col items-center"
                >
                    {{ days }}
                    <div
                        v-if="data[new Date(year, month + 1, days).toISOString().split('T')[0]]"
                        :style="`background-color: ${
                            colors[
                                data[new Date(year, month + 1, days).toISOString().split('T')[0]]
                            ]
                        }`"
                        class="my-2 h-8 w-8 text-white rounded-lg flex justify-center items-center"
                    >
                        {{ data[new Date(year, month + 1, days).toISOString().split('T')[0]] }}
                    </div>
                    <div
                        v-else
                        class="my-2 h-8 w-8 text-text-dark rounded-lg flex justify-center items-center"
                    >
                        -
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import chartConfig from '../../chartConfig';

export default {
    components: { FontAwesomeIcon },
    data() {
        return {
            data: '',
            today: new Date(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            firstDayOfMonth: '',
            lastDayOfMonth: '',
            lastDayOfPrevMonth: '',
            daysOfPrevMonth: '',
            daysOfMonth: '',
            day: '',
            daysOfNextMonth: '',
            prevDaysToShow: [],
            colors: chartConfig.colors.all_colors,
            error: '',
            windowWidth: window.innerWidth,
        };
    },
    props: {
        setdata: { type: Object, default: null },
        url: { type: String, default: null },
        question_id: { type: Number, default: null },
    },
    async mounted() {
        await this.initCalendar();

        // await this.fetchData();
        if (this.setdata) {
            this.data = structuredClone(this.setdata.data)

            for (const key in this.data) {
                if (typeof this.data[key] === 'object' && this.data[key] !== null) {
                    this.data[key] = Math.round(this.data[key].reduce((a, b) => parseInt(a) + parseInt(b), 0) / this.data[key].length);
                }
            }
        }

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        async fetchData(param = '') {
            
            try {
                let response = await fetch(`${this.url}/${this.question_id}${param}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                this.data = data.form_data;
            } catch (error) {
                this.error = error;
            }
        },
        initCalendar() {
            this.firstDayOfMonth = new Date(this.year, this.month, 1); // first of current month
            this.lastDayOfMonth = new Date(this.year, this.month + 1, 0); // last of current month
            this.lastDayOfPrevMonth = new Date(this.year, this.month, 0); // last of previous month
            this.daysOfPrevMonth = this.lastDayOfPrevMonth.getDate(); // number of days in previous month
            this.daysOfMonth = this.lastDayOfMonth.getDate(); // number of days in current month
            this.day = this.firstDayOfMonth.getDay(); //get the actual day
            this.daysOfNextMonth = 7 - this.lastDayOfMonth.getDay() - 1; // number of days in next month to show

            this.prevDaysToShow = [];
            for (let x = this.day; x > 0; x--) {
                this.prevDaysToShow.push(this.daysOfPrevMonth - x + 1);
            }
        },
        prevMonth() {
            this.month--;
            if (this.month < 0) {
                this.month = 11;
                this.year--;
            }
            this.initCalendar();
        },
        nextMonth() {
            this.month++;
            if (this.month > 11) {
                this.month = 0;
                this.year++;
            }
            this.initCalendar();
        },
        toToday() {
            this.month = this.today.getMonth();
            this.year = this.today.getFullYear();
            this.initCalendar();
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.toUpperCase();
        },
    },
    watch: {
        windowWidth: function (newVal, oldVal) {},
        year: async function (newVal, oldVal) {
            let param;
            if (newVal < this.today.getFullYear()) {
                param = `?date=${this.year}-12-31`;
            } else {
                let date = this.today.toISOString().split('T')[0];
                param = `?date=${date}`;
            }

            await this.fetchData(param);
        },
    },
};
</script>
