<template>
    <div class="max-w-[950px] m-auto w-full">
        <div class="md:w-8/12 mb-4 mx-auto md:mx-auto md:mb-4 w-full">
            <div>
                <p class="text-center mb-5">Search by words or specific question</p>
            </div>
            <label class="relative flex justify-around">
                <input
                    class="w-[calc(100%-25px)]  border border-secondary rounded-xl resize-none py-2 px-3 mx-[2px]   text-text-dark text-base focus-visible:outline outline-primary-dark outline-2 mb-10"
                    type="text" v-model="search" placeholder="Keyword (category, title)..." />
                <div v-show="search.length >= 1" @click="clear" class="absolute right-5">
                    <font-awesome-icon icon="xmark" class="mt-3" />
                </div>
            </label>
        </div>
        <div class="bg-card py-2 rounded-3xl pt-px md:mb-8 shadow-feature">
            <div v-if="faqs" class="md:w-8/12 my-4 mx-auto md:mx-auto md:my-4 w-full">
                <div v-if="faqs.length == 0">No data available...</div>
                <div v-else>
                    <div class="cursor-pointer w-full text-right" @click="openAllCategories">
                        {{ openAll ? 'Close All' : 'Open All' }}
                    </div>
                    <!-- list -->

                    <div v-for="categoryName in Object.keys(filteredList)" :key="categoryName">
                        <div :class="{'bg-body': openCategories.includes(categoryName) }"
                            class="w-full flex justify-between items-center bg-white text-text-dark border-body border rounded-xl my-4 p-2 duration-200 cursor-pointer"
                            @click="toggleCategory(categoryName)">
                            <div>
                                {{ categoryName }}
                            </div>
                            <div>
                                <div
                                    :class="['w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2', { 'rotate-90': openCategories.includes(categoryName) }]">
                                </div>
                            </div>
                        </div>
                        <!-- v-if="openCategories.includes(categoryName)" -->
                        <div v-if="openCategories.includes(categoryName)"
                            class="w-[calc(100%-8px)] ml-2 border-l-2 border-secondary my-4">
                            <div v-for="(cats, key) in filteredList[categoryName]" class="ml-3">
                                <div v-if="key != 'FAQ\'s'">
                                    <!-- for -->
                                    <div class="w-full flex justify-between items-center bg-white text-text-dark border-body border rounded-md my-2 p-2 duration-200 cursor-pointer"
                                        @click="toggleCategory(`${categoryName}_${key}`)"
                                        :class="{'bg-body': openCategories.includes(`${categoryName}_${key}`) }">
                                        <div>
                                            {{ key }}
                                        </div>
                                        <div>
                                            <div
                                                :class="['w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2', { 'rotate-90': openCategories.includes(`${categoryName}_${key}`) }]">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="openCategories.includes(`${categoryName}_${key}`) "
                                        class="ml-2 border-l-2 border-secondary">
                                        <div class="ml-3">
                                            <FaqContent :fromAnchor="fromAnchor" :content="filteredList[categoryName][key]['FAQ\'s']" />
                                        </div>
                                    </div>
                                    <!-- end for -->
                                </div>
                                <div v-else>
                                    <FaqContent :fromAnchor="fromAnchor" :content="filteredList[categoryName]['FAQ\'s']" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FaqContent from './FaqContent.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        components: { FontAwesomeIcon, FaqContent },
        data() {
            return {
                loading: true,
                list: [],
                featuredlist: [],
                search: '',
                openAll: false,
                openCategories: [],
                fromAnchor: false
            };
        },
        props: {
            faqs: { type: Object, default: null },
            selectedCategory: { type: String, default: null }
        },
        mounted() {
            // document.getElementById(window.location.hash.split('#')[1]).scrollIntoView();
            let id = window.location.hash.split('#')[1]
            
            if (id) {
                this.search = id.replaceAll('-', ' ')
                this.fromAnchor = true
                this.$nextTick(() => {
                    window.scrollTo({
                        top: document.getElementById(id).offsetTop,
                        left: 0,
                        behavior: "smooth",
                    });
    
                });
            }
        },
        methods: {
            toggleCategory(categoryName) {
                if (this.openCategories.includes(categoryName)) {
                    this.openCategories = this.openCategories.filter(cat => cat !== categoryName);
                } else {
                    this.openCategories.push(categoryName);
                }
            },
            openAllCategories() {
                this.openAll = !this.openAll;
                if (this.openAll) {
                    this.openCategories = Object.keys(this.filteredList);
                } else {
                    this.openCategories = [];
                }
            },
            clear() {
                this.search = '';
            },


        },
        computed: {
            filteredList() {
                if (this.faqs !== null) {
                    let tmp = {}

                    Object.keys(this.faqs).filter(key => {

                        if (key.toLowerCase().match(this.search.toLowerCase())) {
                            if (!tmp.hasOwnProperty(key)) {
                                tmp[key] = {}
                            }
                            tmp[key] = this.faqs[key]
                        } else {
                            Object.keys(this.faqs[key]).filter(k => {

                                if (k.toLowerCase().match(this.search.toLowerCase())) {
                                    if (!tmp.hasOwnProperty(key)) {
                                        tmp[key] = {}
                                    }
                                    if (!tmp[key].hasOwnProperty(k)) {
                                        tmp[key][k] = {}
                                    }
                                    tmp[key][k] = this.faqs[key][k]
                                } else {
                                    if (k == "FAQ's") {
                                        this.faqs[key][k].forEach(faq => {
                                            if (faq.title.toLowerCase().match(this.search
                                                    .toLowerCase())) {
                                                if (!tmp.hasOwnProperty(key)) {
                                                    tmp[key] = {}
                                                }
                                                if (!tmp[key].hasOwnProperty(k)) {
                                                    if (k == "FAQ's") {
                                                        tmp[key][k] = []
                                                    } else {
                                                        tmp[key][k] = {}
                                                    }
                                                }
                                                tmp[key][k].push(faq)
                                            }
                                        });
                                    } else {
                                        Object.keys(this.faqs[key][k]).filter(i => {
                                            if (i == "FAQ's") {
                                                this.faqs[key][k][i].forEach(faq => {
                                                    if (faq.title.toLowerCase().match(
                                                            this.search.toLowerCase()
                                                        )) {
                                                        if (!tmp.hasOwnProperty(key)) {
                                                            tmp[key] = {}
                                                        }
                                                        if (!tmp[key].hasOwnProperty(
                                                                k)) {
                                                            if (k == "FAQ's") {
                                                                tmp[key][k] = []
                                                            } else {
                                                                tmp[key][k] = {}
                                                            }
                                                        }
                                                        if (!tmp[key][k].hasOwnProperty(
                                                                i)) {
                                                            if (i == "FAQ's") {
                                                                tmp[key][k][i] = []
                                                            } else {
                                                                tmp[key][k][i] = {}
                                                            }
                                                        }
                                                        tmp[key][k][i].push(faq)
                                                    }
                                                });
                                            }
                                        })
                                    }
                                }

                            })
                        }

                    })

                    return this.search != '' ? tmp : this.faqs;
                }
            }
        },
        watch: {
            search: function (newVal, oldVal) {
                if (newVal.length >= 3) {
                    this.openCategories = Object.keys(this.filteredList);

                    for (const key in this.filteredList) {
                        const element = Object.keys(this.filteredList[key]);
                        this.openCategories.push(`${key}_${element}`)
                    }
                } else if (newVal.length == 0) {
                    this.openCategories = [];
                }
            },
            selectedCategory: function (newVal, oldVal) {
                this.search = newVal
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>