<template>
    <div class="whitespace-nowrap flex relative">
        <div class="mx-6 mb-5 flex justify-center w-full">
            <div class="w-4 cursor-pointer text-lg m-auto text-center">
                <font-awesome-icon v-if="totalPages > 1" @click="prevPage" :icon="['fas', 'chevron-left']" />
            </div>
            <div class="w-full flex justify-start" ref="cards">
                <anima-card ref="card" :per_page="perPage" :max_width="maxCardWidth" type="mini" v-for="check in display_list" :key="check.id" :title="check.name" :link="`${check.type}s/${check.slug}`" :img="`./../../../svg/${check.icon}.svg`"></anima-card>
            </div>
            <div class="w-4 cursor-pointer text-lg m-auto text-center">
                <font-awesome-icon v-if="totalPages > 1" @click="nextPage" :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div v-if="totalPages > 1" class="flex justify-center absolute left-1/2 -translate-x-1/2 bottom-3">
            <span
                :ref="'dot' + i"
                class="rounded-full w-2 h-2 block m-1 "
                :class="currentPage == i ? 'bg-secondary-500' : 'bg-secondary-200'"
                v-for="i in totalPages"
                :key="i"
            >
            </span>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AnimaCard from './AnimaCard.vue';

export default {
    name: 'animaCarousel',
    components: {AnimaCard, FontAwesomeIcon},
    props: {
        items: { type: Array, required: true }
    },
    data() {
        return {
            currentPage: 1,
            cardWidth: 194,
            windowWidth: 0
        }
    },
    methods: {
        nextPage() {
            if (this.currentPage === this.totalPages) {
                this.currentPage = 1
            } else {
                this.currentPage++
            }
        },
        prevPage() {
            if (this.currentPage === 1) {
                this.currentPage = this.totalPages
            } else {
                this.currentPage--
            }
        },
        onWidthChange() {
            this.windowWidth = this.$refs['cards'].getBoundingClientRect().width
        }
    },
    mounted() {
        window.addEventListener('resize', this.onWidthChange)
        this.$nextTick(() => {
            this.windowWidth = this.$refs['cards'].getBoundingClientRect().width
        });
    },
    onUnmounted() {
        window.removeEventListener('resize', this.onWidthChange)
    },
    computed: {
        totalPages() {
            if (this.items) {
                return Math.ceil(this.items.length / this.perPage);
            } else {
                return 1
            }
        },
        showStartIdx() {
            return this.perPage * this.currentPage - this.perPage;
        },
        showEndIdx() {
            return this.showStartIdx + this.perPage;
        },
        display_list() {
            let arr = [];
            for (let i = this.showStartIdx; i < this.showEndIdx; i++) {
                const element = this.items[i];
                if (element) {
                    arr.push(element);
                }
            }
            return arr;
        },
        actCardWidth() {
            if (this.windowWidth != 0) {
                return this.$refs['card'][0].$el.getBoundingClientRect().width 
            }
        },
        maxCardWidth() {
            if (this.windowWidth != 0) {
                if (parseInt((this.$refs['cards'].getBoundingClientRect().width / this.perPage) - 50) > 200) {
                    return 200
                } else {
                    return parseInt((this.$refs['cards'].getBoundingClientRect().width / this.perPage) - 50)

                }

            }
        },
        perPage() {
            if (this.windowWidth != 0) {
                return Math.floor((this.$refs['cards'].getBoundingClientRect().width - 20) / this.cardWidth)
            }
            return 1
        }
    }
};
</script>
