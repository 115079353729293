<template>
    <div class="mt-4 mb-6">
        <h3 class="text-lg md:text-xl">{{ option | capitalize }}</h3>
        <slot name="tip"></slot>

        <label ref="slider__container" class="input__slider--container" data-min="0" data-max="4">
            <div class="length__title field-title" data-length="0"></div>
            <input
                :name="question_id"
                :ref="question_id"
                @change="update(option)"
                :id="option"
                class="input__slider"
                type="range"
                step="1"
                min="0"
                max="4"
                value="0"
            />
            <span class="input__slider--notch"></span>
        </label>
        <div class="flex justify-between">
            <span 
                v-for="(item, idx) in scales[display]"
                :class="idx == 0 ? 'text-left' : idx == scales[display].length -1 ? 'relative' : 'text-center flex justify-center'" 
                class="w-[calc(100%/22)]"
            >
                <p 
                    :class="idx == scales[display].length - 1 ? 'w-[250%] text-right right-0' : 'w-1/5'"
                    class="absolute font-semibold">{{ item | capitalize }}</p>
            </span>
        </div>
    </div>
</template>

<script>
import { scales } from 'chart.js';
import { capitalize } from 'lodash';

export default {
    data() {
        return {
            sliderProps: {
                fill: '#86c4b9',
                background: '#edd3c2',
            },
            scales: {
                frequency: ['Never','Rarely','Sometimes','Regularly','Daily'],
                accuracy: ['Not At All', 'Slightly', 'Moderately', 'Very', 'Extremely'],
                intensity: ['Very Low','Low','Moderate','High','Very High']
            }
        };
    },
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: null,
        },
        option: {
            type: String,
            default: null,
        },
        display: {
            type: String,
            default: 'frequency',
        },
        question_id: { type: Number, default: null },
        preSelected: {}
    },
    methods: {
        applyFill(slider, sliderValue) {
            const percentage = (100 * (slider.value - slider.min)) / (slider.max - slider.min);
            const bg = `linear-gradient(90deg, ${this.sliderProps.fill} ${percentage}%, ${
                this.sliderProps.background
            } ${percentage + 0.1}%)`;
            slider.style.background = bg;
            sliderValue.setAttribute('data-length', slider.value);
        },
        moveTooltip(slider, sliderValue) {
            let tooltip = slider.querySelector('#tooltip');
            tooltip.innerHTML = sliderValue.dataset.length;

            let per = (sliderValue.dataset.length / slider.dataset.max) * 100;

            tooltip.style.left = per + '%';
        },
        update(option) {
            if (this.options) {
                this.results[option] = this.$refs[option][0].value;
                this.$emit('input', this.results);
                this.$emit('change', {
                    check: this.$refs[option][0].value,
                    id: this.question_id,
                });
            } else {
                this.$emit('input', this.$refs[option].value);
                this.$emit('change', {
                    check: this.$refs[option].value,
                    id: this.question_id,
                    type: 'Numnber',
                });
            }
        },
    },
    created() {
    },
    mounted() {
        if (this.preSelected) {
            for (const key in this.preSelected) {
                if (Object.hasOwnProperty.call(this.preSelected, key)) {
                    const val = this.preSelected[key];
                    if (key == this.question_id) {
                        this.$refs[this.question_id].value = val;
                    }
                }
            }
        }
        
        if (this.$refs.slider__container) {
            if (this.options) {
                const all = this.$refs.slider__container;
                for (let i = 0; i < all.length; i++) {
                    const slider = all[i];
                    const sliderValue = slider.querySelector('.length__title');

                    slider.querySelector('input').addEventListener('input', (event) => {
                        sliderValue.setAttribute('data-length', event.target.value);
                        this.applyFill(event.target, sliderValue);
                    });

                    this.applyFill(slider.querySelector('input'), sliderValue);

                    const notch = parseInt(slider.querySelector('input').max) + 1;

                    for (let i = 0; i < notch; i++) {
                        const el = document.createElement('span');
                        el.classList = 'w-[2px] h-2 opacity-25 text-center bg-[#205685]';
                        const width = i * (100 / (notch - 1)) + '%';

                        el.style.left = width;

                        slider.querySelector('.input__slider--notch').appendChild(el);
                    }
                }
            } else {
                const slider = this.$refs.slider__container;
                const sliderValue = slider.querySelector('.length__title');

                slider.querySelector('input').addEventListener('input', (event) => {
                    sliderValue.setAttribute('data-length', event.target.value);
                    this.applyFill(event.target, sliderValue);
                });

                this.applyFill(slider.querySelector('input'), sliderValue);

                const notch = parseInt(slider.querySelector('input').max) + 1;

                for (let i = 0; i < notch; i++) {
                    const el = document.createElement('span');
                    el.classList = 'w-[2px] h-2 opacity-25 text-center bg-[#205685]';
                    const width = i * (100 / (notch - 1)) + '%';

                    el.style.left = width;

                    slider.querySelector('.input__slider--notch').appendChild(el);
                }
            }
        }
    },
    computed: {
        results() {
            if (this.options) {
                let arr = {};
                this.options.forEach((o) => {
                    arr[o] = this.$refs[o][0].value;
                });
                this.$emit('input', arr);
                return arr;
            } else {
                this.$emit('input', this.$refs[this.option].value);
                return this.$refs[this.option].value;
            }
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style>
.input__slider {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;
    border: none;
    background: #86c4b9;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.input__slider--container {
    position: relative;
    width: 100%;
    height: calc(65px - 10px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
}

.input__slider--notch {
    width: calc(100% - (18px));
    height: 10px;
    position: absolute;
    bottom: 0;
}

.input__slider--notch span {
    display: inline-block;
    width: 2px;
    height: 10px;
    position: absolute;
    background: #205685;
    top: 0;
}

.input__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #1d7078;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.input__slider::-webkit-slider-thumb:hover {
    background: #86c4b9;
    transform: scale(1.2);
}

.input__slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #1d7078;
    cursor: pointer;
    transition: background 0.15s ease-in-out;
}

.input__slider::-moz-range-thumb:hover {
    background: #86c4b9;
}
</style>
