<template>
    <div>
        <p
            v-if="active.length > 0"
            class="mt-8 text-2xl text-text-dark font-bold font-title"
        >
            Active
        </p>
        <a
            :href="'/' + type + '/' + i.id"
            v-for="i in active"
            :key="i.id"
            class="flex justify-between items-center bg-card my-5 rounded-3xl shadow-feature no-underline"
        >
            <div class="flex items-center">
                <span class="rounded-r-3xl w-5 h-5 bg-primary-dark"></span>
                <p class="text-base font-bold text-text-dark pl-4 py-5">
                    {{ i.name }}
                </p>
            </div>

            <div class="text-right pr-5">
                <p class="text-xs font-bold text-text-dark">
                    {{ getDay(i.date) }}
                </p>
                <p class="text-xs text-text-dark">
                    {{ formatDate(i.date) }}
                </p>
            </div>
        </a>

        <p
            v-if="complete.length > 0"
            class="mt-8 text-2xl text-text-dark font-bold font-title"
        >
            Completed
        </p>

        <a
            :href="'/' + type + '/' + i.id"
            v-for="i in complete"
            :key="i.id"
            class="no-underline flex justify-between items-center bg-card my-5 rounded-3xl shadow-feature"
        >
            <div class="flex items-center">
                <span class="rounded-r-3xl w-5 h-5 bg-primary-dark"></span>
                <p class="text-base font-bold text-text-dark pl-4 py-5">
                    {{ i.name }}
                </p>
            </div>

            <div class="text-right pr-5 flex items-center text-text-dark">
                <div>
                    <p class="text-xs font-bold">
                        {{ getDay(i.date) }}
                    </p>
                    <p class="text-xs text-text-dark">
                        {{ formatDate(i.date) }}
                    </p>
                </div>
                <div class="px-4">
                    <font-awesome-icon icon="arrow-right" />
                </div>
                <div>
                    <p class="text-xs font-bold text-text-dark">
                        {{ getDay(i.completed_date) }}
                    </p>
                    <p class="text-xs text-text-dark">
                        {{ formatDate(i.completed_date) }}
                    </p>
                </div>
            </div>
        </a>

        <div class="m-5" v-if="items.length < total">
            <anima-button @click="loadMore" secondary long>Load more</anima-button>
        </div>
    </div>
</template>

<script>
import animaButton from './AnimaBtn.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


export default {
    components: { animaButton, FontAwesomeIcon },
    data() {
        return {
            items: [],
            links: [],
            total: '',
        };
    },
    props: {
        link: { type: String, default: null },
        type: { type: String, default: null },
    },
    methods: {
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(date) {
            date = new Date(date);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear().toString().substr(-2),
            ].join('.');
        },
        getDay(date) {
            return new Date(date).toLocaleString('default', {
                weekday: 'long',
            });
        },
        getList() {
            this.loading_posts = true;
            this.error = null;

            fetch(this.link, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    this.items = response.data;
                    this.links = response.links;
                    this.total = response.meta.total;
                })
                .catch((error) => {
                    this.error = 'Error getting items.';
                });
        },
        loadMore() {
            if (this.links['next'] != null) {
                fetch(this.links['next'])
                    .then((response) => response.json())
                    .then((response) => {
                        this.items.push(...response.data);
                        this.links = response.links;
                    })
                    .catch((error) => {
                        this.error = 'Error getting posts.';
                    })
                    .then(() => {
                        this.loading_posts = false;
                    });
            } else {
                this.error = 'No more posts.';
            }
        },
    },
    created() {
        this.getList();
    },
    computed: {
        complete() {
            return this.items.filter((item) => {
                return item.completed == true;
            });
        },
        active() {
            return this.items.filter((item) => {
                return item.completed == false;
            });
        },
    },
};
</script>

<style></style>
