<template>
    <div class="flex">
      <div class="w-full md:w-1/4 lg:w-1/6 h-screen sticky top-5 mt-10">
        <FilterExercise class="flex-grow" :filter_options="filter_options"/>
      </div>
      <div class="flex flex-col w-5/6">
        <CategoryExercise :exercises='exercises'/>
        <ExerciseList :exercises='exercises' :image="'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRUFwDW2UbaKV_6QpSUfNFeOcolqB-O65HzA&s'" main-text="Texto Principal" secondary-text="Texto Secundario" />
      </div>
    </div>
  </template>

<script>
    import CategoryExercise from './CategoryExercise.vue'
    import ExerciseList from './ExerciseList.vue';
    import FilterExercise from './FilterExercise.vue';

    export default {
        components: {
            CategoryExercise,
            ExerciseList,
            FilterExercise
        },
        props:{
          exercises: { type: [Object, Array], required: true },
          filter_options: { type: [Object, Array], required: true }
        },
    }
</script>