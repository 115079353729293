<template>
    <div class="p-2 bg-card rounded-r-lg shadow-feature overflow-y-auto h-full">
      <div class="flex items-baseline justify-between">
        <p class="font-bold text-lg">Filters</p>
      </div>

      <div class="flex flex-col pt-4 pb-6 border-b border-checked" v-for="(options, key) in filter_options" :key="key">
        <label class="font-bold py-2 px-[2px]">{{key}}</label>
        <select @change="emitFilters(key, $event)" :name="key" :id="key" class="block w-full my-2 px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white rounded-3xl shadow-sm focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed h-10">
          <option selected="selected" value="all">All</option> 
          <option v-for="option in options" :key="option.id">{{ option }}</option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeFilter: null,
        selectedSubfilter: null,
        current_filters: {}
      };
    },
    props:{
      filter_options: { type: [Object, Array], required: true }
    },
    created() {
      for (const key in this.filter_options) {
        this.current_filters[key] = 'all'
      }
    },
    methods: {
      toggleFilter(filter) {
        this.activeFilter = this.activeFilter === filter ? null : filter;
      },
      applySubFilter(subfilter) {
        this.selectedSubfilter = subfilter;
        alert(`'hice clik en'${subfilter}`)
        
      },
      emitFilters(key, $e) {
        this.current_filters[key] = $e.target.value
        eventHub.$emit('filter_update', this.current_filters);
      }
    }
  };
  </script>
  
  <style scoped>
  
  
  </style>
  