<template>
    <div class="min-h-[43vh] md:w-10/12 mx-auto px-5">
        <p v-if="loading_posts">Loading...</p>
        <p v-else-if="error">{{ error }}</p>
        <div v-else-if="posts.length > 0">
            <anima-resource :limit="show_limit" :resources="posts"></anima-resource>
            <div v-if="posts.length % 15 == 0" class="mx-5 p-5 pb-10 md:w-1/2 lg:w-1/4 md:m-auto">
                <anima-button @click="loadMore" secondary long>Load more</anima-button>
            </div>
        </div>
        <p v-else class="pt-8 text-xl text-text-darker">No articles.</p>
    </div>
</template>

<script>
import animaResource from '../../../components/Elements/AnimaResource.vue';
import animaButton from '../../../components/Elements/AnimaBtn.vue';

export default {
    name: 'blog-post-list',
    components: {
        animaResource,
        animaButton,
    },
    props: {
        postsUrl: {
            type: String,
            default: '/json/blog',
        },
        category: {
            type: String,
        },
    },

    data() {
        return {
            layout: localStorage.getItem('blog_layout')
                ? localStorage.getItem('blog_layout')
                : 'grid',
            posts: [],
            loading_posts: false,
            error: null,
            page: 1,
            links: [],
            show_limit: 0,
        };
    },

    created() {
        this.getPosts();
    },

    methods: {
        setLayout(layout) {
            this.layout = layout;
            localStorage.setItem('blog_layout', layout);
        },

        getPosts() {
            this.loading_posts = true;
            this.error = null;

            const params = {
                page: this.page,
            };
            if (this.category) {
                params.category = this.category;
            }
            fetch(this.postsUrl + '?' + new URLSearchParams(Object.entries(params)), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    this.posts = response.data;
                    this.links = response.links;
                    this.show_limit = response.data.length;
                })
                .catch((error) => {
                    this.error = 'Error getting posts.';
                })
                .then(() => {
                    this.loading_posts = false;
                });
        },
        loadMore() {
            if (this.links['next'] != null) {
                fetch(this.links['next'])
                    .then((response) => response.json())
                    .then((response) => {
                        this.posts.push(...response.data);
                        this.links = response.links;
                        this.show_limit = this.posts.length;
                    })
                    .catch((error) => {
                        this.error = 'Error getting posts.';
                    })
                    .then(() => {
                        this.loading_posts = false;
                    });
            } else {
                this.error = 'No more posts.';
            }
        },
    },

    watch: {
        category(new_category) {
            this.getPosts();
        },
    },
};
</script>
