<template>
    <div v-show="!loading">
        <div v-if="display == 'dropdown'">
            <div v-for="(options, idx) in answer" :key="options.id">
                <label v-if="depth > 1" class="mb-2 text-text-dark" :for="question_id">{{
                    labels[idx]
                }}</label>
                <select
                    class="h-12 block w-full sm:text-sm border border-secondary text-text-dark rounded-md py-2 px-3 outline-primary mb-5"
                    @change="onChange"
                    :ref="question_id"
                    :id="question_id+'_'+idx"
                    :name="question_id"
                    v-model="selected[idx]"
                    :disabled="selected[idx - 1] == 'Please Select One'"
                >
                    <option :selected="preSelected == null" disabled>Please Select One</option>
                    <option v-for="option in options" :key="option.id">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
        <div v-else-if="display == 'details'">
            <div v-if="data">
                <div v-if="data.length == 0">No data available...</div>
                <mapping-search
                    v-else
                    :always_open="true"
                    :loading="loading"
                    :ref="question_id"
                    :question_id="question_id"
                    :id="question_id"
                    :search="search"
                    :data="data"
                    :maxDepth="depth"
                    :currentDepth="1"
                    :show_all="true"
                    :preSelected="preSelected"
                    @change="setValue"
                ></mapping-search>
            </div>
        </div>
        <div v-else-if="display === 'meal_question_modal'">
            
            <label class="relative">
                <input
                    class="w-[calc(100%-4px)] border border-secondary rounded-xl resize-none py-2 px-3 mx-[2px] mb-5 text-text-dark text-base focus-visible:outline outline-primary-dark outline-2"
                    type="text"
                    v-model="search"
                    placeholder="Search..."
                />
                <div
                    v-show="search.length >= 1"
                    @click="clear"
                    class="absolute top-1/2 -translate-y-1/2 right-5"
                >
                    <font-awesome-icon icon="xmark" />
                </div>
            </label>
            
            <div class="flex flex-wrap mb-2">
                <div class="block my-3 text-sm" :class="idx !== 0 ? 'ml-3' : null" v-for="(item, idx) in preview">
                    <span
                        @click="remove(idx, item)"
                        class="cursor-pointer shadow-feature bg-checked text-text-darker ease-in duration-100 md:ml-2 px-4 py-2.5 font-semibold rounded-xl text-center hover:bg-primary hover:text-white"
                        >{{ item.split('_')[1] | capitalize }}
                        <svg-vue class="inline h-3 w-3" icon="close"></svg-vue>
                    </span>
                </div>
            </div>

            <div v-if="data && search.length >= 3" 
                :class="isModal ? preview.length > 0 ? 'overflow-scroll h-[45vh]' : 'overflow-scroll h-[54vh]' : null" >
                <div v-if="data.length == 0">No data available...</div>
                <div v-else>
                    <div class="cursor-pointer w-full text-right" @click="open_all" v-if="search.length >= 3">
                        {{ openAll ? 'Close All' : 'Open All' }}
                    </div>
                    <mapping-search
                        :loading="loading"
                        :ref="question_id"
                        :question_id="question_id"
                        :id="question_id"
                        :search="search"
                        :data="filteredData"
                        :maxDepth="depth"
                        :currentDepth="1"
                        @change="setValue"
                        :preSelected="preSelected"
                        display_on_search
                    ></mapping-search>
                </div>
            </div>

        </div>
        <div v-else>
            <label class="relative">
                <input
                    class="w-[calc(100%-4px)] border border-secondary rounded-xl resize-none py-2 px-3 mx-[2px] mb-5 text-text-dark text-base focus-visible:outline outline-primary-dark outline-2"
                    type="text"
                    v-model="search"
                    placeholder="Search..."
                />
                <div
                    v-show="search.length >= 1"
                    @click="clear"
                    class="absolute top-1/2 -translate-y-1/2 right-5"
                >
                    <font-awesome-icon icon="xmark" />
                </div>
            </label>
            
            <div class="flex flex-wrap mb-2">
                <div v-if="isModal" class="w-full">
                    <div v-if="preview.length > 0" class="relative">
                        
                        <div 
                            @click="showPreview" 
                            ref="preview_header"
                            class="bg-white text-text-dark border-body border rounded-md my-2 p-2 flex justify-between items-center cursor-pointer duration-200">
                            My Symptoms: {{ preview.length }}
                            <div
                                class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2"
                            ></div>
                        </div>

                        <div ref="preview" class="hidden flex-wrap absolute bg-card-100 py-4 w-full z-10">
                            <div class="block ml-3 my-3 text-sm" v-for="(item, idx) in preview">
                                <span
                                    @click="remove(idx, item)"
                                    class="cursor-pointer shadow-feature bg-checked text-text-darker ease-in duration-100 md:ml-2 px-4 py-2.5 font-bold rounded-xl text-center hover:bg-primary hover:text-white"
                                    >{{ item | capitalize }}
                                    <svg-vue class="inline h-3 w-3" icon="close"></svg-vue>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="block my-3 text-sm" :class="idx !== 0 ? 'ml-3' : null" v-for="(item, idx) in preview">
                    <span
                        @click="remove(idx, item)"
                        class="cursor-pointer shadow-feature bg-checked text-text-darker ease-in duration-100 md:ml-2 px-4 py-2.5 font-semibold rounded-xl text-center hover:bg-primary hover:text-white"
                        >{{ item | capitalize }}
                        <svg-vue class="inline h-3 w-3" icon="close"></svg-vue>
                    </span>
                </div>
            </div>

            <div v-if="data" 
                :class="isModal ? preview.length > 0 ? 'overflow-scroll h-[45vh]' : 'overflow-scroll h-[54vh]' : null" >
                <div v-if="data.length == 0">No data available...</div>
                <div v-else>
                    <div class="cursor-pointer w-full text-right" @click="open_all">
                        {{ openAll ? 'Close All' : 'Open All' }}
                    </div>
                    <mapping-search
                        :loading="loading"
                        :ref="question_id"
                        :question_id="question_id"
                        :id="question_id"
                        :search="search"
                        :data="filteredData"
                        :maxDepth="depth"
                        :currentDepth="1"
                        @change="setValue"
                        :preSelected="preSelected"
                    ></mapping-search>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MappingSearch from './MappingSearch.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: { MappingSearch, FontAwesomeIcon },
    data() {
        return {
            loading: true,
            data: {},
            depth: 0,
            labels: [],
            error: '',
            selected: [],
            search: '',
            preview: [],
            openAll: false,
            previewVis: false,
            subData: {}
        };
    },
    props: {
        question_id: { type: [Number, String], default: null },
        route: { type: String, default: null },
        request_data: { type: Object, default: null },
        display: { type: String, default: null },
        filter: { type: String, default: null },
        preSelected: { type: [Array, Object], default: null },
        isModal: { type: Boolean, default: false },
    },
    async created() {
        if (this.route != null) {
            await this.fetchData();
        } else {
            this.data = this.request_data;
            this.depth = 2;
            this.labels = ['details', 'name'];
            if (this.display == 'dropdown') {
                this.setSelected();
            }
            if (this.display === 'meal_question_modal') {
                this.selected = this.preSelected
                this.preview = this.preSelected

                if (this.depth === 3) {
                    let flattenedArray = {};
                    for (let category in this.data) {
                        let items = this.data[category];
                        for (let subCategory in items) {
                            let details = items[subCategory];
                            flattenedArray[`${category} _ ${subCategory}`] = details;
                        }
                    }
                    this.data = flattenedArray
                    this.depth -= 1
                    this.labels.shift()
                }
            }
            this.loading = false;
        }
        if (this.preSelected) {
            if (this.display == 'dropdown') {
                this.selected = [this.preSelected];
            } else if (this.display === 'meal_question_modal') {
                this.selected = this.preSelected
                this.preview = this.preSelected

                if (this.depth === 3) {
                    let flattenedArray = {};
                    for (let category in this.data) {
                        let items = this.data[category];
                        for (let subCategory in items) {
                            let details = items[subCategory];
                            flattenedArray[`${category} _ ${subCategory}`] = details;
                        }
                    }
                    this.data = flattenedArray
                    this.depth -= 1
                    this.labels.shift()
                }
            }
        }
    },
    methods: {
        async fetchData() {
            try {
                let response = await fetch(this.route, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                this.data = data.data;
                this.depth = data.depth;
                this.labels = data.labels;
            } catch (error) {
                this.error = error;
            } finally {
                if (this.display == 'dropdown') {
                    this.setSelected();
                }
                this.loading = false;
            }
        },
        setSelected() {
            let returnArr = [];
            for (let i = 0; i < this.depth; i++) {
                returnArr[i] = 'Please Select One';
            }
            this.selected = returnArr;
        },
        onChange(e) {
            let id = e.target.id

            if (id.split('_')[1] === '0') {
                this.selected.forEach((item, idx) => {
                    if (idx !== 0) {
                        this.selected[idx] = 'Please Select One'
                    }
                });
            }

            const selected = this.selected;
            if (selected) {
                this.$emit('change', {
                    check: selected,
                    id: this.question_id,
                    finalData: this.subData
                });
            }
        },
        setValue(e) {
            this.selected = e.check;
            this.preview = e.preview;
            this.$refs[this.question_id].value = e.check;
            this.$emit('change', {
                check: e,
                id: this.question_id,
                finalData: this.subData
            });
        },
        remove(idx, item) {
            idx = this.preview.indexOf(item);
            let parts = this.preview[idx].split(' - ');
            let id

            if (this.display === 'meal_question_modal') {
                id = `${parts[0]}_${parts[1]}`;

                if (parts.length === 3) {
                    id = `${parts[0]}_${parts[1]} - ${parts[2]}`;
                }
            } else {
                id = `${parts[0]}_${parts[1]}`;
            }

            let el = document.getElementById(id)

            if(el) {
                el.checked = false;
            }
            
            for (const property in this.selected) {
                if (this.selected[property].length > 0) {
                    for (let i = 0; i < this.selected[property].length; i++) {
                        const element = this.selected[property][i];

                        if (
                            (parts[0] == element[element.length - 2] && parts[1] == element[element.length - 1]) ||
                            (parts[0] == element[0] && (parts[1] == element[1] || `${parts[1]} - ${parts[2]}` == element[1]))
                        ) {
                            this.selected[property].splice(
                                this.selected[property].indexOf(element),
                                1
                            );
                            this.preview.splice(idx, 1);
                        }
                    }
                }
            }
            this.$forceUpdate()
        },
        open_all() {
            let items = this.$el.querySelectorAll('[data-type]');
            this.openAll = !this.openAll;

            for (let i = 0; i < items.length; i++) {
                if (items[i].getAttribute('data-type') == 'mapping-header') {
                    if (this.openAll) {
                        items[i].lastChild.classList.add('rotate-90');
                        items[i].nextElementSibling.classList.remove('h-0');
                        items[i].classList.add('bg-body');
                    } else {
                        items[i].lastChild.classList.remove('rotate-90');
                        items[i].nextElementSibling.classList.add('h-0');
                        items[i].classList.remove('bg-body');
                    }
                }
            }
        },
        clear() {
            this.search = '';
        },
        showPreview() {
            let arrow = this.$refs.preview_header.lastChild;
            if (this.$refs.preview.classList.contains('hidden')) {
                this.$refs.preview.classList.remove('hidden')
                this.$refs.preview.classList.add('flex')
                this.$refs.preview_header.classList.add('bg-body')
                this.$refs.preview_header.classList.remove('bg-white')
                arrow.classList.add('rotate-90');
            } else {
                this.$refs.preview.classList.add('hidden')
                this.$refs.preview.classList.remove('flex')
                this.$refs.preview_header.classList.add('bg-white')
                this.$refs.preview_header.classList.remove('bg-body')
                arrow.classList.remove('rotate-90');
            }
        }
    },
    computed: {
        answer() {
            let returnArr = [];
            let currentData = this.data;
            for (let i = 0; i < this.depth; i++) {
                if (i === this.depth - 1) {
                    if (this.selected.length > 0) {
                        if (currentData.hasOwnProperty(Object.values(this.selected)[i])) {
                            returnArr[i] = this.selected[i];
                        } else {
                            returnArr[i] = Object.values(currentData);
                        }
                    } else {
                        returnArr[i] = Object.values(currentData);
                    }
                } else {
                    returnArr[i] = Object.keys(currentData);
                    if (this.selected.length > 0) {
                        if (currentData.hasOwnProperty(Object.values(this.selected)[i])) {
                            currentData = currentData[Object.values(this.selected)[i]];
                        } else {
                            currentData = Object.values(currentData)[0];
                        }
                    } else {
                        currentData = Object.values(currentData)[0];
                    }
                }
            }
            return returnArr;
        },
        filteredData() {
            if (this.filter != null) {
                if (this.filter.length == 0) {
                    return this.data;
                } else {
                    return Object.keys(this.data)
                        .filter((key) => this.filter.includes(key))
                        .reduce((obj, key) => {
                            obj[key] = this.data[key];
                            return obj;
                        }, {});
                }
            } else {
                return this.data;
            }
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
    watch: {
        async route(route) {
            if (this.filter == null) {
                this.loading = true;
                await this.fetchData();
            }
        },
        request_data: {
            handler(request_data) {
                this.data = request_data;
                this.depth = 2;
                this.labels = ['details', 'name'];
                this.selected = [];
            },
            deep: true,
        },
        preview: {
            handler(newVal, oldVal) {
                this.subData = {}
                if (this.display == 'meal_question_modal') {
                    newVal.forEach(element => {
                        let pieces = element.split(' _ ')
                        let sub_pieces = pieces[1].split(/ - (.*)/s)
                        if (!this.subData.hasOwnProperty(pieces[0])) {
                            this.subData[pieces[0]] = []
                        }

                        this.subData[pieces[0]].push([
                            pieces[0],
                            sub_pieces[0],
                            sub_pieces[1]
                        ])
                    });
                }

                this.$emit('change', {
                    check: this.preview,
                    id: this.question_id,
                    finalData: this.subData
                });
            },
            deep: true
        }
    },
};
</script>


<style scoped>
div::-webkit-scrollbar{
    display: none;
}

div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>