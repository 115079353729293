<template>
    <section class="flex justify-center relative md:max-w-2xl mx-auto px-5 my-1 md:my-2">
        <div v-if="!loading_categories" class="absolute top-6 md:top-12 w-11/12 md:w-6/12 px-5">
            <select
                class="h-12 block w-full sm:text-sm border border-gray-300 rounded-md py-2 px-3 outline-primary"
                @input="onClick"
                v-model="current_category"
                name="current_category"
                id="current_category"
            >
                <option selected value>All</option>
                <option v-for="category in categories" :key="category.id" :value="category.slug">
                    {{ category.name }}
                </option>
            </select>
        </div>

        <p v-if="loading_categories">...</p>

        <p v-else-if="error">{{ error }}</p>

        <ul v-else-if="categories.length > 0"></ul>
    </section>
</template>

<script>
import animaOption from '../../../components/Forms/AnimaOption.vue';
export default {
    name: 'blog-category-list',
    components: { animaOption },
    props: {
        categoryUrl: { type: String, default: '/json/categories' },
        param: { type: String, default: null },
    },
    data() {
        return {
            categories: [],
            error: null,
            loading_categories: false,
            current_category: '',
        };
    },
    created() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            this.loading_categories = true;
            this.error = null;

            fetch(this.categoryUrl)
                .then((response) => response.json())
                .then((response) => {
                    this.categories = response;
                })
                .catch((error) => {
                    this.error = 'Error getting categories.';
                })
                .then(() => {
                    this.loading_categories = false;
                    const vue = this;
                    this.categories.forEach((category) => {
                        if (category.slug == vue.param) {
                            vue.current_category = category.slug;
                        }
                    });
                    this.$emit('select', this.current_category);
                });
        },
        onClick(e) {
            this.$emit('select', e.target.value);
        },
    },
};
</script>
