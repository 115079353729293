<template>
   <div class="flex justify-center p-4">
      <span v-if="pages > 2" class="pagination__link text-lg w-7 mx-1 inline-block text-center leading-7 rounded-full text-primary hover:bg-primary-200" @click="changePage('first')">
         <i class="fas fa-angle-double-left"></i>
      </span>

      <span class="pagination__link text-lg w-7 mx-1 inline-block text-center leading-7 rounded-full text-primary hover:bg-primary-200" @click="changePage('prev')">
         <i class="fas fa-angle-left"></i>
      </span>

      <span v-for="page in display_range" 
         :key="page" 
         @click="setPage(page)"
         :class="page === currentPage ? 'active text-white bg-primary' : 'text-primary hover:bg-primary-200'" 
         class="pagination__link text-lg w-7 mx-1 inline-block text-center leading-7 rounded-full">
            {{ page }}
      </span>
      
      <span class="pagination__link text-lg w-7 mx-1 inline-block text-center leading-7 rounded-full text-primary hover:bg-primary-200" @click="changePage('next')">
         <i class="fas fa-angle-right"></i>
      </span>
      
      <span v-if="pages > 2" class="pagination__link text-lg w-7 mx-1 inline-block text-center leading-7 rounded-full text-primary hover:bg-primary-200" @click="changePage('last')">
         <i class="fas fa-angle-double-right"></i>
      </span>
   </div>
</template>

<script>
export default {
   data() {
      return {
         currentPage: this.active_page
      }
   },
   props: {
      pages: { type: Number, default: null },
      max_pages: {type: Number, default: 5},
      active_page: {type: Number, default: 1}
   },
   methods: {
      checkPage(position) {
         if (position == 'first') {
            return this.currentPage == 1 ? true : false
         }
         if (position == 'last') {
            return this.currentPage == this.pages ? true : false
         }
      },
      changePage(dir) {
         let first = this.checkPage('first') //returns true if active page is the first page
         let last = this.checkPage('last') //returns true if active page is the last page

         if (dir === 'next' && !last) {
            this.currentPage++
         }

         if (dir === 'first') {
            this.currentPage = 1
         }

         if (dir === 'prev' && !first) {
            this.currentPage--
         }

         if (dir === 'last') {
            this.currentPage = this.pages
         }

         this.$emit('input', this.currentPage)
      },
      setPage(index) {
         this.currentPage = index
         this.$emit('input', this.currentPage)
      },
   },
   computed: {
      nextPage() {
         return this.currentPage + 1 > this.pages ? this.pages : this.currentPage + 1
      },
      lastPage() {
         return this.currentPage - 1 == 0 ? 1 : this.currentPage - 1
      },
      display_range() {
         const distance = this.max_pages / 2
         const mod = Math.floor(distance) !== distance ? 1 : 0
         let start = this.currentPage - Math.floor(distance)
         let end = this.currentPage + Math.floor(distance)

         while (start <= 0) {
            start += 1
            end += 1
         }

         while (end - (1 - mod) > this.pages) {
            start -= 1
            if (start < 1) {
               start = 1
            }
            end -= 1
         }

         return [...Array((end - start) + mod).keys()].map(i => i + start)      
      }
   }
}
</script>

<style lang="scss">

</style>