<template>
    <div class="mt-4 mb-6">
        <label v-if="$slots.label" :for="id" class="block text-text-dark text-sm mb-1">
            <slot name="label"></slot>
            <!-- <span v-if="required == true" style="color: red;">*</span> -->
        </label>

        <!-- preview -->
        <div v-if="current_file" class="w-36 relative">
            <div class="flex items-center justify-center w-6 h-6 absolute top-0 right-0 text-white bg-text-dark rounded-full cursor-pointer translate-x-1/2 -translate-y-1/2">
                <font-awesome-icon icon="xmark" @click="clear"/>
            </div>
            <div class="w-36 h-36 my-3">
                <img :src="current_file" class="w-full h-full max-h-36" alt="current profile image">
            </div>
        </div>

        <label :for="id" class="cursor-pointer group mx">
            <input
                @change="uploadFile"
                :id="id"
                :name="name"
                class="hidden"
                type="file"
                accept="image/*"
                ref="input"
            />

            <span
                class="bg-primary py-1 px-3 mr-1 rounded-lg transition-all group-hover:bg-primary-dark group-hover:text-primary-light"
                >Choose File</span
            >

            <span
                v-if="filename"
                class="italic underline underline-offset-4 decoration-2 decoration-primary"
                >{{ filename }}</span
            >
            <span
                v-else
                class="italic underline underline-offset-4 decoration-2 decoration-gray-400"
                >No File Chosen</span
            >
        </label>

        <span v-if="$slots.error" class="input__error text-error">
            <i class="fas fa-exclamation-triangle"></i>
            <slot name="error"></slot>
        </span>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    data() {
        return {
            filename: '',
            current_file: this.current
        };
    },
    components: { FontAwesomeIcon },
    props: {
        id: { type: String, default: null },
        name: { type: String, default: null },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        current: { type: String, default: null }
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.emitValue(value);
            },
        },
        classes() {
            const { $slots } = this;

            return [
                `focus-visible:outline outline-2 placeholder-[#96BCB6] rounded-full block w-full sm:text-sm rounded-md py-2 px-3 outline-primary`,
                $slots.error ? 'border-error outline-error bg-error-light' : null,
            ];
        },
    },
    methods: {
        clear() {
            this.filename = null
            this.$refs['input'].value = null
            this.current_file = null
            this.$emit('input', null);
        },
        uploadFile(e) {
            let filename = e.target.value.split('\\');
            filename = filename[filename.length - 1];
            let ext = filename.split('.')[1];
            let file = filename.split('.')[0];
            if (file.length > 18) {
                let first = file.substring(0, 8);
                let end = file.substring(file.length - 9, file.length);
                this.filename = `${first}...${end}.${ext}`;
            } else {
                this.filename = filename;
            }

            let src
            if (e.target.files[0]) {
                src = URL.createObjectURL(e.target.files[0])
                this.current_file = src
            }
            this.$emit('input', e.target.files);
        },
        emitValue(value) {
            // if (this.number == true || this.currency == true) {
            //     if (value == '') {
            //         value = 0;
            //     }
            //     value = parseFloat(value);
            // }
            // this.$emit('input', value);
        },
    },
};
</script>
