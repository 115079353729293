<template>
    <div class="flex relative flex-col md:flex-row items-start justify-start w-full m-auto">
        <!-- filters -->
        <div ref="aside" class="w-full md:w-1/4 lg:w-1/6 h-[67px] md:h-screen overflow-hidden md:overflow-visible md:sticky top-0 mr-3 p-4 rounded-b-xl lg:rounded-xl bg-card-100 shadow-feature">           
            <div class="flex items-baseline justify-between" @click="toggleMenu">
                <p class="font-bold text-lg">Filters</p>
                <div v-if="Object.keys(filteredLog).length < Object.keys(activityByDate).length" @click.stop="clearAll" class="inline-flex items-center justify-center whitespace-nowrap rounded-full border-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-5 no-underline border-none cursor-pointer text-text-dark md:m-0 p-0 hover:text-primary-300 ease-in hover:underline underline-offset-2 mr-6 ">Clear all</div>
            </div>

            <div class="flex flex-col pt-4 pb-6 border-b border-checked">
                <label for="type" class="font-bold py-2 px-[2px]">Activity Type</label>
                <select name="type" id="type" v-model="filter.type" class="block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white rounded-3xl shadow-sm focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed h-10">
                    <option selected value="all">All</option>
                    <option value="assessment">Assessments</option>
                    <option value="checkin">Health Checks</option>
                    <option value="journal">Journals</option>
                </select>
            </div>

            <div class="flex flex-col pt-4 pb-6">
                <label for="type" class="font-bold py-2 px-[2px]">Date</label>
                <anima-date ref="rangeSelect" name='date' id='date' placeholder="Select Range" range @input="filter.date = $event"></anima-date>
            </div>

            <div ref="arrow" class="md:hidden absolute top-[23px] right-4">
                <font-awesome-icon icon="caret-right" />
            </div>
        </div>
        
        <!-- log -->
        <div class="relative pb-12 min-h-screen w-full md:w-3/4 lg:w-5/6 px-4 md:px-8 lg:px-14 mt-8 md:mt-0 mb-4 mr-3">

            <div v-for="(item, idx) in display_log" class="flex justify-start items-center mr-5">
                <div class="w-full pb-6 relative">
                    <span class="bg-checked h-full w-[.2rem] block absolute left-[calc(100%/24*3)] md:left-[calc(100%/12)] z-0"></span>
                    <span v-if="idx == display_log.length - 1" class="bg-checked h-4 w-4 -translate-x-[40%] rounded-full block absolute -bottom-1 
                    left-[calc(100%/24*3)] md:left-[calc(100%/12)] z-0"></span>

                    <p class="font-bold relative z-50 bg-body w-3/12 md:w-1/6 text-center">                
                        {{ display_log[idx-1] ? display_log[idx-1][0] != item[0] ? item[0] : null : item[0] }}
                    </p>
                    
                    <div class="grid grid-cols-12 md:grid-cols-6 relative z-50">
                        <div class="col-span-3 md:col-span-1 bg-card-100 p-4 my-4 mx-auto rounded-full h-18 w-18 flex justify-center items-center border-body border-4">
                            <img :src="`svg/${item[1].history_type == 'form' ? icons[item[1].history_type][item[1].submission_type] : icons[item[1].history_type]}.svg`" alt="icon" class="h-10">
                        </div>
                        <div class="col-span-9 md:col-span-5 bg-card-100 p-4 my-4 rounded-3xl flex justify-between items-center shadow-feature">
                            <p class="font-bold">{{item[1].form_name}} completed</p>
                            <a 
                                v-if="item[1].history_type == 'form' && item[1].submission_type == 'assessment'" 
                                :href="`assessments/edit/${slugify(item[1].form_name)}/${item[1].id}`"
                                class="no-underline text-text-dark hover:underline"
                            > 
                                Update Assessment
                                <font-awesome-icon icon="angle-right" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- pagination -->
            <div v-if="totalPages > 1" class="flex justify-evenly bg-card-100 py-3 md:mx-5 mt-10 rounded-3xl shadow-feature sticky top-full">
                <!-- back button -->
                <button @click="movePage('first')" :disabled="currentPage == 1" class="w-12 h-12 hover:bg-card-300 rounded-3xl">
                    <font-awesome-icon icon="angles-left" />
                </button>
                <button @click="movePage('prev')" :disabled="currentPage == 1" class="w-12 h-12 hover:bg-card-300 rounded-3xl">
                    <font-awesome-icon icon="angle-left" />
                </button>
                <!-- page counter -->
                <div>
                    <button v-for="page in display_range" @click="setPage(page)" 
                        class="w-12 h-12 mx-3 rounded-full"
                        :class="currentPage == page ? 'bg-checked' : 'bg-card-100 hover:bg-card-300'"
                    >
                        {{ page }}
                    </button>
                </div>
                <!-- next buttton -->
                <button @click="movePage('next')" :disabled="currentPage == totalPages" class="w-12 h-12 hover:bg-card-300 rounded-3xl">
                    <font-awesome-icon icon="angle-right" />
                </button>
                <button @click="movePage('last')" :disabled="currentPage == totalPages" class="w-12 h-12 hover:bg-card-300 rounded-3xl">
                    <font-awesome-icon icon="angles-right" />
                </button>
            </div>

        </div>
        
    </div>
</template>

<script>
import AnimaButton from './AnimaBtn.vue'
import AnimaDate from '../Forms/AnimaDate.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../tailwind.config.js'

    export default {
        components: { AnimaButton, AnimaDate, FontAwesomeIcon },
        props: {
            activity: { type: [Array, Object], default: null },
        },
        data() {
            return {
                activityByDate: {},
                filter: {
                    type: 'all',
                    date: 'all'
                },
                icons: {
                    form: {
                        checkin: 'heart',
                        assessment: 'checklist',
                        journal: 'journal',
                    },
                    goal: 'goal'
                },
                currentPage: 1,
                perPage: 5,
            }
        },
        created() {
            this.activity.forEach(item => {
                const date = new Date(item.date).toISOString().split('T')[0]
                if (!this.activityByDate[date]) {
                    this.activityByDate[date] = []
                }
                this.activityByDate[date].push(item)
            });

        },
        methods: {
            clearAll() {
                for (const key in this.filter) {
                    this.filter[key] = 'all';
                }
                this.$refs['rangeSelect'].$data.defaultRange = ''
            },
            movePage(dir) {
                if (dir == 'next') {
                    this.currentPage++
                } else if (dir == 'last') {
                    this.currentPage = this.totalPages
                } else if (dir == 'prev') {
                    this.currentPage--
                } else if (dir == 'first') {
                    this.currentPage = 1
                }
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
            },
            setPage(page) {
                this.currentPage = page
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
            },
            toggleMenu() {
                if(this.$refs['aside'].classList.contains('h-[67px]')) {
                    this.$refs['aside'].classList.remove('h-[67px]')
                    this.$refs['arrow'].classList.add('rotate-90')
                } else {
                    this.$refs['aside'].classList.add('h-[67px]')
                    this.$refs['arrow'].classList.remove('rotate-90')
                }
            },
            slugify(str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
                str = str.toLowerCase(); // convert string to lowercase
                str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
                        .replace(/\s+/g, '-') // replace spaces with hyphens
                        .replace(/-+/g, '-'); // remove consecutive hyphens
                return str;
            }
        },
        computed: {
            totalPages() {
                let logArr = Object.entries(this.filteredLog).map(([date, forms]) => {
                    return forms.map(form => {
                        return [date, form];
                    });
                }).flat();

                return Math.ceil(logArr.length / this.perPage);
            },
            display_log() {
                let logArr = Object.entries(this.filteredLog).map(([date, forms]) => {
                    return forms.map(form => {
                        return [date, form];
                    });
                }).flat();

                let arr = [];
                for (let i = this.showStartIdx; i < this.showEndIdx; i++) {
                    const element = logArr[i];
                    if (element) {
                        arr.push(element);
                    }
                }
                return arr;
            },
            filteredLog() {
                let filtered = {}

                let typeMatch = this.filter.type
                let dateMatch = this.filter.date

                for (const date in this.activityByDate) {
                    const items = this.activityByDate[date];
                    filtered[date] = items.filter((el) => {
                        if (el.history_type == 'form') {
                            return el.submission_type == typeMatch || typeMatch == 'all' ? el : null
                        }
                        return el
                    });

                    filtered[date] = filtered[date].filter((el) => {
                        if (dateMatch != 'all') {
                            return new Date(el.date) >= new Date(dateMatch[0]) && new Date(el.date) <= new Date(dateMatch[1]) ? el : null
                        }
                        return el
                    });

                    if (filtered[date].length == 0) {
                        delete filtered[date]
                    }
                }

                return filtered
            },
            display_range() {
                const fullConfig = resolveConfig(tailwindConfig)
                const md = parseInt(fullConfig.theme.screens.md.split('px')[0])
                
                let distance = 2.5

                if (window.innerWidth < md) {
                    distance = 1;
                }
                const mod = 1;
                let start = this.currentPage - Math.floor(distance);
                let end = this.currentPage + Math.floor(distance);

                while (start <= 0) {
                    start += 1;
                    end += 1;
                }

                while (end - (1 - mod) > this.totalPages) {
                    start -= 1;
                    if (start < 1) {
                        start = 1;
                    }
                    end -= 1;
                }

                return [...Array(end - start + mod).keys()].map((i) => i + start);
            },
            showStartIdx() {
                return this.perPage * this.currentPage - this.perPage;
            },
            showEndIdx() {
                return this.showStartIdx + this.perPage;
            },
        },
    }
</script>