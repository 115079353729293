<template>
    <div>
        <p class="label">{{ name }}</p>
        <div>
            <span
                class="tag"
                :class="invalidList.includes(item) ? 'invalid' : null"
                v-for="item in selected"
            >
                <p>{{ item }}</p>
                <font-awesome-icon
                    @click="selectOption(item)"
                    class="close_icon"
                    :icon="['fas', 'xmark']"
                />
            </span>
        </div>
        <div v-click-outside="outside" class="wrapper" @click="activeInput">

            <input
                
                ref="input"
                @click="showList = true"
                @focus="showList = true"
                v-model="search"
                type="text"
                name="search"
                id="search"
                placeholder="Search Option..."
                autocomplete="off"
            />

            <div class="optionList" v-if="showList">
                <div
                    class="option"
                    :class="selected.includes(option) ? 'selected' : ''"
                    @click="selectOption(option)"
                    v-for="option in filteredOptions"
                >
                    {{ option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {FontAwesomeIcon},
    props: {
        name: { type: String, default: null },
        section: { type: String, default: null },
        subsection: { type: String, default: null },
        options: { type: Array, default: null },
        preselect: { type: Array, default: null },
        linked: { type: Boolean, default: false },
        invalid: { type: Array, default() {
            return []
        } },
    },
    data() {
        return {
            search: '',
            selected: [],
            showList: false,
            invalidList: [],
        };
    },
    mounted() {
        if (this.preselect != '') {
            this.selected = this.preselect;
        }
        if (this.preselect == null) {
            this.selected = [];
        } else {
            this.selected = this.preselect;
        }

        if (this.invalid != []) {
            for (let i = 0; i < this.invalid.length; i++) {
                if (this.invalid[i].includes(this.name.toUpperCase().replace(' ', '_'))) {
                    this.invalidList.push(this.invalid[i][1]);
                }
            }
        }
    },
    methods: {
        activeInput(e) {
            if (!e.target.classList.contains('option')) {
                setTimeout(() => {
                    this.$refs['input'].focus();
                }, 10);
            }
        },
        outside() {
            this.showList = false;
        },
        selectOption(option) {
            if (this.selected.includes(option)) {
                let index = this.selected.indexOf(option);
                this.selected.splice(index, 1);
                this.$emit('remove', [
                    [this.section, this.name, this.subsection],
                    option,
                    this.linked,
                ]);
            } else {
                this.selected.push(option);
                this.$emit('add', [
                    [this.section, this.name, this.subsection],
                    option,
                    this.linked,
                ]);
            }
            this.search = '';
            this.showList = false;
        },
    },
    computed: {
        filteredOptions() {
            return this.options.filter((item) => {
                return item.toLowerCase().match(this.search.toLowerCase());
            });
        },
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component '${compName}'`;
                    }

                    console.warn(warn);
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                // add Event Listeners
                document.addEventListener('click', handler);
            },

            unbind: function (el, binding) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
    watch: {
        preselect: {
            handler(newList, oldList) {
                if (newList == null) {
                    this.selected = [];
                } else {
                    this.selected = newList;
                }
            },
            deep: true, // Watch for changes in nested objects or arrays if needed
        },
    },
};
</script>

<style scoped>
.label {
    font-size: 1rem;
    font-weight: 700;
}

.wrapper {
    border-radius: 999px;
    padding: 10px 12px;
    position: relative;
    background: #fff;
}

input {
    width: 100%;
    border: none;
}

input:focus,
input:focus-visible {
    outline: none;
}

.tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 14px;
    color: #fff;
    background: #73C6BA;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis; 
}

.tag.invalid {
    background: #fe6a6a;
}

.close_icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
}

.optionList {
    position: absolute;
    z-index: 999;
    max-height: 220px;
    overflow: scroll;
    background-color: #fff;
    width: calc(100% + 2px);
    border: 1px solid #e8e8e8;
    left: 0;
    transform: translateX(-1px);
}

.option {
        padding: 10px 12px;
        cursor: pointer;
    }

    .option:hover {
            color: #fff;
            background-color: #73C6BA;
        }

        .option.selected:hover {
            color: #fff;
            background-color: #fe6a6a;
        }
</style>
