<template>
    <div v-show="show">
        <div v-if="maxDepth == currentDepth">
            <div v-if="filteredList.length > 0">
                <div v-if="always_open">
                    <div
                        :ref="label"
                        data-type="mapping-header"
                        class="bg-body text-text-dark  border-body border rounded-md my-2 p-2 flex justify-between items-center duration-200"
                    >
                        {{ label.split('_').join('-') }} 
                        <div
                            class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2 rotate-90"
                        ></div>
                    </div>

                    <div class="flex flex-wrap overflow-hidden ml-2 border-l-2 border-secondary">
                        <div class="my-4 ml-4 lg:ml-1 lg:my-3 pb-4" v-for="item in filteredList" :key="item.id">
                            <label class="leading-6">
                                <input
                                    class="hidden peer"
                                    type="checkbox"
                                    :name="question_id"
                                    :value="item"
                                    :aria-label="item"
                                    :ref="question_id"
                                    :id="label + '_' + item"
                                    :checked="checked.includes(item)"
                                    @change="emitChange(item)"
                                />
                                <span
                                    class="cursor-pointer shadow-feature font-semibold bg-white text-text-dark ease-in duration-100 md:ml-2 px-4 py-2.5 rounded-xl text-center hover:bg-primary hover:text-white peer-checked:bg-checked peer-checked:text-text-darker"
                                    >{{ item }}</span
                                >
                            </label>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div
                        :ref="label"
                        data-type="mapping-header"
                        @click="toggleSection(label)"
                        class="bg-white text-text-dark  border-body border rounded-md my-2 p-2 flex justify-between items-center duration-200 cursor-pointer"
                    >
                        {{ label.split('_').join('-') }}
                        <div
                            class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2"
                        ></div>
                    </div>

                    <div
                        class="flex flex-wrap overflow-hidden ml-2 border-l-2 border-secondary h-0"
                    >
                        <div class="my-4 ml-4 lg:ml-1 lg:my-3 pb-4" v-for="item in filteredList" :key="item.id">
                            <label class="leading-6">
                                <input
                                    class="hidden peer"
                                    type="checkbox"
                                    :name="question_id"
                                    :value="item"
                                    :aria-label="item"
                                    :ref="question_id"
                                    :id="label + '_' + item"
                                    :checked="checked.includes(item)"
                                    @change="emitChange(item)"
                                />
                                <span
                                    class="cursor-pointer shadow-feature bg-white text-text-dark ease-in duration-100 md:ml-2 px-4 py-2.5 font-semibold rounded-xl text-center hover:bg-primary hover:text-white peer-checked:bg-checked peer-checked:text-text-darker"
                                    >{{ item }}</span
                                >
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                v-if="label"
                :ref="label"
                data-type="mapping-header"
                @click="toggleSection(label)"
                class="bg-white text-text-dark border-body  border rounded-md my-2 p-2 flex justify-between items-center duration-200 cursor-pointer"
            >
                {{ label.split('_').join('-') }} 
                <div
                    class="w-0 h-0 border-t-[6px] border-b-[6px] border-l-[6px] border-transparent border-l-primary-dark duration-200 mr-2"
                ></div>
            </div>

            <div
                :class="
                    currentDepth > 1
                        ? 'h-0 flex flex-wrap justify-center md:block overflow-hidden ml-2 border-l-2 border-secondary'
                        : null
                "
            >
                <mapping-search
                    @open="setOpen"
                    @change="emitChange"
                    :class="currentDepth > 1 ? 'pl-3 w-full' : null"
                    v-for="(d, idx) in dataLevel"
                    :key="idx"
                    :label="Object.keys(data)[idx]"
                    :search="search"
                    :data="d"
                    :maxDepth="maxDepth"
                    :currentDepth="currentDepth + 1"
                    :question_id="question_id"
                    :show_all="showAll"
                    :always_open="always_open"
                    :preSelected="preSelected ?? preview"
                    display_on_search
                ></mapping-search>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MappingSearch',
    data() {
        return {
            newFinal: false,
            show: true,
            openSections: [],
            selected: {},
            checked: [],
            preview: [],
            showAll: false,
            preSelected_data: [],
        };
    },
    props: {
        question_id: { type: [Number, String], default: null },
        search: { type: String, default: null },
        data: { type: [Object, Array, String], default: null },
        maxDepth: { type: Number, default: null },
        currentDepth: { type: Number, default: null },
        label: { type: String, default: null },
        loading: { type: Boolean, default: true },
        show_all: { type: Boolean, default: false },
        always_open: { type: Boolean, default: false },
        preSelected: { type: Array, default: null },
        display_on_search: { type: Boolean, default: false }
    },
    mounted() {
        this.showAll = this.show_all ? true : false;

        if (this.currentDepth == this.maxDepth) {
            if (this.preSelected) {
                for (let i = 0; i < this.preSelected.length; i++) {
                    let preSelect = this.preSelected[i];
                    let preLabel = preSelect.substr(0, preSelect.lastIndexOf(' - '));
                    let preItem = preSelect.substr(preSelect.lastIndexOf(' - ') + 3);
                    if (preLabel == this.label) {
                        for (let j = 0; j < this.data.length; j++) {
                            const item = this.data[j];
                            if (preItem == item) {
                                this.checked.push(item);
                            }
                        }
                    }
                }
                this.$emit('change', {
                    check: this.checked,
                    id: this.question_id,
                    label: this.label,
                });
            }
        }
    },
    methods: {
        toggleSection(key, toggle = null) {
            if (this.$refs[key] != undefined) {
                let el = this.$refs[key].nextElementSibling;
                let arrow = this.$refs[key].lastChild;

                if (toggle == 'close') {
                    el.classList.add('h-0');
                    this.$refs[key].classList.remove('bg-body');
                    arrow.classList.remove('rotate-90');
                } else if (toggle == 'open') {
                    el.classList.remove('h-0');
                    this.$refs[key].classList.add('bg-body');
                    arrow.classList.add('rotate-90');
                } else {
                    if (el.classList.contains('h-0')) {
                        el.classList.remove('h-0');
                        this.$refs[key].classList.add('bg-body');
                        arrow.classList.add('rotate-90');
                    } else {
                        el.classList.add('h-0');
                        this.$refs[key].classList.remove('bg-body');
                        arrow.classList.remove('rotate-90');
                    }
                }
            }
        },
        hideSection(key) {
            let el = this.$refs[key].nextElementSibling;
            el.classList.add('h-0');
            this.$refs[key].classList.add('h-0');
        },
        setOpen(e) {
            if (e.open) {
                if (!this.openSections.includes(e.label)) {
                    this.openSections.push(e.label);
                }
            } else {
                if (this.openSections.includes(e.label)) {
                    let idx = this.openSections.indexOf(e.label);
                    if (idx > -1) {
                        this.openSections.splice(idx, 1); // 2nd parameter means remove one item only
                    }
                }
            }
        },
        checkValue(val, arr = []) {
            arr.push(val.label);
            if (val.hasOwnProperty('value')) {
                this.checkValue(val.value, arr);
            } else {
                for (let i = 0; i < val.check.length; i++) {
                    const element = val.check[i];
                    let final = arr.concat(element);

                    if (!this.selected[final[0]]) {
                        this.selected[final[0]] = [];
                    }
                    if (this.searchForArray(this.selected[final[0]], final) == -1) {
                        if (!this.selected[final[0]].includes(final)) {
                            this.selected[final[0]].push(final);
                        }
                    }
                }

                if (this.selected.hasOwnProperty(arr[0])) {
                    for (let i = 0; i < this.selected[arr[0]].length; i++) {
                        const element = this.selected[arr[0]][i];
    
                        if (val.label == element[element.length - 2]) {
                            if (!val.check.includes(element[element.length - 1])) {
                                this.selected[arr[0]].splice(
                                    this.selected[[arr[0]]].indexOf(element),
                                    1
                                );
                            }
                        }
                    }
                }
            }
        },
        emitChange(value) {
            if (this.currentDepth == 1) {
                this.checkValue(value);

                this.preview = [];
                for (const property in this.selected) {
                    if (this.selected[property].length > 0) {
                        for (let i = 0; i < this.selected[property].length; i++) {
                            const element = this.selected[property][i];

                            let part = element[element.length - 2];
                            let end = element[element.length - 1];
                            this.preview.push(`${part} - ${end}`);
                        }
                    }
                }

                for (const key in this.selected) {
                    for (let i = 0; i < this.selected[key].length; i++) {
                        const item = this.selected[key][i];
                        if (!this.preSelected_data.includes(`${item[1]} - ${item[2]}`)) {
                            this.preSelected_data.push(`${item[1]} - ${item[2]}`);
                        }
                    }
                }

                this.$emit('change', {
                    check: this.selected,
                    preview: this.preview,
                    id: this.question_id,
                });
            } else if (this.currentDepth != this.maxDepth) {
                this.$emit('change', {
                    value,
                    label: this.label,
                });
            } else {
                let checks = this.$refs[this.question_id];

                checks.forEach((input) => {
                    if (input.checked) {
                        if (!this.checked.includes(input.value)) {
                            this.checked.push(input.value);
                        }
                    } else if (this.checked.includes(input.value)) {
                        this.checked.splice(this.checked.indexOf(input.value), 1);
                    }
                });

                let tempArr = [];
                for (let i = 0; i < this.checked.length; i++) {
                    const item = this.checked[i];
                    if (this.data.indexOf(item) != -1) {
                        tempArr.push(item);
                    }
                }
                this.checked = tempArr;

                this.$emit('change', {
                    check: this.checked,
                    id: this.question_id,
                    label: this.label,
                });
            }
        },
        searchForArray(haystack, needle) {
            var i, j, current;
            for (i = 0; i < haystack.length; ++i) {
                if (needle.length === haystack[i].length) {
                    current = haystack[i];
                    for (j = 0; j < needle.length && needle[j] === current[j]; ++j);
                    if (j === needle.length) return i;
                }
            }
            return -1;
        },
    },
    computed: {
        dataLevel() {
            if (this.maxDepth == this.currentDepth) {
                return null;
            } else {
                return Object.values(this.data);
            }
        },
        filteredList() {
            if (this.data) {
                let peices = this.search.split(' ')
                if (this.maxDepth == this.currentDepth) {
                    if (this.show_all) {
                        return this.data;
                    } else {
                        if (this.label) {
                            if (this.label.toLowerCase().match(this.search.toLowerCase())) {
                                return this.data;
                            } else {
                                return this.data.filter((item) => {
                                    if (typeof item == 'string') {
                                        let toReturn = false

                                        if (peices.length > 1) {
                                            toReturn = item.toLowerCase().match(this.search.toLowerCase())
                                            peices.forEach((term) => {
                                                if (item.toLowerCase().match(term.toLowerCase()) && peices.indexOf(this.label.toLowerCase()) > -1) {
                                                    toReturn = true
                                                }
                                            })
                                            return toReturn
                                        } else {
                                            return item.toLowerCase().match(this.search.toLowerCase())
                                        }

                                    }
                                });
                            }
                        }
                    }
                } else {
                    if (this.label) {
                        let show = false
                        peices.forEach(term => {
                            if (term.toLowerCase().match(this.label.toLowerCase())) {
                                show = true;
                            }
                        });

                        this.showAll = show ? true : false;
                    }
                    return [];
                }
            }
        },
    },
    watch: {
        filteredList: async function (newVal, oldVal) {
            if (newVal.length > 0) {
                await this.$emit('open', {
                    open: true,
                    label: this.label,
                });
            } else {
                await this.$emit('open', {
                    open: false,
                    label: this.label,
                });
            }

            if (this.label) {
                if (this.label.toLowerCase() == this.search.toLowerCase() || this.show_all) {
                    let el = this.$refs[this.label].nextElementSibling;
                    let arrow = this.$refs[this.label].lastChild;

                    el.classList.remove('h-0');
                    this.$refs[this.label].classList.add('bg-body');
                    arrow.classList.add('rotate-90');
                }
            }
        },
        search: function (newVal, oldVal) {
            if (newVal.length >= 3) {
                if (this.label != null) {
                    this.toggleSection(this.label, 'open');
                }
                this.$forceUpdate()
            } else if (newVal.length == 0) {
                if (this.label != null) {
                    this.toggleSection(this.label, 'close');
                }
            }
        },
        openSections: function (newVal, oldVal) {
            if (this.currentDepth != 1 && this.currentDepth != this.maxDepth) {
                if (newVal.length == 0) {
                    this.show = false;
                } else {
                    this.show = true;
                }
            }
        },
        loading: function (newVal, oldVal) {
            if (newVal == false) {
                for (let i = 0; i < Object.keys(this.data).length; i++) {
                    const key = Object.keys(this.data)[i];
                    this.selected[key] = [];
                }
            }
        },
        data: {
            handler(data) {
                this.data = data;
            },
            deep: true,
        },
        label: {
            handler(label) {
                this.label = label;
                if (this.currentDepth == this.maxDepth) {
                    if (this.preSelected) {
                        for (let i = 0; i < this.preSelected.length; i++) {
                            let preSelect = this.preSelected[i];
                            let preLabel = preSelect.substr(0, preSelect.lastIndexOf(' - '));
                            let preItem = preSelect.substr(preSelect.lastIndexOf(' - ') + 3);
                            if (preLabel == this.label) {
                                for (let j = 0; j < this.data.length; j++) {
                                    const item = this.data[j];
                                    if (preItem == item) {
                                        this.checked.push(item);
                                    }
                                }
                            }
                        }
                    }
                }
            },
        },
        preSelected: {
            handler(newVal, oldVal) {
                if (this.display_on_search) {
                    if (this.currentDepth == this.maxDepth) {
                        if (newVal.length === 0) {
                            this.checked = []
                        } else {
                            let arr = []
                            newVal.forEach(value => {
                                if (value.includes(this.label)) {
                                    let item = value.split(this.label)[1].split(' - ')[1]
                                    if (this.checked.includes(item)) {
                                        arr.push(item)
                                    }
                                }
                            });
    
                            this.checked = arr
                        }
                    }
                }
            },
            deep: true
        }
    },
};
</script>
