<template>
    <div class="mt-3 mb-5">
        <label :for="id" class="block text-text-dark text-base">
            <slot name="label"></slot>
            <span v-if="required == true" style="color: red">*</span>
        </label>

        <p>
            <slot name="description"></slot>
            <span v-if="$slots.tip" class="block my-2 text-base">
                <slot name="tip"></slot>
            </span>
        </p>

        <span v-if="type == 'checkbox' && options">
            <div
                v-for="(option, idx) in options"
                :key="option.id"
                :class="columnClass"
                class="inline-block my-2"
            >
                <label class="leading-6 cursor-pointer">
                    <div class="flex items-start mr-4 mb-2">
                        <input
                            v-model="optionSelected"
                            @change="onOption"
                            :class="sizeClass"
                            class="opacity-0 absolute cursor-pointer peer"
                            type="checkbox"
                            :name="option"
                            :value="option"
                            :aria-label="option"
                            :disabled="disabled"
                            :required="required"
                        />
                        <div
                            :class="sizeClass"
                            class="bg-white border rounded-md border-gray-300 flex flex-shrink-0 justify-center items-center mr-2 peer-checked:bg-primary peer-checked:border-primary"
                        >
                            <svg
                                :class="small ? 'w-2 h-2' : 'w-4 h-4'"
                                class="fill-current opacity-0 block text-white pointer-events-none"
                                version="1.1"
                                viewBox="0 0 17 12"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <g
                                        transform="translate(-9 -11)"
                                        fill="#FFFFFF"
                                        fill-rule="nonzero"
                                    >
                                        <path
                                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span :class="small ? 'text-sm' : null" class="cursor-pointer mr-6">
                            {{ options_text[idx] ? options_text[idx] : option | capitalize }}
                        </span>
                    </div>
                </label>
            </div>
        </span>

        <span v-if="type == 'radio' && options">
            <div
                v-for="(option, idx) in options"
                :key="option.id"
                :class="columnClass"
                class="inline-block my-2 cursor-pointer"
            >
                <label class="leading-6">
                    <div class="flex items-start mr-4 mb-2">
                        <input
                            @click="setAllFalse"
                            v-model="optionSelected"
                            class="h-6 w-6 opacity-0 absolute cursor-pointer peer"
                            type="radio"
                            :name="id"
                            :value="option"
                            :aria-label="option"
                            :disabled="disabled"
                            :required="required"
                            :ref="`${id}_input_${idx}`"
                        />
                        <div
                            class="bg-white border rounded-full border-gray-300 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2  peer-checked:[&>span]:bg-primary peer-checked:[&>span]:border-primary peer-checked:border-primary""
                        >
                            <span
                                class="bg-white rounded-full border-gray-300 w-3 h-3 flex flex-shrink-0 justify-center items-center"
                            ></span>
                        </div>
                        <span class="cursor-pointer mr-6">
                            {{ options_text[idx] ? options_text[idx] : option | capitalize }}
                        </span>
                    </div>
                </label>
            </div>
        </span>

        <div v-if="type == 'tag' && options">
            <div class="block">
                <div
                    v-for="option in options"
                    :key="option.id"
                    class="inline-block my-4 mr-3 cursor-pointer"
                >
                    <label class="leading-6">
                        <input
                            v-model="optionSelected"
                            @change="onOption"
                            class="hidden peer"
                            type="checkbox"
                            :name="option"
                            :value="option"
                            :aria-label="option"
                            :disabled="disabled"
                            :required="required"
                        />
                        <span
                            class="cursor-pointer shadow-feature bg-white text-text-dark ease-in duration-100 m-1.5 px-4 py-2.5 font-bold rounded-xl text-center hover:bg-primary hover:text-white peer-checked:bg-checked peer-checked:text-text-darker"
                            >{{ option | capitalize }}</span
                        >
                    </label>
                </div>
            </div>
        </div>

        <div v-if="type == 'select' && options" class="mt-1 relative rounded-md shadow-sm">
            <select
                class="focus-visible:outline outline-2 placeholder-[#96BCB6] rounded-full block w-full sm:text-sm py-2 px-3 outline-primary"
                @change="onSelect"
                ref="input"
                :required="required"
                :disabled="disabled"
                aria-label="select"
            >
                <option :selected="preSelected == null" disabled>Please select one</option>
                <!-- <option v-if="typeof options[0] == 'object'" :value="null">All</option> -->
                <option
                    :selected="preSelected == option"
                    v-for="(option, key) in options"
                    :value="key"
                    :key="option.id"
                >
                    {{ typeof option == 'object' ? option.name : option | capitalize }}
                </option>
            </select>
        </div>

        <slot name="error"></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: '',
            optionSelected: [],
            selectType: '',
        };
    },
    props: {
        id: { type: String, default: null },
        name: { type: String, default: null },
        required: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        type: { type: String, default: null },
        inline: { type: Boolean, default: false },
        options: { type: [Array, Object], default: null },
        options_text: {
            type: Array,
            default() {
                return [];
            },
        },
        small: { type: Boolean, default: false },
        preSelected: { type: String, default: null },
    },
    mounted() {
        this.autofocus ? this.$refs.input.focus() : '';
        this.defaultValue();
    },
    computed: {
        inputClass() {
            const { type } = this;
            return `input__${type}`;
        },
        columnClass() {
            const { options } = this;
            return [options.length > 5 ? 'w-1/2' : 'w-full'];
        },
        sizeClass() {
            const { small } = this;
            return [small ? 'w-4 h-4 rounded' : 'w-6 h-6 rounded-md'];
        },
    },
    methods: {
        defaultValue() {
            let { type, options, $attrs } = this;
            if (type == 'select') {
                if (Object.keys($attrs).length == 0) {
                    this.selected = '';
                    this.selectType = 'string';
                } else if (typeof $attrs.value == 'string') {
                    this.selectType = 'string';
                    if ($attrs.value != '') {
                        for (let i = 0; i < options.length; i++) {
                            if (typeof options[i] == 'object') {
                                options[i].slug == $attrs.value ? (this.selected = i) : null;
                            } else {
                                options[i] == $attrs.value ? (this.selected = i) : null;
                            }
                            this.onSelect();
                        }
                    }
                } else {
                    this.selectType = 'number';
                    this.selected = $attrs.value;
                }

                if (this.preSelected) {
                    this.selectType = 'string';
                    if (Array.isArray(this.options)) {
                        this.selected = this.options.indexOf(this.preSelected);
                    } else {
                        this.selected = this.options[this.preSelected];
                    }
                    this.onSelect();
                }
            } else if (type == 'checkbox' || type == 'toggle') {
                if (Object.keys($attrs).length == 0) {
                    this.selected = '';
                    this.selectType = 'string';
                } else if (typeof $attrs.value == 'string') {
                    if ($attrs.value != '') {
                        this.optionSelected.push($attrs.value);
                    }
                } else {
                    for (let i = 0; i < $attrs.value.length; i++) {
                        this.optionSelected.push($attrs.value[i]);
                    }
                }

                if (this.preSelected) {
                    if (typeof this.preSelected == 'string') {
                        this.optionSelected = [this.preSelected];
                    } else {
                        this.optionSelected = this.preSelected;
                    }
                }
            } else if (type == 'radio') {
                if ($attrs.value != '') {
                    this.optionSelected = $attrs.value;
                }

                if (this.preSelected) {
                    this.optionSelected = this.preSelected;
                }
            }
        },
        onSelect(e) {
            if (e) {
                this.selected = e.target.value;
            }

            if (this.selectType == 'string') {
                this.$emit('change', this.options[this.selected]);
            } else {
                if (this.selected == null) {
                    this.$emit('change', this.options);
                } else {
                    this.$emit('change', this.options[this.selected]);
                }
            }
        },
        onOption(e) {
            this.$emit('change', this.optionSelected);
        },
        setAllFalse(e) {
            if (e.target.checked) {
                this.optionSelected = '';
                this.$emit('change', '');
            } else {
                this.$emit('change', this.optionSelected);
            }
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style>
input[type='checkbox']:checked + div {
    @apply border-primary-dark;
    @apply bg-primary-dark;
}

input[type='radio']:checked + div > span {
    @apply bg-primary-dark;
}

input:checked + div svg {
    opacity: 1;
}
</style>
