<template>
    <div class="flex flex-col items-center md:mx-auto mb-4 mx-4">
        <SearchCategory :categories='categories' @change="change"/>
        <ListQuestions :faqs="faqs" :selectedCategory="selectedCategory"/>
    </div>
</template>

<script>
    import SearchCategory from './SearchCategory.vue'
    import ListQuestions from './ListQuestions.vue';

    export default {
        components: {
            SearchCategory,
            ListQuestions,
        },
        data(){
            return {
                selectedCategory: ''
            }
        },
        props: {
            faqs: { type: Object, default: null },
            categories: { type: Object, default: null }
        },
        methods:{
            change(e){
                this.selectedCategory = e
            }
        }
    }
</script>