<template>
    <div
        v-if="show"
        ref="cookie"
        id="cookie"
        class="animate-slide-up z-20 w-full lg:w-[40%] fixed bottom-0 lg:bottom-3 lg:left-[30%] bg-card rounded-tr-3xl rounded-tl-3xl lg:rounded-3xl transition-translate duration-300"
    >
        <svg-vue class="absolute h-5 w-5 my-5 mx-4" icon="cookie"></svg-vue>
        <div class="my-4 mr-9 ml-12 m-auto">
            <p class="font-body mb-4">
                We use cookies to improve your experience, for more information read our
                <a class="underline underline-offset-2" href="/privacy-policy#what-are-cookies">cookie policy</a>.
            </p>
            <div class="flex justify-start lg:justify-center">
                <span class="w-full lg:hidden mr-1">
                    <anima-button @click="accept" long round>Accept All</anima-button>
                </span>
                <span class="w-full lg:hidden ml-1">
                    <anima-button @click="necessary" long round>Only Necessary</anima-button>
                </span>

                <span class="hidden lg:block mr-2 w-1/3">
                    <anima-button @click="accept" small long round>Accept All</anima-button>
                </span>
                <span class="hidden lg:block ml-2 w-1/3">
                    <anima-button @click="necessary" small long round>Only Necessary</anima-button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import animaButton from './AnimaBtn.vue';

export default {
    data() {
        return {
            show: true,
        };
    },
    components: {
        animaButton,
    },
    methods: {
        accept() {
            this.$refs.cookie.classList.add('translate-y-[110%]');
            this.$cookies.set('anima_hub_accpet_cookies', true, '1y');
        },
        necessary() {
            this.$refs.cookie.classList.add('translate-y-[110%]');
            this.$cookies.set('anima_hub_necessary_cookies', true, '1y');
        },
    },
    mounted() {
        //check if cookies accepted or declined
        this.show = this.$cookies.isKey('anima_hub_accpet_cookies') || this.$cookies.isKey('anima_hub_necessary_cookies') ? false : true;
    },
};
</script>

<style></style>
