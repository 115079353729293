<template>
    <div>
        <div class="md:w-10/12 mx-auto px-5 mt-10 md:mt-12">
            <h1 class="mx-5 text-xl md:text-3xl font-bold text-text-dark">Resources</h1>
        </div>
        <blog-category-list
            :param="param"
            :category-url="categoryUrl"
            @select="onSelectCategory"
        ></blog-category-list>

        <svg
            class="fill-primary md:hidden"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 400"
        >
            <path
                d="M1920 400V308.114c-233.701-49.046-500.172-38.51-824.711-74.57C653.233 184.427 0 0 0 0v400h1920z"
                fill-rule="evenodd"
            />
        </svg>

        <svg
            class="fill-primary hidden md:block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 120"
        >
            <path
                d="M1920 120V68.47c-233.701-10.9-500.172-8.558-824.711-16.571C653.233 40.984 0 0 0 0v120h1920z"
                fill-rule="evenodd"
            />
        </svg>

        <div class="bg-primary md:pt-10">
            <blog-post-list postsUrl="/json/resources" :category="category"></blog-post-list>
        </div>
    </div>
</template>

<script>
import BlogCategoryList from './BlogCategoryList.vue';
import BlogPostList from './BlogPostList.vue';
import animaButton from '../../../components/Elements/AnimaBtn.vue';

export default {
    components: {
        BlogCategoryList,
        BlogPostList,
        animaButton,
    },
    props: {
        categoryUrl: {
            type: String,
            default: '/json/categories',
        },
        defaultCategory: {
            type: String,
        },
    },

    data() {
        return {
            category: this.defaultCategory,
            param:
                window.location.href.indexOf('?category=') != -1
                    ? window.location.href.split('?category=').pop()
                    : null,
        };
    },
    methods: {
        onSelectCategory(e) {
            if (e == undefined) {
                this.category = undefined;
            } else {
                this.category = e;
            }
        },
    },
};
</script>
