<template>
    <div>
        <anima-resource :limit="show_limit" :resources="posts"></anima-resource>
        <div v-if="load && show_limit <= posts.length">
            <div class="mx-5 py-5 pb-10 md:w-1/4 md:m-auto">
                <anima-button @click="loadmore" secondary long>Load more</anima-button>
            </div>
        </div>
        <div v-if="posts.length == 0">
            <p class="pl-5 pt-8 text-xl text-text-darker">No articles.</p>
        </div>
    </div>
</template>

<script>
import animaResource from './AnimaResource.vue';
import animaButton from './AnimaBtn.vue';

export default {
    data() {
        return {
            posts: [],
            links: [],
            error: '',
            loading_posts: false,
            show_limit: this.limit,
        };
    },
    components: {
        animaResource,
        animaButton,
    },
    props: {
        load: { type: Boolean, default: false },
        limit: { type: Number, default: 15 },
    },
    mounted() {
        fetch('/json/resources?page=1')
            .then((response) => response.json())
            .then((response) => {
                this.posts.push(...response.data);
                this.links = response.links;
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            })
            .then(() => {
                this.loading_posts = false;
            });
    },
    methods: {
        loadmore() {
            this.show_limit += this.limit;

            if (this.show_limit > this.posts.length) {
                fetch(this.links['next'])
                    .then((response) => response.json())
                    .then((response) => {
                        this.posts.push(...response.data);
                        this.links = response.links;
                    })
                    .catch((error) => {
                        this.error = 'Error getting posts.';
                    })
                    .then(() => {
                        this.loading_posts = false;
                    });
            }
        },
    },
};
</script>

<style></style>
