<template>
    <div class="flex flex-col p-8 mx-auto mt-3 mb-10 max-w-[950px] bg-card-100 shadow-feature rounded-xl" >
        <div class="pb-2 bg-body-1 w-full max-w-[716px] m-auto" v-for="(question, key) in form" :key="question.id">
            <p class="block text-text-dark text-base" v-html="question.label"></p>

            <div v-if="question.type == 'text'">
                <anima-text-area :question_id="key" @change="setText"></anima-text-area>
            </div>

            <div v-if="question.type == 'rating'" class="mb-6">
                <star-rating :increment="0.5" :show-rating="false" @rating-selected="setRating"/>
            </div>
        </div>

        <div class="block p-12 w-full max-w-[716px] m-auto">
            <anima-button long @click="submit">Submit</anima-button>
        </div>
    </div>
</template>

<script>
import animaButton from './../Elements/AnimaBtn.vue';
import animaTextArea from './AnimaTextArea.vue';
import StarRating from 'vue-star-rating'    

    export default {
        components: { animaButton, animaTextArea, StarRating },
        props: {
            token: { type: String, default: null },
        },
        data() {
            return {
                form: {
                    feedback: {
                        label: 'What do you like about Anima?',
                        type: 'text'
                    },
                    improvements: {
                        label: 'What can we improve?',
                        type: 'text'
                    },
                    new_features: {
                        label: 'What new features would you like to see?',
                        type: 'text'
                    },
                    rating: {
                        label: 'Overall rating',
                        type: 'rating'
                    },
                    comments: {
                        label: 'Additional comments',
                        type: 'text'
                    },

                },
                answers: {
                    feedback: '',
                    improvements: '',
                    new_features: '',
                    rating: 0,
                    comments: '',
                }
            }
        },
        methods: {
            setText(e) {
                this.answers[e.id] = e.check
            },
            setRating(e) {
                this.answers.rating = e
            },
            submit() {
                fetch('api/feedback', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=utf-8',
                        'X-CSRF-TOKEN': this.token,
                    },
                    body: JSON.stringify(this.answers),
                })
                .then(async (response) => {
                    if (response.ok) {
                        window.location.href = '/';
                        let res = await response.json();

                        sessionStorage.setItem(
                            'notification',
                            JSON.stringify({
                                type: 'info',
                                messages: [
                                    {
                                        title: 'Feedback recieved',
                                        message: res.message,
                                    },
                                ],
                            })
                        );
                    }
                    let res = await response.json();

                    sessionStorage.setItem(
                        'notification',
                        JSON.stringify({
                            type: 'error',
                            messages: [
                                {
                                    title: 'Error',
                                    message: res.message,
                                },
                            ],
                        })
                    );
                    eventHub.$emit('show');
                    // return Promise.reject(response);
                })
                .catch(async (error) => {});
            }
        }
    }
</script>