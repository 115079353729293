<template>
    <section class="grid grid-cols-10 grid-rows-3 md:grid-rows-2 gap-5 relative px-6 lg:px-14 py-4 w-full max-w-[1440px] m-auto">
        <div class="md:row-span-2 col-span-10 lg:col-span-4 xl:col-span-5">
            <div class="flex flex-col items-center bg-white shadow-feature p-4 rounded-3xl h-full">
                <h2 class="font-bold mb-5 text-xl">Weekly Wellness Score</h2>

                <div class="text-center">
                    <p class="text-base">
                        Your wellness score has changed 
                    </p>
                    <p class="text-base">
                        by
                        <span :class="diff > 0 ? 'text-green-500' : diff < 0 ? 'text-red-500' : 'text-text-dark'">
                            <font-awesome-icon v-if="diff != 0" :icon="diff > 0 ? 'caret-up' : 'caret-down'" />
                            {{ diff }}
                        </span>
                        from your previous score.
                    </p>
                </div>

                <a href="details?type=wellness&name=impact">
                    <chart-Container :data="wellness" id="Wellness Score" :hasTitle="false" type="polar"></chart-Container>
                </a>

                <div class="flex flex-wrap justify-center w-full md:w-10/12 text-center">
                    <div v-for="day in lastSevenDays" class="p-1 md:p-2.5">
                        <p>{{days[new Date(day).getDay()]}}</p>
                        <div ref='wellness' class='h-10 w-10 shadow-feature text-white rounded-lg p-2'>{{parseFloat(daily_scores[day]).toFixed(1) ?? '-'}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white shadow-feature p-4 rounded-3xl col-span-10 lg:col-span-6 xl:col-span-5">
            <h2 class="mx-5 text-xl font-bold text-text-dark text-center">Wellness Stats</h2>
            <div class="flex flex-col md:flex-row items-center justify-around py-4">
                <anima-card type="mini" v-for="form_type in wellness_stats" :key="form_type.id" :title="form_type.name" :link="`/details?type=wellness&name=${form_type.slug}`" :img="`./../../../svg/${form_type.icon}.svg`"></anima-card>
            </div>
        </div>

        <div class="bg-white shadow-feature p-4 rounded-3xl col-span-10 lg:col-span-6 xl:col-span-5">
            <h2 class="mx-5 text-xl font-bold text-text-dark text-center">Feature Stats</h2>
            <div class="flex flex-col md:flex-row items-center justify-around py-4">
                <anima-card type="mini" v-for="form_type in featured" :key="form_type.id" :title="form_type.name" :link="`/details?type=${form_type.slug}`" :img="`./../../../svg/${form_type.icon}.svg`"></anima-card>
            </div>

        </div>
    </section>
</template>

<script>
    import AnimaTabs from './AnimaTabs.vue';
    import AnimaTab from './AnimaTab.vue';
    import chartContainer from '../Charts/chartContainer.vue';
    import AnimaCard from '../Elements/AnimaCard.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        components: {
            AnimaTab,
            AnimaTabs,
            chartContainer,
            AnimaCard,
            FontAwesomeIcon
        },
        props: {
            url: {
                type: String,
                default: null
            },
            wellness_url: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                featured: [{
                    name: 'Assessments',
                    slug: 'assessment',
                    icon: 'checklist'
                },
                {
                    name: 'Health Checks',
                    slug: 'checkin',
                    icon: 'heart'
                },{
                    name: 'Journals',
                    slug: 'journal',
                    icon: 'journal'
                }],
                assessments: [],
                journals: [],
                checkins: [],
                wellness: [],
                diff: 0,
                daily_scores: [],
                wellness_stats: [{
                    name: 'Feeling',
                    slug: 'feeling',
                    icon: 'feelings'
                },{
                    name: 'Impact',
                    slug: 'impact',
                    icon: 'impact'
                },{
                    name: 'Mood',
                    slug: 'mood',
                    icon: 'mood'
                }],
                days: [
                    'S',
                    'M',
                    'T',
                    'W',
                    'T',
                    'F',
                    'S',
                ],
                chroma: [
                '#BB5B44',
                '#D19181',
                '#E99B44',
                '#FFBC74',
                '#FFCF09',
                '#97D86C',
                '#73C6BA',
                '#0189A2',
                '#01C2E4',
                '#4A4AE6',
                '#79A8FF'
                ]
            };
        },
        async mounted() {
            await this.fetchWellness();
            await this.fetchStats();
        },
        methods: {
            async fetchWellness() {
                try {
                    let response = await fetch(this.wellness_url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    this.wellness = data.data;
                    this.diff = parseFloat(data.diff).toFixed(1);
                    this.daily_scores = data.daily_scores
                    
                    for (const date in this.daily_scores) {
                        const scores = this.daily_scores[date];
                        const sum = scores.reduce((partialSum, a) => partialSum + a, 0);
                        this.daily_scores[date] = sum / scores.length
                    }

                    this.$refs['wellness'].forEach((el, idx) => {
                        if (this.daily_scores[this.lastSevenDays[idx]]) {
                            el.style.background = this.chroma[Math.floor(this.daily_scores[this.lastSevenDays[idx]])]
                        }
                    });
                } catch (error) {
                    this.error = 'Error getting stats.';
                }
            },
            async fetchStats() {
                try {
                    let response = await fetch(this.url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].type == 'assessment') {
                            this.assessments.push(data[i]);
                        }
                        if (data[i].type == 'journal') {
                            this.journals.push(data[i]);
                        }
                        if (data[i].type == 'checkin') {
                            this.checkins.push(data[i]);
                        }
                    }
                } catch (error) {
                    this.error = 'Error getting stats.';
                }
            },
        },
        computed: {
            lastSevenDays() {
                const dates = [];
                const today = new Date();

                for (let i = 0; i < 7; i++) {
                    // Create a new date object for each day in the last 7 days
                    const date = new Date();
                    date.setDate(today.getDate() - i);
                    
                    // Format the date as needed (e.g., YYYY-MM-DD)
                    const formattedDate = date.toISOString().split('T')[0];
                    dates.push(formattedDate);
                }

                return dates.reverse();
            }
        }
    };
</script>