<template>
    <div>
        <anima-button long @click="openModal">Log {{display | capitalize}}</anima-button>
        
        <!-- modal container -->
        <div v-show="showModal" class="fixed top-0 left-0 z-[99] overflow-hidden">
            <!-- overlay -->
            <div
                id="overlay"
                @click="showModal = false"
                class="z-20 h-screen w-screen bg-slate-600 opacity-50"
            ></div>
            <!-- modal -->
            <div
                id="modal"
                class="fixed z-30 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col bg-card-100 min-h-[95%] min-w-[95%] h-[95vh] w-full lg:w-[95%] rounded-lg shadow-feature text-text-dark"
            >
                <!-- close button -->
                <div class="cursor-pointer" @click="showModal = false">
                    <svg-vue class="h-5 w-5 m-5 absolute right-0" icon="close"></svg-vue>
                </div>

                <!-- step count -->
                <div class="flex justify-center h-[15%]">
                    <div v-for="(step, idx) in steps" class="flex items-center">
                        <div class="flex flex-col items-center relative">
                            <span
                                :class="
                                    idx <= currentStep
                                        ? 'border-green-300 bg-green-300'
                                        : 'border-slate-300'
                                "
                                class="h-5 w-5 rounded-full border-2 flex items-center justify-center text-white text-xs"
                            >
                                <font-awesome-icon
                                    v-if="idx < currentStep"
                                    icon="fa-solid fa-check"
                                />
                            </span>
                            <p
                                :class="idx == currentStep ? 'font-bold' : null"
                                class="absolute top-6"
                            >
                                {{ step.name | capitalize }}
                            </p>
                        </div>
                        <div
                            v-if="idx != steps.length - 1"
                            :class="idx < currentStep ? 'bg-green-300' : 'bg-slate-300'"
                            class="h-2 w-[9vw] lg:w-[5vw] rounded-md mx-3"
                        ></div>
                    </div>
                </div>

                <!-- content -->
                <!-- w-full my-5 px-8 lg:px-10 absolute h-[65%] top-[20%] lg:relative lg:top-0 lg:h-[70vh] bg-body-200 -->
                <div class="w-full px-8 lg:px-10 h-3/4 bg-body-200"
                    :class="currentStep == 1 ? 'overflow-hidden' : 'overflow-scroll'"
                    ref="main_content"
                >
                    <!-- main content -->
                    <div v-if="currentStep == steps.length - 1" class="flex flex-col w-3/5 m-auto">
                        <table class="w-full text-center mx-auto mt-5 mb-7 rounded-md overflow-hidden">
                            <thead>
                                <tr class="bg-secondary text-base">
                                    <th class="p-2">{{display | capitalize}} Category</th>
                                    <th class="p-2">{{display | capitalize}} Name</th>
                                </tr>
                            </thead>
                            <tbody class="border-2 border-secondary-200">
                                <tr
                                    v-for="(item, idx) in preview"
                                    :class="idx % 2 != 0 ? 'bg-secondary-200' : 'bg-body-100'"
                                >
                                    <td class="w-1/2 text-sm p-1">{{ item.split(' - ')[0].split('_').join('-') }}</td>
                                    <td class="w-1/2 text-sm p-1">{{ item.split(' - ')[1] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="my-6">
                        <div v-for="question in steps[currentStep].questions">
                            <div v-show="show[question.id]">
                                <div v-if="errors[question.id]" class="text-red-600">
                                    {{ errors[question.id] }}
                                </div>
                                <p class="text-base text-text-dark py-6">
                                    {{ question.question }}
                                </p>
                                <div v-if="question.type == 'mapping'">
                                    <anima-mapping
                                        @change="logFood"
                                        :question_id="question_id"
                                        :display="question.display"
                                        :route="question.route"
                                        :request_data="question.request_data"
                                        :preSelected="preview"
                                        display="meal_question_modal"
                                    ></anima-mapping>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- buttons -->
                <div class="flex justify-between h-[10%] w-full bottom-0 py-4 lg:pb-4 lg:pt-2 px-8 lg:px-10 bg-card-100">
                    <div class="w-4/12 lg:w-2/12">
                        <anima-button v-if="currentStep >= 1" long @click="goBack"
                            >Back</anima-button
                        >
                    </div>
                    <div class="w-4/12 lg:w-2/12">
                        <anima-button v-if="currentStep < steps.length - 1" long @click="validate"
                            >Next</anima-button
                        >
                        <anima-button
                            v-else
                            long
                            @click="
                                showModal = false;
                                currentStep = 0;
                            "
                            >Finish</anima-button
                        >
                    </div>
                </div>
            </div>
        </div>


        <div v-if="!showModal && started">
            <div class="flex flex-col text-text-dark">
                <table class="w-full text-center mx-auto mt-5 mb-7 rounded-md overflow-hidden">
                    <thead>
                        <tr class="bg-secondary text-base">
                            <th class="p-2">{{display | capitalize}} Category</th>
                            <th class="p-2">{{display | capitalize}} Name</th>
                        </tr>
                    </thead>
                    <tbody class="border-2 border-secondary-200">
                        <tr
                            v-for="(item, idx) in preview"
                            :class="idx % 2 != 0 ? 'bg-secondary-200' : 'bg-body-100'"
                        >
                            <td class="w-1/2 text-sm p-1">{{ item.split(' - ')[0].split('_').join('-') }}</td>
                            <td class="w-1/2 text-sm p-1">{{ item.split(' - ')[1] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- preview once closed -->
        <div class="hidden" id="meal_selector_data" :ref="question_id">{{ subDtata }}</div>
    </div>
</template>

<script>
import { capitalize } from 'lodash';
import animaButton from './../Elements/AnimaBtn.vue';
import animaMapping from './AnimaMapping.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        FontAwesomeIcon,
        animaButton,
        animaMapping,
    },
    data() {
        return {
            started: false,
            show: {},
            showModal: false,
            currentStep: 0,
            steps: [
                {
                    name: `${this.display}`,
                    questions: [
                        {
                            id: 'meal',
                            type: 'mapping',
                            question: `Please select your ${this.display}:`,
                            route: `${this.api_route}`,
                            display: 'search',
                            filter: '',
                        },
                    ],
                },{
                    name: 'Review',
                },
            ],
            errors: {},
            preview: [],
            skip: false,
            details_data: null,
            subDtata: {}
        };
    },
    props: {
        token: { type: String, default: null },
        url: { type: String, default: null },
        api_route: { type: String, default: null },
        display: { type: String, default: null },
        question_id: { type: Number, default: null },
    },
    methods: {
        openModal() {
            this.showModal = true;
            this.started = true;
            this.getVisible();
        },
        getVisible(id) {
            for (let i = 0; i < this.steps[this.currentStep].questions.length; i++) {
                const question = this.steps[this.currentStep].questions[i];
                if (question.condition) {
                    let all_meet = [];
                    for (let i = 0; i < question.condition.length; i++) {
                        const element = question.condition[i];
                        if (element.length == 1) {
                            if (this.preview[element[0]] != '') {
                                all_meet[i] = true;
                            } else {
                                all_meet[i] = false;
                            }
                        } else {
                            if (this.preview[element[0]] == element[1]) {
                                all_meet[i] = true;
                            } else {
                                all_meet[i] = false;
                            }
                        }
                    }
                    if (all_meet.includes(true)) {
                        this.show[question.id] = true;
                    } else {
                        this.show[question.id] = false;
                    }
                } else {
                    this.show[question.id] = true;
                }
            }
        },
        setVisible() {
            // let obj = {};
            // for (let i = 0; i < this.steps[this.currentStep].questions.length; i++) {
            //     const question = this.steps[this.currentStep].questions[i];
            //     if (question.condition) {
            //         obj[question.id] = false;
            //     } else {
            //         obj[question.id] = true;
            //     }
            // }
            // this.show = obj;
        },
        goBack() {
            if (this.currentStep == 3 && this.skip) {
                this.currentStep = 1;
            } else {
                this.currentStep--;
            }
            this.$refs.main_content.scrollTop = 0
            this.setVisible();
        },
        logFood(e) {
            this.subDtata = e.finalData
            this.preview = e.check;
        },
        async validate() {
            let errors = {};
            let data;

            switch (this.currentStep) {
                case 0:
                    break;
                default:
                    break;
            }

            if (Object.keys(errors).length == 0) {

                if (this.currentStep == 1) {
                    if (this.skip) {
                    } else {
                    }
                }
                this.errors = {};
                this.currentStep++;
                this.setVisible();
                
            } else {
                this.errors = errors;
            }
        },
        async fetchData(url, abortSignal = false) {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                if (abortSignal) {
                    // await controller.abort();
                }

                let response = await fetch(url, {
                    signal,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                return data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    },
    async created() {
        this.setVisible();
    },
    watch: {
        showModal: function () {
            document.body.style.overflow = this.showModal ? 'hidden' : ''
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style scoped>
div::-webkit-scrollbar{
    display: none;
}

div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.body_part {
    fill: #fff;
    stroke: #fff;
    stroke-width: 4.17px;
    cursor: pointer;
}

.body_part:hover {
    fill: #5c9e95;
    stroke: #5c9e95;
}

.active {
    fill: #73c6ba;
    stroke: #73c6ba;
}
</style>
