<template>
    <div>
        <div class="bg-primary flex flex-wrap justify-around h-[calc(100vh-50px)] md:h-[calc(100vh-120px)]">
            <div class="w-full mt-5">
                
                <font-awesome-icon @click="toggleSelectedFavorite"
                    :class="{ 'text-yellow-500 p-[4px] scale-150  ': selectedStart, 'border-black': selectedStart }"
                    class="cursor-pointer mr-20 mb-[4px] " :icon="['fas', 'star']" />
                <button @click="addToRoutine"
                    class="text-white text-base mr-4 font-bold rounded-full bg-primary-dark py-3 px-5 transition-all cursor-pointer hover:bg-primary-900 hover:shadow-feature mb-1.5">
                    Add to Routine
                </button>
                <br>
                <div class="flex ml-4">

                    <h3 class="ml-8">
                        DISCIPLINE /
                    </h3>
                    <h5 class="ml-4">
                        CROSSFIT
                    </h5>
                </div>
            </div>

            <div class="w-2/5">
                <div class="w-[590px]">
                    <!-- <img src="../../../images/enso/imagen-video-prueba.jpg" alt="Logo"> -->
                </div>
                <carousel paginationPosition="bottom-overlay" :per-page="1" :mouse-drag="true" :autoplay="true"
                    :loop="true" :autoplayTimeout="5000" :autoplayHoverPause="true">
                    <!-- <slide v-for="img in exercise.IMAGE" :key="img">
                        <img
                            class="max-h-96 m-auto"
                            :src="baseurl + '/storage/media/images/general/' + img"
                            alt=""
                        />
                    </slide> -->
                </carousel>
            </div>

            <div class="bg-card w-1/2 p-5 rounded-t-2xl flex flex-wrap content-around">
                <div class="flex justify-between mb-3 w-full">
                    <!-- body area -->
                    <div class="w-6/12 m-3 text-center">
                        <p class="font-semibold">Body Area</p>
                        <!-- <div >
                            <p
                                class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"     
                            >   
                            </p>
                        </div> -->
                        <div>
                            <p>No Body Area set</p>
                        </div>
                    </div>

                    <!-- purpose -->
                    <div class="w-6/12 m-3 text-center">
                        <p class="font-semibold">Sub Body Area</p>
                        <!-- <div >
                            <p
                                class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"                              
                            >                      
                            </p>
                        </div> -->
                        <div>
                            <p>No Sub Body Area set</p>
                        </div>
                    </div>
                </div>

                <div class="flex justify-between mb-3 w-full">
                    <!-- equipment -->
                    <div class="w-6/12 m-3 text-center">
                        <p class="font-semibold"> Level</p>
                        <!-- <div >
                            <p
                                class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"                               
                            >                               
                          </p>
                        </div> -->
                        <div>
                            <p>No level set</p>
                        </div>
                    </div>

                    <!-- CONTRAINDICATIONS -->
                    <div class="w-6/12 m-3 text-center">
                        <p class="font-semibold">Purpose</p>
                        <!-- <div >
                            <p
                                class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"                                
                            >                                
                            </p> 
                        </div> -->
                        <div>No Purpose set</div>
                    </div>
                </div>

                <div class="flex justify-between mb-3 w-full">
                    <!-- level -->
                    <div class="w-6/12 m-3 text-center relative">
                        <p class="font-semibold">Dificulty: </p>

                        <div class="pt-4">
                            <span class="inline-block mx-1 h-4 w-12 bg-slate-300 rounded-full"></span>
                        </div>
                    </div>

                    <!-- rating -->
                    <div class="w-6/12 m-3 text-center relative">
                        <p class="font-semibold">Rating:</p>

                        <div class="pt-4">
                            <span class="inline-block mx-1 h-4 w-12 bg-slate-300 rounded-full"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-card rounded-3xl p-5 m-10 shadow-feature flex flex-wrap justify-start">
            <h1 class="text-center text-2xl font-body font-bold m-5 w-full inline-block">
                Muscle Details
            </h1>
            <div class="flex justify-between flex-wrap w-3/5">
                <!-- body parts -->
                <div class="w-8/12 my-3 text-center">
                    <table class="table-fixed bg-white w-full border-collapse border border-slate-400 rounded-md overflow-hidden">
                        <thead class="bg-primary text-primary-500 p-4">
                            <tr >
                                <th class="text-left text-black p-4 font-semibold" colspan="2">Position</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-500 bg-text p-4">
                            <tr>
                                <td class="break-all text-left py-6 px-4">ARM POSITION</td>
                                <td class="break-all text-left py-6 px-4">No Arm set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">LEG POSITION</td>
                                <td class="break-all text-left py-6 px-4">No Leg set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">BODY POSITION</td>
                                <td class="break-all text-left py-6 px-4">No Body set</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-8/12 my-3 text-center">
                    <table class="table-fixed bg-white w-full border-collapse border border-slate-400 rounded-md overflow-hidden">
                        <thead class="bg-primary text-primary-500 p-4">
                            <tr>
                                <th class="text-left text-black p-4 font-semibold" colspan="2">Equipment</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-500 bg-text p-4">
                            <tr>
                                <td class="break-all text-left py-6 px-4">GYM BASE</td>
                                <td class="break-all text-left py-6 px-4">No Gym Base set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">STUDIO BASE</td>
                                <td class="break-all text-left py-6 px-4">No Studio Base set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">WEIGHT</td>
                                <td class="break-all text-left py-6 px-4">No Weight set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">ACCESSORIES</td>
                                <td class="break-all text-left py-6 px-4">No Accessories set</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-8/12 my-3 text-center ">
                    <table class="table-fixed bg-white w-full border-collapse border border-slate-400 rounded-md overflow-hidden">
                        <thead class="bg-primary text-primary-500 p-4">
                            <tr>
                                <th class="text-left text-black p-4 font-semibold" colspan="2">Muscles</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-500 bg-text p-4">
                            <tr>
                                <td class="break-all text-left py-6 px-4">Muscle Groups</td>
                                <td class="break-all text-left py-6 px-4">No Muscle set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">Primary Muscles</td>
                                <td class="break-all text-left py-6 px-4">No Primary Muscles set</td>
                            </tr>
                            <tr>
                                <td class="break-all text-left py-6 px-4">Secundary Muscles</td>
                                <td class="break-all text-left py-6 px-4">No Secundary Muscles set</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <!-- Duplicate-->

        <div class="bg-card rounded-3xl p-5 m-10 shadow-feature flex flex-wrap justify-center">
            <h1 class="text-center text-2xl font-body font-bold m-5 w-full inline-block">
                Muscle Details +
            </h1>
            <div class="flex justify-between flex-wrap w-3/5">
                <!-- body parts -->
                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Grip</p>
                    <!-- <div>
                        <p
                            class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"                           
                        >                           
                        </p>
                    </div> -->
                    <div>
                        <p>No Grip set</p>
                    </div>
                </div>
                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Direction</p>
                    <!--                     <div>
                        <p
                            class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"
                            
                        >
                            
                        </p>
                    </div> -->
                    <div>
                        <p>No Direction set</p>
                    </div>
                </div>
                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Body Parts</p>
                    <!--                     <div>
                        <p
                            class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2"
                            
                        >
                            
                        </p>
                    </div> -->
                    <div>
                        <p>No Body Parts set</p>
                    </div>
                </div>

                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Action</p>
                    <!-- <div>
                        <p class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2">

                        </p>
                    </div> -->
                    <div>
                        <p>No Action set</p>
                    </div>
                </div>
                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Performance</p>
                    <!-- <div>
                        <p class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2">

                        </p>
                    </div> -->
                    <div>
                        <p>No Performance set</p>
                    </div>
                </div>
                <div class="w-4/12 my-3 text-center">
                    <p class="font-semibold">Range</p>
                    <!-- <div>
                        <p class="bg-white border border-card-200 rounded-full inline-flex px-5 py-1 m-2">

                        </p>
                    </div> -->
                    <div>
                        <p>No Range set</p>
                    </div>
                </div>       
            </div>

        </div>

        <!---->

        <div class="bg-card rounded-3xl p-5 m-10 shadow-feature flex flex-wrap justify-around">
            <h1 class="text-center text-2xl font-body font-bold m-5 w-full inline-block">
                Details
            </h1>
            <div class="w-full">
                <p class="text-lg font-bold mb-2 text-center">Description</p>
                <div class="flex justify-between flex-wrap">
                    <!-- description -->
                    <div class="w-full my-3 text-center">
                        <div></div>
                        <div>
                            <p>No Description</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- video -->
            <!-- <div class="w-full">
                <p class="text-lg font-bold mb-2 text-center">Videos</p>
                <div class="flex justify-between flex-wrap">
                    <div class="w-full my-3 text-center">
                        <div v-if="exercise.VIDEO">
                            <iframe
                                class="m-auto"
                                v-for="vid in exercise.VIDEO"
                                :key="vid"
                                width="560"
                                height="315"
                                :src="vid"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            >
                            </iframe>
                        </div>

                        <div v-else>
                            <p>No Videos</p>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="flex justify-between flex-wrap">
                <div class="flex justify-between">
                    <div class="w-4/12">
                        <p class="text-lg font-bold mb-2 text-center">Form</p>
                        <div class="flex justify-between flex-wrap">
                            <!-- form -->
                            <div class="w-full my-3 text-center">
                                <div></div>
                                <div>
                                    <p>No Form</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-4/12">
                        <p class="text-lg font-bold mb-2 text-center">Sequence</p>
                        <div class="flex justify-between flex-wrap">
                            <!-- sequence -->
                            <div class="w-full my-3 text-center">
                                <div></div>
                                <div>
                                    <p>No Sequence</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap justify-center">
            <h1 class="text-center text-2xl font-body font-bold m-5 w-4/5 inline-block">
                Related Exercises
            </h1>
            <div class="w-full md:w-1/4">
                <div class="bg-card text-text-dark rounded-3xl m-5 shadow-feature">
                    <!-- <img class="w-full rounded-t-3xl max-h-48" :src="related.img" alt="" /> -->
                    <div class="text-base p-4">
                        <a :href="'/exercise/'" class="cursor-pointer hover:underline"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        FontAwesomeIcon
    } from '@fortawesome/vue-fontawesome';

    export default {
        data() {
            return {
                selectedStart: false,
            }
        },
        components: {
            FontAwesomeIcon
        },
        methods: {
            addToRoutine() {
                alert('Add to Routine');
            },
            toggleSelectedFavorite() {
                // if (!this.selectedStart) {
                //     alert('Add to favorite');
                // } else {
                //     alert('Remove from favorite');
                // }
                this.selectedStart = !this.selectedStart;
            }
        },
    };
</script>

<style lang="scss" scoped></style>