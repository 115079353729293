<template>
    <div class="bg-white shadow-feature overflow-hidden rounded-3xl min-h-[30px] order-3 md:order-2 col-span-2 md:col-span-3 lg:col-span-4 grid grid-cols-4 grid-rows-[repeat(15,_24px)] gap-5" :style="grid">
        <div class="min-h-[30px] col-span-4 row-span-2 flex justify-center items-center" v-if="recommendation['Assessments'].length > 0">
            <h2 class="font-bold text-xl text-center">
                Recommended Assessments
            </h2>
        </div>

        <div class="min-h-[30px] col-span-4 row-span-5" v-if="recommendation['Assessments'].length > 0">
            <anima-carousel :items="recommendation['Assessments']"></anima-carousel>
        </div>

        <div class="min-h-[30px] col-span-4 row-span-2 flex justify-center items-center" v-if="recommendation['Health Checks'].length > 0">
            <h2 class="font-bold text-xl text-center">
                Recommended Health Checks
            </h2>
        </div>

        <div class="min-h-[30px] col-span-4 row-span-5" v-if="recommendation['Health Checks'].length > 0">
            <anima-carousel :items="recommendation['Health Checks']"></anima-carousel>
        </div>

        <div class="min-h-[30px] col-span-4 row-span-2 flex justify-center items-center" v-if="recommendation['Journals'].length > 0">
            <h2 class="font-bold text-xl text-center">
                Recommended Journals
            </h2>
        </div>

        <div class="min-h-[30px] col-span-4 row-span-5" v-if="recommendation['Journals'].length > 0">
            <anima-carousel :items="recommendation['Journals']"></anima-carousel>
        </div>
    </div>
</template>

<script>
    import AnimaCarousel from './AnimaCarousel.vue';

    export default {
        name: 'todo',
        components: {
            AnimaCarousel
        },
        props: {
            url: {
                type: String,
                default: ''
            },
            recommend: {
                type: String,
                default: ''
            },
            grid: {
                type: [String, Number],
                default: ''
            },
        },
        data() {
            return {
                recommendation: {
                    Assessments: [],
                    Journals: [],
                    'Health Checks': [],
                },
                numOfRecom: 0,
                error: '',
            };
        },
        async mounted() {
            const recommend = JSON.parse(this.recommend)
            this.numOfRecom = recommend.length
            recommend.forEach((item) => {
                if (item.type == 'assessment') {
                    this.recommendation.Assessments.push(item);
                } else if (item.type == 'journal') {
                    this.recommendation.Journals.push(item);
                } else {
                    this.recommendation['Health Checks'].push(item);
                }
            });
        },
        methods: {
            toggleSection(type) {
                let el = this.$refs[type][0];
                if (el.classList.contains('max-h-screen')) {
                    el.classList.remove('max-h-screen');
                } else {
                    el.classList.add('max-h-screen');
                }
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        },
    };
</script>

<style></style>