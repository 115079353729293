<template>
    <div ref="aside" @click="toggleMenu" class="bg-card-100 shadow-feature flex flex-col md:block w-full md:w-1/4 lg:w-1/6 z-[1] sticky top-0 h-[67px] overflow-scroll md:h-screen md:rounded-tr-lg mb-8 md:mb-auto">
        <div class="w-full no-underline text-text-dark text-base py-3 px-4 m-2 rounded-md md:cursor-pointer cursor-pointer md:hidden">Contents</div>
        <ul class="mb-12">
            <li class="flex" v-for="item in items">
                <a 
                    class="w-full no-underline text-text-dark text-base py-3 px-4 m-2 rounded-md md:cursor-pointer hover:bg-secondary-300 cursor-pointer  order-2" 
                    :href="'#'+slugify(item['fields']['title']['content'])"
                >
                    {{ item['fields']['title']['content'] }}
                </a>
            </li>
        </ul>
        <div ref="arrow" class="md:hidden absolute top-[23px] right-4">
            <font-awesome-icon icon="caret-right" />
        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        props: {
            items: { type: Array, required: true }
        },
        components: {
            FontAwesomeIcon 
        },
        methods: {
            slugify(str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
                str = str.toLowerCase(); // convert string to lowercase
                str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
                        .replace(/\s+/g, '-') // replace spaces with hyphens
                        .replace(/-+/g, '-'); // remove consecutive hyphens
                return str;
            },
            toggleMenu() {
                if(this.$refs['aside'].classList.contains('h-[67px]')) {
                    this.$refs['aside'].classList.remove('h-[67px]')
                    this.$refs['arrow'].classList.add('rotate-90')
                } else {
                    this.$refs['aside'].classList.add('h-[67px]')
                    this.$refs['arrow'].classList.remove('rotate-90')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>