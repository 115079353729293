<template>
    <div class="flex items-center justify-center w-full max-w-[1440px] flex-col">
        <div v-if="params.type != 'wellness'" class="w-full flex flex-col items-center">
            <!-- hidden menu -->
            <div class="p-3 w-full sticky top-0 z-[9] md:w-3/4" :class="!showFilters ? 'block' : 'hidden'">
                <div class="text-lg absolute top-1 right-0 px-2 mt-[10px] mr-3 z-20 bg-card-100 rounded-xl shadow-feature cursor-pointer">
                    <font-awesome-icon @click="showFilters = true" icon="sliders" />
                </div>
            </div>
            <!-- full menu -->
            <div ref="menu" :class="showFilters ? 'block' : 'hidden'" class=" w-full lg:w-9/12 flex justify-center flex-col p-3 rounded-b-xl lg:rounded-xl bg-card-200 sticky top-0 z-[9] shadow-feature">
                <div class="text-lg absolute top-1 pr-5 right-0 pt-[10px] z-20 cursor-pointer">
                    <font-awesome-icon icon="sliders" @click="showFilters = false"/>
                </div>

                <anima-stats-list ref="statsList" @select="changeStats" :name="params.name" :type="params.type" :url="forms_url">
                </anima-stats-list>
                <anima-date-range @date-change="setDates"></anima-date-range>
            </div>

            <div v-if='params.type === "" || params.name === ""' class="w-full lg:w-9/12 my-5"></div>
            <anima-stats v-else :url="url" :type="type" :name="params.name" :dates="dates" :date_type="date_type"></anima-stats>
        </div>
        <div v-else class="w-full flex flex-col items-center">
            <!-- hidden menu -->
            <div class="p-3 w-full sticky top-0 z-[9] md:w-3/4" :class="!showFilters ? 'block' : 'hidden'">
                <div class="text-lg absolute top-1 right-0 px-2 mt-[10px] mr-3 z-20 bg-card-100 rounded-xl shadow-feature cursor-pointer">
                    <font-awesome-icon @click="showFilters = true" icon="sliders" />
                </div>
            </div>

            <div ref="menu" :class="showFilters ? 'block' : 'hidden'" class=" w-full lg:w-9/12 flex justify-center flex-col p-3 rounded-b-xl lg:rounded-xl bg-card-200 sticky top-0 z-[9] shadow-feature">
                <div class="text-lg absolute top-1 pr-5 right-0 pt-[10px] z-20 cursor-pointer">
                    <font-awesome-icon icon="sliders" @click="showFilters = false"/>
                </div>
                <div class="flex justify-center relative">
                    <a
                        href="/stats"
                        class="absolute top-1/2 -translate-y-1/2 right-full md:right-auto left-0 pl-2 no-underline text-text-dark text-lg"
                    >
                        <font-awesome-icon icon="arrow-left" />
                    </a>
                    <select
                        class="h-11 w-2/3 md:w-1/2 sm:text-sm py-2 px-3 m-[2px] md:mx-[2px] rounded-[0.65rem] outline-primary shadow-feature font-bold"
                        name="current_category"
                        id="current_category"
                        v-model="current_category"
                        @change="changeStats(current_category)"
                    >
                        <option v-for="category in list" :key="category.id" :value="category.slug">
                            {{ category.name }}
                        </option>
                    </select>
                </div>
                <anima-date-range @date-change="setDates"></anima-date-range>
            </div>

            <anima-stats :url="url" :type="type" :name="params.name" :dates="dates" :date_type="date_type"></anima-stats>
        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import AnimaStatsList from './AnimaStatsList.vue';
    import AnimaStats from './AnimaStats.vue';
    import AnimaDateRange from './AnimaDateRange.vue';  
    export default {
        components: {
            AnimaStatsList,
            AnimaStats,
            FontAwesomeIcon,
            AnimaDateRange
        },
        props: {
            name: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: null
            },
            url: {
                type: String,
                default: null
            },
            forms_url: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                params: {
                    name: this.name,
                    type: this.type,
                },
                current_category: this.name,
                list: [{
                    id: 1,
                    slug: 'impact',
                    name: 'Impact',
                },{
                    id: 2,
                    slug: 'mood',
                    name: 'Mood',
                },{
                    id: 3,
                    slug: 'feeling',
                    name: 'Feeling',
                }],
                date_type: 'daily',
                dates: {
                    start: '',
                    end: '',
                },
                showFilters: true
            };
        },
        created() {
            this.updateUrlFromCookieAndDelete();
            window.addEventListener('beforeunload', this.leaving);
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        methods: {
            updateScroll() {
                this.scrollPosition = window.scrollY
            },
            leaving() {
                this.deleteCookie('anima_hub_stats_current_url')

                sessionStorage.removeItem('anima_current_stat')
            },
            changeStats(value) {
                if (typeof value === 'object') {
                    this.params.name = value.name;
                    this.params.type = value.type;
                } else {
                    this.params.name = value;
                }
                
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set('name', this.params.name);
                queryParams.set('type', this.params.type);
                const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
                window.history.pushState({}, '', newUrl);

                this.handleUrlChange();
            },
            setCookie(name, value, days) {
                const expires = new Date();
                expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
                document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
            },
            getCookie(name) {
                const cookies = document.cookie.split(';');
                for (let cookie of cookies) {
                    const [cookieName, cookieValue] = cookie.split('=');
                    if (cookieName.trim() === name) {
                        return decodeURIComponent(cookieValue);
                    }
                }
                return null;
            },
            deleteCookie(name) {
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
            },
            updateUrlFromCookieAndDelete() {
                const cookieUrl = this.getCookie('anima_hub_stats_current_url');
                if (cookieUrl) {
                    // Update the URL using the stored value from the cookie
                    window.history.replaceState({}, '', cookieUrl);
                    // Delete the cookie
                    this.deleteCookie('anima_hub_stats_current_url');
                }
            },
            handleUrlChange() {
                const currentUrl = window.location.href;
                this.setCookie('anima_hub_stats_current_url', currentUrl, 7); // Expires in 7 days
            },
            async setDates(dateObj) {
                this.dates = dateObj.dates; //send down to anima stats
                this.date_type = dateObj.range; //send down to anima stats
                this.$forceUpdate()
            },
        },
        watch: {
            scrollPosition(newVal, oldVal) {
                // console.log(newVal)
                // if (newVal > 500) {
                //     this.$refs['statsList'].$el.classList.add('block', 'h-[28px]', 'py-[0px]')
                // } else {
                //     this.$refs['statsList'].$el.classList.remove('block', 'h-[28px]', 'py-[0px]')
                // }
            }
        }
    };
</script>

<style scoped>
.is-pinned {
    color: red;
    background-color: green;
}
</style>