<template>
    <div>
        <input
            class="w-full border border-secondary rounded-xl resize-none py-2 px-3 mb-5 text-text-dark text-base focus-visible:outline outline-primary-dark outline-2"
            type="text"
            :name="question_id"
            :ref="question_id"
            @change="emitChange"
            v-model="value"
        />
    </div>
</template>

<script>
export default {
    props: {
        question_id: {
            type: Number,
            default: null,
        },
        preSelect: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            value: this.preSelect ? this.preSelect : ''
        }
    },
    methods: {
        emitChange(e) {
            this.$emit('change', {
                check: this.value,
                id: this.question_id,
            });
        },
    },
};
</script>

<style></style>
