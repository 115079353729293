<template>
    <div>
        <p v-if="hasTitle" class="text-center text-lg">{{ id }}</p>
        <div :class="style">
            <canvas :ref="id" :id="id"></canvas>
            <div
                class="absolute top-1/2 -translate-y-2/4 text-text-dark bg-card-200 rounded-full w-10 h-10 pointer-events-none"
            >
                <span class="text-base leading-[40px] flex justify-center items-baseline"
                    >{{ total }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, Tooltip } from 'chart.js/auto';
import chartConfig from '../../chartConfig';
import ChartDeferred from 'chartjs-plugin-deferred';

export default {
    data() {
        return {
            data_arr: [],
            label_arr: [],
            chart: '',
            style: 'py-3 px-5 flex justify-center relative lg:h-[300px] xl:h-[400px]'
        };
    },
    props: {
        id: { type: String, default: null },
        data: { type: [Object, Array], default: null },
        question_type: { type: String, default: null },
        hasTitle: { type: Boolean, default: true }
    },
    mounted() {
        if (window.location.pathname === '/stats' && this.id === 'Wellness Score') {
            this.style = 'py-3 flex justify-center relative h-[400px] w-[400px] lg:h-[300px] xl:h-[400px]'
        } else {
            this.style = 'py-3 px-5 flex justify-center relative lg:h-[300px] xl:h-[400px]'
        }

        this.data_arr = Object.values(this.data);
        this.label_arr = Object.keys(this.data);

        let width = this.$refs[this.id].parentElement.clientWidth / 2;
        Tooltip.positioners.myCustomPositioner = function () {
            let tipWidth = this.width / 2;
            return {
                x: width - tipWidth,
                y: 100000,
            };
        };
        chartConfig.options.tooltip.position = 'myCustomPositioner';

        chartConfig.options.tooltip.callbacks = {
                                label: (ctx) => {
                                    if (this.question_type != 'date_time') {
                                        return `${ctx.dataset.data[ctx.dataIndex]}`
                                    }
                                    return `${this.getDurationFromMinutes(ctx.dataset.data[ctx.dataIndex])}`;
                                }
                            },

        Chart.register(ChartDeferred);
        this.renderChart();
    },
    methods: {
        renderChart() {
            this.chart = new Chart(this.$refs[this.id], {
                type: 'polarArea',
                data: {
                    labels: this.label_arr,
                    datasets: [
                        {
                            backgroundColor: chartConfig.colors.all_colors,
                            data: this.data_arr,
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                        ...chartConfig.options,
                        // tooltip: {
                        // },
                    },
                    scales: {
                        r: {
                            ticks: {
                                backdropColor: chartConfig.colors.body.DEFAULT,
                                color: chartConfig.colors.primary.DEFAULT,
                                callback: this.question_type == 'date_time' ? function (value, index, values) {
                                    const hours = Math.floor(value / 60);
                                    return `${hours}h`
                                } : function (value, index, values) {
                                    return value
                                },
                                stepSize: this.question_type == 'date_time' ? Math.max(...this.data_arr) >= 1200 ? 240 : 120 : 2
                            },
                            max: this.question_type == 'date_time' ? Math.max(...this.data_arr) : 10,
                            min: this.question_type == 'date_time' ? Math.max(...this.data_arr) >= 1200 ? -240 : -120 : -2,
                        },
                    },
                    layout: {
                        padding: 20,
                    },
                    responsive: true,
                    aspectRatio: 1,
                    maintainAspectRatio: false,
                    cutoutPercentage: 90,
                    elements: {
                        arc: {
                            borderWidth: 0,
                        },
                    },
                },
            });
            Chart.defaults.color = '#1B3452';
        },
        getDurationFromMinutes(minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(remainingMinutes).padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
    },
    computed: {
        total() {
            if (this.question_type == 'date_time') {
                let val = this.data_arr.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                )
                return this.getDurationFromMinutes(val)
            } else {
                return this.data_arr.reduce(
                    (partialSum, a) => partialSum + a / this.data_arr.length,
                    0
                ).toFixed(1);
            }
        },
    },
    watch: {
        data: {
            handler() {
                // destroy the chart
                this.chart.destroy();
                this.data_arr = Object.values(this.data);
                this.label_arr = Object.keys(this.data);
                this.renderChart();
            },
            deep: true,
        },
    },
};
</script>

<style></style>
