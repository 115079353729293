<template>
    <div class="w-full flex justify-center text-text-dark text-base">
        <div class="w-full">
            <div v-if="type == 'bar'">
                <Stacked-Bar-Chart 
                    :id="id" 
                    :data="data" 
                    :labels="labels" 
                    :lineSteps="lineSteps" 
                    :start_date="start_date"
                    :question_type="question_type">
                </Stacked-Bar-Chart>
            </div>

            <div v-if="type == 'stacked bar'">
                <Stacked-Bar-Chart 
                    :id="id" 
                    :data="data" 
                    :labels="labels" 
                    :lineSteps="lineSteps" 
                    :start_date="start_date" 
                    :question_type="question_type"
                    stacked>
                </Stacked-Bar-Chart>
            </div>

            <div v-if="type == 'line'">
                <Line-Chart
                    :id="title"
                    :linedata="data"
                    :dates="dates"
                    :lineSteps="lineSteps"
                    :currentFilter="currentFilter"
                    :question_type="question_type"
                    :question_display="question_display"
                    :filter_type="filter_type"
                    :data_aggregation="data_aggregation"
                    :persist="persist"
                ></Line-Chart>
            </div>

            <div v-if="type == 'polar'">
                <Polar-Chart :id="id" :data="data" :hasTitle="hasTitle" :question_type="question_type"></Polar-Chart>
            </div>

            <div v-if="type == 'doughnut'">
                <Doughnut :id="id" :data="data" :title="title" :maxHeadline="maxHeadline"></Doughnut>
            </div>

            <div v-if="type == 'calendar'">
                <Calendar
                    :id="id"
                    :setdata="data"
                    :title="title"
                    :question_id="question_id"
                    :url="url"
                ></Calendar>
            </div>

            <div v-if="type == 'word cloud'">
                <div v-if="data.data.words.length > 0" class="m-auto w-5/6 p-3 mt-4 mb-12 rounded-lg bg-card-100 border-2 border-card-300 shadow-feature" ref="chart">
                    <vue-word-cloud
                        style="height: 480px; width: 100%"
                        :words="data.data.words"
                        :font-size-ratio="3"
                        font-family="sans-serif"
                        :spacing="0.25"
                    />
                </div>
                <div v-else>    
                    <p class=" p-5 mx-[20%] my-5 rounded-md bg-card-300 text-base text-center">No stats available</p>
                </div>
            </div>
            
            <div v-if="type == 'table'">
                <table-chart v-if="data.labels.length > 0" :label="data_label" :data="data"></table-chart>
                <div v-else>
                    <p class=" p-5 mx-[20%] my-5 rounded-md bg-card-300 text-base text-center">No stats available</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from './BarChart.vue';
import StackedBarChart from './StackedBarChart.vue';
import LineChart from './LineChart.vue';
import PolarChart from './PolarChart.vue';
import Doughnut from './Doughnut.vue';
import Calendar from './Calendar.vue';
import VueWordCloud from 'vuewordcloud';
import TableChart from './TableChart.vue';

export default {
    components: {
        BarChart,
        StackedBarChart,
        LineChart,
        PolarChart,
        Doughnut,
        Calendar,
        TableChart,
        [VueWordCloud.name]: VueWordCloud,
    },
    props: {
        id: { type: String, default: null },
        title: { type: String, default: null },
        type: { type: String, default: null },
        data: { type: [Object, Array], default: null },
        dates: { type: Object, default: null },
        lineSteps: { type: Object, default: null },
        url: { type: String, default: null },
        question_type: { type: String, default: null },
        question_display: { type: String, default: null },
        question_id: { type: Number, default: null },
        labels: { type: Array, default: null },
        start_date: { type: String, default: null },
        data_label: { type: String, default: null },
        currentFilter: { type: String, default: null },
        filter_type: { type: String, default: null },
        data_aggregation: { type: String, default: null },
        maxHeadline: { type: Number, default: null },
        hasTitle: { type: Boolean, default: true },
        persist: { type: Boolean, default: false }
    },
    watch: {
        data: {
            handler() {
                this.$forceUpdate()
            },
            deep: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            // if (this.type == 'word cloud') {
            //     console.log(this.$refs['chart'].firstChild.firstChild.clientHeight)
            //     console.log(this.$refs['chart'].firstChild.firstChild.scrollHeight)
            // }
        })
    }
};
</script>

<style></style>
