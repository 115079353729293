<template>
    <div class="my-6">
        <table
            class="table-fixed bg-white w-full border-collapse border border-slate-400 rounded-md overflow-hidden"
        >
            <thead class="bg-primary text-primary-500">
                <tr>
                    <th
                        class="text-left p-4 font-semibold"
                        v-for="(column, idx) in filterVisibleColumns(columns)"
                        :class="{
                            'w-2/5': idx === 0,
                            'table__sortable js-table__sortable': column.sortable,
                            asc:
                                column.id === sort_state.column_id &&
                                sort_state.direction === 'asc',
                            desc:
                                column.id === sort_state.column_id &&
                                sort_state.direction === 'desc',
                        }"
                        v-on="column.sortable ? { click: () => sortColumn(column.id, idx) } : {}"
                        :key="idx"
                        :data-column="column.id"
                    >
                        {{ column.title | capitalize }}
                    </th>
                </tr>
            </thead>

            <tbody class="text-gray-500 bg-text">
                <tr v-for="(row, idx) in rows" :key="idx">
                    <td
                        v-for="(value, column_id) in getRowObject(row, true)"
                        :key="column_id"
                        class="break-all text-left py-6 px-4"
                        :data-column="column_id"
                    >
                        <slot :name="column_id" v-bind:row="getRowObject(row, false)">{{
                            value
                        }}</slot>
                    </td>
                </tr>
            </tbody>
        </table>

        <anima-pagination v-if="pagination" :pages="pages" v-model="active_page"></anima-pagination>
    </div>
</template>

<script>
import animaPagination from './AnimaPagination.vue';
export default {
    components: { animaPagination },
    data() {
        return {
            active_page: 1,
            /**
             * The default options for a column
             * These can all be overridden in the table prop for each column
             */
            column_defaults: {
                /** A unique snake_case name for the column, this is generated if left blank */
                id: '',
                /** The column heading */
                title: '',
                /** Client-side sorting */
                sortable: false,
                /** Show/hide this column */
                visible: true,
            },
            sort_state: {
                /** The slug_name id of the column */
                column_id: null,
                /** Sorting direction asc|desc */
                direction: 'asc',
                /** The column index to be sorted */
                column_num: null,
            },
        };
    },
    props: {
        table: { type: Object, default: null },
        pagination: { type: Number, default: null },
    },
    methods: {
        /**
         * Get the column settings by the column/row array index
         * @param idx Integer
         * @returns String|null
         */
        getColByIdx(idx) {
            return this.columns[idx];
        },
        /**
         * Get the column title by the column/row array index
         * @param idx Integer
         * @returns String|null
         */
        getColId(idx) {
            const column = this.getColByIdx(idx);
            return column ? column['id'] : null;
        },
        /**
         * Filter out columns where visible is set to false
         * @param columns Array
         * @returns []
         */
        filterVisibleColumns(columns) {
            const visible_columns = [];
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                if (column['visible']) {
                    visible_columns.push(column);
                }
            }
            return visible_columns;
        },
        /**
         * Convert row array into keyed object (by column ID)
         * @param row Array
         * @param filter_visible
         * @returns {{}}
         */
        getRowObject(row, filter_visible = false) {
            // Get list of columns names which are visible
            const visible_columns = this.filterVisibleColumns(this.columns);
            const visible_column_ids = [];
            for (let i = 0; i < visible_columns.length; i++) {
                visible_column_ids.push(visible_columns[i]['id']);
            }

            const row_obj = {};

            for (let i = 0; i < row.length; i++) {
                const column_id = this.getColId(i);

                if (filter_visible && !visible_column_ids.includes(column_id)) {
                    continue;
                }
                row_obj[column_id] = row[i];
            }

            return row_obj;
        },
        /**
         * If a column ID is not provided this function will create
         * a snake_case version of the name
         * E.g. "This is my cool :) title!" becomes "this_is_my_cool_title"
         */
        createIdFromTitle(title) {
            // Make lower case
            let id = title.toLowerCase();

            // Replace non-alphanumeric characters with underscores
            id = id.replace(/[^a-z0-9]/, '_');

            // Remove any repeated underscores (2 or more)
            id = id.replace(/_{2,}/, '_');

            // Trim underscores from beginning and end
            id = id.replace(/(^_|_$)/, '');

            return id;
        },
        /**
         * Set the sort state and emit sort event
         */
        sortColumn(column_id, idx) {
            // If the table is already sorted by the column
            // toggle the direction instead
            if (this.sort_state.column_id === column_id) {
                this.sort_state.direction = this.sort_state.direction === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort_state.column_id = column_id;
            }
            this.sort_state.column_num = idx;

            // Emit an event so parent can react if fetching data serverside
            this.$emit('sort', this.sort_state);
        },
        /**
         * Mutate the order of the rows
         */
        sortRows(rows) {
            const column_id = this.sort_state.column_id;

            // If the column type is date a certain type of sort is needed
            if (column_id == 'date') {
                return rows.sort(this.dateSort);
            } else {
                return rows.sort(this.stringSort);
            }
        },
        /**
         * Sort rows by a certain column
         */
        stringSort(a, b) {
            const column_num = this.sort_state.column_num;
            return this.sort_state.direction == 'asc'
                ? a[column_num] > b[column_num]
                    ? 1
                    : -1
                : a[column_num] > b[column_num]
                ? -1
                : 1;
        },
        /**
         * Sort rows by a certain type if the column type is a date
         */
        dateSort(a, b) {
            const column_num = this.sort_state.column_num;
            a = a[column_num].split('/').reverse().join('');
            b = b[column_num].split('/').reverse().join('');
            return this.sort_state.direction == 'asc'
                ? a > b
                    ? 1
                    : a < b
                    ? -1
                    : 0
                : a > b
                ? -1
                : a < b
                ? 1
                : 0;
        },
    },
    computed: {
        // Store columns and rows as computed so we can mutate/reformat the data if needed
        /**
         * The table columns merged with default properties
         * @returns []
         */
        columns() {
            const columns = [];
            if (!this.table.columns) {
                return columns;
            }
            for (let i = 0; i < this.table.columns.length; i++) {
                // Merge default options and column settings
                const column = Object.assign({}, this.column_defaults, this.table.columns[i]);

                // Create snake_case ID if one was not assigned
                if (column.id === '') {
                    column.id = this.createIdFromTitle(column.title);
                }
                columns.push(column);
            }
            return columns;
        },
        /**
         * A sorted and paginated set of rows
         * @returns []
         */
        rows() {
            let rows = this.table.rows;

            // Sort the rows based on the sort_state
            if (this.sort_state.column_id) {
                rows = this.sortRows(rows);
            }

            // Return a slice of the rows if pagination is set
            if (this.pagination) {
                const start = this.pagination * (this.active_page - 1);
                const end = start + this.pagination;
                return rows.slice(start, end);
            }

            // Return all table rows
            return rows;
        },
        pageIndex() {
            return this.active_page;
        },
        pages() {
            return this.pagination != null
                ? Math.ceil(this.table.rows.length / this.pagination)
                : 1;
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style scoped>
.table__sortable {
    cursor: pointer;
}

.table__sortable:before {
    content: '\f0dc';
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
}

.table__sortable .asc:before {
    content: '\f0dd';
}

.table__sortable .desc:before {
    content: '\f0de';
}
</style>
