<template>
    <div>
        <div v-for="answer in answers" :key="answer.id" class="block my-3">
            <label>
                <input
                    class="opacity-0 absolute cursor-pointer peer"
                    type="radio"
                    :ref="question_id"
                    @change="emitChange(answer.name)"
                    :name="question_id"
                    :id="question_id"
                    :value="answer.id"
                    :aria-label="answer.name"
                    :data-attributes="answer.name"
                />
                <div
                    class="bg-white border rounded-full border-secondary w-5 h-5 inline-flex flex-shrink-0 justify-center items-center mr-2 cursor-pointer peer-checked:[&>span]:bg-primary peer-checked:[&>span]:border-primary peer-checked:border-primary"
                >
                    <span
                        class="bg-white rounded-full border-secondary w-3 h-3 flex flex-shrink-0 justify-center items-center"
                    ></span>
                </div>
                <span class="text-base text-text-dark cursor-pointer">{{
                    answer.name | capitalize
                }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        answers: {
            type: Array,
            default: null,
        },
        question_id: {
            type: [Number, String],
            default: null,
        },
    },
    methods: {
        emitChange(value) {
            this.$emit('change', {
                check: value,
                id: this.question_id,
            });
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>

<style></style>
